import { styled } from "@mui/material";
import React from "react";
import { SynckitColors } from "../../../assets/SynckitColors";

import Schedule from "./Schedule";

const Root = styled("div")(({ theme }) => ({
  // maxWidth: 708,
  // minHeight: "fit-content",
  // background: "white",
  // filter: SynckitColors.Shadow[1],
  padding: 10,
}));

export default function SchedulePanel() {
  return (
    <Root>
      <Schedule />
    </Root>
  );
}

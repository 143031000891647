import React, { useState } from "react";
import { Typography, Checkbox, IconButton, Grid } from "@mui/material";
import { Box, styled } from "@mui/system";
import { SynckitColors } from "../../../../../assets/SynckitColors";
import ImgDemoCR from "../../../../../assets/images/demo/CompareReport.jpg";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import {
  IconEditLine,
  IconTrash2Line,
} from "../../../../../assets/SynckitIcons";
import CloseIcon from "@mui/icons-material/Close";
import SynckitSearchBar2 from "../../../../../components/SynckitSearchBar2";

const TitleWrap = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: 60,
});

const ToolBar = styled("div")(({ theme }) => ({
  padding: "16px 24px",
  background: SynckitColors.Gray[200],
  display: "flex",
  justifyContent: "space-between",
}));

const ReportCard = styled("div")(({ theme }) => ({
  minWidth: 220,
  background: "white",
  boxShadow: SynckitColors.Shadow[3],
  borderRadius: 12,
  padding: 20,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

export default function BrowseReportList() {
  const [editMode, setEditMode] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  return (
    <Box>
      <TitleWrap>
        <Typography variant="h5">Browse report</Typography>
      </TitleWrap>
      <SynckitSearchBar2 />
      <ToolBar>
        {editMode ? (
          <>
            <Checkbox />
            <Box>
              <IconButton>
                <IconTrash2Line />
              </IconButton>
              <IconButton onClick={() => setEditMode(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </>
        ) : (
          <>
            {selectedId === null ? (
              <span>　</span>
            ) : (
              <IconButton onClick={() => setSelectedId(null)}>
                <ArrowBackIosNewIcon />
              </IconButton>
            )}

            <IconButton onClick={() => setEditMode(true)}>
              <IconEditLine />
            </IconButton>
          </>
        )}
      </ToolBar>
      <br />
      {selectedId === null ? (
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="center"
          alignContent="center"
        >
          {Array.from(Array(5), (e, i) => (
            <Grid item sm={12} md={6} key={i} onClick={() => setSelectedId(i)}>
              {recordCardRender(editMode)}
            </Grid>
          ))}
        </Grid>
      ) : (
        recordCardRender(editMode)
      )}
    </Box>
  );
}

const recordCardRender = ({ editMode }) => (
  <ReportCard>
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
      }}
    >
      {editMode && <Checkbox />}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            height: 28,
          }}
        >
          <Typography variant="body2" sx={{ color: SynckitColors.Gray[800] }}>
            2021.10.22
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: SynckitColors.Gray["core"] }}
          >
            121241
          </Typography>
        </Box>
        <Typography variant="h5">Single Report</Typography>
      </Box>
    </Box>
    <br />

    <img src={ImgDemoCR} alt="" style={{ width: "100%" }} />
  </ReportCard>
);

import { styled, IconButton, Typography, Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import VideoDemo1 from "../../../../../../assets/demo/yorkie1.mp4";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { CR_MODE_PREVIEW_VIDEO_DEFAULT } from "../../../../../../assets/constant";
import _ from "lodash";

const imgWidth = 192;
const imgHeight = 108;
const Root = styled("div")(({ theme }) => ({
  width: 775,
  height: 458,
  borderRadius: 7,
  overflow: "hidden",
  position: "relative",
  marginBottom: 24,
}));

const SelectButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "0.3em",
  right: "0.3em",
  zIndex: 10,
}));

const SelectButton2 = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  bottom: "3.6em",
  zIndex: 10,
}));

const VideoBox = styled("video")(({ theme }) => ({
  height: "100%",
  objectFit: "contain",
}));

const TrackWrap = styled("div")(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  width: "100%",
  height: 145,
  padding: "20px 24px",
  background: "rgba(37, 40, 43, 0.2)",
  zIndex: 10,
}));

const PlayButtonWrap = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  textAlign: "center",
  zIndex: 10,
}));
const ThumbnailBox = styled("div")(({ theme }) => ({
  display: "flex",
  borderRadius: 8,
  // overflow: "hidden",

  "& figure": {
    opacity: 0.6,
    transition: "0.3s",
    position: "relative",
    "&:hover": {
      opacity: 1,
    },
    "&.selected": {
      opacity: 1,
      "&::before": {
        position: "absolute",
        content: "''",
        width: imgWidth,
        height: imgHeight,
        border: "6px solid white",
        zIndex: 99,
        top: -6,
        left: -6,
      },
    },
  },
}));
const ThumbnailIndex = styled("div")(({ theme }) => ({
  position: "absolute",
  bottom: 12,
  width: imgWidth + 5,
  height: imgHeight + 11,
  zIndex: 12,
  border: "6px solid rgba(255,255,255,0)",
  transition: "0.3s",
  "& .MuiButtonBase-root": {
    display: "none",
  },
  "&:hover": { border: "6px solid rgba(255,255,255,0.7)" },
  "&.selected": {
    border: "6px solid rgba(255,255,255,1)",
    "& .MuiButtonBase-root": {
      display: "block",
    },
  },
}));

const SelectButton3 = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  bottom: 64,
  right: 0,
  zIndex: 10,
}));

export default function VideoEditorPlay({
  setDisplayMode,
  selectedImgList,
  setSelectedImgList,
}) {
  const [isPlay, setIsPlay] = useState(true);
  const [count, setCount] = useState(0);
  const [selectedThumbnailIndex, setSelectedThumbnailIndex] = useState(null);

  const videoRef = useRef();

  useEffect(() => {
    let playInterval = null;
    setSelectedThumbnailIndex(null);
    if (isPlay && count > 138) {
      setCount(0);
      setIsPlay(false);
    }

    if (isPlay) {
      playInterval = setInterval(() => {
        videoRef.current.currentTime = count * 0.1;
        setCount((count) => count + 1);
      }, 100);
    } else if (!isPlay && count !== 0) {
      clearInterval(playInterval);
    }
    return () => clearInterval(playInterval);
  }, [isPlay, count]);
  const playVideo = (_count) => {
    videoRef.current.currentTime = _count * 0.1; //以一秒十張圖的速度
    let tempCount = _count + 1;
    if (tempCount < 140) {
      setTimeout(() => {
        setCount(tempCount);
        playVideo(tempCount);
      }, 100);
    } else {
      videoRef.current.currentTime = 0;
      setCount(0);
    }
  };

  const handleSelectImg = (_img) => {
    let tempList = [];
    if (_.filter(selectedImgList, { imgUrl: _img }).length)
      //再次選擇 則取消選取
      tempList = selectedImgList.filter((item) => item.imgUrl !== _img);
    else {
      tempList = selectedImgList;
      tempList.push({ imgUrl: _img });
    }

    setSelectedImgList([...tempList]);
  };
  return (
    <Root>
      <IconButton
        sx={{ position: "absolute", top: "1em", left: "1em", zIndex: 20 }}
        onClick={() => setDisplayMode(CR_MODE_PREVIEW_VIDEO_DEFAULT)}
      >
        <ArrowBackIosNewIcon sx={{ color: "white" }} />
      </IconButton>
      {Array.from(Array(140 - 3), (e, index) => (
        // <SelectButton onClick={() => setIsSelected(!isSelected)}>
        <SelectButton
          onClick={() =>
            handleSelectImg(`/assets/demo/yorkie1/thumbnail${index}.jpg`)
          }
          sx={{ display: selectedThumbnailIndex === index ? "block" : "none" }}
        >
          {_.filter(selectedImgList, {
            imgUrl: `/assets/demo/yorkie1/thumbnail${index}.jpg`,
          }).length ? (
            <CheckCircleIcon color="primary" fontSize="large" />
          ) : (
            <CircleOutlinedIcon sx={{ color: "white" }} fontSize="large" />
          )}
        </SelectButton>
      ))}
      <PlayButtonWrap>
        {isPlay ? (
          <Box>
            <IconButton
              onClick={() => {
                // videoRef.current.pause();
                setIsPlay(false);
              }}
            >
              <PauseCircleIcon
                sx={{ width: 100, height: 100, color: "white" }}
              />
            </IconButton>
            <Typography variant="subtitle2" sx={{ color: "white" }}>
              {"　"}
            </Typography>
          </Box>
        ) : (
          <Box>
            <IconButton onClick={() => setIsPlay(true)}>
              <PlayCircleIcon
                sx={{ width: 100, height: 100, color: "white" }}
              />
            </IconButton>

            <Typography variant="subtitle2" sx={{ color: "white" }}>
              Play to choose an image for your video
            </Typography>
          </Box>
        )}
      </PlayButtonWrap>
      <TrackWrap>
        <ThumbnailBox
          sx={{
            width: 140 * imgWidth,
            transform: `translateX(-${(count - 2) * imgWidth}px)`,
          }}
        >
          {Array.from(Array(147 - 3), (e, i) => (
            <figure
              key={i}
              className={selectedThumbnailIndex === i ? "selected" : null}
              onClick={() => {
                setSelectedThumbnailIndex(i);
              }}
            >
              <img
                key={i}
                src={`/assets/demo/yorkie1/thumbnail${i}.jpg`}
                alt=""
                width={imgWidth}
              />
            </figure>
          ))}
        </ThumbnailBox>
      </TrackWrap>
      {/* {Array.from(Array(140 - 3), (e, i) => (
        <ThumbnailIndex
          className={selectedThumbnailIndex === i ? "selected" : null}
          sx={{
            left: i * imgWidth + 58,
            zIndex: isPlay ? 0 : 10,
            transform: `translateX(-${(count - 2) * imgWidth}px)`,
          }} //沒有在播放video時才能選取截圖畫面(用zIndex來設置)
          onClick={() => {
            console.log("i:", i);
            setSelectedThumbnailIndex(i);
          }}
        >
          <SelectButton3
            onClick={() => {
              setIsSelected(!isSelected);
              handleSelectImg(`/assets/demo/yorkie1/thumbnail${i + 1}.jpg`);
            }}
          >
            {isSelected ? (
              <CheckCircleIcon color="primary" />
            ) : (
              <CircleOutlinedIcon sx={{ color: "white" }} />
            )}
          </SelectButton3>
        </ThumbnailIndex>
      ))} */}
      {/* {Array.from(Array(140 - 3), (e, i) => (
        <SelectButton2
          onClick={() => setIsSelected(!isSelected)}
          sx={{ left: i * imgWidth + 60 }}
        >
          {isSelected ? (
            <CheckCircleIcon color="primary" />
          ) : (
            <CircleOutlinedIcon sx={{ color: "white" }} />
          )}
        </SelectButton2>
      ))} */}
      {/* <PreviewImg src={ImgDemo1} /> */}

      <VideoBox ref={videoRef} muted>
        <source src={VideoDemo1} type="video/mp4" />
      </VideoBox>
    </Root>
  );
}

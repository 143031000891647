import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from "@mui/material";
import React from "react";
import { SynckitColors } from "../../../assets/SynckitColors";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";

const LabelList = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: 72,
  width: 500,
  [theme.breakpoints.down("md")]: {
    width: 350,
  },
  [theme.breakpoints.down("sm")]: {
    width: 230,
  },
}));
export default function PointsPurchaeList({ setPointsMode }) {
  return (
    <div>
      <Button
        sx={{ color: SynckitColors.Gray["core"], mb: 2 }}
        startIcon={<ArrowBackIosIcon />}
        onClick={() => setPointsMode("default")}
      >
        <Typography variant="subtitle1"> {"Points Purchase"}</Typography>
      </Button>
      <br />
      <FormControl sx={{ width: "100%" }}>
        <RadioGroup>
          {pointsPurchaseList.map((item, index) => (
            <FormControlLabel
              value={item.id}
              key={index}
              control={<Radio />}
              label={
                <LabelList>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      ml: 2,
                    }}
                  >
                    <PaidOutlinedIcon
                      sx={{
                        color:
                          item.type === "Unlimited"
                            ? SynckitColors.Orange[600]
                            : SynckitColors.Primary[600],
                        width: 20,
                        height: 20,
                        mr: 1,
                      }}
                    />
                    <Typography variant="h6">{item.point}</Typography>
                  </Box>
                  <Typography variant="body2">{item.type}</Typography>
                  <Typography variant="subtitle1">{item.price}</Typography>
                </LabelList>
              }
              sx={{
                boxShadow: SynckitColors.Shadow["ButtomDivider"],
              }}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <Box sx={{ mt: 5, textAlign: "center" }}>
        <Button
          variant={"secondary"}
          sx={{ width: 116 }}
          onClick={() => setPointsMode("default")}
        >
          Back
        </Button>
        {"　"}
        <Button variant={"primary"} sx={{ width: 116 }}>
          Buy points
        </Button>
      </Box>
    </div>
  );
}

const pointsPurchaseList = [
  { id: 1, point: 10, type: "Unlimited", price: "$100" },
  { id: 2, point: 20, type: "Unlimited", price: "$200" },
  { id: 3, point: 30, type: "Unlimited", price: "$300" },
  { id: 4, point: 50, type: "3 month", price: "$500" },
  { id: 5, point: 60, type: "3 month", price: "$600" },
  { id: 6, point: 70, type: "3 month", price: "$700" },
];

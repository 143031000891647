import { IconButton } from "@mui/material";
import { styled } from "@mui/system";
import Button from "@mui/material/Button";
import React, { useContext, useEffect } from "react";
import DemoConsultationPatient from "../../../assets/demo/consultation_patient.png";
import VideocamIcon from "@mui/icons-material/Videocam";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import MicOffIcon from "@mui/icons-material/MicOff";
import CropFreeIcon from "@mui/icons-material/CropFree";
import { ConsultContext } from "../ConsultIndex";
import {
  OPERATION_VIEW_DEFAULT,
  OPERATION_VIEW_VIDEO,
} from "../../../assets/constant";
import { setBtData } from "../../../redux/slice/ws.slice";
import { useDispatch } from "react-redux";

const Root = styled("div")(({ theme }) => ({
  width: 410,
  height: 236,
  borderRadius: 8,
  overflow: "hidden",
  position: "relative",
  background: "black",
}));
const ButtonWrap = styled("div")(({ theme }) => ({
  position: "absolute",
  right: 12,
  bottom: 10,
  height: 216,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
}));

const FunctionButton = styled(IconButton)({
  background: "rgba(255, 255, 255, 0.5)",
  width: 48,
  height: 48,
});

var g_lastTemperatureTimestamp = null;
var g_roomId = null;
var g_localMediaStream = null;
var g_mapTrackHandled = {};
const URL_BASE = "synckit2.viuto-aiot.com";

export default function PatientView(props) {
  const { operationView, currentExamItem } = useContext(ConsultContext);
  const dispatch = useDispatch();

  const Root = styled("div")(({ theme }) => ({
    width: 410,
    height: 480,
    borderRadius: 8,
    overflow: "hidden",
    position: "relative",
    background: "black",
    pointerEvents: operationView === OPERATION_VIEW_DEFAULT ? "none" : "",
  }));

  const fetchTemperature = () => {
    var dataJSON = {};
    //
    dataJSON["roomId"] = g_roomId;
    //
    var dataUrl = "https://" + URL_BASE + "/SyncKitServer/GetTemperature";
    // 樓下有效代理
    // var dataUrl = "/SyncKitServer/GetTemperature";
    var xhr = new XMLHttpRequest();
    xhr.open("POST", dataUrl, true);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.send(JSON.stringify(dataJSON));
    xhr.onload = function () {
      var data = JSON.parse(this.responseText);
      //
      var now = Date.now();
      //
      if (g_lastTemperatureTimestamp == null) {
        if (data && data.temperature) {
          g_lastTemperatureTimestamp = data.timestamp;
          //
          var lastTimestamp = Date.parse(g_lastTemperatureTimestamp);
          if (now - lastTimestamp <= 30000)
            //直接存在redux 在找到tem 取出來
            dispatch(setBtData(data.temperature));
          // alert(
          //   "Temperature: " +
          //     data.temperature +
          //     "\n" +
          //     g_lastTemperatureTimestamp
          // );
        }
      } else {
        if (data && data.temperature) {
          if (g_lastTemperatureTimestamp != data.timestamp) {
            g_lastTemperatureTimestamp = data.timestamp;
            var lastTimestamp = Date.parse(g_lastTemperatureTimestamp);
            if (now - lastTimestamp <= 30000)
              dispatch(setBtData(data.temperature));
            // alert(
            //   "Temperature: " +
            //     data.temperature +
            //     "\n" +
            //     g_lastTemperatureTimestamp
            // );
          }
        }
      }
    };
  };

  const handleVideo = () => {
    console.log("handleVideo.20230104");
    g_roomId = props.roomID;
    //
    console.log("currentExamItem: "+currentExamItem);
    if( currentExamItem == 2 ) // BT temperature
    {
      document.getElementById("temperatureData").style.visibility = "visible";
      setInterval(fetchTemperature, 3000);
    }
    else
    {
      document.getElementById("temperatureData").style.visibility = "hidden";
    }
    //
    document.getElementById("remoteVideoDoctor").innerHTML = "";
    document.getElementById("remoteVideos").innerHTML = "";
    document.getElementById("localVideo").innerHTML = "";
    //
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: false })
      .then((stream) => {
        const _config = {
          iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
        };
        console.log("navigator.mediaDevices.getUserMedia");
        let _pc = new RTCPeerConnection(_config);
        let inboundStream = null;
        let localVideoEl = document.getElementById("localVideo");
        localVideoEl.srcObject = stream;
        localVideoEl.autoplay = "true";
        localVideoEl.controls = "true";
        //
        var tracks = stream.getTracks();
        for(var x=0;x<tracks.length;x++)
          console.log("local video label["+x+"]: "+tracks[x].label);
        // var urlPath = window.location.pathname;
        // var param = window.location.search;
        // var roomID = "bb56abdf-1fc1-4dbf-bb22-a90e30f9abe0";
        // if (urlPath == "/room")
        // {
        // 	roomID = param.replace("?", "");
        // }
        // console.log("roomID="+roomID);
        //
        localVideoEl.addEventListener("loadedmetadata", function () {
          console.log(
            "Local video videoWidth.1: " +
              this.videoWidth +
              "px,  videoHeight: " +
              this.videoHeight +
              "px"
          );
        });
        //
        g_localMediaStream = stream;
        console.log("roomID=" + props.roomID);

        stream.getTracks().forEach((track) => _pc.addTrack(track, stream));
        var _url =
          "wss://" + URL_BASE + "/v1/app/webrtc/room/" + props.roomID + "/ws";
        //"wss://test.viuto-aiot.com/room/bb56abdf-1fc1-4dbf-bb22-a90e30f9abe0/webSocket";
        let _ws = new WebSocket(_url);
        _pc.onicecandidate = (_e) => {
          if (!_e.candidate) return;
          else
            _ws.send(
              JSON.stringify({
                event: "candidate",
                data: JSON.stringify(_e.candidate),
              })
            );
        };
        var qidx = 0;
        var label = "";
        var trackArr = [];
        var trackVideo = [];
        // Qing
        console.log("qontrack.idx=" + qidx);
        g_mapTrackHandled = {};
        document.getElementById("remoteVideos").innerHTML = "";
        _pc.ontrack = function (event) {
          console.log("ontrack.idx=" + qidx);
          console.log("g_mapTrackHandled["+qidx+"]="+g_mapTrackHandled[qidx]);
          if( !g_mapTrackHandled[qidx] )
          {
            g_mapTrackHandled[qidx] = true;
            console.log("g_mapTrackHandled["+qidx+"].set");
          }
          else
          {
            console.log("g_mapTrackHandled["+qidx+"].ignore");
            return;
          }
          qidx++;

          /*
			if (event.streams && event.streams[0])
			{
			  let el = document.createElement(event.track.kind);
			  el.srcObject = event.streams[0];
			  el.autoplay = true;
			  el.controls = true;
			  document.getElementById('remoteVideos').appendChild(el);
			}
			else
			{
			  if (!inboundStream)
			  {
          inboundStream = new MediaStream();
          //
          let el = document.createElement(event.track.kind);
          el.srcObject = inboundStream;
          document.getElementById('remoteVideos').appendChild(el);
			  }
			  inboundStream.addTrack(event.track);
			}
      */
          var q_srcObj = null;
          var q_fAudio = false;
          if (event.streams && event.streams[0]) {
            console.log("case1");
            q_srcObj = event.streams[0];
            q_fAudio = false;
          } else {
            console.log("case2");
            if (!inboundStream) {
              inboundStream = new MediaStream();
              //
              q_srcObj = inboundStream;
              q_fAudio = true;
            }
            inboundStream.addTrack(event.track);
          }

          label = event.track.label;
          console.log("idx=" + qidx);
          console.log("length=" + event.streams.length);
          console.log("label=" + label);
          console.log("trackCount=" + event.streams[0].getVideoTracks().length);
          //

          if (trackArr.indexOf(label) == -1) {
            trackArr.push(label);
            trackVideo.push(event.streams[0]);
          }

          console.log("arrLenght=" + trackArr.length);
          console.log("arr=" + trackArr.toString());

          let el = document.createElement(event.track.kind);
          let elRtsp = document.createElement(event.track.kind);
          let elDef = document.createElement(event.track.kind);

          var videoDoctor = document.getElementById("remoteVideoDoctor");
          var fSwitch = false;
          //
          for (var i = 0; i < trackArr.length; i++) {
            if (trackArr[i] != undefined) {
              console.log("arr[" + i + "]=" + trackArr[i]);
              console.log("default video");
              q_fAudio = false;
              if (trackVideo[i].getVideoTracks().length <= 0) q_fAudio = true;
              q_srcObj = trackVideo[i];
              if (i == 0) {
                console.log(
                  "arr[" + i + "]=" + trackArr[i] + " -> remoteVideoDoctor"
                );
                // Qing
                //videoDoctor.srcObject = trackVideo[i]
                videoDoctor.srcObject = q_srcObj;
                if (q_fAudio == false) {
                  videoDoctor.autoplay = true;
                  videoDoctor.controls = true;
                }
              } else {
                console.log("arr[" + i + "]=" + trackArr[i] + " -> elDef");
                // Qing
                //elDef.srcObject = trackVideo[i]
                elDef.srcObject = q_srcObj;
                if (q_fAudio == false) {
                  elDef.autoplay = true;
                  elDef.controls = true;
                  elDef.width = "640";
                  elDef.height = "480";
                } else {
                  elDef.autoplay = true;
                  elDef.controls = true;
                }
                //
                //console.log("remoteVideos.append");
                //document.getElementById("remoteVideos").appendChild(elDef);
                // 在 metadata load 之後再加入
              }
            }
          }
          videoDoctor.addEventListener("loadedmetadata", function () {
            console.log(
              "Remote video videoWidth.1: " +
                this.videoWidth +
                "px,  videoHeight: " +
                this.videoHeight +
                "px"
            );
            if (this.videoHeight > 0) {
              console.log("this.videoHeight > 0");
              //
              if( this.videoWidth < this.videoHeight )
              {
                console.log("set g_videoDoctorSrcObj");
                if( document.getElementById("remoteVideoDoctor").srcObject != videoDoctor.srcObject )
                  document.getElementById("remoteVideoDoctor").srcObject = videoDoctor.srcObject;
              }
              //
              var w = (this.videoHeight * 960) / 544;
              var w2 = (this.videoHeight * 960) / 540;
              console.log("this.videoWidth: "+this.videoWidth+" w: "+w+" w2: "+w2);
              //if ( q == 13 && fSwitch == false) {
              if ( (this.videoWidth == w || this.videoWidth == w2) && fSwitch == false) {
                console.log("set!");
                var tmp = elDef.srcObject;
                if( tmp )
                {
                  elDef.srcObject = videoDoctor.srcObject;
                  videoDoctor.srcObject = tmp;
                  console.log("switch!");
                  fSwitch = true;
                }
                else
                {
                  console.log("null temp");
                }
              } else {
                console.log("do nothing!");
              }
            } else {
              console.log("this.videoHeight = 0");
            }
          });
          //
          console.log("addEventListener.2");
          elDef.addEventListener("loadedmetadata", function () {
            console.log(
              "Remote video videoWidth.2: " +
                this.videoWidth +
                "px,  videoHeight: " +
                this.videoHeight +
                "px"
            );
            if (this.videoHeight > 0) {
              console.log("this.videoHeight > 0");
              var w = (this.videoHeight * 960) / 544;
              var w2 = (this.videoHeight * 960) / 540;
              console.log("this.videoWidth: "+this.videoWidth+" w: "+w+" w2: "+w2);
              if (this.videoWidth == w || this.videoWidth == w2)
              //if ( q == 13)
              {
                if( elDef.parentNode != null )
                {
                  console.log("remove!");
                  elDef.parentNode.removeChild(elDef);
                }
                else
                {
                  console.log("append");
                  document.getElementById("remoteVideos").appendChild(elDef);
                }
              }
              else
              {
                if( this.videoWidth < this.videoHeight )
                {
                  console.log("set g_videoDoctorSrcObj");
                  if( document.getElementById("remoteVideoDoctor").srcObject != elDef.srcObject )
                    document.getElementById("remoteVideoDoctor").srcObject = elDef.srcObject;
                }
              }
            } else {
              console.log("this.videoHeight = 0");
            }
          });

          event.track.onmute = function (event) {
            el.play();
            elRtsp.play();
            elDef.play();
          };

          event.streams[0].onremovetrack = ({ track }) => {
            if (el.parentNode) {
              el.parentNode.removeChild(el);
            }
            if (elRtsp.parentNode) {
              elRtsp.parentNode.removeChild(elRtsp);
            }
            if (elDef.parentNode) {
              elDef.parentNode.removeChild(elDef);
            }
          };
        };
        _ws.onopen = (_e) => {
          _ws.send(
            JSON.stringify({
              event: "token",
              data: {
                authorization:
                  "Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NDE3MjAxOTYsInRva2VuX3R5cGUiOiJyZWZyZXNoIiwidXNlcl9pZCI6MjZ9.hxjn0rdrf9DMf49OibnmklSV6vOEySvjJdbgegO-nVwI6GJluju4q7R_m_Lkle1d41BHPevlfaRNBNDxmJeS8Q",
              },
            })
          );
        };
        _ws.onclose = (_e) => {
          alert("WebSocket has closed!");
        };
        _ws.onerror = (_e) => {
          console.log("WebSocket Error:" + _e.data);
        };
        _ws.onmessage = (_e) => {
          if (_e.data === "keep-alive") return;
          let _msg = JSON.parse(_e.data);
          if (!_msg) console.log("Failed to parse WebSocket msg!");

          switch (_msg.event) {
            case "offer":
              let _offer = JSON.parse(_msg.data);
              if (!_offer) console.log("Failed to parse WebSocket answer");
              _pc.setRemoteDescription(_offer);
              _pc.createAnswer().then((_answer) => {
                _pc.setLocalDescription(_answer);
                _ws.send(
                  JSON.stringify({
                    event: "answer",
                    data: JSON.stringify(_answer),
                  })
                );
              });
              break;
            case "candidate":
              let _candidate = JSON.parse(_msg.data);
              if (!_candidate)
                console.log("Failed to parse WebSocket candidate");
              _pc.addIceCandidate(_candidate);
              break;
            default:
              break;
          }
        };
      })
      .catch((error) => console.log("error for  navigator.mediaDevices!"));
  };

  function beforeUnloadPage(e)
  {
    console.log("beforeUnloadPage");
    if( g_localMediaStream != null )
      g_localMediaStream.getTracks().forEach(function(track) { track.stop(); })
  }

  useEffect(() => {
    console.log("useEffect.operationView.1="+operationView);
    console.log("useEffect.operationView.2="+OPERATION_VIEW_VIDEO);
    console.log("useEffect.currentExamItem="+currentExamItem);
    if( operationView === OPERATION_VIEW_VIDEO ) // BT temperature
      handleVideo();
    //
    console.log("beforeUnloadPage.add");
    window.addEventListener("beforeunload", beforeUnloadPage);
  }, [operationView]);
  return (
    <Root>
      {/* <figure> */}
      {/* <img
          src={DemoConsultationPatient}
          alt="Synckit"
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        /> */}
      {/* </figure> */}
      <video
        id="localVideo"
        style={{ objectFit: "cover" }}
        width="100%"
        height="240"
      ></video>
      <video
        id="remoteVideoDoctor"
        style={{ objectFit: "cover" }}
        width="100%"
        height="240"
      ></video>
      <Button variant="text" id="temperature">
        Temperatore
      </Button>

      {/* <ButtonWrap>
        <FunctionButton>
          <VideocamIcon />
        </FunctionButton>
        <FunctionButton>
          <VolumeOffIcon />
        </FunctionButton>
        <FunctionButton>
          <MicOffIcon />
        </FunctionButton>
        <FunctionButton>
          <CropFreeIcon />
        </FunctionButton>
      </ButtonWrap> */}
    </Root>
  );
}

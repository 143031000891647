import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { Box, Divider, styled, Typography } from "@mui/material";
import React from "react";
import { SynckitColors } from "../../../assets/SynckitColors";
import {
  IconAwardFill,
  IconBriefcaseFill,
  IconPersonFill,
  IconPhoneFill,
} from "../../../assets/SynckitIcons";

const Root = styled("div")(({ theme }) => ({
  padding: 40,
}));

export default function ProfilePanel() {
  return (
    <Root>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color: SynckitColors.Gray[800],
          mb: 1,
        }}
      >
        <IconPersonFill sx={{ mr: 1 }} />
        <Typography variant="subtitle2">Personal Info.</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          lineHeight: 3,
        }}
      >
        <Typography variant="body1" sx={{ color: SynckitColors.Gray[800] }}>
          Nationality:
        </Typography>
        {"　"}
        <Typography variant="body1">Nationality</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          lineHeight: 3,
        }}
      >
        <Typography variant="body1" sx={{ color: SynckitColors.Gray[800] }}>
          Languages :
        </Typography>
        {"　"}
        <Typography variant="body1">Languages</Typography>
      </Box>

      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color: SynckitColors.Gray[800],
          mt: 1,
          mb: 1,
        }}
      >
        <IconAwardFill sx={{ mr: 1 }} />
        <Typography variant="subtitle2">Speciality</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          lineHeight: 3,
        }}
      >
        <Typography variant="body1" sx={{ color: SynckitColors.Gray[800] }}>
          Speciality :
        </Typography>
        {"　"}
        <Typography variant="body1">Speciality</Typography>
      </Box>

      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color: SynckitColors.Gray[800],
          mt: 1,
          mb: 1,
        }}
      >
        <IconBriefcaseFill sx={{ mr: 1 }} />
        <Typography variant="subtitle2">Experience</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          lineHeight: 3,
        }}
      >
        <Timeline position="alternate">
          <TimelineItem>
            <TimelineOppositeContent color="text.secondary">
              2017-2020
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant="body1"> (Hospital Name)</Typography>

              <Typography
                variant="body2"
                sx={{ color: SynckitColors.Primary[600] }}
              >
                (Job position)
              </Typography>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent color="text.secondary">
              2017-2020
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant="body1"> (Hospital Name)</Typography>

              <Typography
                variant="body2"
                sx={{ color: SynckitColors.Primary[600] }}
              >
                (Job position)
              </Typography>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent color="text.secondary">
              2017-2020
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant="body1"> (Hospital Name)</Typography>
              <Typography
                variant="body2"
                sx={{ color: SynckitColors.Primary[600] }}
              >
                (Job position)
              </Typography>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color: SynckitColors.Gray[800],
          mt: 1,
          mb: 1,
        }}
      >
        <IconPhoneFill sx={{ mr: 1 }} />
        <Typography variant="subtitle2">Contact</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          lineHeight: 3,
        }}
      >
        <Typography variant="body1" sx={{ color: SynckitColors.Gray[800] }}>
          Email :
        </Typography>
        {"　"}
        <Typography variant="body1">Email</Typography>
      </Box>
    </Root>
  );
}

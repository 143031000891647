import { IconMedcial, IconPersonFill } from "../../assets/SynckitIcons";
import AccountIndex from "./account/AccountIndex";
import MyPointsIndex from "./my-points/MyPointsIndex";
import MyServiceIndex from "./my-service/MyServiceIndex";
import ProblemReport from "./ProblemReport";
import SystemIndex from "./system/SystemIndex";
import TransactionRecordIndex from "./transaction-record/TransactionRecordIndex";

export const settingMenuList = [
  {
    id: 1,
    title: "Account",
    icon: IconPersonFill,
    component: <AccountIndex />,
  },
  {
    id: 2,
    title: "My Service",
    icon: IconMedcial,
    component: <MyServiceIndex />,
  },
  {
    id: 3,
    title: "My Points",
    icon: IconPersonFill,
    component: <MyPointsIndex />,
  },
  {
    id: 4,
    title: "Transaction Record",
    icon: IconPersonFill,
    component: <TransactionRecordIndex />,
  },
  {
    id: 5,
    title: "System",
    icon: IconPersonFill,
    component: <SystemIndex />,
  },
  {
    id: 6,
    title: "Problem Report",
    icon: IconPersonFill,
    component: <ProblemReport />,
  },
];

import { SynckitColors } from "../../../../assets/SynckitColors";

export const ModelBodyBackBottom = ({ currentPartId = "", handleClick }) => {
  return (
    <svg viewBox="69 507 168 582" stroke="" onClick={handleClick}>
      <path
        d="M134.49 518.68C134.9 517.12 135.39 510.74 135.36 507.83H105C105.25 515.09 103.77 521.24 103.77 521.24C101.81 528.86 99.9297 541.03 99.9297 541.03C99.6197 542.47 99.3797 543.78 99.1797 545H129.69C129.98 543.95 130.28 543.48 130.28 543.48C134.05 538.45 134.02 520.45 134.49 518.68Z"
        stroke=""
        style={{
          fill: currentPartId === "r11" ? SynckitColors.Yellow[200] : "",
        }}
        id="r11"
      />
      <path
        d="M127.962 591.59C130.062 582.92 129.992 571.45 129.992 571.45C129.922 563.13 129.172 550.43 129.172 550.43C129.072 547.87 129.372 546.11 129.672 545H99.162C98.252 550.45 98.1819 554.12 97.3319 560.12C96.2819 567.46 96.422 575.64 96.422 575.64C96.282 579.55 98.032 598.72 98.032 598.72C98.242 602.63 101.322 626.2 101.322 626.2C101.332 626.25 101.342 626.32 101.352 626.38H120.782C121.262 620.73 121.912 615.11 122.782 612.01C124.362 606.41 127.962 591.59 127.962 591.59Z"
        stroke=""
        style={{
          fill: currentPartId === "r12" ? SynckitColors.Yellow[200] : "",
        }}
        id="r12"
      />
      <path
        d="M102.511 640.081C102.651 644.871 101.021 652.861 101.021 652.861L99.8113 657.381C99.8413 657.681 99.7112 657.761 99.7112 657.761C97.0112 657.571 96.0712 658.961 96.0712 658.961C95.9412 659.211 95.6213 659.131 95.6213 659.131C91.9513 658.741 91.1113 660.681 91.1113 660.681C91.0713 661.001 90.7012 660.941 90.7012 660.941C86.8812 661.141 87.7813 663.921 87.7813 663.921C87.8113 664.351 87.4113 664.431 87.4113 664.431C86.0713 664.341 85.1613 664.841 85.1613 664.841C83.2913 666.331 83.6012 668.421 83.6012 668.421C83.6012 669.451 84.3913 669.911 84.3913 669.911C84.6913 670.281 84.6713 670.291 84.6713 670.291C86.1013 675.111 92.2212 677.281 92.2212 677.281C93.2312 677.591 94.4213 678.121 94.4213 678.121L99.6713 683.471C105.301 689.101 111.331 688.131 111.331 688.131C119.581 688.411 120.351 682.961 120.351 682.961C121.891 681.091 120.211 667.251 120.211 667.251C120.121 665.531 120.301 665.291 120.301 665.291C121.421 662.911 121.021 656.621 121.021 656.621C120.391 651.731 119.971 638.021 119.971 638.021C120.151 634.821 120.411 630.591 120.771 626.381H101.341C101.881 629.341 102.511 640.081 102.511 640.081Z"
        stroke=""
        style={{
          fill: currentPartId === "r13" ? SynckitColors.Yellow[200] : "",
        }}
        id="r13"
      />

      <path
        d="M173.068 514.43C172.648 523.24 175.308 538.1 175.308 538.1C175.578 539.48 176.438 542.78 177.028 545H207.628C206.718 538.55 205.438 531.24 205.438 531.24C205.298 525.37 203.618 520.33 203.618 520.33C201.998 514.59 202.028 509.62 202.108 507.83H172.738C173.068 511.57 173.068 514.43 173.068 514.43Z"
        stroke=""
        style={{
          fill: currentPartId === "r19" ? SynckitColors.Yellow[200] : "",
        }}
        id="r19"
      />
      <path
        d="M177.61 547.18C178.59 551.24 177.14 569.02 177.14 569.02C175.37 581.98 181.62 602.31 181.62 602.31C183.11 606.97 186.42 622.68 186.42 622.68C186.66 623.77 186.84 625.03 186.98 626.37H205.09C205.55 621.64 206.14 617.41 206.14 617.41C207.63 608.55 209.68 584.5 209.68 584.5C211.08 571.26 208.51 552.99 208.51 552.99C208.44 551.22 208.08 548.22 207.62 545H177.02C177.36 546.27 177.61 547.18 177.61 547.18Z"
        stroke=""
        style={{
          fill: currentPartId === "r20" ? SynckitColors.Yellow[200] : "",
        }}
        id="r20"
      />
      <path
        d="M187.311 640.629C187.361 643.989 186.571 655.079 186.571 655.079C186.011 660.949 186.681 662.889 186.681 662.889C186.891 663.539 187.261 664.729 187.261 664.729L186.981 673.069C187.031 678.809 186.911 682.349 186.911 682.349C186.871 689.059 193.761 688.679 193.761 688.679C198.551 689.129 202.081 687.979 202.081 687.979C206.061 686.409 210.851 679.549 210.851 679.549C211.871 677.699 212.881 677.309 212.881 677.309C215.331 676.609 218.371 673.809 218.371 673.809C220.261 671.989 220.151 669.689 220.151 669.689C220.011 668.949 220.111 668.949 220.111 668.949C220.741 668.289 221.051 667.129 221.051 667.129C221.091 663.459 218.151 663.669 218.151 663.669C216.821 663.849 216.931 662.799 216.931 662.799C216.791 660.209 214.341 660.459 214.341 660.459C213.471 660.499 213.331 660.249 213.331 660.249C212.351 658.079 209.831 659.379 209.831 659.379C209.131 659.589 209.131 659.379 209.131 659.379C208.811 658.329 207.451 658.049 207.451 658.049C206.191 657.659 205.591 653.009 205.591 653.009C205.101 648.569 204.861 644.859 204.861 644.859C204.161 642.339 204.511 635.279 204.511 635.279C204.511 632.789 204.761 629.469 205.071 626.359H186.961C187.621 632.629 187.311 640.629 187.311 640.629Z"
        stroke=""
        style={{
          fill: currentPartId === "r21" ? SynckitColors.Yellow[200] : "",
        }}
        id="r21"
      />
    </svg>
  );
};

import React from "react";
import MessageSection from "./MessageSection";
import UpcomingAppointment from "./UpcomingAppointment";
import Grid from "@mui/material/Grid";
import RecentlyFollowed from "./RecentlyFollowed";

const Homeindex = () => {
  return (
    <div>
      <MessageSection />
      <Grid
        container
        spacing={3}
        direction="row"
        justify="center"
        alignItems="flex-start"
        alignContent="center"
      >
        <Grid item xs={12} lg={8}>
          <UpcomingAppointment />
        </Grid>
        <Grid item xs={12} lg={4}>
          <RecentlyFollowed />
        </Grid>
      </Grid>
    </div>
  );
};

export default Homeindex;

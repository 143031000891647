import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  styled,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { SynckitColors } from "../../../../assets/SynckitColors";
import { IconDoctor, IconPinLine } from "../../../../assets/SynckitIcons";

const InfoCard = styled(Card)({
  background: "white",
  borderRadius: 12,
  boxShadow: SynckitColors.Shadow[3],
});

export default function MedicalCard() {
  return (
    <InfoCard>
      <CardHeader
        avatar={
          <IconDoctor
            style={{ width: 18, height: 18, color: SynckitColors.Primary[600] }}
          />
        }
        title="Medical"
        titleTypographyProps={{
          fontSize: 16,
          fontWeight: "bold",
          color: SynckitColors.Primary[600],
        }}
      />
      <Divider />
      <CardContent sx={{ padding: "3%" }}>
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              sx={{ color: SynckitColors.Gray[800] }}
            >
              Medical Record Number
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="subtitle1">Facility</Typography>
            <Typography variant="body1">Facility</Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="subtitle1">MRN</Typography>
            <Typography variant="body1">MRN</Typography>
          </Grid>
          <Grid item xs={12}>
            <br />
            <Typography
              variant="subtitle2"
              sx={{ color: SynckitColors.Gray[800] }}
            >
              Medication
            </Typography>
          </Grid>

          <Grid item xs={6} md={3}>
            <Typography variant="subtitle1">Medication name</Typography>
            <Typography variant="body1">Medication name</Typography>
          </Grid>

          <Grid item xs={6} md={3}>
            <Typography variant="subtitle1">Dose</Typography>
            <Typography variant="body1">Dose</Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography variant="subtitle1">Frequency</Typography>
            <Typography variant="body1">Frequency</Typography>
          </Grid>

          <Grid item xs={6} md={3}>
            <Typography variant="subtitle1">Route</Typography>
            <Typography variant="body1">Route</Typography>
          </Grid>

          <Grid item xs={12}>
            <br />
            <Typography
              variant="subtitle2"
              sx={{ color: SynckitColors.Gray[800] }}
            >
              Allergic
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="subtitle1">Allergic</Typography>
            <Typography variant="body1">Allergic</Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="subtitle1">Drug</Typography>
            <Typography variant="body1">Drug</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </InfoCard>
  );
}

const demoData = [{ id: 1, title: "Country", value: "Taiwan" }];

import { styled } from "@mui/material";
import React, { useContext } from "react";
import ImgModelAbdominal from "../../../../assets/images/body/model_abdominal.png";
import { SynckitColors } from "../../../../assets/SynckitColors";
import { ConsultContext } from "../../ConsultIndex";
import { AbdominalSVG } from "./AbdominalSVG";
const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  position: "relative",
}));

const ImgModelBodyWrap = styled("div")(({ theme }) => ({
  position: "absolute",
  height: "77%",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  "& img": {
    height: "100%",
  },
}));
const SVGModelBodyWrap = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  height: "77%",
  "& svg": {
    height: "100%",
  },
  "& path": {
    fill: "#8A25EF",
    fillOpacity: 0.01,
    transition: "0.5s",
  },
  "& path:hover": {
    fillOpacity: 0.2,
    cursor: "pointer",
  },
}));

export default function Abdominal() {
  const { currentPartId, setCurrentPartId } = useContext(ConsultContext);
  return (
    <Root>
      <ImgModelBodyWrap>
        <img src={ImgModelAbdominal} alt="synckit" />
      </ImgModelBodyWrap>
      <SVGModelBodyWrap>
        <AbdominalSVG
          handleClick={(e) => e.target.id && setCurrentPartId(e.target.id)}
          currentPartId={currentPartId}
        />
      </SVGModelBodyWrap>
    </Root>
  );
}

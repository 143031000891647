import {
  Avatar,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { SynckitButton } from "../../../components/SynckitButton";
import PaymentSetting from "./PaymentSetting";

export default function SettingAccount({ setAccountMode }) {
  return (
    <Grid
      container
      spacing={4}
      direction="row"
      justify="center"
      alignItems="center"
      alignContent="center"
    >
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Avatar sx={{ width: 80, height: 80, mr: 2 }} />
        <SynckitButton variant="outlined">Upload</SynckitButton>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField label="First Name" size="small" fullWidth />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField label="Last Name" size="small" fullWidth />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Birthday"
          size="small"
          type="date"
          defaultValue="2021-12-12"
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField label="Phone" size="small" fullWidth />
      </Grid>
      <Grid item xs={12} md={3}>
        <Typography variant="subtitle2">Email :</Typography>
        {"　"}
        <Typography variant="body2">Email</Typography>{" "}
      </Grid>
      <Grid item xs={12} md={3}>
        <Button variant="third" onClick={() => setAccountMode("change_email")}>
          Change
        </Button>
      </Grid>
      <Grid item xs={12} md={3}>
        <Typography variant="subtitle2">Password :</Typography>
        {"　"}
        <Typography variant="body2">●●●●●●●●</Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        <Button
          variant="third"
          onClick={() => setAccountMode("change_password")}
        >
          Change
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ mt: 2, mb: 3 }} />
        <PaymentSetting />
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "right" }}>
        <Divider sx={{ mb: 3 }} />
        <Button variant={"secondary"} sx={{ width: 116 }}>
          Cancel
        </Button>
        {"　"}
        <Button variant={"primary"} sx={{ width: 116 }}>
          Save
        </Button>
      </Grid>
    </Grid>
  );
}

import { createTheme } from "@mui/material";
import { SynckitColors } from "./SynckitColors";

export const SynckitTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      nr: 1440,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: "Lato",
    letterSpacing: 0.1,
    h1: {
      fontWeight: "bold",
      fontSize: 56,
      lineHeight: "60px",
      letterSpacing: "0.4px",
    },
    h2: {
      fontWeight: "bold",
      fontSize: 40,
      lineHeight: "46px",
      letterSpacing: "0.2px",
    },
    h3: {
      fontWeight: "normal",
      fontSize: 56,
      lineHeight: "60px",
      letterSpacing: "0.4px",
    },
    h4: {
      fontWeight: "bold",
      fontSize: 26,
      lineHeight: "34px",
      letterSpacing: "0.2px",
    },
    h5: {
      fontWeight: "bold",
      fontSize: 20,
      lineHeight: "26px",
      letterSpacing: "0.2px",
    },
    h6: {
      fontWeight: "normal",
      fontSize: 18,
      lineHeight: "24px",
      letterSpacing: "0.1px",
    },

    subtitle1: {
      fontWeight: "bold",
      fontSize: 16,
      lineHeight: "24px",
      letterSpacing: "0.1px",
    },
    subtitle2: {
      fontWeight: "bold",
      fontSize: 14,
      lineHeight: "20px",
      letterSpacing: "0.1px",
    },
    body1: {
      fontWeight: "normal",
      fontSize: 16,
      lightHeight: "24px",
      letterSpacing: "0.1px",
    },
    body2: {
      fontWeight: "normal",
      fontSize: 14,
      lightHeight: "20px",
      letterSpacing: "0.1px",
    },
    small1: {
      fontWeight: "normal",
      fontSize: 13,
      lineHeight: "16px",
      letterSpacing: "0.1px",
    },
    small2: {
      fontWeight: "bold",
      fontSize: 12,
      lineHeight: "16px",
      letterSpacing: "0.1px",
    },
    small3: {
      fontWeight: "bold",
      fontSize: 10,
      lineHeight: "12px",
      letterSpacing: "0.2px",
    },
  },
  palette: {
    primary: { main: "#008BE8" },
    secondary: {
      main: "#FFB54D",
    },
    gray: {
      main: "#CACCCF",
    },
    error: {
      main: "#FF6760",
    },
    warning: {
      main: "#FAC032",
    },
  },
  components: {
    MuiButton: {
      variants: [
        { props: { variant: "text" }, style: { textTransform: "none" } },
        {
          props: { variant: "primary" },
          style: {
            textTransform: "none",
            color: "white",
            fontSize: 14,
            fontWeight: "bold",
            letterSpacing: 0.2,
            background: SynckitColors.Primary[600],
            borderRadius: 100,
            padding: "4px 16px",
            "&:hover": {
              background: SynckitColors.Primary[800],
            },
            "&:disabled": {
              background: SynckitColors.Gray[500],
              color: "white",
            },
          },
        },
        {
          props: { variant: "secondary" },
          style: {
            textTransform: "none",
            color: SynckitColors.Primary[600],
            fontSize: 14,
            fontWeight: "bold",
            letterSpacing: 0.2,
            background: "white",
            borderRadius: 100,
            padding: "4px 16px",
            border: `1px solid ${SynckitColors.Primary[600]}`,
            "&:hover": {
              background: SynckitColors.Primary[50],
            },
            "&:disabled": {
              background: SynckitColors.Gray[500],
              color: "white",
            },
          },
        },
        {
          props: { variant: "third" },
          style: {
            textTransform: "none",
            color: "#008BE8",
            fontSize: 14,
            fontWeight: "bold",
            letterSpacing: 0.2,
            background: "#E3F2FD",
            borderRadius: 100,
            padding: "4px 16px",
            "&:hover": {
              background: SynckitColors.Primary[100],
            },
            "&:disabled": {
              background: SynckitColors.Gray[500],
              color: "white",
            },
          },
        },
        {
          props: { variant: "forth" },
          style: {
            textTransform: "none",
            color: "white",
            fontSize: 14,
            fontWeight: "bold",
            letterSpacing: 0.2,
            background: SynckitColors.Yellow[700],
            borderRadius: 100,
            padding: "4px 16px",
            "&:hover": {
              background: SynckitColors.Yellow[900],
            },
            "&:disabled": {
              background: SynckitColors.Gray[500],
              color: "white",
            },
          },
        },
        {
          props: { variant: "fifth" },
          style: {
            textTransform: "none",
            color: SynckitColors.Gray[800],
            fontSize: 14,
            fontWeight: "bold",
            letterSpacing: 0.2,
            background: "white",
            borderRadius: 100,
            padding: "4px 16px",
            border: `1px solid ${SynckitColors.Gray[400]}`,
            "&:hover": {
              background: SynckitColors.Gray[200],
            },
            "&:disabled": {
              background: SynckitColors.Gray[500],
              color: "white",
            },
          },
        },
        {
          props: { variant: "sixth" },
          style: {
            textTransform: "none",
            color: SynckitColors.Gray[800],
            background: SynckitColors.Gray[300],
            fontSize: 14,
            fontWeight: "bold",
            letterSpacing: 0.2,
            borderRadius: 100,
            padding: "4px 16px",
            border: `1px solid ${SynckitColors.Gray[400]}`,
            "&:hover": {
              background: "white",
            },
            "&:disabled": {
              background: SynckitColors.Gray[500],
              color: "white",
            },
          },
        },
      ],
    },

    MuiIconButton: {
      variants: [
        {
          props: { variant: "primary" },
          style: {
            color: "white",
            fontSize: 12,
            fontWeight: "bold",
            letterSpacing: 0.2,
            background: SynckitColors.Primary[600],
            "&:hover": {
              background: "#BADFFC",
            },
            "&:disabled": {
              background: SynckitColors.Gray[500],
              color: "white",
            },
          },
        },
        {
          props: { variant: "secondary" },
          style: {
            color: SynckitColors.Primary[600],
            background: "white",

            border: "1px solid rgba(0, 139, 232, 1)",
            "&:hover": {
              background: "#BADFFC",
            },
            "&:disabled": {
              background: SynckitColors.Gray[500],
              color: "white",
            },
          },
        },
        {
          props: { variant: "third" },
          style: {
            color: SynckitColors.Primary[600],
            fontSize: 12,
            fontWeight: "bold",
            letterSpacing: 0.2,
            background: SynckitColors.Primary[50],
            "&:hover": {
              background: "#BADFFC",
            },
            "&:disabled": {
              background: SynckitColors.Gray[500],
              color: "white",
            },
          },
        },
      ],
    },
    MuiAlert: {
      variants: [
        {
          props: { variant: "outlined", severity: "success" },
          style: {
            background: "#F1FFF4",
            boxShadow:
              "0px 27px 80px rgba(16, 57, 123, 0.05), 0px 13.6687px 34.875px rgba(16, 57, 123, 0.03375), 0px 5.4px 13px rgba(16, 57, 123, 0.025), 0px 1.18125px 4.625px rgba(16, 57, 123, 0.01625)",
            border: "1px solid #C0ECD4",
          },
        },
      ],
    },
  },
});

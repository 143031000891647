import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
  TablePagination,
  Avatar,
  TableFooter,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import { APO_TYPE_REMOTE } from "../../../assets/constant";
import { SynckitColors } from "../../../assets/SynckitColors";
import { IconAlertCircleLine } from "../../../assets/SynckitIcons";
import { ImgOrganActive2List } from "../../../assets/SynckitImg";
import RemoteApoDialog from "../../../components/dialog/RemoteApoDialog";
import RemoteApoStaticDialog from "../../../components/dialog/RemoteApoStaticDialog";

import { DemoRowData } from "./demolApoData";

const MainTableHead = styled(TableHead)({
  background: SynckitColors.Gray[200],
  "& .MuiTableCell-root": {
    color: SynckitColors.Gray[700],
    fontSize: 14,
    fontWeight: 700,
  },
});
const MainTableRow = styled(TableRow)({
  background: "white",

  "&.open": {
    background: SynckitColors.Primary[50],
  },
  "& .MuiTableCell-root": {
    color: SynckitColors.Gray["core"],
    fontSize: 14,
    fontWeight: 700,
    border: "none",
  },
});

const HightLightRow = styled(TableRow)({
  background: "#FFECEB",
  width: "100%",
  height: 44,
  position: "relative",

  "& .MuiTableCell-root": {
    color: SynckitColors.Error["Dark"],
    border: "none",
  },
  "&::after": {
    content: "' '",
    position: "absolute",
    top: -10,
    left: 6,
    // width: 34,
    // height: 24,
    borderRadius: 2,
    borderLeft: "16px solid transparent",
    borderRight: "16px solid transparent",
    borderBottom: "16px solid #FFECEB",
  },
});

export default function Refused() {
  const [rows, setRows] = useState(DemoRowData);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <Fragment>
      <Table sx={{ background: "white" }}>
        <MainTableHead>
          <TableRow hover={false} selected={false}>
            <TableCell>Patient</TableCell>
            <TableCell>Doctor</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Divison</TableCell>
          </TableRow>
        </MainTableHead>
        <TableBody>
          {rows.map(
            (row, index) =>
              row.type === APO_TYPE_REMOTE && <Row key={index} rowData={row} />
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={6} sx={{ padding: 0 }}>
              <TablePagination
                rowsPerPageOptions={[8, 16, 32]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </Fragment>
  );
}

function Row({ rowData, handleSelectTemplate, handleDeleteTemplate }) {
  const [openRemoteApoDialog, setOpenRemoteApoDialog] = useState(false);

  return (
    <Fragment>
      <MainTableRow onClick={() => setOpenRemoteApoDialog(true)}>
        <TableCell>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Avatar
              src={rowData.avatar}
              sx={{ margin: "0px 12px", width: 48, height: 48 }}
            />
            {rowData.patient}
          </div>
        </TableCell>
        <TableCell>{rowData.doctor}</TableCell>
        <TableCell>{rowData.date}</TableCell>
        <TableCell>
          {rowData.divison !== null && ImgOrganActive2List[rowData.divison]}
        </TableCell>
      </MainTableRow>
      <HightLightRow
        hover={false}
        selected={false}
        onClick={() => setOpenRemoteApoDialog(true)}
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <div style={{ display: "flex" }}>
            <IconAlertCircleLine />
            &nbsp; {rowData.refusedStatus}
            {rowData.recommendDoctor && (
              <Fragment>
                ,&nbsp;
                <a
                  href={rowData.recommendDoctor}
                  style={{ textDecoration: "underline" }}
                >
                  recommend doctor
                </a>
              </Fragment>
            )}
          </div>
        </TableCell>
      </HightLightRow>
      <RemoteApoStaticDialog
        open={openRemoteApoDialog}
        setOpen={setOpenRemoteApoDialog}
      />
    </Fragment>
  );
}

import React, { useState } from "react";
import MyService from "./MyService";
import ServicePlan from "./ServicePlan";

export default function MyServiceIndex() {
  const [serviceMode, setServiceMode] = useState("default");
  return (
    <div>
      {serviceMode === "default" && (
        <MyService setServiceMode={setServiceMode} />
      )}
      {serviceMode === "service_plan" && (
        <ServicePlan setServiceMode={setServiceMode} />
      )}
    </div>
  );
}

import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { Box, Divider, styled, Typography, Grid } from "@mui/material";
import React, { useState } from "react";
import { SynckitColors } from "../../../assets/SynckitColors";
import {
  IconAwardFill,
  IconBriefcaseFill,
  IconPersonFill,
  IconPhoneFill,
} from "../../../assets/SynckitIcons";
import ProfileCard from "./ProfileCard";
import ProfilePanel from "./ProfilePanel";
import SchedulePanel from "./SchedulePanel";

const Tabs = styled("div")(({ theme }) => ({
  display: "flex",
  filter: SynckitColors.Shadow[1],
}));
const Tab = styled("div")(({ theme }) => ({
  width: 115,
  height: 52,
  padding: "14px 33px",
  borderRadius: "12px 12px 0 0",
  background: SynckitColors.Gray[200],
  color: SynckitColors.Gray[700],
  fontWeight: 700,
  fontSize: 16,
  cursor: "pointer",
  transition: "0.5s",
  "&.select": {
    background: "white",
    color: SynckitColors.Gray["core"],
  },
  "&:hover": {
    background: "white",
    color: SynckitColors.Gray["core"],
  },
}));
const Panels = styled("div")(({ theme }) => ({
  width: "100%",
  minHeight: "fit-content",
  background: "white",
  filter: SynckitColors.Shadow[1],
  borderRadius: "0px 12px 12px 12px",
}));

export default function DoctorProfileIndex() {
  const [tabIndex, setTabIndex] = useState("profile");
  return (
    <Grid
      container
      spacing={3}
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      alignContent="center"
      sx={{ mt: 3 }}
    >
      <Grid item sm={12} md={8}>
        <Tabs>
          <Tab
            className={tabIndex === "profile" ? "select" : null}
            onClick={() => setTabIndex("profile")}
          >
            Profile
          </Tab>
          <Tab
            className={tabIndex === "schedule" ? "select" : null}
            onClick={() => setTabIndex("schedule")}
          >
            Schedule
          </Tab>
        </Tabs>
        <Panels>
          {tabIndex === "profile" ? <ProfilePanel /> : <SchedulePanel />}
        </Panels>
      </Grid>
      <Grid item sm={12} md={4}>
        <ProfileCard />
      </Grid>
    </Grid>
  );
}

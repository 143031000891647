import { Avatar, Divider, Typography, IconButton } from "@mui/material";
import React from "react";
import { SynckitButton } from "../../../components/SynckitButton";
import { SynckitPanel } from "../../../components/SynckitPanel";
import FemaleIcon from "@mui/icons-material/Female";
import AvatarImg from "../../../assets/images/demo/avatar_patient.png";
import { SynckitColors } from "../../../assets/SynckitColors";
import { styled } from "@mui/system";
import { IconStarFill, IconUpload } from "../../../assets/SynckitIcons";
import { useNavigate } from "react-router-dom";

const IconButtonWrap = styled("div")({
  position: "absolute",
  top: 9,
  right: 9,
  textAlign: "right",
});
const PatientInfoWrap = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: 246,
  width: "100%",
  margin: "36px auto 0px auto",
  marginTop: 36,
});
const PatientNameAndGender = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  height: 32,
});
const InfoWrap = styled("div")({
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: 100,
});

export default function InfoCard() {
  let navigate = useNavigate();
  return (
    <SynckitPanel
      sx={{
        position: "relative",
        minWidth: 280,
        height: 322,
        marginTop: 8,
      }}
    >
      <Avatar
        sx={{
          position: "absolute",
          top: -50,
          left: "50%",
          transform: "translateX(-50%) ",
          width: 96,
          height: 96,
        }}
        src={AvatarImg}
      />

      <IconButtonWrap>
        <IconButton onClick={() => navigate("/pm/patient/share")}>
          <IconUpload style={{ stroke: SynckitColors.Gray[700] }} />
        </IconButton>
        <IconButton>
          <IconStarFill style={{ color: SynckitColors.Warning["Default"] }} />
        </IconButton>
      </IconButtonWrap>

      <PatientInfoWrap>
        <div style={{ textAlign: "center" }}>
          <PatientNameAndGender>
            <Typography variant="h5" color="initial">
              Cammy Lytle
            </Typography>
            &nbsp;
            <FemaleIcon color="error" />
          </PatientNameAndGender>
          <Typography variant="body2" color="initial">
            G3982958195
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            height: 56,
          }}
        >
          <InfoWrap>
            <Typography variant="subtitle1" color="initial">
              Birthday
            </Typography>
            <Typography variant="body2" color="initial">
              1954.02.08
            </Typography>
          </InfoWrap>
          <Divider orientation="vertical" />
          <InfoWrap>
            <Typography variant="subtitle1" color="initial">
              PID
            </Typography>
            <Typography variant="body2" color="initial">
              A158692830
            </Typography>
          </InfoWrap>
        </div>
        <SynckitButton
          disableElevation
          variant="contained"
          sx={{
            width: "100%",
            // backgroundColor: SynckitColors.Primary[800],
            textTransform: "none",
          }}
          onClick={() => navigate("/pm/patient/profile")}
        >
          Profile
        </SynckitButton>
      </PatientInfoWrap>
    </SynckitPanel>
  );
}

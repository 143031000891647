//Layout
export const SIDMENU_WIDTH_NORMAL = 270;
export const SIDMENU_WIDTH_MD = 80;
export const SIDMENU_WIDTH_SM = 0;

//Shift
export const TIME_SELECTER_ADD_MODE = 0;
export const TIME_SELECTER_EDIT_MODE = 1;

//Organ
export const ORGAN_THEETH = 0;
export const ORGAN_EAR = 1;
export const ORGAN_TEMPERATURE = 2;
export const ORGAN_NOSE = 3;
export const ORGAN_THROAT = 4;
export const ORGAN_SKIN = 5;
export const ORGAN_LUNG = 6;
export const ORGAN_HEART = 7;
export const ORGAN_ABDOMINAL = 8;
export const ORGAN_ARTERY = 9;
export const ORGAN_DEFAULT = 10;
export const EXAM_INSTRUCTION = 11;
export const organList = [
  "Teeth",
  "Ear",
  "Skin",
  "Nose",
  "T.P",
  "Heart",
  "Lung",
  "Colon",
  "Artery",
];

//Appointment
export const APO_CANCELLED_STATUS_NONE = 0;
export const APO_CANCELLED_STATUS_WAITING = 1;
export const APO_CANCELLED_STATUS_REFUSED = 2;
export const APO_CANCELLED_STATUS_REACHED = 3;

//Schedule
export const APO_TYPE_REMOTE = 2;
export const APO_TYPE_LOCAL = 1;
export const ApoTypeAry = ["", "Local", "Remote"];

//Consult
export const OPERATION_VIEW_DEFAULT = 0;
export const OPERATION_VIEW_BODY = 1;
export const OPERATION_VIEW_INSTRUCTIONS = 2;
export const OPERATION_VIEW_VIDEO = 3;

//Comparison Report
export const CR_MODE_PREVIEW_IMG = 0;
export const CR_MODE_PREVIEW_VIDEO_DEFAULT = 1;
export const CR_MODE_PREVIEW_VIDEO_PLAY = 2;
export const CR_MODE_PREVIEW_ORGAN = 3;

//Notifications
export const NOTIFICATIONS_STATUS_ONLINE = 0;
export const NOTIFICATIONS_STATUS_OFFLINE = 1;

export const NOTIFICATIONS_TYPE_APPOINTMENT = 0;
export const NOTIFICATIONS_TYPE_CONTACT = 1;
export const NOTIFICATIONS_TYPE_PAYMENT = 2;

export const REQUEST_STATUS_WAITING_CONFIRM = 0;
export const REQUEST_STATUS_WAITING_PAY = 1;
export const REQUEST_STATUS_ACCEPTED = 2;
export const REQUEST_STATUS_REJECTED = 3;

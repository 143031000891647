import { Alert, AlertTitle, Snackbar } from "@mui/material";
import React from "react";

export default function SynckitSnackbar({
  open,
  setOpen,
  severity = "success",
  alertTitle = "",
  content = "",
}) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      onClose={() => setOpen(false)}
      autoHideDuration={3000}
    >
      <Alert
        onClose={() => setOpen(false)}
        variant="outlined"
        severity={severity}
        sx={{ width: "100%" }}
      >
        <AlertTitle>{alertTitle}</AlertTitle>
        {content}
      </Alert>
    </Snackbar>
  );
}

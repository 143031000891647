import React from "react";
import { Typography, Avatar, IconButton, Menu, MenuItem } from "@mui/material";
import { Box, styled } from "@mui/system";
import { SynckitColors } from "../../../../assets/SynckitColors";
import { IconClockLine } from "../../../../assets/SynckitIcons";
import { ImgOrganActive2List } from "../../../../assets/SynckitImg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SynckitSearchBar2 from "../../../../components/SynckitSearchBar2";
import { demoDoctorList } from "../../../../assets/demoData";
import MoreMenuButton from "./MoreMenuButton";

const ApoListItem = styled("li")(({ theme }) => ({
  background: "white",
  width: "100%",
  height: 96,
  borderRadius: 12,
  boxShadow: SynckitColors.Shadow[2],
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 40px",
  marginBottom: "2%",
  transition: "0.3s",
  [theme.breakpoints.down("md")]: {
    padding: "16px 20px",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    height: 140,
  },
}));

const InfoWrap = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  margin: "0 1%",
  width: 210,
}));

const TypeWrap = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: 230,
}));

const TitleWrap = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: 60,
});

const ConsultationLabel = styled("span")({
  background: SynckitColors.Primary[50],
  borderRadius: 100,
  padding: "8px 16px",
  color: SynckitColors.Primary[600],
  fontSize: 14,
  fontWeight: "bold",
});

export default function ApoList() {
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  return (
    <Box>
      <TitleWrap>
        <Typography variant="h5">Appointment</Typography>
      </TitleWrap>
      <SynckitSearchBar2 />
      <ul>
        {demoDoctorList.map((item) => (
          <ApoListItem key={item.id}>
            <Box sx={{ display: "flex" }}>
              <Avatar
                alt={item.name}
                src={item.avatar}
                sx={{ width: 48, height: 48, marginRight: 2 }}
              />
              <InfoWrap>
                <Typography
                  variant="subtitle1"
                  sx={{ color: SynckitColors.Gray["core"] }}
                >
                  {item.name}
                </Typography>

                <Typography
                  variant="body2"
                  component={"div"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    color: SynckitColors.Gray[800],
                  }}
                >
                  <IconClockLine sx={{ width: 13, heigth: 13 }} />
                  &nbsp; {item.apoDatetime}
                </Typography>
              </InfoWrap>
            </Box>

            <TypeWrap>
              <ConsultationLabel>remote</ConsultationLabel>
              {ImgOrganActive2List[item.divison]}
              {/* <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                <MoreVertIcon />
              </IconButton> */}
              <MoreMenuButton />
            </TypeWrap>
          </ApoListItem>
        ))}
      </ul>
    </Box>
  );
}

const renderListItem = (item) => (
  <ApoListItem key={item.id}>
    <Box sx={{ display: "flex" }}>
      <Avatar
        alt={item.name}
        src={item.avatar}
        sx={{ width: 48, height: 48, marginRight: 2 }}
      />
      <InfoWrap>
        <Typography
          variant="subtitle1"
          sx={{ color: SynckitColors.Gray["core"] }}
        >
          {item.name}
        </Typography>

        <Typography
          variant="body2"
          component={"div"}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            color: SynckitColors.Gray[800],
          }}
        >
          <IconClockLine sx={{ width: 13, heigth: 13 }} />
          &nbsp; {item.apoDatetime}
        </Typography>
      </InfoWrap>
    </Box>

    <TypeWrap>
      <ConsultationLabel>Consultation</ConsultationLabel>
      {ImgOrganActive2List[item.divison]}
      <IconButton>
        <MoreVertIcon />
      </IconButton>
    </TypeWrap>
  </ApoListItem>
);

import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/logo/logo.png";
import LogoSmall from "../../assets/images/logo/logo_samll.png";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import { SynckitColors } from "../../assets/SynckitColors";
import { Link, useLocation } from "react-router-dom";
import {
  IconAppointment,
  IconCalendarLine,
  IconDoctor,
  IconHomeLine,
  IconPatient,
  IconPeopleLine,
  IconSettings2,
  IconShifts,
} from "../../assets/SynckitIcons";

const LogoImg = styled("img")(({ theme }) => ({
  position: "absolute",
  content: `url(${Logo})`,
  padding: 16,
  width: 250,
  top: 50,
  left: 10,
  backgroundColor: SynckitColors.Primary[600],
  transition: "left 0.3s",
  [theme.breakpoints.down("md")]: {
    width: 80,
    top: 48,
    left: 0,
    content: `url(${LogoSmall})`,
  },
}));

const NavList = styled(List)(({ theme }) => ({
  width: "247px !important",
  margin: "0px auto",
  fontSize: 16,
  fontWeight: 700,
  color: "rgba(255, 255, 255, 0.5)",
  transition: "0.3s",
  [theme.breakpoints.down("md")]: {
    width: "60px !important",
  },
}));
const NavListItemButton = styled(ListItemButton)(({ theme }) => ({
  height: 56,
  transition: "0.3s",
  borderRadius: 8,
  paddingLeft: 26,

  "&.Mui-selected": {
    color: "white",
    background: SynckitColors.Primary[700],
    "& .MuiSvgIcon-root": {
      color: "white",
    },
  },

  "& .MuiListItemIcon-root": {
    minWidth: 35,
  },
  "& .MuiSvgIcon-root": {
    color: "rgba(255, 255, 255, 0.5)",
    transition: "0.3s",
    fontSize: 20,
  },

  "&:hover": {
    background: "rgba(0, 121, 213, 0.3)",
    color: "white",
    "& .MuiSvgIcon-root": {
      color: "white",
    },
  },

  [theme.breakpoints.down("md")]: {
    paddingLeft: 20,

    "& .MuiTypography-root": {
      display: "none",
    },
  },
}));
const CustomizedExpandLess = styled(ExpandLess)(({ theme }) => ({
  [theme.breakpoints.down("md")]: { display: "none" },
}));
const CustomizedExpandMore = styled(ExpandMore)(({ theme }) => ({
  [theme.breakpoints.down("md")]: { display: "none" },
}));

const NestedList = styled(NavList)(({ theme }) => ({
  borderRadius: 8,
  padding: "0",
  "&.openSubmenu": {
    background: SynckitColors.Primary[700],
  },
}));
export default function SideMenu() {
  const [openSchedule, setOpenSchedule] = useState(false);
  const [openContacts, setOpenContacts] = useState(false);
  const [index, setIndex] = useState(0);
  const location = useLocation();
  useEffect(() => {
    if (
      ["/schedule/appointment", "/schedule/shifts"].includes(location.pathname)
    )
      setOpenSchedule(true);
    else setOpenSchedule(false);
  }, [location.pathname]);
  return (
    <div>
      <LogoImg alt="Synckit" />
      <NavList sx={{ width: "100%", marginTop: "171px" }} component="nav">
        <NavListItemButton
          selected={["/home", "/"].includes(location.pathname)}
          // onClick={() => setIndex(0)}
          component={Link}
          to="/home"
        >
          <ListItemIcon>
            <IconHomeLine />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </NavListItemButton>
        <NestedList className={openSchedule ? "openSubmenu" : null}>
          <NavListItemButton
            component={Link}
            to="/schedule"
            onClick={() => {
              setOpenSchedule(!openSchedule);
              // setIndex(8);
            }}
            selected={location.pathname === "/schedule"}
          >
            <ListItemIcon>
              <IconCalendarLine />
            </ListItemIcon>
            <ListItemText primary="Schedule" />
            {openSchedule ? <CustomizedExpandLess /> : <CustomizedExpandMore />}
          </NavListItemButton>
          <Collapse in={openSchedule} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <NavListItemButton
                sx={{ pl: 4 }}
                selected={location.pathname === "/schedule/appointment"}
                // onClick={() => setIndex(2)}
                component={Link}
                to="/schedule/appointment"
              >
                <ListItemIcon>
                  <IconAppointment />{" "}
                </ListItemIcon>
                <ListItemText primary="Appointment" />
              </NavListItemButton>
              <NavListItemButton
                sx={{ pl: 4 }}
                selected={location.pathname === "/schedule/shifts"}
                // onClick={() => setIndex(3)}
                component={Link}
                to="/schedule/shifts"
              >
                <ListItemIcon>
                  <IconShifts />{" "}
                </ListItemIcon>
                <ListItemText primary="Shifts" />
              </NavListItemButton>
            </List>
          </Collapse>
        </NestedList>
        <NavListItemButton
          component={Link}
          to="/pm"
          selected={location.pathname.startsWith("/pm")}
          // onClick={() => setIndex(1)}
        >
          <ListItemIcon>
            <IconPatient />
          </ListItemIcon>
          <ListItemText primary="Patient Management" />
        </NavListItemButton>

        <NestedList
          component="div"
          disablePadding
          className={openContacts ? "openSubmenu" : null}
        >
          <NavListItemButton
            onClick={() => setOpenContacts(!openContacts)}
            selected={location.pathname === "/contacts"}
            // onClick={() => setIndex(2)}
            component={Link}
            to="/contacts"
          >
            <ListItemIcon>
              <IconPeopleLine />{" "}
            </ListItemIcon>
            <ListItemText primary="Contacts" />
            {openContacts ? <CustomizedExpandLess /> : <CustomizedExpandMore />}
          </NavListItemButton>
          <Collapse in={openContacts} timeout="auto" unmountOnExit>
            <NavListItemButton
              sx={{ pl: 4 }}
              selected={location.pathname === "/pd"}
              component={Link}
              to="/pd"
            >
              <ListItemIcon>
                <IconDoctor />
              </ListItemIcon>
              <ListItemText primary="Public Doctor" />
            </NavListItemButton>
          </Collapse>
        </NestedList>

        <NavListItemButton
          selected={location.pathname === "/setting"}
          component={Link}
          to="/setting"
        >
          <ListItemIcon>
            <IconSettings2 />{" "}
          </ListItemIcon>
          <ListItemText primary="Setting" />
        </NavListItemButton>
      </NavList>
    </div>
  );
}

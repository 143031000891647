import { SynckitColors } from "../../../../assets/SynckitColors";

export const AdultTeethSVGUpper = ({ currentPartId = "" }) => {
  return (
    <svg viewBox="4 2 186 67" stroke={""}>
      <path
        d="M87.8,11.2c-0.3-0.9-0.7-1.2-1.6-1c-2.2,0.4-3.8,1.8-4.7,3.9c-0.2,0.5-0.2,1.1,0.3,1.4
		c1.4,0.9,2.8,1.7,4.4,2.1c1.9,0.5,2.6-0.1,2.5-2.1C88.6,14,88.2,12.6,87.8,11.2z"
        style={{
          fill:
            currentPartId === "teeth_adult_12"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_adult_12"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_adult_12"
      />
      <path
        d="M81.7,16.4c-0.9-0.2-1.8-0.2-2.7,0c-1.2,0.2-1.7,1.1-1.5,2.3c0.3,1.8,2.9,3.8,4.5,3.7c0.8,0,1.6-0.2,1.9-1
		c0.5-1.3,0.4-2.6-0.4-3.8C83,16.9,82.4,16.6,81.7,16.4z"
        style={{
          fill:
            currentPartId === "teeth_adult_13"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_adult_13"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_adult_13"
      />
      <path
        d="M78.1,21.4c-0.5-0.2-1.1-0.3-1.6,0c-1.4,0.9-2.4,2-2.9,3.6c-0.3,0.9,0,1.7,0.7,2.4c0.9,0.7,1.8,1.2,2.9,1.5
		c0.3,0.1,0.6,0.1,0.9,0c1.5-0.4,3.1-2.3,3.1-3.9c0-0.6-0.2-1.2-0.6-1.7C79.9,22.4,79.1,21.8,78.1,21.4z"
        style={{
          fill:
            currentPartId === "teeth_adult_14"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_adult_14"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_adult_14"
      />
      <path
        d="M95.5,8.4C94.8,8,94,7.9,93.2,7.9c-1,0-2,0.2-2.9,0.5c-0.7,0.3-1.4,0.6-1.9,1.2c-0.2,0.3-0.4,0.7-0.2,1
		c0.7,1.6,1.6,3.2,3,4.4c1.5,1.3,3,1.1,3.9-0.7c0.1-0.3,0.3-0.5,0.4-0.8c0.4-1.2,0.7-2.4,0.9-3.6C96.5,9.1,96.3,8.7,95.5,8.4z"
        style={{
          fill:
            currentPartId === "teeth_adult_11"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_adult_11"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_adult_11"
      />
      <path
        d="M104.8,9.3c-0.2-0.2-0.5-0.4-0.8-0.5c-1.1-0.6-2.4-0.9-3.3-0.9c-1.2,0-2,0.1-2.8,0.5c-0.7,0.3-0.8,0.7-0.7,1.4
		c0.1,0.8,0.3,1.5,0.5,2.3c0.3,1.1,0.6,2.1,1.4,3c0.7,0.8,1.5,1,2.5,0.5c0.6-0.3,1.2-0.8,1.6-1.3c0.9-1,1.5-2.1,2.1-3.3
		C105.5,10.3,105.4,9.9,104.8,9.3z"
        style={{
          fill:
            currentPartId === "teeth_adult_21"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_adult_21"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_adult_21"
      />
      <path
        d="M72.7,43.4c-1.7,0.1-3.4-0.2-4.9-1.1c-0.1-0.1-0.2-0.2-0.4-0.1c-0.7,0.6-1.3,1.1-1.5,2
		c-0.3,1.4-0.3,2.8-0.2,4.2c0.1,1.5,1,2.3,2.4,2.6c0.2,0,0.4,0.1,0.6,0.1c1.5,0.2,3-0.3,4.2-1.1c0.5-0.3,0.8-0.8,0.9-1.4
		c0.2-1.3,0.2-2.5,0.1-3.8c0-0.3-0.1-0.7-0.3-1C73.3,43.5,73.1,43.3,72.7,43.4z"
        style={{
          fill:
            currentPartId === "teeth_adult_17"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_adult_17"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_adult_17"
      />
      <path
        d="M74.8,36.4c-1.7-0.4-3.1-1.3-4.7-1.7c-0.1,0-0.2-0.1-0.3-0.1c-0.6-0.4-1.1-0.3-1.6,0.2c-0.3,0.3-0.5,0.6-0.6,1
		c-0.4,1.3-0.5,2.7-0.4,4c0,0.9,0.5,1.7,1.3,2.1c0.6,0.3,1.2,0.6,1.9,0.7c0.1,0,0.2,0,0.4,0.1c2.1,0.5,2.9,0.1,3.7-1.6
		c0.5-1.1,0.7-2.2,1.2-3.3C75.7,37.1,75.3,36.5,74.8,36.4z"
        style={{
          fill:
            currentPartId === "teeth_adult_16"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_adult_16"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_adult_16"
      />
      <path
        d="M71.6,51.4c-1.6,0.5-3.1,0.7-4.7-0.1c-0.3-0.1-0.3,0-0.4,0.2C66,52.2,65.9,53,66,53.8c0.1,1.4,0.9,2.5,1.6,3.6
		c0.7,1.2,1.8,1.7,3.2,1.4c0.3,0,0.6-0.1,1-0.1c0.6,0,1.2-0.2,1.8-0.4c0.9-0.4,1.3-1,1.1-1.9c-0.2-1.3-0.5-2.6-0.8-3.9
		C73.5,51.2,72.9,50.9,71.6,51.4z"
        style={{
          fill:
            currentPartId === "teeth_adult_18"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_adult_18"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_adult_18"
      />
      <path
        d="M76.7,29.2c-1-0.3-1.9-0.7-2.6-1.4c-0.8-0.7-1.6-0.5-2.2,0.3c-0.4,0.5-0.4,1.1-0.5,1.7
		c-0.1,0.4-0.1,0.7-0.5,0.9c-0.8,0.3-1,1-1.2,1.7c-0.2,1,0,1.4,0.9,1.7c1.2,0.4,2.4,0.8,3.5,1.4c0.8,0.4,1.6,0.2,2.1-0.4
		c1-1.2,1.5-2.7,1.7-4.2C77.8,29.8,77.6,29.5,76.7,29.2z"
        style={{
          fill:
            currentPartId === "teeth_adult_15"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_adult_15"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_adult_15"
      />
      <path
        d="M120.2,42.4c0.9,0.6,1.8,0.4,2.7,0.2c0.1,0,0.2,0,0.4,0c0.5-0.1,1.1-0.3,1.6-0.5c0.9-0.4,1.5-1,1.6-2
		c0.1-1.6,0.1-3.1-0.5-4.6c-0.2-0.5-0.6-0.8-1-1.1c-0.3-0.2-0.6-0.2-0.9,0.1c-0.4,0.3-0.8,0.4-1.2,0.5c-0.8,0.2-1.6,0.5-2.3,0.9
		c-0.5,0.3-1,0.4-1.5,0.5c-0.6,0.1-1,0.7-0.8,1.3c0.5,1.2,0.7,2.4,1.3,3.5C119.6,41.7,119.8,42.1,120.2,42.4z"
        style={{
          fill:
            currentPartId === "teeth_adult_26"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_adult_26"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_adult_26"
      />
      <path
        d="M112.1,13.9c-0.9-2-2.4-3.2-4.5-3.7c-0.3-0.1-0.5-0.1-0.8,0c-0.4,0-0.7,0.3-0.9,0.7c-0.5,1.5-0.9,3-1,4.7
		c0,0.2,0,0.5,0.1,0.8c0.2,1,0.6,1.4,1.7,1.3c0.2,0,0.5-0.1,0.7-0.1c1.5-0.4,2.9-1.2,4.2-2C112.3,15.2,112.4,14.7,112.1,13.9z"
        style={{
          fill:
            currentPartId === "teeth_adult_22"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_adult_22"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_adult_22"
      />
      <path
        d="M126.7,51.3c-1.7,0.8-3.3,0.6-5,0c-0.9-0.3-1.6,0-1.9,0.9c-0.5,1.4-0.7,2.9-0.9,4.4c-0.1,0.7,0.2,1.3,0.8,1.6
		c0.7,0.3,1.3,0.5,2.1,0.5c0.3,0,0.6,0.1,1,0.1c1.3,0.2,2.4-0.2,3.1-1.3c0.6-0.9,1.1-1.8,1.4-2.8c0.4-1.1,0.3-2.2-0.2-3.2
		C127,51.4,127,51.1,126.7,51.3z"
        style={{
          fill:
            currentPartId === "teeth_adult_28"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_adult_28"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_adult_28"
      />
      <path
        d="M126.3,42.3c-0.2-0.1-0.3-0.2-0.6,0c-1.4,0.9-2.9,1.2-4.5,1.1c-0.9,0-1.2,0.2-1.4,1.1c-0.2,1.2-0.1,2.4,0,3.6
		c0.1,1.2,0.7,2,1.8,2.4c1.1,0.4,2.2,0.8,3.4,0.5c0.2,0,0.3,0,0.5-0.1c1.6-0.3,2.3-1.2,2.4-2.4c0.1-1.2,0-2.4-0.1-3.5
		C127.7,43.9,127.2,43,126.3,42.3z"
        style={{
          fill:
            currentPartId === "teeth_adult_27"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_adult_27"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_adult_27"
      />
      <path
        d="M116,19.1c0.4-1,0.1-2.5-1.6-2.7c-0.4,0-0.7-0.1-1.1-0.1c-2.2,0-3.2,0.7-3.7,2.4c-0.2,0.6-0.2,1.2-0.1,1.7
		c0.2,1.4,1,2.1,2.4,1.9C113.9,22,115.3,20.9,116,19.1z"
        style={{
          fill:
            currentPartId === "teeth_adult_23"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_adult_23"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_adult_23"
      />
      <path
        d="M116.2,28.8c1.1-0.3,2.2-0.7,3.1-1.5c0.7-0.6,1-1.4,0.7-2.3c-0.5-1.6-1.6-2.8-3-3.6c-0.2-0.1-0.5-0.2-0.9-0.2
		c0,0-0.3,0-0.5,0.1c-1.1,0.5-2,1.1-2.8,2c-0.5,0.5-0.6,1.2-0.5,1.9c0.3,1.7,1.4,2.8,3,3.5C115.6,28.9,115.9,28.9,116.2,28.8z"
        style={{
          fill:
            currentPartId === "teeth_adult_24"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_adult_24"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_adult_24"
      />
      <path
        d="M119.5,35.6c1.2-0.6,2.4-1,3.6-1.4c0.4-0.1,0.8-0.4,0.9-1c0.1-0.7-0.3-2-0.9-2.3c-0.7-0.3-0.8-0.9-0.9-1.5
		c-0.1-0.7-0.4-1.3-0.9-1.7c-0.5-0.4-1.1-0.4-1.6,0c-0.8,0.8-1.9,1.2-2.9,1.6c-0.9,0.3-1.1,0.6-1.1,1.7c0.2,1.4,0.7,2.9,1.7,4.1
		C117.9,35.8,118.7,36,119.5,35.6z"
        style={{
          fill:
            currentPartId === "teeth_adult_25"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_adult_25"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_adult_25"
      />
    </svg>
  );
};

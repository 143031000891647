import React, { useState } from "react";
import ChangeEmail from "./ChangeEmail";
import ChangePassword from "./ChangePassword";
import SettingAccount from "./SettingAccount";

export default function AccountIndex() {
  const [accountMode, setAccountMode] = useState("default");
  return (
    <div>
      {accountMode === "default" && (
        <SettingAccount setAccountMode={setAccountMode} />
      )}
      {accountMode === "change_email" && (
        <ChangeEmail setAccountMode={setAccountMode} />
      )}
      {accountMode === "change_password" && (
        <ChangePassword setAccountMode={setAccountMode} />
      )}
    </div>
  );
}

import {
  Badge,
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  styled,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { SynckitColors } from "../../assets/SynckitColors";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SynckitStatusLight from "../../assets/SynckitStatusLight";

import { useNavigate } from "react-router-dom";
import { notificationDemoData } from "./NotificationDemoData";
import {
  RenderNotificationsType,
  RenderRequestSection,
} from "./RenderCommonSection";

const NotificationsBadge = styled(Badge)({
  "& .MuiBadge-badge ": {
    color: "white",
    background: SynckitColors.Error["Default"],
    border: `2px solid ${SynckitColors.MainBackground}`,
    width: 18,
    height: 18,
  },
});

export default function NotificationsAlert() {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const openMenu = Boolean(menuAnchorEl);
  const navigate = useNavigate();
  return (
    <div>
      <IconButton onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
        <NotificationsBadge badgeContent={3}>
          <NotificationsIcon
            fontSize="medium"
            sx={{ color: SynckitColors.Gray[600], fontSize: 23 }}
          />
        </NotificationsBadge>
      </IconButton>
      <Menu
        anchorEl={menuAnchorEl}
        open={openMenu}
        onClose={() => setMenuAnchorEl(null)}
      >
        {notificationDemoData.map((item, index) => (
          <MenuItem key={index} divider disableRipple>
            <ListItemIcon>
              {item.status !== null ? (
                <SynckitStatusLight status={item.status} />
              ) : (
                "　"
              )}
            </ListItemIcon>

            <Box>
              <Typography component={"div"} variant="subtitle1">
                {item.title}
              </Typography>
              <RenderNotificationsType {...item} />
              <RenderRequestSection requestStatus={item.requestStatus} />
            </Box>
          </MenuItem>
        ))}

        <MenuItem
          onClick={() => {
            setMenuAnchorEl(null);
            navigate("/home/messages");
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              color: SynckitColors.Primary[600],
              margin: "0 auto",
              mt: 1,
              mb: 1,
            }}
          >
            See all Messages
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}

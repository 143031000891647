import { IconButton } from "@mui/material";
import { styled } from "@mui/system";
import React, { useState } from "react";
import { SynckitColors } from "../../../../assets/SynckitColors";
import {
  IconEditLine,
  IconPlusLine,
  IconTrash2Line,
} from "../../../../assets/SynckitIcons";
import Templatemanagementindex from "./template-management/TemplateManagementIndex";
import TimeSelecterDialog from "./TimeSelecterDialog";
import _ from "lodash";
import {
  TIME_SELECTER_ADD_MODE,
  TIME_SELECTER_EDIT_MODE,
} from "../../../../assets/constant";

const WeekBox = styled("div")(({ theme }) => ({
  background: "white",
  width: 980,
  minHeight: 760,
  margin: "0px auto",
  filter: SynckitColors.Shadow[1],
  borderRadius: 12,
  overflow: "hidden",
  marginBottom: 20,
  [theme.breakpoints.down(980)]: {
    overflow: "scroll",
    width: "calc(100vw - 6px)",
    minHeight: "fit-content",
  },
}));

const TitleBox = styled("ul")(({ theme }) => ({
  display: "flex",
  width: 980,
  "&>li": {
    width: 140,
    height: 52,
    lineHeight: "52px",
    textAlign: "center",
    color: SynckitColors.Gray[800],
    fontSize: 14,
    fontWeight: "bold",
    letterSpacing: "0.1px",
    borderRight: "1px solid  rgba(133, 133, 133, 0.1)",
    borderBottom: "1px solid  rgba(133, 133, 133, 0.1)",
  },
}));

const DayBox = styled("ul")(({ theme }) => ({
  display: "flex",
  width: 980,
  [theme.breakpoints.down(980)]: {
    height: "calc(100vh - 200px)",
    maxHeight: 500,
  },
  "&>li": {
    width: 140,
    height: 707,
    borderRight: "1px solid  rgba(133, 133, 133, 0.1)",
    [theme.breakpoints.down(980)]: {
      height: "100%",
    },
  },
}));

const ScheduleBox = styled("ul")(({ theme }) => ({
  marginTop: 8,
  height: "100%",
  "&>li": {
    background: SynckitColors.Primary[50],
    width: 124,
    height: 80,
    textAlign: "center",
    margin: "0px 8px 8px 8px",
    borderRadius: 8,
    overflow: "hidden",
  },

  "&>li:last-child": {
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    background: "white",
    border: `1px dashed ${SynckitColors.Primary[100]}`,
    color: SynckitColors.Primary[600],
    fontWeight: "bold",
    lineHeight: "30px",
    cursor: "pointer",
    display: "flex",
    opacity: 0,
  },
  "&:hover >li:last-child": {
    opacity: 1,
    transition: "0.5s",
  },

  "&:hover .MuiIconButton-root": {
    opacity: 1,
  },
}));

const ButtonWrap = styled("ul")(({ theme }) => ({
  textAlign: "right",
  lineHeight: "30px",
  marginBottom: 1,
  "& .MuiIconButton-root": {
    width: 26,
    height: 26,
    marginRight: "6px",
    background: SynckitColors.Primary[100],
    opacity: 0,
    transition: "0.3s",
  },
  "& .MuiSvgIcon-root": {
    width: 16,
    height: 16,
  },
}));

// const weekList = ["Mon", "Tue", "Wen", "Thu", "Fri", "Sat", "Sun"];

function WeeklyPanel() {
  const [openTimeSelecter, setOpenTimeSelecter] = useState(false);
  const [selectedDayId, setSelectedDayId] = useState(null);
  const [selectedShift, setSelectedShift] = useState(null);
  const [timeSelecterMode, setTimeSelecterMode] = useState(
    TIME_SELECTER_ADD_MODE
  );
  const [shiftList, setShiftList] = useState(DemoShiftList);

  const handleAddNew = (dayId) => {
    setTimeout(() => {
      setTimeSelecterMode(TIME_SELECTER_ADD_MODE);
      setSelectedDayId(dayId);
      setOpenTimeSelecter(true);
    }, 100);
  };
  const handleRemove = (dayId, removeShift) => {
    let tempList = shiftList.map((day) =>
      day.id !== dayId
        ? day
        : {
            ...day,
            shifts: day.shifts.filter((shift) => shift !== removeShift),
          }
    );
    setShiftList([...tempList]);
  };

  const handleEdit = (dayId, selectedShift) => {
    setTimeout(() => {
      setSelectedDayId(dayId);
      setSelectedShift(selectedShift);
      setTimeSelecterMode(TIME_SELECTER_EDIT_MODE);
      setOpenTimeSelecter(true);
    }, 100);
  };

  return (
    <div>
      <Templatemanagementindex />

      <WeekBox>
        <TitleBox>
          {shiftList.map((day) => (
            <li key={day.id}>{day.day}</li>
          ))}
        </TitleBox>

        <DayBox>
          {shiftList.map((day) => (
            <li key={day.id}>
              <ScheduleBox key={day.id}>
                {_.orderBy(day.shifts, ["start"], ["asc"]).map(
                  (shift, index) => (
                    <li>
                      <ButtonWrap key={index}>
                        <IconButton
                          variant="third"
                          onClick={() => handleEdit(day.id, shift)}
                        >
                          <IconEditLine />
                        </IconButton>
                        <IconButton
                          variant="third"
                          onClick={() => handleRemove(day.id, shift)}
                        >
                          <IconTrash2Line />
                        </IconButton>
                      </ButtonWrap>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: 14,
                          letterSpacing: 0.1,
                          color: SynckitColors.Gray[900],
                        }}
                      >
                        {`${shift.start}-${shift.end}`}
                      </span>
                    </li>
                  )
                )}

                <li onClick={() => handleAddNew(day.id)}>
                  <IconPlusLine />
                  <span>Add</span>
                </li>
              </ScheduleBox>
            </li>
          ))}
        </DayBox>
      </WeekBox>
      <TimeSelecterDialog
        open={openTimeSelecter}
        setOpen={setOpenTimeSelecter}
        selectedDayId={selectedDayId}
        dataList={shiftList}
        setDataList={setShiftList}
        selectedShift={selectedShift}
        mode={timeSelecterMode}
      />
    </div>
  );
}

export default WeeklyPanel;

let DemoShiftList = [
  {
    id: 1,
    day: "Mon",
    shifts: [
      { shiftId: 2, start: "09:30", end: "12:00" },
      { shiftId: 1, start: "07:00", end: "09:00" },
    ],
  },
  {
    id: 2,
    day: "Tue",
    shifts: [
      { shiftId: 1, start: "08:00", end: "09:00" },
      { shiftId: 2, start: "09:30", end: "12:00" },
    ],
  },
  {
    id: 3,
    day: "Wen",
    shifts: [
      { shiftId: 1, start: "08:00", end: "09:00" },
      { shiftId: 2, start: "09:30", end: "12:00" },
    ],
  },
  {
    id: 4,
    day: "Thu",
    shifts: [
      { shiftId: 1, start: "08:00", end: "09:00" },
      { shiftId: 2, start: "09:30", end: "12:00" },
      { shiftId: 3, start: "14:30", end: "15:00" },
    ],
  },
  {
    id: 5,
    day: "Fri",
    shifts: [
      { shiftId: 1, start: "08:00", end: "09:00" },
      { shiftId: 2, start: "09:30", end: "12:00" },
    ],
  },
  {
    id: 6,
    day: "Sat",
    shifts: [
      { shiftId: 1, start: "08:00", end: "09:00" },
      { shiftId: 2, start: "09:30", end: "12:00" },
    ],
  },
  {
    id: 7,
    day: "Sun",
    shifts: [
      { start: "08:00", end: "09:00" },
      { start: "09:30", end: "12:00" },
    ],
  },
];

import { SynckitColors } from "../../../../assets/SynckitColors";

export const ChildTeethSVGLower = ({ currentPartId = "" }) => {
  return (
    <svg viewBox="3.5 68 187 57" stroke={""}>
      <path
        d="M90.2,117.8c0,0,1.6-2,2-2.8c0,0,1.4-2.5,3.2,0.1c0,0,3.3,7.3,0,7.1C95.4,122.3,87.3,122.8,90.2,117.8z"
        style={{
          fill:
            currentPartId === "teeth_child_81"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_child_81"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_child_81"
      />
      <path
        d="M97.5,120.9l1.7-4.8c0,0,1-3.1,2.7-1.4l2.8,3.7c0,0,1.5,2.7-1.3,3.6C103.4,122.1,96.8,124.4,97.5,120.9z"
        style={{
          fill:
            currentPartId === "teeth_child_71"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_child_71"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_child_71"
      />
      <path
        d="M86.1,119.8c3.1,1.6,3.5-1.7,3.5-1.7c-0.1-4.9-2-6.1-2-6.1c-1.1-0.9-5,3-5,3S80,117.6,86.1,119.8z"
        style={{
          fill:
            currentPartId === "teeth_child_82"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_child_82"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_child_82"
      />
      <path
        d="M77.4,109.2c0,0,1.8-4.1,5.1-2.5c0,0,3.1,1.4,2.5,5.5c0,0-1.3,2-4.3,2.6c0,0-4.7,1.1-4.1-2.9
		C76.6,111.9,76.8,111.1,77.4,109.2z"
        style={{
          fill:
            currentPartId === "teeth_child_83"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_child_83"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_child_83"
      />
      <path
        d="M64.9,79.9c0-0.1,0.1-6,5.8-5.2c0,0,4.8,1.5,4.6,6.6c0,0-0.3,4.5,0.5,5.7c0,0,2.1,5.5-5.1,5.4c0,0-4.8-1-5.5-7
		C65.2,85.3,64.7,83.8,64.9,79.9z"
        style={{
          fill:
            currentPartId === "teeth_child_85"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_child_85"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_child_85"
      />
      <path
        d="M69.9,98.4c0,0-1.2-6.2,3.7-7.1c0,0,4.3-0.9,5.5,5.2c0,0,0.5,3.2,1.6,4.6c0,0,1.9,5.4-3.2,6.5
		c0,0-4.8,0.6-6.8-5.9L69.9,98.4z"
        style={{
          fill:
            currentPartId === "teeth_child_84"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_child_84"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_child_84"
      />
      <path
        d="M105.4,118.3c0,0-0.2-6.1,2.3-5.6c0,0,3.1,0.7,4.9,3.1c0,0,1,1.7-1.2,3.1c0,0-3.2,3-5.3,2.4
		C106,121.2,105.1,121.4,105.4,118.3z"
        style={{
          fill:
            currentPartId === "teeth_child_72"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_child_72"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_child_72"
      />
      <path
        d="M114.7,105.1c0,0-3.1,1.2-3.7,4.7c0,0-1.5,4.7,1.7,5c0,0,0.8,0.3,1.7,0c0,0,0.7,0,1,0.1c0,0,2.4,0.3,3-1.3
		c0,0,1.1-4.3-1.6-7.2C116.8,106.5,115.5,105.2,114.7,105.1z"
        style={{
          fill:
            currentPartId === "teeth_child_73"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_child_73"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_child_73"
      />
      <path
        d="M120.6,91c0,0-3.4,0.4-4.2,3.3c0,0-0.3,0.9-0.4,2.2c0,0-0.1,2.3-1.2,3.8c0,0-1.7,4.6,1.7,6l0.1,0.1
		c0,0,3.7,1.3,5.6-1.3c0,0,6.5-6.3,2.6-12.2C124.9,92.9,123.7,91,120.6,91z"
        style={{
          fill:
            currentPartId === "teeth_child_74"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_child_74"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_child_74"
      />
      <path
        d="M121.1,76.6c0,0-1.5,1.5-1.2,4.3c0,0,0.1,3.2,0,4.3c0,0-1.2,4.9,2.2,6c0,0,3.4,2.8,5.5-1.1c0,0,0.5-0.7,1.1-3
		c0,0,1.2-3.9,1.8-4.4c0,0,1.6-7.3-4.1-8.1C126.4,74.6,123.5,74,121.1,76.6z"
        style={{
          fill:
            currentPartId === "teeth_child_75"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_child_75"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_child_75"
      />
    </svg>
  );
};

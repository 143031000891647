import { Button, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import { SynckitColors } from "../../../../../assets/SynckitColors";
import ImgDemo1 from "../../../../../assets/demo/demo1.jpg";

const ExportRoot = styled("div")(({ theme }) => ({
  width: 775,
  position: "relative",
  display: "flex",
}));

const InfoBox = styled("div")(({ theme }) => ({
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: SynckitColors.Gray[800],
  fontSize: 14,
  letterSpacing: 0.1,
}));

const PreviewSelectedSection = styled("div")(({ theme }) => ({
  background: "white",
  width: "100%",
  height: 127,
  marginRight: "1.5em",
  boxShadow: SynckitColors.Shadow[3],
  borderRadius: 8,
  padding: "1.3em",
  overflow: "hidden",
}));

const SelectedImgsBox = styled("div")(({ theme }) => ({
  height: "100%",
  display: "flex",
  justifyContent: "flex-start",

  alignItems: "center",
}));

const ImgWrap = styled("div")(({ theme }) => ({
  position: "relative",
  marginRight: 16,
  "& span": {
    position: "absolute",
    left: 4,
    top: 1,
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },
  "& .MuiIconButton-root": {
    position: "absolute",
    right: -18,
    top: -18,
  },
  "& figure": {
    borderRadius: 8,
    overflow: "hidden",
    width: 80,
    height: 80,
    "& img": { width: 80, height: 80, objectFit: "cover" },
  },
}));

const ExportButton = styled(Button)(({ theme }) => ({
  width: 127,
  height: 127,
  flexDirection: "column",
  "& .MuiSvgIcon-root": {},
}));

export default function ExportSection({ selectedImgList, setSelectedImgList }) {
  const handleRemoveImg = (_img) => {
    console.log("_img:", _img);
    let tempList = selectedImgList.filter((item) => item.imgUrl !== _img);
    setSelectedImgList([...tempList]);
  };
  return (
    <ExportRoot>
      <PreviewSelectedSection>
        <SelectedPreviewSection
          selectedList={selectedImgList}
          handleRemoveImg={handleRemoveImg}
        />
        {/* <InfoBox>Please select at least two</InfoBox> */}
      </PreviewSelectedSection>
      <ExportButton variant="contained">
        <FeedOutlinedIcon fontSize="large" />
        Export
      </ExportButton>
    </ExportRoot>
  );
}

function SelectedPreviewSection({ selectedList, handleRemoveImg }) {
  return (
    <SelectedImgsBox>
      {selectedList.map((item, index) => (
        <ImgWrap key={index}>
          <span>{index + 1}</span>
          <figure>
            <img src={item.imgUrl} alt="synckit" />
          </figure>
          <IconButton onClick={() => handleRemoveImg(item.imgUrl)}>
            <CancelIcon color="gray" />
          </IconButton>
        </ImgWrap>
      ))}
    </SelectedImgsBox>
  );
}

import { Box, Dialog, styled } from "@mui/material";
import React, { useState } from "react";
import { SynckitColors } from "../../assets/SynckitColors";

import MenuBar from "./MenuBar";
import DataGallery from "./DataGallery";
import EditorSection from "./EditorSection";
import ChiefComplaint from "./ChiefComplaint";
import MedicalOrder from "./MedicalOrder";
import PreviewBodySection from "../patient/patient/report/comparison-report/PreviewBodySection";
import HeaderBar from "./HeaderBar";

const Root = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    background: SynckitColors.Primary[50],
  },
}));

const ContentSection = styled("div")(({ theme }) => ({
  marginTop: "1.5em",
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
}));

export default function ExamRecordEditorDialog({ open, setOpen }) {
  const [showPart, setShowPart] = useState(false);
  return (
    <Root fullScreen open={open} onClose={() => setOpen(false)}>
      <HeaderBar setOpen={setOpen} />
      <MenuBar />
      <ContentSection>
        <Box sx={{ mr: 3 }}>
          {showPart ? (
            <PreviewBodySection setShowPart={setShowPart} />
          ) : (
            <EditorSection setShowPart={setShowPart} />
          )}
          <br />
          <DataGallery />
        </Box>
        <Box>
          <ChiefComplaint />
          <br />
          <MedicalOrder />
        </Box>
      </ContentSection>
    </Root>
  );
}

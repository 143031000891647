import { styled } from "@mui/material";
import React, { useContext } from "react";
import ImgModelHeadEar from "../../../../assets/images/body/model_head_ear.png";
import { SynckitColors } from "../../../../assets/SynckitColors";
import { ConsultContext } from "../../ConsultIndex";
import { HeadEarSVG } from "./HeadEarSVG";
const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  position: "relative",
}));

const ImgModelBodyWrap = styled("div")(({ theme }) => ({
  position: "absolute",
  height: "90%",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  "& img": {
    height: "100%",
  },
}));
const SVGModelBodyWrap = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  height: "95%",
  "& svg": {
    height: "100%",
  },
  "& path": {
    fill: "rgba(255, 255, 255, 0.00009)",
    transition: "0.3s",
  },
  "& path:hover": {
    fill: SynckitColors.Yellow[200],
    cursor: "pointer",
  },
}));

export default function HeadEar() {
  const { currentPartId, setCurrentPartId } = useContext(ConsultContext);
  return (
    <Root>
      <ImgModelBodyWrap>
        <img src={ImgModelHeadEar} alt="synckit" />
      </ImgModelBodyWrap>
      <SVGModelBodyWrap>
        <HeadEarSVG
          handleClick={(e) => e.target.id && setCurrentPartId(e.target.id)}
          currentPartId={currentPartId}
        />
      </SVGModelBodyWrap>
    </Root>
  );
}

import { IconButton } from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect } from "react";
import DemoConsultationPatient from "../../../assets/demo/consultation_patient.png";
import VideocamIcon from "@mui/icons-material/Videocam";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import MicOffIcon from "@mui/icons-material/MicOff";
import CropFreeIcon from "@mui/icons-material/CropFree";
import axios from "axios";

// const demoRoomId = "bb56abdf-1fc1-4dbf-bb22-a90e30f9abe3";
// const demoRoomId = "bb56abdf-1fc1-4dbf-bb22-a90e30f9abe0";

const Root = styled("div")(({ theme }) => ({
  width: 800,
  height: 600,
  borderRadius: 8,
  overflow: "hidden",
  position: "relative",
}));
const ButtonWrap = styled("div")(({ theme }) => ({
  position: "absolute",
  right: 12,
  bottom: 15,
  height: 216,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
}));

const FunctionButton = styled(IconButton)({
  background: "rgba(255, 255, 255, 0.5)",
  width: 48,
  height: 48,
});

const RemoteViewWrap = styled("div")({
  // background: "red",
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  "& iframe": {
    // width: 410,
    // height: 500,
    // transform: "rotate(90deg)",
    // overflow: "hidden",
  },
});

export default function PatientViewDemo2() {
  return (
    <Root>
      {/* <figure>
        <img
          src={DemoConsultationPatient}
          alt="Synckit"
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
      </figure> */}
      <RemoteViewWrap>
        {/* <div id="patientRemoteView"></div> */}
        <iframe
          // src="https://test.viuto-aiot.com/monitor/bb56abdf-1fc1-4dbf-bb22-a90e30f9abe0"
          src="https://synckit.viuto-aiot.com/monitor/bb56abdf-1fc1-4dbf-bb22-a90e30f9abe0"
          frameborder="0"
          // scrolling="no"
          width="500px"
          height="1000px"
        ></iframe>
      </RemoteViewWrap>
    </Root>
  );
}

import { CalendarNav } from "@mobiscroll/react";
import { styled } from "@mui/system";
import { SynckitColors } from "../../assets/SynckitColors";

export const SynckitCalendarNav = styled(CalendarNav)({
  fontWeight: 700,
  fontSize: "1em",
  letterSpacing: 0.2,
  "& .mbsc-calendar-title": {
    color: SynckitColors.Gray["core"],
  },
});

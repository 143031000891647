import { styled, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useContext, useState } from "react";
import { SynckitColors } from "../../../../assets/SynckitColors";
import { ConsultContext } from "../../ConsultIndex";
import LungBack from "./LungBack";
import LungFront from "./LungFront";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
  position: "relative",
}));
const ModeSwitch = styled(ToggleButtonGroup)(({ theme }) => ({
  position: "absolute",
  zIndex: 10,
  top: 72,
  right: "3%",
  padding: 4,
  borderRadius: 8,
  background: SynckitColors.Gray[200],
  width: 132,
  height: 44,
  "& .MuiToggleButton-root": {
    background: "none",
    border: "none",
    textTransform: "initial",
    padding: "8px 15px",
    fontSize: 14,
    fontWeight: 400,
    color: SynckitColors.Gray[700],
    borderRadius: "8px !important",

    "&:hover": {
      background: SynckitColors.Gray[400],
    },
  },
  "& .Mui-selected": {
    background: "white !important",
    fontWeight: 700,
    color: SynckitColors.Gray["core"],
  },
}));

export default function Lung({ lungMode, setLungMode }) {
  const { setCurrentPartId } = useContext(ConsultContext);
  return (
    <Root>
      <ModeSwitch
        value={lungMode}
        exclusive
        onChange={(e) => {
          setLungMode(e.target.value);
          setCurrentPartId("");
        }}
      >
        <ToggleButton value="front">Front</ToggleButton>
        <ToggleButton value="Back">back</ToggleButton>
      </ModeSwitch>
      {lungMode === "front" ? <LungFront /> : <LungBack />}
    </Root>
  );
}

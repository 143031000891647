import { styled } from "@mui/system";

export const SynckitPanel = styled("div")({
  borderRadius: 12,
  padding: "4%",
  boxShadow:
    "0px 1.181249976158142px 4.625px 0px rgba(16, 57, 123, 0.0162), 0px 5.400000095367432px 13px 0px rgba(16, 57, 123, 0.025), 0px 13.668749809265137px 34.875px 0px rgba(16, 57, 123, 0.0338),0px 27px 80px 0px rgba(16, 57, 123, 0.05)",
  width: "100%",
  marginBottom: "3%",
  backgroundColor: "white",
});

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import { IconCloseCircleLine } from "../../assets/SynckitIcons";
import { SynckitColors } from "../../assets/SynckitColors";
import { SynckitDialog } from "./SynckitDialog";
import CloseIcon from "@mui/icons-material/Close";

const CustomButton = styled(Button)({
  borderRadius: 100,
  width: "50%",
  border: `1px solid ${SynckitColors.Gray[700]}`,
  color: SynckitColors.Gray[700],
  "&.cancel": {
    background: SynckitColors.Error["Dark"],
    color: "white",
    border: "none",
  },
});
function ApoCancelDialog() {
  const [openDialog, setOpenDialog] = useState(false);
  return (
    <Fragment>
      <IconButton onClick={() => setOpenDialog(true)}>
        <IconCloseCircleLine sx={{ fill: SynckitColors.Gray[800] }} />
      </IconButton>
      <SynckitDialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle sx={{ padding: 1, textAlign: "right" }}>
          <IconButton onClick={() => setOpenDialog(false)}>
            <CloseIcon sx={{ fill: SynckitColors.Gray[600] }} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: "0px 56px" }}>
          <Typography variant="h2" color="initial" sx={{ marginBottom: 2 }}>
            Appointment Cancel
          </Typography>
          <Typography variant="h6" color="initial">
            Please be notified that the consensus must be reached for the
            appointment to be successfully cancled. You will have to wait and
            acqiure the agreement on the other end upon the cancellation.
            <br /> The service point charged for this appointment will not be
            returned until the cancellation is confirmed.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ padding: "30px 56px" }}>
          <CustomButton onClick={() => setOpenDialog(false)}>Back</CustomButton>
          &nbsp; &nbsp;
          <CustomButton className="cancel" onClick={() => setOpenDialog(false)}>
            Cancel
          </CustomButton>
        </DialogActions>
      </SynckitDialog>
    </Fragment>
  );
}

export default ApoCancelDialog;

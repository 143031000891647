import React, { Fragment, useState } from "react";
import { Typography, IconButton, Divider, Checkbox } from "@mui/material";
import { Box, styled } from "@mui/system";
import { SynckitColors } from "../../../../assets/SynckitColors";
import {
  IconDoctor,
  IconEditLine,
  IconTrash2Line,
} from "../../../../assets/SynckitIcons";

import SynckitSearchBar2 from "../../../../components/SynckitSearchBar2";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { setOpenExamRecordEditor } from "../../../../redux/slice/examRecord.slice";

const TitleWrap = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: 60,
});

const ERList = styled("ul")(({ theme }) => ({
  background: "white",
  filter: SynckitColors.Shadow[1],
  borderRadius: 8,
  overflow: "hidden",
}));

const ERListItem = styled("li")(({ theme }) => ({
  padding: "20px 24px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "white",
  transition: "0.3s",
  "&:hover": {
    backgroundColor: SynckitColors.Primary[50],
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));
const ListToolBar = styled("li")(({ theme }) => ({
  padding: "16px 24px",
  background: SynckitColors.Gray[200],
  display: "flex",
  justifyContent: "space-between",
}));

export default function ExamRecordList() {
  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();
  return (
    <Box>
      <TitleWrap>
        <Typography variant="h5">Examination Record</Typography>
      </TitleWrap>
      <SynckitSearchBar2 />

      <ERList>
        <ListToolBar>
          {editMode ? (
            <>
              <Checkbox />
              <Box>
                <IconButton>
                  <IconTrash2Line />
                </IconButton>
                <IconButton onClick={() => setEditMode(false)}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </>
          ) : (
            <>
              <span></span>
              <IconButton onClick={() => setEditMode(true)}>
                <IconEditLine />
              </IconButton>
            </>
          )}
        </ListToolBar>

        {Array.from(Array(8), (e, i) => (
          <Fragment key={i}>
            <ERListItem onClick={() => dispatch(setOpenExamRecordEditor(true))}>
              {editMode && <Checkbox />}
              <IconDoctor color="primary" />
              <Typography variant="body2">G7S24620</Typography>
              <Typography variant="body2">Multiple</Typography>
              <Typography variant="body2">2021.10.22</Typography>
              <Typography variant="body2">10:00</Typography>
            </ERListItem>
            {i < 7 && <Divider />}
          </Fragment>
        ))}
      </ERList>
    </Box>
  );
}

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { SynckitColors } from "../../../assets/SynckitColors";
import { SettingList } from "./SystemIndex";
import CloseIcon from "@mui/icons-material/Close";
import { IconDoctor } from "../../../assets/SynckitIcons";

const PercentBar = styled("div")(({ theme }) => ({
  background: "#F5F9FB",
  borderRadius: 4,
  overflow: "hidden",
  width: "100%",
  height: 20,
  display: "flex",
  boxShadow: SynckitColors.Shadow["ButtomDivider"],
  div: { borderRight: `1px solid white` },
}));

const InfoList = styled("div")(({ theme }) => ({
  boxShadow: SynckitColors.Shadow["ButtomDivider"],
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: 64,
  width: 520,
}));

export default function Storage() {
  const [openDialog, setOpenDialog] = useState(false);
  const handleClose = () => {
    setOpenDialog(false);
  };
  return (
    <Box style={{ maxWidth: 600, textAlign: "center" }}>
      <SettingList>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 2,
          }}
        >
          <Typography variant="subtitle1">System</Typography>
          <Typography variant="body2" sx={{ color: SynckitColors.Gray[800] }}>
            Used 800MB ( Total 1GB )
          </Typography>
        </Box>
        <PercentBar>
          <div style={{ background: "#FDCC00", width: `${30}%` }}>{"　"}</div>
          <div style={{ background: "#FE2C55", width: `${40}%` }}>{"　"}</div>
          <div style={{ background: "#8B8A8F", width: `${10}%` }}>{"　"}</div>
        </PercentBar>
        <ul style={{ display: "flex" }}>
          {itemList.map((item) => (
            <li
              style={{ display: "flex", alignItems: "center", marginRight: 16 }}
            >
              <span style={{ color: item.color, fontSize: 26 }}>{"●"}</span>
              <Typography variant="body2" sx={{ ml: 0.4, mt: 0.5 }}>
                {item.title}
              </Typography>
            </li>
          ))}
        </ul>
        <br />
      </SettingList>
      <Box sx={{ mt: 5, textAlign: "center" }}>
        <Button variant={"secondary"}>Clear all data</Button>
        {"　"}
        <Button variant={"primary"} onClick={() => setOpenDialog(true)}>
          Clear data before 90 days
        </Button>
      </Box>
      <Dialog open={openDialog} onClose={handleClose} fullWidth>
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>{"　"}</span>
          <Typography variant="h5">
            These Examination Record wil be deleted.
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {Array.from(Array(6), (e, i) => (
            <InfoList>
              <Checkbox />
              <IconDoctor sx={{ color: SynckitColors.Primary[600] }} />
              <Typography variant="body2">G7S24620</Typography>
              <Typography variant="body2">Multiple</Typography>
              <Typography variant="body2">2021.10.22</Typography>
              <Typography variant="body2">10:00</Typography>
            </InfoList>
          ))}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button variant="secondary">back</Button>

          <Button variant="primary">Clear data</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
const itemList = [
  { id: 1, title: "Record", color: "#FDCC00" },
  { id: 2, title: "User Data", color: "#FE2C55" },
  { id: 3, title: "Cache", color: "#8B8A8F" },
];

import {
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { Fragment, useState } from "react";
import { IconSaveLine } from "../../../../../assets/SynckitIcons";
import TemplateDialog from "./TemplateDialog";

const Root = styled("div")(({ theme }) => ({
  maxWidth: 980,
  margin: "0px auto 16px",
  padding: "0px 8px",
}));
const Templatemanagementindex = () => {
  const [saveAsTemplate, setSaveAsTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [saveChangesToSelectedTemplate, setSaveChangesToSelectedTemplate] =
    useState(false);

  return (
    <Root>
      {selectedTemplate ? (
        <Chip
          label={selectedTemplate.name}
          variant="outlined"
          color="primary"
          onDelete={() => setSelectedTemplate(null)}
          sx={{ margin: "0px 10px" }}
        />
      ) : (
        <TemplateDialog setSelectedTemplate={setSelectedTemplate} />
      )}
      {selectedTemplate ? (
        <FormControlLabel
          control={
            <Checkbox
              checked={saveChangesToSelectedTemplate}
              onChange={(e) =>
                setSaveChangesToSelectedTemplate(e.target.checked)
              }
            />
          }
          label={
            <Typography variant="Body2">Save changes to template</Typography>
          }
        />
      ) : (
        <Fragment>
          <FormControlLabel
            control={
              <Checkbox
                checked={saveAsTemplate}
                onChange={(e) => setSaveAsTemplate(e.target.checked)}
              />
            }
            label={<Typography variant="Body2">Save as template</Typography>}
            sx={{ margin: "0px 10px" }}
          />
          {saveAsTemplate && (
            <TextField
              label="Template‘s name"
              size="small"
              sx={{ background: "white" }}
              InputProps={{
                endadornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <IconSaveLine color="primary" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </Fragment>
      )}
    </Root>
  );
};

export default Templatemanagementindex;

import { Button, styled } from "@mui/material";
import React, { useContext, useState } from "react";
import { IconLeave } from "../../assets/SynckitIcons";
import ConfirmDialog from "../../components/dialog/ConfirmDialog";
import { ConsultThemeAry } from "./ConsultTheme";
import { ConsultContext } from "./ConsultIndex";

const SaveButton = styled(Button)({
  background: "white",
  borderRadius: 100,
  padding: "6px 20px",
  fontSize: 14,
  fontWeight: 600,
  lineHeight: "23px",
  color: "#FFB54D",
});
export default function SureToLeaveDialog({ open, setOpen }) {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const { currentExamItem } = useContext(ConsultContext);
  return (
    <div>
      <SaveButton
        endIcon={<IconLeave />}
        onClick={() => setOpenConfirmDialog(true)}
        sx={ConsultThemeAry[currentExamItem]["saveBtnStyle"]}
      >
        Save and Leave
      </SaveButton>

      <ConfirmDialog
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        title={"Sure to leave ?"}
        content={
          " You will no longer be able to access this patient’s profile and examination record. The other authorized user will still have the access."
        }
        cancelBtnTitle={"Back"}
        confirmBtnTitle={"Leave"}
        confirmBtnHandleClick={() => {
          setOpenConfirmDialog(false);
          setOpen(false);
        }}
      />
    </div>
  );
}

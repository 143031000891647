import { SynckitColors } from "../../../../assets/SynckitColors";

export const ModelBodyFrontTop = ({ currentPartId = "" }) => {
  return (
    <svg viewBox="74 115 194 217" stroke="">
      <path
        d="M185.971 179.089C186.121 178.399 186.211 177.979 186.211 177.979C188.551 175.669 189.771 170.779 189.771 170.779C190.021 168.089 190.651 167.199 190.651 167.199C190.771 166.899 191.501 166.959 191.501 166.959C194.091 166.559 195.771 163.879 195.771 163.879C197.801 161.669 197.711 156.139 197.711 156.139C197.781 153.089 196.941 151.009 196.941 151.009C196.471 149.699 195.051 150.589 195.051 150.589C194.001 151.079 194.091 149.399 194.091 149.399C194.671 146.369 193.951 141.849 193.951 141.849C192.271 131.309 185.981 124.369 185.981 124.369C179.971 116.259 169.801 116.769 169.801 116.769C163.131 116.439 158.521 118.869 158.521 118.869C154.281 120.829 150.601 125.719 150.601 125.719C147.891 129.729 145.841 135.229 145.841 135.229C142.901 142.869 144.071 150.429 144.071 150.429C144.201 151.649 143.091 150.759 143.091 150.759C140.571 149.319 140.481 157.519 140.481 157.519C140.431 165.349 146.281 166.589 146.281 166.589C147.431 166.629 147.471 167.359 147.471 167.359C148.101 169.319 149.361 173.299 149.361 173.299C150.551 177.699 152.581 179.379 152.581 179.379L152.651 179.899C156.551 183.749 162.731 188.299 170.031 188.089C170.021 188.079 177.741 188.509 185.971 179.089Z"
        stroke=""
        id="f1"
        style={{
          fill: currentPartId === "f1" ? SynckitColors.Yellow[200] : "",
        }}
      />
      <path
        d="M198.55 206.84C197.76 206.34 197.24 205.99 197.24 205.99C195.54 204.75 191 201.78 191 201.78C185.23 198.67 184.39 192.52 184.39 192.52C184.02 188.74 185.39 181.77 185.96 179.08C177.73 188.51 170.01 188.07 170.01 188.07C162.71 188.29 156.53 183.74 152.63 179.88L153.54 186.78C154.38 191.95 152.35 196.22 152.35 196.22C149.13 201.74 144.66 204.82 144.66 204.82C143.92 205.44 142.93 206.11 141.77 206.8C145.77 208.94 156.24 213.67 170.56 213.6C170.59 213.62 184.89 213.83 198.55 206.84Z"
        stroke=""
        id="f2"
        style={{
          fill: currentPartId === "f2" ? SynckitColors.Yellow[200] : "",
        }}
      />
      <path
        d="M215.651 281.47C215.711 281.28 215.771 281.13 215.821 280.99C215.661 269.95 216.451 239.74 228.651 218.67C225.861 217.21 223.811 216.89 223.811 216.89C220.991 216.21 215.811 214.82 215.811 214.82C212.521 213.47 204.621 210.07 204.621 210.07C202.981 209.58 200.191 207.9 198.541 206.86C184.881 213.84 170.581 213.63 170.581 213.63C156.261 213.69 145.791 208.97 141.791 206.83C135.631 210.55 124.961 214.91 124.961 214.91C116.571 216.8 116.221 217.43 116.221 217.43C115.081 217.84 114.021 218.32 113.031 218.87C124.461 238.44 125.651 267.27 125.511 280.78C125.681 281.05 125.801 281.44 125.801 281.44C126.201 283.44 127.591 288.23 129.051 293.05H211.601L215.651 281.47Z"
        stroke=""
        id="f3"
        style={{
          fill: currentPartId === "f3" ? SynckitColors.Yellow[200] : "",
        }}
      />
      <path
        d="M210.41 342.229C208.94 336.009 207.47 312.649 207.47 312.649C207.26 306.009 209.15 300.059 209.15 300.059L211.61 293.029H129.06C131.01 299.459 133.08 305.939 133.08 305.939C134.48 309.109 132.34 325.239 132.34 325.239C130.66 334.659 129.73 346.679 129.73 346.679C127.02 353.019 127.12 360.759 124.79 368.309C124.23 370.139 123.51 373.699 122.75 378.029H216.87C214.28 362.419 210.41 342.229 210.41 342.229Z"
        stroke=""
        id="f4"
        style={{
          fill: currentPartId === "f4" ? SynckitColors.Yellow[200] : "",
        }}
      />
      <path
        d="M113.031 218.859C103.371 224.199 100.831 235.249 100.831 235.249C97.4714 245.319 98.1014 257.699 98.1014 257.699C98.3114 259.519 99.5014 278.049 99.5014 278.049C99.7114 284.339 97.2614 296.509 97.2614 296.509C96.3514 301.609 93.2814 321.329 93.2814 321.329C93.2814 322.799 91.3914 329.259 91.3914 329.259C91.3314 329.459 91.2614 329.659 91.1914 329.879H114.361C114.361 322.179 117.401 308.659 117.401 308.659C118.891 304.089 124.391 282.739 124.391 282.739C124.741 280.159 125.211 280.309 125.501 280.779C125.651 267.249 124.471 238.429 113.031 218.859Z"
        stroke=""
        id="f6"
        style={{
          fill: currentPartId === "f6" ? SynckitColors.Yellow[200] : "",
        }}
      />
      <path
        d="M215.819 280.99C216.549 279.25 216.979 281.89 216.979 281.89L219.289 292.1C221.599 299.02 225.579 323.35 225.579 323.35C226.119 325.91 226.309 328.1 226.329 329.87H248.829C247.389 325.42 246.479 318.88 246.479 318.88L242.709 287.34C241.589 280.21 242.219 273.85 242.219 273.85C243.479 267.91 243.969 257 243.969 257C244.949 242.1 240.679 232.6 240.679 232.6C237.399 224.61 232.369 220.63 228.649 218.67C216.459 239.73 215.669 269.95 215.819 280.99Z"
        stroke=""
        id="f14"
        style={{
          fill: currentPartId === "f14" ? SynckitColors.Yellow[200] : "",
        }}
      />
    </svg>
  );
};

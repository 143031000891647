import React, { useState } from "react";
import SynckitSnackbar from "../../../assets/SynckitSnackbar";
import OwnIndex from "./own/OwnIndex";
import PatientsIndex from "./patients/PatientsIndex";
import Toolbar from "./Toolbar";

export default function ManagementIndex() {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  return (
    <>
      <Toolbar />
      <OwnIndex />
      <PatientsIndex />
      <SynckitSnackbar
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        alertTitle="Successfully added"
      />
    </>
  );
}

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  styled,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { SynckitColors } from "../../../assets/SynckitColors";
import Eula from "./Eula";
import Language from "./Language";
import Notification from "./Notification";
import Privacy from "./Privacy";
import Storage from "./Storage";

const CTab = styled(Tab)(({ theme }) => ({
  background: SynckitColors.Gray[100],
  borderRadius: 200,
  marginRight: 16,

  textTransform: "none",
  letterSpacing: 0.1,
  "&.Mui-selected": {
    background: SynckitColors.Primary[50],
    fontWeight: 700,
  },
}));

export const SettingList = styled("div")(({ theme }) => ({
  maxWidth: 600,
  marginTop: 26,
  boxShadow: SynckitColors.Shadow["ButtomDivider"],
}));

export default function SystemIndex() {
  const [selectedId, setSelectedId] = useState(1);
  const handleChange = (event, newValue) => {
    setSelectedId(newValue);
  };
  return (
    <div>
      <Tabs
        value={selectedId}
        onChange={handleChange}
        variant="scrollable"
        indicatorColor=""
        sx={{ mb: 5 }}
      >
        {buttonList.map((item, index) => (
          <CTab label={item.title} key={index} value={item.id} />
        ))}
      </Tabs>

      {selectedId === 1 && <Notification />}
      {selectedId === 2 && <Language />}
      {selectedId === 3 && <Privacy />}
      {selectedId === 4 && <Eula />}
      {selectedId === 5 && <Storage />}
    </div>
  );
}

const buttonList = [
  { id: 1, title: "Notification" },
  { id: 2, title: "Language" },
  { id: 3, title: "Privacy" },
  { id: 4, title: "EULA / TOU" },
  { id: 5, title: "Storage" },
];

import { IconButton, InputAdornment, TextField } from "@mui/material";
import React from "react";
import TuneIcon from "@mui/icons-material/Tune";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/system";
import { SynckitColors } from "../assets/SynckitColors";

const SearchBar = styled(TextField)(({ theme }) => ({
  marginBottom: 29,

  "& .MuiOutlinedInput-root": {
    paddingTop: 2,
    paddingBottom: 2,
    borderRadius: 8,
    background: "white",
    width: "100%",
    maxWidth: 777,

    "& fieldset": {
      border: `0px solid white`,
      transition: "0.3s",
    },
    "&:hover fieldset": {
      border: `1px solid ${SynckitColors.Primary[300]}`,
    },
    "&.Mui-focused fieldset": {
      border: `2px solid ${SynckitColors.Primary[300]}`,
    },
  },
}));

export default function SynckitSearchBarSimple2() {
  return (
    <>
      <SearchBar
        fullWidth
        variant="outlined"
        placeholder="Search"
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: SynckitColors.Gray[600] }} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end">
                <TuneIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}

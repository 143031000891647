import { Box, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import React, { useState } from "react";
import { SynckitColors } from "../../../../../assets/SynckitColors";
import CloseIcon from "@mui/icons-material/Close";
import ImgModelBodyFront from "../../../../../assets/images/body/model_body_front.png";
import { ModelBodyFront } from "../../../../consult/body-card/body-front/ModelBodyFront";
import { CR_MODE_PREVIEW_IMG } from "../../../../../assets/constant";

const PreviewRoot = styled("div")(({ theme }) => ({
  background: "#FFFEFA",
  border: "1px solid #FFC802",
  width: 775,
  height: 458,
  borderRadius: 7,
  overflow: "hidden",
  position: "relative",
  marginBottom: 24,
  padding: "25px 43px",
}));
const FileNameLable = styled("span")(({ theme }) => ({
  position: "absolute",
  top: "1em",
  left: "1em",
  color: "white",
  fontWeight: 700,
  fontSize: 16,
  letterSpacing: 0.1,
}));
const SelectButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "0.3em",
  right: "0.3em",
}));
const BodyButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "12em",
  right: "0.6em",
  background: "rgba(22, 42, 75, 0.2)",
  zIndex: 10,
}));

const PreviewImg = styled("img")(({ theme }) => ({
  height: "100%",
  objectFit: "contain",
}));

const CommentSection = styled("div")(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  width: "100%",
  padding: "0em 1em 1em 1em",
  "& .MuiOutlinedInput-root": {
    background: "rgba(0, 0, 0, 0.2)",
    color: SynckitColors.Gray[600],
    "& fieldset": {
      borderColor: SynckitColors.Gray[600],
    },
    "&:hover fieldset": {
      borderColor: SynckitColors.Gray[200],
    },
    "&.Mui-focused fieldset": {
      borderColor: SynckitColors.Gray[200],
    },
  },
}));

const ImgModelBodyWrap = styled("div")(({ theme }) => ({
  position: "absolute",
  height: "90%",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  "& #bodyFront": {
    height: "100%",
  },
  "& #bodyBack": {
    marginTop: 8,
    marginLeft: 3,
    width: 642,
    height: 480,
  },
}));
const SVGModelBodyWrap = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  height: "90%",
  "& svg": {
    height: "100%",
  },
  "& path": {
    fill: "rgba(255, 255, 255, 0.00009)",
    transition: "0.3s",
  },
}));

export default function PreviewBodySection({ setDisplayMode }) {
  const [isSelected, setIsSelected] = useState(false);
  const [commentMode, setCommentMode] = useState("doctor");
  const [doctorComment, setDoctorComment] = useState("");
  const [patientComment, setPatientComment] = useState("");

  return (
    <div>
      <PreviewRoot>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">R / L</Typography>
          <IconButton onClick={() => setDisplayMode(CR_MODE_PREVIEW_IMG)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <ImgModelBodyWrap>
          <img src={ImgModelBodyFront} alt="synckit" id="bodyFront" />
        </ImgModelBodyWrap>
        <SVGModelBodyWrap>
          <ModelBodyFront currentPartId={"f3"} />
        </SVGModelBodyWrap>
      </PreviewRoot>
    </div>
  );
}

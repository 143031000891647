import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  styled,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { SynckitColors } from "../../../assets/SynckitColors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";

const CList = styled("div")(({ theme }) => ({
  borderRadius: 8,
  overflow: "hidden",
  "& .header": {
    background: SynckitColors.Gray[200],
    display: "flex",
    justifyContent: "space-between",
    padding: "16px 24px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: 8,
    },
  },
}));

const CAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: "none",
  "&.Mui-expanded": {
    marginTop: 0,
  },
}));
const CAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  marginTop: 0,
  height: 70,
  [theme.breakpoints.down("sm")]: {
    height: "auto",
  },
  "& .MuiAccordionSummary-content": {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}));
const InfoCard = styled("ul")(({ theme }) => ({
  border: `1px solid ${SynckitColors.Gray[400]}`,
  padding: "2%",
  height: 130,
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  li: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 4,
    maxWidth: 330,
  },
  [theme.breakpoints.down("md")]: {
    height: "auto",
    padding: "3%",
  },
}));

export default function MyPointsWithExpiration({ setPointsMode }) {
  return (
    <div>
      <Button
        sx={{ color: SynckitColors.Gray["core"], mb: 2 }}
        startIcon={<ArrowBackIosIcon />}
        onClick={() => setPointsMode("default")}
      >
        <Typography variant="subtitle1">
          {"My points (with expiration)"}
        </Typography>
      </Button>

      <CList>
        <div className="header">
          <Typography
            variant="subtitle2"
            sx={{ color: SynckitColors.Gray[700] }}
          >
            Expiration date
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ color: SynckitColors.Gray[700] }}
          >
            Description
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{ color: SynckitColors.Gray[700] }}
          >
            Points
          </Typography>
          <Box sx={{ width: 130 }}>　</Box>
        </div>
        {expirationList.map((item, index) => (
          <CAccordion key={index}>
            <CAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ justifyContent: "space-between" }}
            >
              <Typography
                variant="body2"
                sx={{ color: SynckitColors.Gray[500] }}
              >
                {item.date}
              </Typography>
              <Typography variant="body2">{item.description}</Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <PaidOutlinedIcon
                  sx={{
                    color: SynckitColors.Primary[600],
                    width: 20,
                    height: 20,
                    mr: 1,
                  }}
                />
                <Typography variant="h6">{item.points}</Typography>
              </Box>
              <span>　</span>
            </CAccordionSummary>
            <AccordionDetails>
              <RenderInfoCard />
            </AccordionDetails>
          </CAccordion>
        ))}
      </CList>
    </div>
  );
}

const RenderInfoCard = () => {
  return (
    <InfoCard>
      {infoDataList.map((item, index) => (
        <li key={index}>
          <Typography variant="subtitle2">{item.title}</Typography>
          <Typography variant="body2">{item.val}</Typography>
        </li>
      ))}
    </InfoCard>
  );
};

const expirationList = [
  { id: 1, date: "2021.10.23", description: "Purchase", points: 25 },
  { id: 2, date: "2021.10.24", description: "Purchase", points: 26 },
  { id: 3, date: "2021.10.25", description: "Purchase", points: 27 },
];

const infoDataList = [
  { title: "Points", val: "40 points" },
  { title: "Date", val: "2021/2/22   14:22" },
  { title: "Expiration date", val: "2021/2/22" },
  { title: "Amount ", val: "40 points" },
  { title: "Credit card", val: "4556 09** **** 8888 " },
  { title: "Record", val: "2022/2/22   14:22      -10" },
];

import { SynckitColors } from "../../../../assets/SynckitColors";

export const HeartSVG = ({ currentPartId = "", handleClick }) => {
  return (
    <svg viewBox="0 6 488 378" fill="none" stroke="" onClick={handleClick}>
      <circle
        cx="220.612"
        cy="148.9"
        r="18.4323"
        fill="white"
        fill-opacity="0.8"
        stroke="#9E50F3"
        stroke-width="1.55661"
        id="heart_a"
        style={{
          fill: currentPartId === "heart_a" ? SynckitColors.Purple[100] : "",
        }}
      />
      <path
        d="M216.351 144.159L213.461 152.117H212.279L215.607 143.386H216.369L216.351 144.159ZM218.774 152.117L215.877 144.159L215.859 143.386H216.621L219.961 152.117H218.774ZM218.624 148.884V149.832H213.719V148.884H218.624Z"
        style={{ fill: "black" }}
      />
      <circle
        cx="278.741"
        cy="148.9"
        r="18.4323"
        fill="white"
        fill-opacity="0.8"
        stroke="#9E50F3"
        stroke-width="1.55661"
        id="heart_p"
        style={{
          fill: currentPartId === "heart_p" ? SynckitColors.Purple[100] : "",
        }}
      />
      <path
        d="M275.886 148.693H273.553V147.751H275.886C276.338 147.751 276.703 147.679 276.983 147.535C277.263 147.391 277.467 147.191 277.595 146.936C277.727 146.68 277.793 146.388 277.793 146.06C277.793 145.76 277.727 145.478 277.595 145.215C277.467 144.951 277.263 144.739 276.983 144.579C276.703 144.415 276.338 144.333 275.886 144.333H273.823V152.117H272.666V143.386H275.886C276.545 143.386 277.103 143.5 277.559 143.727C278.015 143.955 278.36 144.271 278.596 144.675C278.832 145.075 278.95 145.532 278.95 146.048C278.95 146.608 278.832 147.086 278.596 147.481C278.36 147.877 278.015 148.179 277.559 148.387C277.103 148.591 276.545 148.693 275.886 148.693Z"
        style={{ fill: "black" }}
      />
      <circle
        cx="278.741"
        cy="197.588"
        r="18.4323"
        fill="white"
        fill-opacity="0.8"
        stroke="#9E50F3"
        stroke-width="1.55661"
        id="heart_e"
        style={{
          fill: currentPartId === "heart_e" ? SynckitColors.Purple[100] : "",
        }}
      />
      <path
        d="M278.213 200.467V201.408H273.589V200.467H278.213ZM273.823 192.677V201.408H272.666V192.677H273.823ZM277.601 196.431V197.373H273.589V196.431H277.601ZM278.153 192.677V193.625H273.589V192.677H278.153Z"
        style={{ fill: "black" }}
      />
      <circle
        cx="278.741"
        cy="264.391"
        r="18.4323"
        fill="white"
        fill-opacity="0.8"
        stroke="#9E50F3"
        stroke-width="1.55661"
        id="heart_t"
        style={{
          fill: currentPartId === "heart_t" ? SynckitColors.Purple[100] : "",
        }}
      />
      <path
        d="M275.892 260.899V269.63H274.753V260.899H275.892ZM278.698 260.899V261.846H271.952V260.899H278.698Z"
        style={{ fill: "black" }}
      />
      <circle
        cx="324.116"
        cy="291.567"
        r="18.4323"
        fill="white"
        fill-opacity="0.8"
        stroke="#9E50F3"
        stroke-width="1.55661"
        id="heart_m"
        style={{
          fill: currentPartId === "heart_m" ? SynckitColors.Purple[100] : "",
        }}
      />
      <path
        d="M315.571 286.945H316.692L319.552 294.063L322.407 286.945H323.534L319.984 295.676H319.109L315.571 286.945ZM315.205 286.945H316.194L316.356 292.27V295.676H315.205V286.945ZM322.904 286.945H323.894V295.676H322.742V292.27L322.904 286.945Z"
        style={{ fill: "black" }}
      />
    </svg>
  );
};

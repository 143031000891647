import { styled, IconButton, Typography, Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import VideoDemo1 from "../../../../../../assets/demo/yorkie1.mp4";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { SynckitColors } from "../../../../../../assets/SynckitColors";
import CommentSection from "../CommentSection";
import { CR_MODE_PREVIEW_VIDEO_PLAY } from "../../../../../../assets/constant";

const Root = styled("div")(({ theme }) => ({
  width: 775,
  height: 458,
  borderRadius: 7,
  overflow: "hidden",
  position: "relative",
  marginBottom: 24,
}));
const FileNameLable = styled("span")(({ theme }) => ({
  position: "absolute",
  top: "1em",
  left: "1em",
  color: "white",
  fontWeight: 700,
  fontSize: 16,
  letterSpacing: 0.1,
}));
const SelectButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "0.3em",
  right: "0.3em",
  zIndex: 10,
}));

const VideoBox = styled("video")(({ theme }) => ({
  height: "100%",
  objectFit: "contain",
}));

const PlayButtonWrap = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  textAlign: "center",
  zIndex: 10,
}));

export default function VideoEditorDefault({ setDisplayMode }) {
  const [isSelected, setIsSelected] = useState(false);
  const [isPlay, setIsPlay] = useState(false);
  const [count, setCount] = useState(0);

  const videoRef = useRef();
  useEffect(() => {
    let playInterval = null;
    if (isPlay && count > 138) {
      setCount(0);
      setIsPlay(false);
    }

    if (isPlay) {
      playInterval = setInterval(() => {
        videoRef.current.currentTime = count * 0.1;
        setCount((count) => count + 1);
      }, 100);
    } else if (!isPlay && count !== 0) {
      clearInterval(playInterval);
    }
    return () => clearInterval(playInterval);
  }, [isPlay, count]);

  return (
    <Root>
      <FileNameLable>124459151</FileNameLable>
      <SelectButton
        onClick={() => setIsSelected(!isSelected)}
        disabled
        sx={{ color: SynckitColors.Gray[50] }}
      >
        <CircleOutlinedIcon
          sx={{ color: "rgba(255,255,255,0.1)" }}
          fontSize="large"
        />
      </SelectButton>
      <PlayButtonWrap>
        <Box>
          <IconButton
            onClick={() => setDisplayMode(CR_MODE_PREVIEW_VIDEO_PLAY)}
          >
            <PlayCircleIcon sx={{ width: 100, height: 100, color: "white" }} />
          </IconButton>

          <Typography variant="subtitle2" sx={{ color: "white" }}>
            Play to choose an image for your video
          </Typography>
        </Box>
      </PlayButtonWrap>

      <CommentSection />
      <VideoBox
        ref={videoRef}
        muted
        onPlay={() =>
          console.log("videoRef current:", videoRef.current.currentTime)
        }
      >
        <source src={VideoDemo1} type="video/mp4" />
      </VideoBox>
    </Root>
  );
}

import { styled } from "@mui/material";
import React, { useContext } from "react";
import ImgModelHeart from "../../../../assets/images/body/model_heart.png";
import { SynckitColors } from "../../../../assets/SynckitColors";
import { ConsultContext } from "../../ConsultIndex";
import { HeartSVG } from "./HeartSVG";
const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  position: "relative",
}));

const ImgModelBodyWrap = styled("div")(({ theme }) => ({
  position: "absolute",
  height: "76%",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  "& img": {
    height: "100%",
  },
}));
const SVGModelBodyWrap = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  height: "76%",
  "& svg": {
    height: "100%",
  },
  "& circle": {
    fill: "rgba(255, 255, 255, 0.8)",
    transition: "0.3s",
  },
  "& circle:hover": {
    fill: SynckitColors.Purple[100],
    cursor: "pointer",
  },
}));

export default function Heart() {
  const { currentPartId, setCurrentPartId } = useContext(ConsultContext);
  return (
    <Root>
      <ImgModelBodyWrap>
        <img src={ImgModelHeart} alt="synckit" />
      </ImgModelBodyWrap>
      <SVGModelBodyWrap>
        <HeartSVG
          handleClick={(e) => e.target.id && setCurrentPartId(e.target.id)}
          currentPartId={currentPartId}
        />
      </SVGModelBodyWrap>
    </Root>
  );
}

import { SynckitColors } from "../../../../assets/SynckitColors";

export const HeadThroatSVG = ({ currentPartId = "", handleClick }) => {
  return (
    <svg viewBox="0.6 25 400 272" onClick={handleClick}>
      <path
        opacity="0.2"
        d="M143.712 180.124C143.712 171.468 135.788 165.397 131.827 163.894C127.438 133.599 140.36 111.598 147.369 103.483L154.684 99.8768C156.208 98.0736 160.17 96.2702 161.998 96.2702C165.295 96.2702 171.141 95.3686 172.969 93.5654C174.432 92.1229 175.713 92.6636 177.541 91.762C182.112 93.5653 189.427 95.3688 194.912 93.5654C199.819 91.9524 202.227 89.0571 204.055 91.762C205.518 93.9259 214.721 93.8659 218.683 93.5654C221.121 93.2648 226.546 92.4833 228.74 91.762C231.483 90.8604 243.369 95.3686 250.683 97.172C256.535 98.6146 261.045 104.385 264.398 110.697C268.664 118.812 279.026 146.763 271.712 156.681C263.503 167.812 261.655 183.731 259.826 189.141C258.363 193.469 253.121 190.944 250.683 189.141C250.378 185.234 240.809 177.059 204.969 175.616C169.129 174.173 151.636 185.234 147.369 190.944C145.541 191.846 143.712 190.944 143.712 180.124Z"
        fill={SynckitColors.Tertiary[100]}
        id="throat_pu"
        style={{
          opacity: currentPartId === "throat_pu" ? 0.8 : null,
        }}
      />
      <path
        opacity="0.1"
        d="M173.924 135.073C166.992 135.073 159.482 147.602 155.355 156.791C153.667 160.549 150.816 166.814 150.816 172.244C150.816 182.688 161.132 179.344 166.497 173.914C168.56 170.573 168.973 170.156 167.322 163.473C165.671 156.791 170.761 147.463 173.924 141.337C177.638 137.161 175.575 135.073 173.924 135.073Z"
        id="throat_left"
        fill={SynckitColors.Tertiary[600]}
        style={{
          opacity: currentPartId === "throat_left" ? 0.7 : null,
        }}
      />
      <path
        opacity="0.7"
        d="M164.051 142.843C160.828 147.075 157.94 152.461 155.887 157.032L155.832 157.156C154.124 160.956 151.4 167.023 151.4 172.244C151.4 174.768 152.023 176.342 152.927 177.262C153.824 178.176 155.094 178.543 156.606 178.446C159.65 178.25 163.417 176.177 166.034 173.548C166.063 173.501 166.091 173.455 166.119 173.41C167.093 171.834 167.589 171.033 167.72 169.88C167.861 168.646 167.584 166.976 166.753 163.615C165.884 160.094 166.798 155.949 168.269 151.991C169.606 148.393 171.44 144.855 172.945 141.954C173.102 141.651 173.255 141.355 173.404 141.067L173.438 141.001L173.487 140.946C175.299 138.908 175.555 137.54 175.367 136.78C175.185 136.043 174.529 135.66 173.923 135.66C172.365 135.66 170.718 136.366 169.029 137.643C167.343 138.918 165.662 140.726 164.051 142.843ZM168.326 136.705C170.123 135.346 172.014 134.485 173.923 134.485C174.967 134.485 176.168 135.146 176.502 136.497C176.827 137.815 176.27 139.559 174.408 141.673C174.27 141.941 174.128 142.215 173.983 142.494C172.475 145.403 170.674 148.876 169.364 152.402C167.912 156.309 167.107 160.17 167.888 163.332C168.708 166.653 169.05 168.533 168.881 170.014C168.719 171.436 168.087 172.457 167.175 173.928C167.115 174.025 167.054 174.123 166.992 174.224L166.957 174.281L166.91 174.328C164.165 177.107 160.119 179.397 156.681 179.618C154.944 179.73 153.299 179.314 152.095 178.087C150.897 176.867 150.23 174.942 150.23 172.244C150.23 166.766 153.041 160.511 154.727 156.759C154.759 156.688 154.79 156.618 154.821 156.549C156.895 151.932 159.825 146.459 163.122 142.129C164.77 139.965 166.524 138.067 168.326 136.705Z"
        fill={SynckitColors.Tertiary[100]}
      />
      <path
        opacity="0.1"
        d="M232.177 136.317C239.11 136.317 246.62 148.847 250.746 158.035C252.434 161.794 255.285 168.059 255.285 173.489C255.285 183.932 244.969 180.589 239.605 175.159C237.542 171.818 237.129 171.4 238.779 164.718C240.43 158.035 235.341 148.708 232.177 142.582C228.463 138.406 230.527 136.317 232.177 136.317Z"
        id="throat_right"
        fill={SynckitColors.Tertiary[600]}
        style={{
          opacity: currentPartId === "throat_right" ? 0.7 : null,
        }}
      />
      <path
        opacity="0.7"
        d="M242.051 144.087C245.274 148.32 248.162 153.706 250.215 158.277L250.27 158.4C251.977 162.201 254.702 168.267 254.702 173.489C254.702 176.012 254.079 177.586 253.175 178.507C252.278 179.42 251.008 179.788 249.495 179.69C246.452 179.494 242.685 177.421 240.068 174.792C240.039 174.746 240.01 174.7 239.982 174.655C239.008 173.079 238.513 172.277 238.382 171.125C238.241 169.891 238.518 168.221 239.348 164.859C240.218 161.338 239.304 157.194 237.833 153.236C236.496 149.637 234.661 146.1 233.157 143.199C233 142.896 232.846 142.6 232.697 142.312L232.664 142.246L232.615 142.191C230.802 140.153 230.547 138.784 230.734 138.025C230.916 137.288 231.573 136.905 232.179 136.905C233.736 136.905 235.383 137.61 237.073 138.888C238.758 140.162 240.439 141.971 242.051 144.087ZM237.776 137.949C235.979 136.59 234.087 135.73 232.179 135.73C231.134 135.73 229.933 136.391 229.6 137.742C229.274 139.059 229.831 140.804 231.693 142.918C231.832 143.186 231.974 143.459 232.118 143.738C233.627 146.647 235.427 150.12 236.737 153.647C238.189 157.554 238.994 161.415 238.214 164.576C237.393 167.898 237.051 169.777 237.22 171.258C237.382 172.68 238.015 173.702 238.926 175.173C238.986 175.27 239.047 175.368 239.109 175.469L239.144 175.526L239.191 175.573C241.936 178.351 245.983 180.641 249.421 180.862C251.158 180.974 252.802 180.559 254.007 179.332C255.205 178.111 255.871 176.187 255.871 173.489C255.871 168.011 253.061 161.756 251.375 158.004C251.343 157.933 251.311 157.863 251.28 157.794C249.207 153.176 246.276 147.703 242.98 143.374C241.332 141.21 239.577 139.311 237.776 137.949Z"
        fill={SynckitColors.Tertiary[100]}
      />
    </svg>
  );
};

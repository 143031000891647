import { SynckitColors } from "../../../../assets/SynckitColors";

export const ModelBodyFrontBottom = ({ currentPartId = "" }) => {
  return (
    <svg viewBox="13 2 136 150" stroke=" ">
      <path
        id="f21"
        stroke=""
        style={{
          fill: currentPartId === "f21" ? SynckitColors.Yellow[200] : "",
        }}
        d="M107.78,102.37l-.69,9.26a9,9,0,0,0,.37,5,2.92,2.92,0,0,1,.2.75c-.23,1.4-.59,4.64-.59,4.64l-.39,5.08a4.21,4.21,0,0,0,.52,2.91c.92,1.82,4.12,3.45,4.12,3.45.39.14.22.18.22.18,0,1.57.25,4.14.25,4.14a16.44,16.44,0,0,0,1.77,5.11,4,4,0,0,0,3.27,2c.3,0,.42.32.42.32a15.09,15.09,0,0,0,2.29,3.08c1.35,1.11,3.69.51,3.69.51,1.47-.13,2-2,2-2,.17-.67.55-.07.55-.07l.59,1a2.14,2.14,0,0,0,2,.76c1.1-.07,1.41-1.43,1.41-1.43.2-.33.56-.05.56-.05a1.8,1.8,0,0,0,1.89.4c.74-.28.49-.69.72-1s.39-.16.39-.16a3,3,0,0,0,1.2.33,1.36,1.36,0,0,0,1.22-1.06c.08-.67.23-.43.23-.43,1.63.57,2.1-.6,2.1-.6,1.62-3.19-1.91-7-1.91-7,.3-1.15-5-6.6-5-6.6-6-7.26-7-11.86-7-11.86a16.65,16.65,0,0,1,0-4.5,8.76,8.76,0,0,0,0-3.69L122,99.72a4.74,4.74,0,0,1-.19-1.09H108C107.91,100.85,107.78,102.37,107.78,102.37Z"
      />
      <path
        id="f19"
        stroke=""
        style={{
          fill: currentPartId === "f19" ? SynckitColors.Yellow[200] : "",
        }}
        d="M124.48,17.11c.69-3.8-2-13-2-13A13.94,13.94,0,0,1,121.86,1H98c.22,3.87.69,11.08.69,11.08a87.69,87.69,0,0,0,2.21,11.19,14.32,14.32,0,0,0,.69,2.1,2,2,0,0,1,.3,1.07l-.15,5.16h25.17a108.34,108.34,0,0,0-2.08-12.54C124.31,16.76,124.48,17.11,124.48,17.11Z"
      />
      <path
        id="f13"
        d="M40.92,104.52c-.31,1.62-1.27,5.5-1.27,5.5a48.16,48.16,0,0,0-.73,7.67c-.53,1.11-1.84,4.84-1.84,4.84-.93,1.8-6.11,7.9-6.11,7.9-2.91,3.15-6.76,7.57-6.76,7.57A10.17,10.17,0,0,0,22,140.94a7.7,7.7,0,0,0-.26,3.85c.34,1.06,1.57.7,1.57.7.4-.23.47,0,.47,0a1.1,1.1,0,0,0,.24,1,1.51,1.51,0,0,0,1.9.31.52.52,0,0,1,.33-.1,1.49,1.49,0,0,0,.79.81,2.13,2.13,0,0,0,2-.09c.09-.12.3-.09.3-.09a1.47,1.47,0,0,0,.23.55c.41.78,2.05.58,2.05.58a2,2,0,0,0,1.68-1.07c.19-.34.73-.35.73-.35a1.74,1.74,0,0,0,.25.77c1.14,1.81,4.26,1.33,4.26,1.33,2.27-.82,4-4.09,4-4.09.23-.76.77-.56.77-.56,3.35-.2,4.9-3.76,4.9-3.76,1.1-2.07,1.69-6.59,1.69-6.59.07-1.19.3-1.13.3-1.13,1.89,0,4.57-2.15,4.57-2.15,2.56-2.19,2-6.74,2-6.74a38.63,38.63,0,0,0-.83-6.72,2.2,2.2,0,0,1,0-1.3,6.45,6.45,0,0,0,.33-.92c.54-2.2,0-7,0-7l-.32-9.55H41.81C41.54,101.06,40.92,104.52,40.92,104.52Z"
        stroke=""
        style={{
          fill: currentPartId === "f13" ? SynckitColors.Yellow[200] : "",
        }}
      />
      <path
        id="f12"
        d="M56.65,82.18c1.21-6.8,3.8-16.13,3.8-16.13a92.77,92.77,0,0,0,4-17.33A92,92,0,0,0,64.6,31.6H38.66L38,38.46c-.75,8.82-.34,12.32-.34,12.32-.18,2.94,1.78,22.48,1.78,22.48l1.73,12.9c.42,2,.81,10.68.81,10.68,0,.43-.06,1.06-.14,1.78h14.1l0-.78A91,91,0,0,1,56.65,82.18Z"
        stroke=""
        style={{
          fill: currentPartId === "f12" ? SynckitColors.Yellow[200] : "",
        }}
      />
      <path
        id="f11"
        d="M63.86,25.23c-.08-1.08,0-1.17,0-1.17,3.18-4.72,3.37-16,3.37-16,.15-1,.38-4.1.58-7H43.35a20.46,20.46,0,0,1-.56,3.44C41.91,6.59,39.68,21,39.68,21l-1,10.58H64.58C64.28,27.73,63.86,25.23,63.86,25.23Z"
        stroke=""
        style={{
          fill: currentPartId === "f11" ? SynckitColors.Yellow[200] : "",
        }}
      />
      <path
        id="f20"
        d="M121.73,97.08c.08-2.31.85-10.06.85-10.06A13.16,13.16,0,0,1,123,82.8C124.81,77,126,64.91,126,64.91c1.92-12,1.15-28.73,1.15-28.73,0-1.48-.12-3-.28-4.57H101.74l-.08,2.75c-.11,1.57-.58,9.67-.58,9.67-.46,3.81,2.19,16.62,2.19,16.62.62,1.85,2.62,11.9,2.62,11.9,1.61,5.08,2.1,14.32,2.1,14.32a89,89,0,0,1,0,11.76h13.76A13.82,13.82,0,0,1,121.73,97.08Z"
        stroke=""
        style={{
          fill: currentPartId === "f20" ? SynckitColors.Yellow[200] : "",
        }}
      />
    </svg>
  );
};

import { Button, Menu, MenuItem, styled } from "@mui/material";
import React, { useContext } from "react";
import { useState } from "react";
import {
  OPERATION_VIEW_INSTRUCTIONS,
  OPERATION_VIEW_VIDEO,
} from "../../../assets/constant";
import { SynckitColors } from "../../../assets/SynckitColors";
import { ConsultContext } from "../ConsultIndex";
import { ConsultThemeAry } from "../ConsultTheme";

const ButtonNext = styled(Button)(({ theme }) => ({
  position: "absolute",
  right: "3%",
  bottom: "3%",
  width: 160,
}));

const NavMenu = styled(Menu)(({ theme }) => ({
  "& 	.MuiMenu-paper": {
    background: "#FFFFFF",
    minWidth: 160,
    boxShadow: SynckitColors.Shadow[2],
  },
  "& 	.MuiMenu-list": {
    padding: 0,
  },
  "& 	.MuiMenuItem-root": {
    padding: "12px 16px",
    color: SynckitColors.Gray["core"],
    fontWeight: "normal",
    fontSize: 16,
    lineHeight: "24px",
  },
}));

export default function InstructionNav() {
  const { currentExamItem, setOperationView } = useContext(ConsultContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  return (
    <div>
      <ButtonNext
        variant="forth"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        sx={ConsultThemeAry[currentExamItem]["saveBtnStyle"]}
      >
        Next
      </ButtonNext>
      <NavMenu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={() => setOperationView(OPERATION_VIEW_VIDEO)}>
          Regular
        </MenuItem>
        <MenuItem onClick={() => setOperationView(OPERATION_VIEW_INSTRUCTIONS)}>
          Polarized
        </MenuItem>
      </NavMenu>
    </div>
  );
}

import { Divider, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { useContext, useRef, useState, useEffect } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Slider from "react-slick";
import DemoSkinRecord01 from "../../assets/demo/skin_record01.png";
import DemoSkinRecord02 from "../../assets/demo/skin_record02.png";
import DemoSkinRecord03 from "../../assets/demo/skin_record03.png";
import DemoSkinRecord04 from "../../assets/demo/skin_record04.png";
import DemoSkinRecord05 from "../../assets/demo/skin_record05.png";
import DemoSkinRecord06 from "../../assets/demo/skin_record06.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ConsultContext } from "./ConsultIndex";
import { OPERATION_VIEW_DEFAULT } from "../../assets/constant";
import { SynckitColors } from "../../assets/SynckitColors";

const RecordHeader = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "16px 20px",
  "& span": {
    // fontFamily: "Inter",
    fontWeight: 700,
    fontSize: 18,
  },
  "& .MuiIconButton-root": {
    border: "1px solid #EBF2FA",
    borderRadius: 100,
    width: 28,
    height: 28,
  },
}));

const ImgCard = styled("div")({
  width: 102,
  height: 102,
  borderRadius: 8,
  // overflow: "hidden",
  position: "relative",
  "& span": {
    fontSize: 12,
    fontWeight: 700,
    position: "absolute",
    color: "white",
    bottom: 4,
    right: 8,
  },
});
const ButtonWrap = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: 68,
  "& .MuiIconButton-root": {
    border: "1px solid #EBF2FA",
    borderRadius: 100,
    width: 28,
    height: 28,
  },
}));
const RecordContent = styled("div")(({ theme }) => ({
  padding: "18px 24px",
}));
const settings = {
  dots: false,
  infinite: true,
  draggable: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  arrows: false,
};

var g_currentExamItemCategory = ["T", "E", "X", "N", "I", "D", "L", "H", "G", "A", "D"];
var g_idxCategoryImageListLoaded = -1;
var g_roomId;
var g_category;
var loginUserRoomId = "";
var webSocket;
var webSocketConnected = false;

export default function RecordGallery(props) {
  const { operationView } = useContext(ConsultContext);
  const slickRef = useRef(null);

  const Root = styled("div")(({ theme }) => ({
    width: 668,
    height: 191,
    background: "rgba(255, 255, 255, 0.8)",
    borderRadius: 8,
    pointerEvents: operationView === OPERATION_VIEW_DEFAULT ? "none" : null,
  }));

  console.log("roomId: "+props.roomId);
  console.log("currentExamItem: "+props.currentExamItem);
  const roomId = props.roomId;
  const category = g_currentExamItemCategory[props.currentExamItem];
  console.log("currentExamItemCategory: "+category);
  //
  g_roomId = roomId;
  g_category = category;
  //
  const [demoImgList, setDemoImgList] = useState([]);
  //
  if( props.currentExamItem != 10 && g_idxCategoryImageListLoaded != props.currentExamItem )
  {
    listUserFiles(roomId, category);
    g_idxCategoryImageListLoaded = props.currentExamItem;
  }
  if( webSocketConnected == false && roomId.length > 0 )
    initPushNotification(roomId);
  //listUserFiles(roomId, category);
  //
  return (
    <Root>
      <RecordHeader>
        <span>Record</span>
        <ButtonWrap>
          <IconButton onClick={() => slickRef.current.slickPrev()}>
            <ArrowBackIosIcon fontSize="small" sx={{ marginLeft: 1 }} />
          </IconButton>
          <IconButton onClick={() => slickRef.current.slickNext()}>
            <ArrowForwardIosIcon fontSize="small" />
          </IconButton>
        </ButtonWrap>
      </RecordHeader>
      <Divider />
      <RecordContent>
        {operationView === OPERATION_VIEW_DEFAULT ? (
          <Typography
            variant="body1"
            sx={{ color: SynckitColors.Gray[600], mt: 3 }}
          >
            Record or capture to add records.
          </Typography>
        ) : (
          <Slider {...settings} ref={slickRef}>
            {
            demoImgList.map((item, index) => (
              <div key={index}>
                <ImgCard>
                  <a href={item.fileUrl} target="_blank">
                  <figure>
                    <img
                      src={item.imgUrl}
                      alt={item.id}
                      style={{
                        width: 102,
                        height: 102,
                        objectFit: "cover",
                      }}
                    />
                  </figure>
                  </a>
                  <span>{item.duration}</span>
                </ImgCard>
              </div>
            ))}
          </Slider>
        )}
      </RecordContent>
    </Root>
  );

  function ping()
  {
    var dataJSON = {};
    //
    dataJSON["cmd"] = "ping";
    //
    webSocket.send(JSON.stringify(dataJSON));
    var tm = setTimeout(function () {
      ping();
    }, 30000);
  }

  function loadImageList(userFiles)
  {
    console.log("loadImageList");
    var _demoImgList = new Array(userFiles.length);
    var i;
    for(i=0;i<userFiles.length;i++)
    {
      var thumb = "userId="+userFiles[i].userId+"&roomId="+userFiles[i].roomId+"&fileName="+userFiles[i].fileName;
      var file = "userId="+userFiles[i].userId+"&roomId="+userFiles[i].roomId+"&fileName="+userFiles[i].fileName;
      if( file.endsWith(".mp4.jpg") )
      {
        file = file.substring(0, file.length-4);
      }
      _demoImgList[i] = { id:(i+1), 
                          imgUrl:"https://synckit2.viuto-aiot.com/SyncKitServer/GetThumbnail?"+thumb,
                          fileUrl:"https://synckit2.viuto-aiot.com/SyncKitServer/GetFile?"+file,
                       }
    }
    return _demoImgList;
  }
  
  function listUserFiles(roomId, category)
  {
      console.log("listUserFiles");
      //
      var dataJSON = {};
      //
      dataJSON["userId"] = "Anonymous";
      dataJSON["roomId"] = roomId;
      dataJSON["category"] = category;
      //
      var dataUrl= "https://synckit2.viuto-aiot.com/SyncKitServer/ListFile";
      var xhr = new XMLHttpRequest();
      xhr.open('POST', dataUrl, true);
      xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      xhr.send(JSON.stringify(dataJSON));
      xhr.onload = function(){
          console.log(this.responseText);
          var data = JSON.parse(this.responseText);
          console.log(data);
          if( data.status != "200" )
              return;
          var imgList = loadImageList(data.userFiles);
          setDemoImgList(imgList)
      }        
  }  
  //
  function initPushNotification(roomId)
  {
    loginUserRoomId = roomId;
    console.log("initPushNotification: "+loginUserRoomId);
    //
    webSocket = new WebSocket("wss://synckit2.viuto-aiot.com/SyncKitServer/websocketendpoint");
    webSocket.onopen = function(message){ wsOpen(message);};
    webSocket.onmessage = function(message){ wsOnMessage(message);};
    webSocket.onclose = function(message){ wsClose(message);};
    webSocket.onerror = function(message){ wsError(message);};
  }  
  //
  function wsOpen(message)
  {
    webSocketConnected = true;
    console.log("Connected ...");
    //
    var dataJSON = {};
    //
    dataJSON["cmd"] = "addRoom";
    dataJSON["roomId"] = loginUserRoomId;
    //
    webSocket.send(JSON.stringify(dataJSON));
    //
    ping();
  }
  function wsCloseConnection()
  {
    webSocket.close();
  }
  function wsOnMessage(message)
  {
    console.log("Message received from to the server : " + message.data);
    var response = JSON.parse(message.data);
    console.log("wsResponse.status: " + response.status);
    if( response.status != 200 )
      return;
    console.log("Action received from to the server : " + response.action);
    if( response.action != null )
    {
      if( response.action == "RefreshFileList" )
      {
        console.log("Action received from to the server : " + response.action);
        listUserFiles(g_roomId, g_category);
      }
    }
  }
  function wsClose(message)
  {
    console.log("Disconnect ...");
  }
  function wsError(message)
  {
    console.log("Error ...");
  }
}


/*
var demoImgList = [
  { id: 1, imgUrl: "https://synckit2.viuto-aiot.com/SyncKitServer/GetThumbnail?userId=Anonymous&fileName=VD01F01N_20221108_135035.jpg" },
  { id: 2, imgUrl: DemoSkinRecord02 },
  { id: 3, imgUrl: DemoSkinRecord03 },
  { id: 4, imgUrl: DemoSkinRecord04, duration: "0:14" },
  { id: 5, imgUrl: DemoSkinRecord05, duration: "0:05" },
  { id: 6, imgUrl: DemoSkinRecord06 },
];
*/

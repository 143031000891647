import { Typography } from "@mui/material";
import React from "react";
import { SynckitColors } from "../../../../assets/SynckitColors";
import OwnTable from "./OwnTable";

export default function OwnIndex() {
  return (
    <div>
      <Typography
        variant="subtitle1"
        sx={{ color: SynckitColors.Gray["core"], marginBottom: 2 }}
      >
        Your own
      </Typography>
      <OwnTable />
    </div>
  );
}

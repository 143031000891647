import {
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  styled,
  Typography,
  TextField,
  InputAdornment,
  Slider,
} from "@mui/material";
import React, { useState } from "react";
import { SynckitColors } from "../../../assets/SynckitColors";
import { IconEditLine, IconPaperPlane } from "../../../assets/SynckitIcons";
import _ from "lodash";
const PlanInfoCard = styled("div")(({ theme }) => ({
  background: "#FAFAFA",
  maxWidth: 750,
  boxShadow: SynckitColors.Shadow["ButtomDivider"],
  marginTop: 24,
  borderRadius: 8,
  "& .header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 40px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      height: 220,
    },
  },
  "& .content": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    minHeight: 130,
  },
}));

const PriceBox = styled("div")(({ theme }) => ({
  width: 280,
  minHeight: 60,
  background: "#F2F2F2",
  borderRadius: 12,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "flex-start",
  border: `1px solid  #FAFAFA`,
  transition: "0.3s",
  padding: "12px 16px",
  "&:hover, &.editMode": {
    color: SynckitColors.Primary[600],
    border: `1px solid ${SynckitColors.Primary[600]}`,
  },
}));

const CSlider = styled(Slider)(({ theme }) => ({
  maxWidth: 390,
  [theme.breakpoints.down("md")]: {
    width: "80%",
  },
  "& .MuiSlider-track": {
    color: SynckitColors.Gray[900],
    borderRadius: 4,
    height: 8,
  },
  "& .MuiSlider-rail": {
    color: SynckitColors.Gray[500],
    height: 8,
  },
  "& .MuiSlider-valueLabel": {
    color: SynckitColors.Gray[700],
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: SynckitColors.Gray[900],
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabelCircle": { color: SynckitColors.Gray[700] },
  "& .MuiSlider-mark": {
    background: "none",
  },
}));

export default function EditService({
  planDataList,
  handleSetPrice,
  handleApplyAdjustPrice,
  RenderPriceInfo,
  selectedList,
  setSelectedList,
  handleDelete,
}) {
  const handleSwitchEditMode = (_id) => {
    let tempList = selectedList.map((item) =>
      item.id === _id ? { ...item, editMode: !item.editMode } : item
    );
    setSelectedList([...tempList]);
  };

  return (
    <div>
      {selectedList.map((item, index) =>
        item.selectedPlanId === null ? (
          <PlanInfoCard key={index} sx={{ padding: "40px 27px" }}>
            <Typography variant="body2" sx={{ color: SynckitColors.Gray[800] }}>
              Select the plan from table.
            </Typography>
          </PlanInfoCard>
        ) : (
          <PlanInfoCard key={index}>
            <div className="header">
              <Box sx={{ width: 220 }}>
                <Typography variant="subtitle1">
                  {
                    _.filter(planDataList, { id: item.selectedPlanId })[0]
                      .serviceType
                  }
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ color: SynckitColors.Primary[600] }}
                >
                  {
                    _.filter(planDataList, { id: item.selectedPlanId })[0]
                      .planType
                  }
                </Typography>
              </Box>
              <PriceBox className={item.editMode ? "editMode" : ""}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <RenderPriceInfo
                    planData={
                      _.filter(planDataList, { id: item.selectedPlanId })[0]
                    }
                  />
                  {_.filter(planDataList, { id: item.selectedPlanId })[0]
                    .applying ? null : item.editMode ? (
                    <IconButton
                      onClick={() => {
                        handleSwitchEditMode(item.id);
                        handleApplyAdjustPrice(item.selectedPlanId);
                      }}
                      sx={{
                        background: SynckitColors.Primary[600],
                        color: "white",
                        ml: 1,
                      }}
                    >
                      <IconPaperPlane />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => handleSwitchEditMode(item.id)}
                      sx={{ background: SynckitColors.Gray[500], ml: 1 }}
                    >
                      <IconEditLine />
                    </IconButton>
                  )}
                </Box>

                {_.filter(planDataList, { id: item.selectedPlanId })[0]
                  .applying ? (
                  <Typography
                    variant="body1"
                    sx={{ color: SynckitColors.Primary[600] }}
                  >
                    Application sent
                  </Typography>
                ) : null}
              </PriceBox>
              <Button
                variant="third"
                disabled={item.editMode}
                onClick={() => handleDelete(item.id)}
              >
                Delete
              </Button>
            </div>
            <Collapse in={item.editMode} timeout="auto" unmountOnExit>
              <Divider />
              <div className="content">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1">
                    Apply for customizing service plan
                  </Typography>
                  {"　"}
                  <TextField
                    label=""
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    size="small"
                    sx={{ width: 88 }}
                    value={
                      _.filter(planDataList, { id: item.selectedPlanId })[0]
                        .price
                    }
                    onChange={(e) =>
                      handleSetPrice(item.selectedPlanId, e.target.value)
                    }
                  />
                </Box>
                <CSlider
                  value={
                    _.filter(planDataList, { id: item.selectedPlanId })[0].price
                  }
                  // getAriaValueText={valuetext}
                  min={0}
                  max={200}
                  step={0.5}
                  marks={marks}
                  valueLabelDisplay="on"
                  onChange={(e) =>
                    handleSetPrice(item.selectedPlanId, e.target.value)
                  }
                />
              </div>
            </Collapse>
          </PlanInfoCard>
        )
      )}

      <Typography
        variant="body2"
        sx={{ color: SynckitColors.Gray[800], maxWidth: 750, mt: 2 }}
      >
        You can Customize your charging within 20% of total ammount. Send out
        your application and we wil help you modify your plan as soon as
        possible.
      </Typography>
    </div>
  );
}

const marks = [
  {
    value: 0,
    label: "$ 0",
  },

  {
    value: 200,
    label: "$ 200",
  },
];

import React from "react";
import { Grid } from "@mui/material";
import SingleReportList from "./SingleReportList";
import ReportCard from "../../exam-record/ReportCard";

export default function SingleReport() {
  return (
    <Grid
      container
      spacing={4}
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      sx={{ marginTop: 2 }}
    >
      <Grid item md={12} lg={8}>
        <SingleReportList />
      </Grid>
      <Grid item md={12} lg={4}>
        <ReportCard />
      </Grid>
    </Grid>
  );
}

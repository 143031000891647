import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
  TablePagination,
  Avatar,
  TableFooter,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import { SynckitColors } from "../../../assets/SynckitColors";

import { ImgOrganActive2List } from "../../../assets/SynckitImg";
import { APO_TYPE_LOCAL, APO_TYPE_REMOTE } from "../../../assets/constant";
import ApoCancelDialog from "../../../components/dialog/ApoCancelDialog";
import LocalApoDialog from "../../../components/dialog/LocalApoDialog";
import RemoteApoDialog from "../../../components/dialog/RemoteApoDialog";
import { DemoRowData } from "./demolApoData";
import RemoteApoStaticDialog from "../../../components/dialog/RemoteApoStaticDialog";

const MainTableHead = styled(TableHead)({
  background: SynckitColors.Gray[200],
  "& .MuiTableCell-root": {
    color: SynckitColors.Gray[700],
    fontSize: 14,
    fontWeight: 700,
  },
});

const TypeLabel = styled("span")({
  fontSize: 14,
  fontWeight: "bold",
  borderRadius: 100,

  "&.remote": {
    border: `1px solid ${SynckitColors.Gray[500]}`,
    color: SynckitColors.Gray[800],
    padding: "8px 32px",
  },
  "&.local": {
    background: SynckitColors.Gray[800],
    color: "white",
    padding: "8px 40px",
  },
});

export default function Inviting() {
  const [rows, setRows] = useState(DemoRowData);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [openRemoteApoDialog, setOpenRemoteApoDialog] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <Fragment>
      <Table sx={{ background: "white" }}>
        <MainTableHead>
          <TableRow>
            <TableCell>Patient</TableCell>
            <TableCell>Doctor</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Divison</TableCell>
            <TableCell width={80}>Cancel</TableCell>
          </TableRow>
        </MainTableHead>
        <TableBody>
          {rows.map(
            (row, index) =>
              row.type === APO_TYPE_REMOTE && (
                <TableRow key={index}>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontWeight: 700,
                      }}
                      onClick={() => setOpenRemoteApoDialog(true)}
                    >
                      <Avatar
                        src={row.avatar}
                        sx={{ margin: "0px 12px", width: 48, height: 48 }}
                      />
                      {row.patient}
                    </div>
                  </TableCell>
                  <TableCell
                    onClick={() => setOpenRemoteApoDialog(true)}
                    sx={{
                      fontWeight: 700,
                    }}
                  >
                    {row.doctor}
                  </TableCell>
                  <TableCell
                    onClick={() => setOpenRemoteApoDialog(true)}
                    sx={{
                      fontWeight: 700,
                    }}
                  >
                    {row.date}
                  </TableCell>

                  <TableCell
                    onClick={() => setOpenRemoteApoDialog(true)}
                    sx={{
                      fontWeight: 700,
                    }}
                  >
                    {row.divison !== null && ImgOrganActive2List[row.divison]}
                  </TableCell>
                  <TableCell>
                    <ApoCancelDialog />
                  </TableCell>
                </TableRow>
              )
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={6} sx={{ padding: 0 }}>
              <TablePagination
                rowsPerPageOptions={[8, 16, 32]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>

      <RemoteApoStaticDialog
        open={openRemoteApoDialog}
        setOpen={setOpenRemoteApoDialog}
      />
    </Fragment>
  );
}

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  styled,
  Box,
  Avatar,
  Divider,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { SynckitColors } from "../../assets/SynckitColors";
import avatar_patient1 from "../../assets/images/demo/avatar_patient1.jpg";
import FemaleIcon from "@mui/icons-material/Female";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";

const Root = styled(Dialog)(({ theme }) => ({
  // "& .MuiDialog-container": {
  //   margin: 0,
  //   padding: 0,
  // },
  "& .MuiDialog-paper": { minWidth: 600 },
  [theme.breakpoints.down("sm")]: {
    "& .MuiDialog-paper": { minWidth: 300 },
  },
}));

const InfoBox = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  // justifyContent: "space-between",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));

const InfoCard = styled("div")(({ theme }) => ({
  width: 260,
  height: 145,
  marginRight: 16,
  border: `1px solid ${SynckitColors.Gray[400]}`,
  borderRadius: 8,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  padding: "16px 18px",
}));

const InfoList = styled("div")(({ theme }) => ({
  display: "flex",
  paddingTop: 8,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const FileInfoBox = styled("div")(({ theme }) => ({
  width: "100%",
  borderRadius: 8,
  padding: "6px 8px",
  background: SynckitColors.Primary[50],
  display: "flex",
  justifyContent: "space-between",
  "& div": {
    display: "flex",
    paddingTop: 5,
    paddingBottom: 5,
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

export default function RecordInfoDialog({ open, setOpen }) {
  return (
    <Root open={open}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span></span>
        <IconButton onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Examination Information
        </Typography>
        <InfoBox>
          <InfoCard>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Avatar
                alt=""
                src={avatar_patient1}
                sx={{ width: 60, height: 60, marginRight: 2 }}
              />
              <Box>
                <Box sx={{ display: "flex" }}>
                  <Typography variant="subtitle1">Cammy Lytle</Typography>
                  &nbsp;
                  <FemaleIcon color="error" />
                </Box>
                <Typography variant="body2">G3982958195</Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CakeOutlinedIcon />
              <Typography variant="body2">1954.02.08</Typography>
              {"　"}
              <AccountBoxOutlinedIcon />
              <Typography variant="body2">A158692830</Typography>
            </Box>
          </InfoCard>
          <Box>
            <InfoList>
              <Typography variant="subtitle1" sx={{ mr: 1 }}>
                Exmaination Id:
              </Typography>
              <Typography variant="body1">G7S24601</Typography>
            </InfoList>
            <InfoList>
              <Typography variant="subtitle1" sx={{ mr: 1 }}>
                Examination Date:
              </Typography>
              <Typography variant="body1">2021.10.22 09:00</Typography>
            </InfoList>
            <InfoList>
              <Typography variant="subtitle1" sx={{ mr: 1 }}>
                Exmaination Item :
              </Typography>
              <Typography variant="body1">Teeth, Ear, Heart</Typography>
            </InfoList>
          </Box>
        </InfoBox>
        <Divider sx={{ mt: 3, mb: 2 }} />
        <Typography variant="h5" sx={{ mb: 2 }}>
          Consult Information
        </Typography>
        <InfoBox>
          <InfoCard>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Avatar
                alt=""
                src={avatar_patient1}
                sx={{ width: 60, height: 60, marginRight: 2 }}
              />
              <Box>
                <Box sx={{ display: "flex" }}>
                  <Typography variant="subtitle1">
                    Dr. Kristin Watson
                  </Typography>
                  &nbsp;
                  <FemaleIcon color="error" />
                </Box>
                <Typography variant="body2">G1233447891</Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <LocalHospitalIcon sx={{ mr: 1 }} />
              <Typography variant="body2">Division of Cardiology</Typography>
            </Box>
          </InfoCard>
          <Box>
            <InfoList>
              <Typography variant="subtitle1" sx={{ mr: 1 }}>
                Consulted Date:
              </Typography>
              <Typography variant="body1">2021.10.22</Typography>
            </InfoList>
            <InfoList>
              <Typography variant="subtitle1" sx={{ mr: 1 }}>
                Consulted Item :
              </Typography>
              <Typography variant="body1">Heart</Typography>
            </InfoList>
            <InfoList>
              <Typography variant="subtitle1" sx={{ mr: 1 }}>
                Consulted Time :
              </Typography>
              <Typography variant="body1">09:00 - 09:38</Typography>
            </InfoList>
            <InfoList>
              <Typography variant="subtitle1" sx={{ mr: 1 }}>
                Time Duration:
              </Typography>
              <Typography variant="body1">38 minutes</Typography>
            </InfoList>
          </Box>
        </InfoBox>
        <Divider sx={{ mt: 3, mb: 2 }} />

        <FileInfoBox>
          <div>
            <Typography variant="subtitle2" sx={{ mr: 1 }}>
              Last Edited Time :
            </Typography>
            <Typography variant="body2">2021.10.22 11:33</Typography>
          </div>

          <div>
            <Typography variant="subtitle2" sx={{ mr: 1 }}>
              Last Editor :
            </Typography>
            <Typography variant="body2">Jacob Jones</Typography>
          </div>

          <div>
            <Typography variant="subtitle2" sx={{ mr: 1 }}>
              File Size :
            </Typography>
            <Typography variant="body2">5 MB</Typography>
          </div>
        </FileInfoBox>
      </DialogContent>
    </Root>
  );
}

import { Button } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import { IconArrowiOSBackward } from "../../../assets/SynckitIcons";
import SynckitSearchbarSimple from "../../../components/SynckitSearchbarSimple";
import PatientTable from "./PatientTable";

const Toolbar = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "1%",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));
export default function SearchIndex() {
  const navigate = useNavigate();

  return (
    <div>
      <Toolbar>
        <Button
          startIcon={<IconArrowiOSBackward />}
          onClick={() => navigate("/schedule")}
        >
          Schedule
        </Button>
        <SynckitSearchbarSimple />
      </Toolbar>
      <PatientTable />
    </div>
  );
}

import { styled } from "@mui/material";
import React, { useContext } from "react";
import ImgModelMouthAdult from "../../../../assets/images/body/model_mouth_adult.png";
import { ConsultContext } from "../../ConsultIndex";
import { AdultTeethSVG } from "./AdultTeethSVG";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  position: "relative",
}));

const ImgModelBodyWrap = styled("div")(({ theme }) => ({
  position: "absolute",
  height: "90%",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  "& img": {
    height: "100%",
  },
}));
const SVGModelBodyWrap = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  height: "100%",
  "& svg": {
    height: "99%",
  },
  "& path": {
    fill: "rgba(255, 255, 255, 0.00009)",
    transition: "0.3s",
  },
  "& path:hover": {
    fill: "rgba(83, 208, 200, 0.6)",
    cursor: "pointer",
  },
}));

export default function AdultTeeth() {
  const { currentPartId, setCurrentPartId } = useContext(ConsultContext);
  return (
    <Root>
      <ImgModelBodyWrap>
        <img src={ImgModelMouthAdult} alt="synckit" />
      </ImgModelBodyWrap>
      <SVGModelBodyWrap>
        <AdultTeethSVG
          handleClick={(e) => e.target.id && setCurrentPartId(e.target.id)}
          currentPartId={currentPartId}
        />
      </SVGModelBodyWrap>
    </Root>
  );
}

import { Typography } from "@mui/material";
import React from "react";
import { SynckitColors } from "../../../../assets/SynckitColors";
import PatientTable from "./PatientTable";

export default function PatientsIndex() {
  return (
    <div>
      <Typography
        variant="subtitle1"
        sx={{ color: SynckitColors.Gray["core"], marginBottom: 2 }}
      >
        Patients
      </Typography>
      <PatientTable />
    </div>
  );
}

import { createSlice } from "@reduxjs/toolkit";

const { actions, reducer: SnackbarReducer } = createSlice({
  name: "snackbar",
  initialState: {
    openSnackbar: false,
    alertTitle: "Title",
  },
  reducers: {
    setOpenSnackbar(state, action) {
      state.openSnackbar = action.payload;
    },
    setAlertTitle(state, action) {
      state.alertTitle = action.payload;
    },
  },
});

export const snackbarState = (state) => state.snackbar;
export const { setOpenSnackbar, setAlertTitle } = actions;
export default SnackbarReducer;

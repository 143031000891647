import {
  Avatar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  styled,
  Typography,
} from "@mui/material";
import React, { Fragment } from "react";
import { SynckitColors } from "../../assets/SynckitColors";
import { IconArrowiOSForward } from "../../assets/SynckitIcons";
import StarIcon from "@mui/icons-material/Star";
import { DemoRowData } from "../schedule/appointment/demolApoData";

const CustomizedList = styled(List)(({ theme }) => ({
  background: "white",
  padding: "16px 24px",
  boxShadow: SynckitColors.Shadow[1],
  borderRadius: 12,
  transition: "0.3s",
  [theme.breakpoints.down(1400)]: {
    padding: "16px 18px",
  },
}));
const CustomizedListItem = styled(ListItem)(({ theme }) => ({
  height: 80,
  transition: "0.3s",
  [theme.breakpoints.down(1400)]: {
    padding: 0,
  },
}));

export default function RecentlyFollowed() {
  return (
    <div>
      <Typography
        variant="h5"
        sx={{ color: SynckitColors.Gray["core"], marginBottom: 2 }}
      >
        Recently Followed
      </Typography>
      <CustomizedList>
        {DemoRowData.map((item) => (
          <Fragment key={item.id}>
            <CustomizedListItem
              secondaryAction={
                <Fragment>
                  <IconButton>
                    <StarIcon
                      sx={{ color: SynckitColors.Warning["Default"] }}
                    />
                  </IconButton>
                  <IconButton>
                    <IconArrowiOSForward
                      sx={{ color: SynckitColors.Gray[600] }}
                    />
                  </IconButton>
                </Fragment>
              }
              sx={{}}
            >
              <ListItemAvatar>
                <Avatar
                  alt={item.patient}
                  src={item.avatar}
                  sx={{ width: 48, height: 48 }}
                />
              </ListItemAvatar>
              <Typography
                variant="subtitle1"
                sx={{ color: SynckitColors.Gray["core"] }}
              >
                {item.patient}
              </Typography>
            </CustomizedListItem>
            <Divider />
          </Fragment>
        ))}
      </CustomizedList>
    </div>
  );
}

import { Grid } from "@mui/material";
import React, { useState } from "react";
import {
  CR_MODE_PREVIEW_IMG,
  CR_MODE_PREVIEW_ORGAN,
  CR_MODE_PREVIEW_VIDEO_DEFAULT,
  CR_MODE_PREVIEW_VIDEO_PLAY,
} from "../../../../../assets/constant";
import { demoImgList } from "../../../../../assets/demoData";
import ExportSection from "./ExportSection";
import PreviewBodySection from "./PreviewBodySection";
import PreviewSection from "./PreviewSection";
import RecordGallery from "./RecordGallery";
import VideoEditorDefault from "./video-editor/VideoEditorDefault";
import VideoEditorPlay from "./video-editor/VideoEditorPlay";

export default function ComparisonReportIndex() {
  const [displayMode, setDisplayMode] = useState(CR_MODE_PREVIEW_IMG);
  const [selectedImgList, setSelectedImgList] = useState(demoImgList);

  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justify="center"
      alignItems="center"
      alignContent="center"
      wrap="nowrap"
    >
      <Grid item md={8}>
        {displayMode === CR_MODE_PREVIEW_ORGAN && (
          <PreviewBodySection setDisplayMode={setDisplayMode} />
        )}

        {displayMode === CR_MODE_PREVIEW_IMG && (
          <PreviewSection setDisplayMode={setDisplayMode} />
        )}
        {displayMode === CR_MODE_PREVIEW_VIDEO_DEFAULT && (
          <VideoEditorDefault setDisplayMode={setDisplayMode} />
        )}
        {displayMode === CR_MODE_PREVIEW_VIDEO_PLAY && (
          <VideoEditorPlay
            setDisplayMode={setDisplayMode}
            selectedImgList={selectedImgList}
            setSelectedImgList={setSelectedImgList}
          />
        )}

        <ExportSection
          selectedImgList={selectedImgList}
          setSelectedImgList={setSelectedImgList}
        />
      </Grid>
      <Grid item md={4}>
        <RecordGallery setDisplayMode={setDisplayMode} />
      </Grid>
    </Grid>
  );
}

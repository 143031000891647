import {
  Box,
  IconButton,
  styled,
  TablePagination,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { SynckitColors } from "../../../assets/SynckitColors";
import SynckitStatusLight from "../../../assets/SynckitStatusLight";
import {
  latestNewsDemoData,
  notificationDemoData,
} from "../NotificationDemoData";
import {
  RenderNotificationsType,
  RenderRequestSection,
} from "../RenderCommonSection";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const Root = styled("div")({
  background: "white",
  padding: "16px 24px",
});
const NotificationList = styled("div")(({ theme }) => ({
  boxShadow: SynckitColors.Shadow["ButtomDivider"],
  padding: "16px 12px",
  display: "flex",
  justifyContent: "space-between",
  "& .btnWrap": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: 360,
    marginTop: 10,
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    "& .btnWrap": {
      //   flexDirection: "column",
      //   alignItems: "center",
      //   justifyContent: "space-around",
    },
  },
}));

export default function LatestNews() {
  const [page, setPage] = useState(2);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <Root>
      {latestNewsDemoData.map((item, index) => (
        <NotificationList key={index}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {item.status !== null ? (
              <SynckitStatusLight status={item.status} />
            ) : (
              "　"
            )}
            {"　"}
            <Box sx={{ width: "100%" }}>
              <Typography component={"div"} variant="subtitle1">
                {item.title}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: SynckitColors.Gray[800] }}
              >
                {item.datetime}
              </Typography>{" "}
            </Box>
          </Box>
        </NotificationList>
      ))}
      <TablePagination
        component="div"
        count={26}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Root>
  );
}

import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Box,
  IconButton,
  styled,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { SynckitColors } from "../../../assets/SynckitColors";
import { Datepicker } from "@mobiscroll/react";
import { IconFileTextLine } from "../../../assets/SynckitIcons";
import { ImgCrediCardVisa, ImgMasterCard } from "../../../assets/SynckitImg";
import RemoteApoStaticDialog from "../../../components/dialog/RemoteApoStaticDialog";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";

const CToggleButton = styled(ToggleButton)(({ theme }) => ({
  color: SynckitColors.Gray[600],
  fontSize: 14,
  width: 88,
  height: 36,
  border: `1px solid ${SynckitColors.Gray[400]}`,
  textTransform: "none",
  "&.Mui-selected": {
    background: SynckitColors.Primary[600],
    color: "white",
  },
}));

const RecordTable = styled("table")(({ theme }) => ({
  width: 1005,
  borderRadius: 12,
  overflow: "hidden",
  borderSpacing: 0,
  thead: {
    tr: {
      height: 52,
      background: SynckitColors.Gray[200],
      th: { color: SynckitColors.Gray[700], textAlign: "left" },
    },
  },
  tbody: {
    tr: {
      height: 69,
      boxShadow: SynckitColors.Shadow["ButtomDivider"],
    },
  },
}));

export default function PointsTransactionRecord() {
  const [mode, setMode] = useState("refund");
  const [selectedDate, setSelectedDate] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <div>
      <Typography variant="subtitle1" sx={{ mt: 2, mb: 2 }}>
        Points Transaction Record
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <ToggleButtonGroup color="primary" exclusive sx={{ mr: 2 }}>
          <CToggleButton
            value="payment"
            selected={mode === "payment"}
            onClick={() => setMode("payment")}
          >
            Payment
          </CToggleButton>
          <CToggleButton
            value="refund"
            selected={mode === "refund"}
            onClick={() => setMode("refund")}
          >
            Refund
          </CToggleButton>
        </ToggleButtonGroup>
        <Datepicker
          display="center"
          inputComponent={TextField}
          controls={["calendar"]}
          dateFormat="YYYY/MM"
          dateWheels="MMMM"
          touchUi={true}
          onChange={(e) => setSelectedDate(e.valueText)}
          inputProps={{
            label: "Date",
            size: "small",
            sx: { mt: 2, mb: 2 },
            value: selectedDate,
          }}
        />
      </Box>
      <RecordTable>
        <thead>
          <tr>
            {["　Date", "Description", "Points", "Details"].map((item) => (
              <th key={item}>
                <Typography variant="subtitle2">{item}</Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {demoDataList.map((item, index) => (
            <tr key={index}>
              <td>　{item.date}</td>
              <td>{item.description}</td>
              <td>
                <Box sx={{ display: "flex" }}>
                  <PaidOutlinedIcon
                    sx={{
                      color: SynckitColors.Primary[600],
                      width: 20,
                      height: 20,
                      mr: 1,
                    }}
                  />
                  <Typography variant="subtitle1">{`${item.amount}`}</Typography>
                </Box>
              </td>
              <td>
                <IconButton onClick={() => setOpenDialog(true)}>
                  <IconFileTextLine />
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </RecordTable>
      <RemoteApoStaticDialog open={openDialog} setOpen={setOpenDialog} />
    </div>
  );
}

const demoDataList = [
  {
    id: 1,
    date: "2021.10.23 18:30",
    description: "Appointment pre-paid",
    creditCard: (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <ImgMasterCard />
        　"*1234"
      </Box>
    ),
    amount: -25,
  },
  {
    id: 2,
    date: "2021.10.23 18:30",
    description: "Appointment pre-paid",
    creditCard: (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <ImgMasterCard />
        　"*1234"
      </Box>
    ),
    amount: 25,
  },
  {
    id: 3,
    date: "2021.10.23 18:30",
    description: "Consultation fee",
    creditCard: (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <ImgCrediCardVisa />
        　"*1234"
      </Box>
    ),
    amount: -25,
  },

  {
    id: 4,
    date: "2021.10.23 18:30",
    description: "Consultation fee",
    creditCard: (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <ImgCrediCardVisa />
        　"*1234"
      </Box>
    ),
    amount: 25,
  },
];

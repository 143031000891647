import { Button, IconButton, TextField, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import React, { useState } from "react";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ImgDemo1 from "../../../../../assets/demo/demo1.jpg";

import { SynckitColors } from "../../../../../assets/SynckitColors";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { CR_MODE_PREVIEW_ORGAN } from "../../../../../assets/constant";
const PreviewRoot = styled("div")(({ theme }) => ({
  width: 775,
  height: 458,
  borderRadius: 7,
  overflow: "hidden",
  position: "relative",
  marginBottom: 24,
}));
const FileNameLable = styled("span")(({ theme }) => ({
  position: "absolute",
  top: "1em",
  left: "1em",
  color: "white",
  fontWeight: 700,
  fontSize: 16,
  letterSpacing: 0.1,
}));
const SelectButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "0.3em",
  right: "0.3em",
}));
const BodyButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "12em",
  right: "0.6em",
  background: "rgba(22, 42, 75, 0.2)",
  zIndex: 10,
}));

const PreviewImg = styled("img")(({ theme }) => ({
  height: "100%",
  objectFit: "contain",
}));

const CommentSection = styled("div")(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  width: "100%",
  padding: "0em 1em 1em 1em",
  "& .MuiOutlinedInput-root": {
    background: "rgba(0, 0, 0, 0.2)",
    color: SynckitColors.Gray[600],
    "& fieldset": {
      borderColor: SynckitColors.Gray[600],
    },
    "&:hover fieldset": {
      borderColor: SynckitColors.Gray[200],
    },
    "&.Mui-focused fieldset": {
      borderColor: SynckitColors.Gray[200],
    },
  },
}));
const ButtonWrap = styled("div")(({ theme }) => ({
  color: SynckitColors.Gray[600],
  "& .MuiButton-root": {
    color: SynckitColors.Gray[600],
    "&.selectedMode": {
      color: SynckitColors.Gray[200],
    },
  },
}));

const PlayButtonWrap = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  textAlign: "center",
}));
export default function PreviewSection({ setDisplayMode }) {
  const [isSelected, setIsSelected] = useState(false);
  const [commentMode, setCommentMode] = useState("doctor");
  const [doctorComment, setDoctorComment] = useState("");
  const [patientComment, setPatientComment] = useState("");

  return (
    <div>
      <PreviewRoot>
        <FileNameLable>124459151</FileNameLable>
        <SelectButton onClick={() => setIsSelected(!isSelected)}>
          {isSelected ? (
            <CheckCircleIcon color="primary" fontSize="large" />
          ) : (
            <CircleOutlinedIcon sx={{ color: "white" }} fontSize="large" />
          )}
        </SelectButton>
        <BodyButton onClick={() => setDisplayMode(CR_MODE_PREVIEW_ORGAN)}>
          <AccessibilityNewIcon sx={{ color: "white" }} fontSize="small" />
        </BodyButton>

        <CommentSection>
          <ButtonWrap>
            <Button
              onClick={() => setCommentMode("doctor")}
              className={commentMode === "doctor" ? "selectedMode" : null}
            >
              Doctor
            </Button>
            |
            <Button
              onClick={() => setCommentMode("patient")}
              className={commentMode === "patient" ? "selectedMode" : null}
            >
              Patient
            </Button>
          </ButtonWrap>
          {commentMode === "doctor" ? (
            <TextField
              multiline
              rows={3}
              color="gray"
              fullWidth
              placeholder="Doctor's comment"
              value={doctorComment}
              onChange={(e) => setDoctorComment(e.target.value)}
            />
          ) : (
            <TextField
              multiline
              rows={3}
              color="primary"
              fullWidth
              placeholder="Patient's comment"
              value={patientComment}
              onChange={(e) => setPatientComment(e.target.value)}
            />
          )}
        </CommentSection>

        <PreviewImg src={ImgDemo1} />
      </PreviewRoot>
    </div>
  );
}

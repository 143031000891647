import { CircularProgress, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect } from "react";
import DemoConsultationPatient from "../../../assets/demo/consultation_patient.png";
import VideocamIcon from "@mui/icons-material/Videocam";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import MicOffIcon from "@mui/icons-material/MicOff";
import CropFreeIcon from "@mui/icons-material/CropFree";
import axios from "axios";
import PatientViewDemo3 from "./PatientViewDemo3";

// const demoRoomId = "bb56abdf-1fc1-4dbf-bb22-a90e30f9abe3";
// const demoRoomId = "bb56abdf-1fc1-4dbf-bb22-a90e30f9abe0";
const demoRoomId = "a4c71e4a-4ce5-4038-8a78-181e2e61f92d";

const Root = styled("div")(({ theme }) => ({
  width: 600,
  height: 516,
  borderRadius: 8,
  overflow: "hidden",
  position: "relative",
  margin: "0px auto",
}));
const ButtonWrap = styled("div")(({ theme }) => ({
  position: "absolute",
  right: 12,
  bottom: 15,
  height: 216,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
}));

const FunctionButton = styled(IconButton)({
  background: "rgba(255, 255, 255, 0.5)",
  width: 48,
  height: 48,
});

const RemoteViewWrap = styled("div")({
  width: "100%",
  height: 516,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  overflow: "hidden",

  "& #patientRemoteView": {
    position: "absolute",
    width: 478,
    height: 516,
    // bottom: 587, for Tablet
    bottom: 391, // for smartphone

    "& video": {
      // width: 478,for Tablet
      width: 424, //  for smartphone
    },
  },
});

const SelfVideo = styled("div")({
  position: "absolute",
  bottom: 0,
  right: 40,
  width: 260,
  height: 190,
});
export default function PatientViewDemo1() {
  useEffect(() => {
    createRoomId();
  }, []);

  const createRoomId = async () => {
    await axios({
      method: "post",
      //url: "https://test.viuto-aiot.com/create/room/useid",
      url: "https://synckit.viuto-aiot.com/create/room/useid",
      data: {
        // new_room_id: "bb56abdf-1fc1-4dbf-bb22-a90e30f9abe0",
        new_room_id: demoRoomId,
      },
    })
      .then((res) => console.log(res))
      .catch((error) => console.log("Can't create roomid.", error));
  };

  const configuration = {
    iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
  };
  let pc = new RTCPeerConnection(configuration);
  pc.ontrack = function (event) {
    console.log("pc.ontrack ");
    if (event.track.kind === "audio") {
      return;
    }

    let el = document.createElement(event.track.kind);
    el.srcObject = event.streams[0];
    el.autoplay = true;
    el.controls = true;
    document.getElementById("patientRemoteView").appendChild(el);

    event.track.onmute = function (event) {
      el.play();
    };

    event.streams[0].onremovetrack = ({ track }) => {
      if (el.parentNode) {
        el.parentNode.removeChild(el);
      }
    };
  };

  const rtsURL = "rtsp://172.30.1.234:18554/camera1";

  let ws = new WebSocket(
    //`wss://test.viuto-aiot.com/room/${demoRoomId}/webSocket`
    `wss://synckit.viuto-aiot.com/room/${demoRoomId}/webSocket`
  );
  pc.onicecandidate = (e) => {
    if (!e.candidate) {
      return;
    }

    ws.send(
      JSON.stringify({
        event: "candidate",
        data: JSON.stringify(e.candidate),
      })
    );
  };

  ws.onclose = function (evt) {
    window.alert("Websocket has closed");
  };
  ws.onmessage = function (evt) {
    try {
      let msg = JSON.parse(evt.data);
      if (!msg) {
        return;
      }
      switch (msg.event) {
        case "offer":
          if (!msg.data) return;
          let offer = JSON.parse(msg.data);
          if (!offer) {
            console.log("failed to parse answer");
          }
          pc.setRemoteDescription(offer);
          pc.createAnswer().then((answer) => {
            pc.setLocalDescription(answer);
            ws.send(
              JSON.stringify({
                event: "answer",
                data: JSON.stringify(answer),
              })
            );
          });
          // pcSendersLog.textContent = pc.getSenders().length;
          return;

        case "candidate":
          let candidate = JSON.parse(msg.data);
          if (!candidate) {
            return console.log("failed to parse candidate");
          }

          pc.addIceCandidate(candidate);
      }
    } catch (error) {
      console.log(error);
    }
  };

  ws.onerror = function (evt) {
    console.log("ERROR: " + evt.data);
  };
  return (
    <Root>
      {/* <figure>
        <img
          src={DemoConsultationPatient}
          alt="Synckit"
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
      </figure> */}
      <RemoteViewWrap>
        <CircularProgress />
        <div id="patientRemoteView"></div>
        {/* <SelfVideo>
          <PatientViewDemo3 />
        </SelfVideo> */}
      </RemoteViewWrap>

      {/* <ButtonWrap>
        <FunctionButton>
          <VideocamIcon />
        </FunctionButton>
        <FunctionButton>
          <VolumeOffIcon />
        </FunctionButton>
        <FunctionButton>
          <MicOffIcon />
        </FunctionButton>
        <FunctionButton>
          <CropFreeIcon />
        </FunctionButton>
      </ButtonWrap> */}
    </Root>
  );
}

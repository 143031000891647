import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { SynckitColors } from "../../../assets/SynckitColors";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { IconEyeOff } from "../../../assets/SynckitIcons";
export default function ChangePassword({ setAccountMode }) {
  const [valObj, setValObj] = useState({
    currentPassword: "",
    showCurrentPassword: false,
    currentPasswordError: false,
    currentPassowrdHelperText: (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <ErrorOutlineIcon sx={{ width: 14, height: 14, mr: 1 }} />
        Incorrect password
      </Box>
    ),
    newPassword: "",
    showNewPassword: false,
    newPasswordError: false,
    newPassowrdHelperText: (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <ErrorOutlineIcon sx={{ width: 14, height: 14, mr: 1 }} />
        (Same as registor)
      </Box>
    ),
    confirmPassword: "",
    showConfirmPassword: false,
    confirmPasswordError: false,
    confirmPassowrdHelperText: (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <ErrorOutlineIcon sx={{ width: 14, height: 14, mr: 1 }} />
        Not corresponding to new password
      </Box>
    ),
  });

  const handleChange = (prop) => (event) => {
    setValObj({ ...valObj, [prop]: event.target.value });
  };
  const handleShowPassword = (prop) => {
    setValObj({
      ...valObj,
      [prop]: !valObj[prop],
    });
  };
  const handleError = () => {
    setValObj({
      ...valObj,
      currentPasswordError: !valObj.currentPasswordError,
      newPasswordError: !valObj.newPasswordError,
      confirmPasswordError: !valObj.confirmPasswordError,
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        maxWidth: 330,
      }}
    >
      <Button
        sx={{ color: SynckitColors.Gray["core"] }}
        startIcon={<ArrowBackIosIcon />}
        onClick={() => setAccountMode("default")}
      >
        <Typography variant="subtitle1"> {"Change Password"}</Typography>
      </Button>
      <Box
        sx={{
          mt: 3,
          mb: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          height: 250,
        }}
      >
        <TextField
          label="Current password"
          size="small"
          type={valObj.showCurrentPassword ? "text" : "password"}
          value={valObj.currentPassword}
          onChange={handleChange("currentPassword")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleShowPassword("showCurrentPassword")}
                >
                  {valObj.showCurrentPassword ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <IconEyeOff />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={valObj.currentPasswordError}
          helperText={
            valObj.currentPasswordError
              ? valObj.currentPassowrdHelperText
              : null
          }
        />
        <TextField
          label="New password"
          size="small"
          type={valObj.showNewPassword ? "text" : "password"}
          value={valObj.newPassword}
          onChange={handleChange("newPassword")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleShowPassword("showNewPassword")}
                >
                  {valObj.showNewPassword ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <IconEyeOff />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={valObj.newPasswordError}
          helperText={
            valObj.newPasswordError ? valObj.newPassowrdHelperText : null
          }
        />
        <TextField
          label="Confirm Password"
          size="small"
          type={valObj.showConfirmPassword ? "text" : "password"}
          value={valObj.confirmPassword}
          onChange={handleChange("confirmPassword")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleShowPassword("showConfirmPassword")}
                >
                  {valObj.showConfirmPassword ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <IconEyeOff />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={valObj.confirmPasswordError}
          helperText={
            valObj.confirmPasswordError
              ? valObj.confirmPassowrdHelperText
              : null
          }
        />
      </Box>
      <Box>
        <Button variant={"secondary"} sx={{ width: 150 }}>
          Cancel
        </Button>
        {"　"}
        <Button variant={"primary"} onClick={handleError} sx={{ width: 150 }}>
          Save
        </Button>
      </Box>
    </Box>
  );
}

import { Box, Button, styled, Typography } from "@mui/material";
import React, { useState } from "react";
import { SynckitColors } from "../../../assets/SynckitColors";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CheckIcon from "@mui/icons-material/Check";
import EditService from "./EditService";
import _ from "lodash";
import { useDispatch } from "react-redux";
import {
  setAlertTitle,
  setOpenSnackbar,
} from "../../../redux/slice/snackbar.slice";

const PlanTable = styled("table")(({ theme }) => ({
  borderRadius: 12,
  overflow: "hidden",
  width: 1020,
  borderSpacing: 0,
  td: {
    border: "0px",
  },
  "& .subthead": {
    tr: {
      background: SynckitColors.Primary[50],
      height: 52,
      th: {
        borderRight: "1px solid white",
      },
    },
  },
  tbody: {
    tr: {
      td: {
        border: "1px solid white",
      },
    },
  },
}));

const PlanButton = styled(Button)(({ theme }) => ({
  width: 260,
  height: 103,
  border: `1px solid ${SynckitColors.Gray[200]}`,
  borderRadius: 0,
  color: SynckitColors.Gray[900],
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  "&.selected": {
    border: `1px solid ${SynckitColors.Primary[600]}`,
    background: SynckitColors.Primary[50],
    "& .MuiSvgIcon-root": {
      display: "inline-block",
    },
  },
  "& .MuiSvgIcon-root": {
    // position: "absolute",
    display: "none",
    color: SynckitColors.Success["Default"],
    // top: 4,
    // right: 10,
  },
}));

export default function ServicePlan({ setServiceMode }) {
  const [planDataList, setPlanDataList] = useState([
    {
      id: 1,
      serviceType: "Examination",
      planType: "Single session / 30min",
      price: 60,
      applying: false, //修改價格 申請中
    },
    {
      id: 2,
      serviceType: "Examination",
      planType: "sensitive pricing / 5min",
      price: 10,
      applying: false, //修改價格 申請中
    },
    {
      id: 3,
      serviceType: "Examination",
      planType: "single session unlimited time",
      price: 120,
      applying: false, //修改價格 申請中
    },
    {
      id: 4,
      serviceType: "Examination + Consultation",
      planType: "Single session / 30min",
      price: 60,
      applying: false, //修改價格 申請中
    },
    {
      id: 5,
      serviceType: "Examination + Consultation",
      planType: "sensitive pricing / 5min",
      price: 10,
      applying: false, //修改價格 申請中
    },
    {
      id: 6,
      serviceType: "Examination + Consultation",
      planType: "single session unlimited time",
      price: 120,
      applying: false, //修改價格 申請中
    },
    {
      id: 7,
      serviceType: "Examination + Consultation + Prescription",
      planType: "Single session / 30min",
      price: 60,
      applying: false, //修改價格 申請中
    },
    {
      id: 8,
      serviceType: "Examination + Consultation + Prescription",
      planType: "sensitive pricing / 5min",
      price: 10,
      applying: false, //修改價格 申請中
    },
    {
      id: 9,
      serviceType: "Examination + Consultation + Prescription",
      planType: "single session unlimited time",
      price: 120,
      applying: false, //修改價格 申請中
    },
  ]);
  const [selectedList, setSelectedList] = useState([
    {
      id: 1,
      title: "Examination",
      selectedPlanId: 1,
      editMode: false,
      applying: false,
    }, //Examination
    {
      id: 2,
      title: "Examination + Consultation",
      selectedPlanId: 5,
      editMode: false,
      applying: true,
    }, //examination + Consultation
    {
      id: 3,
      title: "Examination + Consultation + Prescription",
      selectedPlanId: 9,
      editMode: false,
      applying: false,
    }, //examination + Consultation + Prescription
  ]);

  const dispatch = useDispatch();

  const handleSelectPlan = (_planId) => {
    let serviceTypeId = null;
    // service type "Examination" (id = 1) 可選擇 planDataList id 為 1,2,3
    // service type "examination + Consultation" (id = 2) 可選擇 planDataList id 為 4,5,6
    // service type "examination + Consultation + Prescriptionn" (id = 3) 可選擇 planDataList id 為 7,8,9
    if ([1, 2, 3].includes(_planId)) serviceTypeId = 1;
    else if ([4, 5, 6].includes(_planId)) serviceTypeId = 2;
    else if ([7, 8, 9].includes(_planId)) serviceTypeId = 3;

    const tempList = selectedList.map((item) =>
      item.id === serviceTypeId
        ? {
            ...item,
            selectedPlanId: item.selectedPlanId === _planId ? null : _planId, //重選（再點擊一次）為取消選取 => item.selectedPlanId === _planId
          }
        : item
    );
    setSelectedList([...tempList]);
  };

  const handleSetPrice = (_id, _price) => {
    const tempList = planDataList.map((item) =>
      item.id === _id ? { ...item, price: _price } : item
    );
    setPlanDataList([...tempList]);
  };
  const handleApplyAdjustPrice = (_id) => {
    const tempList = planDataList.map((item) =>
      item.id === _id ? { ...item, applying: true } : item
    );
    setTimeout(() => {
      setPlanDataList([...tempList]);
      dispatch(setAlertTitle("Application sent"));
      dispatch(setOpenSnackbar(true));
    }, 100);
  };

  const handleDelete = (_id) => {
    let tempList = selectedList.map((item) =>
      item.id === _id
        ? { ...item, selectedPlanId: null, editMode: false }
        : item
    );
    setTimeout(() => {
      setSelectedList([...tempList]);
      dispatch(setAlertTitle("Successfully deletedt"));
      dispatch(setOpenSnackbar(true));
    }, 100);
  };

  return (
    <div>
      <Button
        sx={{ color: SynckitColors.Gray["core"], mb: 2 }}
        startIcon={<ArrowBackIosIcon />}
        onClick={() => setServiceMode("default")}
      >
        <Typography variant="subtitle1"> {"My service plan"}</Typography>
      </Button>
      <Box sx={{ width: "100%", overflowX: "auto" }}>
        <PlanTable>
          <thead>
            <tr
              style={{
                textAlgin: "center",
                background: SynckitColors.Primary[600],
                height: 40,
              }}
            >
              <th colSpan={5}>
                <Typography variant="subtitle2" sx={{ color: "white" }}>
                  Plan type
                </Typography>
              </th>
            </tr>
          </thead>
          <thead className="subthead">
            <tr>
              <th colSpan={2}></th>
              <th style={{ width: 255 }}>
                <Typography
                  variant="subtitle2"
                  sx={{ color: SynckitColors.Primary[700] }}
                >
                  Single session / 30min
                </Typography>
              </th>
              <th style={{ width: 255 }}>
                <Typography
                  variant="subtitle2"
                  sx={{ color: SynckitColors.Primary[700] }}
                >
                  sensitive pricing / 5min
                </Typography>
              </th>
              <th style={{ width: 255 }}>
                <Typography
                  variant="subtitle2"
                  sx={{ color: SynckitColors.Primary[700] }}
                >
                  single session unlimited time
                </Typography>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                rowSpan={3}
                style={{
                  background: SynckitColors.Gray[900],
                  color: "white",
                  width: 116,
                  textAlign: "center",
                }}
              >
                <Typography variant="subtitle2">Service type</Typography>
              </td>
              <td
                style={{
                  background: SynckitColors.Gray[700],
                  color: "white",
                  height: 103,
                  width: 116,
                  paddingLeft: 6,
                }}
              >
                Examination
              </td>
              <td colSpan={3} rowSpan={3} style={{ padding: 0 }}>
                <RenderPlanBox
                  planDataList={planDataList}
                  selectedList={selectedList}
                  handleSelectPlan={handleSelectPlan}
                />
              </td>
            </tr>
            <tr>
              <td
                style={{
                  background: SynckitColors.Gray[700],
                  color: "white",
                  height: 103,
                  width: 116,
                  paddingLeft: 6,
                }}
              >
                examination + Consultation{" "}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  background: SynckitColors.Gray[700],
                  color: "white",
                  height: 103,
                  width: 116,
                  paddingLeft: 6,
                }}
              >
                examination + Consultation + Prescription{" "}
              </td>
            </tr>
          </tbody>
        </PlanTable>
      </Box>
      <EditService
        planDataList={planDataList}
        handleSetPrice={handleSetPrice}
        handleApplyAdjustPrice={handleApplyAdjustPrice}
        RenderPriceInfo={RenderPriceInfo}
        selectedList={selectedList}
        setSelectedList={setSelectedList}
        handleDelete={handleDelete}
      />
    </div>
  );
}

const RenderPlanBox = ({ planDataList, selectedList, handleSelectPlan }) => {
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      {planDataList.map((item, index) => (
        <PlanButton
          key={index}
          className={
            _.filter(selectedList, { selectedPlanId: item.id }).length //Plan有選取時 length 為1
              ? "selected"
              : null
          }
          onClick={() => handleSelectPlan(item.id)}
        >
          <Box sx={{ textAlign: "right", width: "100%", height: 32 }}>
            <CheckIcon />
          </Box>
          <RenderPriceInfo planData={item} />
          {item.applying ? (
            <Typography
              variant="body1"
              sx={{ color: SynckitColors.Primary[600] }}
            >
              Application sent
            </Typography>
          ) : (
            "　"
          )}
        </PlanButton>
      ))}
    </Box>
  );
};

const RenderPriceInfo = ({ planData }) => (
  <Typography variant="body1">
    {`${planData.price * 10}pts-$${planData.price}USD / ${
      [1, 4, 7].includes(planData.id) ? "30min" : "5min"
    }`}
  </Typography>
);

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, styled, Tab } from "@mui/material";
import React, { useState } from "react";
import { settingMenuList } from "./settingMenuList";
import { SynckitColors } from "../../assets/SynckitColors";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  minHeight: "calc(100vh - 120px)",
  background: "white",
  borderRadius: 12,
  overflow: "hidden",
  filter: SynckitColors.Shadow[1],
  padding: "8px 12px",
}));

export default function SettingIndex() {
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Root>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} variant="scrollable">
            {settingMenuList.map((item) => (
              <Tab
                label={item.title}
                value={item.id.toString()}
                key={item.id}
                sx={{ textTransform: "none" }}
              />
            ))}
          </TabList>
        </Box>
        {settingMenuList.map((item, index) => (
          <TabPanel value={item.id.toString()} key={index}>
            {item.component}
          </TabPanel>
        ))}
      </TabContext>
    </Root>
  );
}

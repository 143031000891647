import { styled } from "@mui/material";
import React from "react";
import ImgBackBottom from "../../../../assets/images/body/model_body_back_bottom.png";
import { ModelBodyBackBottom } from "./ModelBodyBackBottom";

const SVGWrap = styled("div")(({ theme }) => ({
  margin: "0px auto",
  position: "relative",
  width: "100%",
  height: "100%",
  "& img": {
    position: "absolute",
    height: "100%",
    left: "50%",
    transform: "translateX(-50%)",
  },
  "& svg": {
    position: "absolute",
    // height: "100%",
    // left: "50%",
    // transform: "translateX(-50%)",
    "& path": {
      fill: "rgba(255, 255, 255, 0.00009)",
      transition: "0.3s",
    },
    // "& path:hover": {
    //   fill: SynckitColors.Yellow[200],
    //   cursor: "pointer",
    // },
  },
}));

export default function BodyBackBottom({ currentPartId }) {
  return (
    <SVGWrap>
      <img src={ImgBackBottom} alt="synckit" />
      <ModelBodyBackBottom currentPartId={currentPartId} />
    </SVGWrap>
  );
}

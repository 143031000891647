import { SynckitColors } from "../../../../assets/SynckitColors";

export const HeadBTSVG = ({ currentPartId = "", handleClick }) => {
  return (
    <svg viewBox="3.5 2 186 136" stroke=" " onClick={handleClick}>
      <path
        d="M64.3,37.3c-0.1,1.8,1.4,3.2,3.2,2.6c5.4-2.1,16.4-3,30.4-3.1c0,0,7.7-0.8,28.6,3.6c2,0.4,3.4-1,3.2-3
			c-0.7-8-8.4-23.2-32.6-23.2C97,14.1,65.9,12.6,64.3,37.3z"
        id="bt"
        style={{
          fill: currentPartId === "bt" ? SynckitColors.Green[200] : "",
        }}
      />
    </svg>
  );
};

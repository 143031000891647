import { Datepicker } from "@mobiscroll/react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { SettingList } from "./SystemIndex";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const daysList = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const DisurbTimeWrap = styled("div")(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));
export default function Notification() {
  const [duration, setDuration] = useState("");
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  return (
    <Box style={{ maxWidth: 600, textAlign: "center" }}>
      <SettingList>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle1">Notifications</Typography> <Switch />
        </Box>

        <FormGroup>
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Appointments Notifications"
          />
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Contacts Notifications"
          />
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Payment Notifications"
          />
        </FormGroup>
      </SettingList>
      <SettingList>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle1">Do not Disturb time</Typography>{" "}
          <Switch />
        </Box>
        <DisurbTimeWrap>
          <FormControl sx={{ mt: 1, mr: 1, minWidth: 200, width: "100%" }}>
            <InputLabel>Days</InputLabel>
            <Select
              multiple
              value={personName}
              onChange={handleChange}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {daysList.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Datepicker
            controls={["time"]}
            select="range"
            touchUi={true}
            inputComponent={TextField}
            onChange={(e) => setDuration(e.valueText)}
            inputProps={{
              label: "Start Time - End Time",
              value: duration,
              sx: { mt: 1 },
              fullWidth: true,
            }}
          />
        </DisurbTimeWrap>
      </SettingList>
      <Button variant="primary" sx={{ mt: 3 }}>
        Save
      </Button>
    </Box>
  );
}

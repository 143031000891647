import { Button, Menu, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import { organList } from "../../../../assets/constant";
import { IconFileTextLine } from "../../../../assets/SynckitIcons";
import { SynckitPanel } from "../../../../components/SynckitPanel";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate } from "react-router-dom";

export default function ReportCard() {
  const [generateMenuAnchorEl, setGenerateMenuAnchorEl] = useState(null);
  const [comparisonMenuAnchorEl, setComparisonMenuAnchorEl] = useState(null);
  const navitage = useNavigate();

  const handleCloseGenerateMenu = () => {
    setGenerateMenuAnchorEl(null);
  };

  const handleCloseComparisonMenu = () => {
    setComparisonMenuAnchorEl(null);
  };
  return (
    <SynckitPanel
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        width: "100%",
        height: 314,
        marginTop: 7,
        padding: "40px 55px",
      }}
    >
      <IconFileTextLine color="primary" sx={{ fontSize: 80 }} />

      <Typography variant="h5" sx={{ pt: 1, pb: 1 }}>
        {"Report"}
      </Typography>
      <Button
        variant="third"
        fullWidth
        sx={{ height: 36 }}
        onClick={(e) => setGenerateMenuAnchorEl(e.currentTarget)}
      >
        Generate
      </Button>
      <Button
        variant="third"
        fullWidth
        sx={{ height: 36 }}
        onClick={() => {
          navitage("/pm/patient/er/br");
        }}
      >
        See all
      </Button>
      <Menu
        anchorEl={generateMenuAnchorEl}
        open={Boolean(generateMenuAnchorEl)}
        onClose={handleCloseGenerateMenu}
      >
        <MenuItem
          onClick={() => {
            navitage("/pm/patient/er/sr");
          }}
        >
          Single Report
        </MenuItem>
        <MenuItem onClick={(e) => setComparisonMenuAnchorEl(e.currentTarget)}>
          {"Comparison Report"}
          {Boolean(comparisonMenuAnchorEl) ? (
            <ArrowDropDownIcon />
          ) : (
            <ArrowRightIcon />
          )}
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={comparisonMenuAnchorEl}
        open={Boolean(comparisonMenuAnchorEl)}
        onClose={handleCloseComparisonMenu}
      >
        {organList.map((organ, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              navitage("/pm/patient/er/cr");
            }}
            sx={{ width: 195 }}
          >
            {organ}
          </MenuItem>
        ))}
      </Menu>
    </SynckitPanel>
  );
}

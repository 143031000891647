import { Box, Button, Typography } from "@mui/material";
import {
  NOTIFICATIONS_TYPE_APPOINTMENT,
  NOTIFICATIONS_TYPE_CONTACT,
  NOTIFICATIONS_TYPE_PAYMENT,
  REQUEST_STATUS_ACCEPTED,
  REQUEST_STATUS_REJECTED,
  REQUEST_STATUS_WAITING_CONFIRM,
  REQUEST_STATUS_WAITING_PAY,
} from "../../assets/constant";
import { SynckitColors } from "../../assets/SynckitColors";
import { IconCalendarLine } from "../../assets/SynckitIcons";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import NoMessagesImg from "../../assets/images/common/no_messages.png";

const notificationTypeList = [
  //NOTIFICATIONS_TYPE_APPOINTMENT = 0;
  {
    type: NOTIFICATIONS_TYPE_APPOINTMENT,
    title: "Appointment",
    icon: <IconCalendarLine />,
  },
  //NOTIFICATIONS_TYPE_CONTACT = 1;
  {
    type: NOTIFICATIONS_TYPE_CONTACT,
    title: "Contacts",
    icon: <IconCalendarLine />,
  },
  // NOTIFICATIONS_TYPE_PAYMENT = 2;
  {
    type: NOTIFICATIONS_TYPE_PAYMENT,
    title: "Payment",
    icon: <PaidOutlinedIcon />,
  },
];
export const RenderNotificationsType = ({
  datetime,
  type,
  displayDirection = "row",
}) => (
  <Box
    sx={{
      display: "flex",
      justifyContent:
        displayDirection === "row" ? "space-between" : "space-around",
      flexDirection: displayDirection === "row" ? "row" : "column",
      alignItems: displayDirection === "row" ? "center" : "flex-start",
      height: 56,
    }}
  >
    <Typography
      variant="body2"
      sx={{
        color: SynckitColors.Gray[800],
        display: "flex",
        alignItems: "center",
      }}
    >
      {notificationTypeList[type]["icon"]}
      &nbsp; {notificationTypeList[type]["title"]}
    </Typography>

    <Typography variant="body2" sx={{ color: SynckitColors.Gray[800] }}>
      {datetime}
    </Typography>
  </Box>
);

export const RenderRequestSection = ({ requestStatus }) => {
  switch (requestStatus) {
    case REQUEST_STATUS_ACCEPTED:
      return (
        <Typography
          variant="body2"
          sx={{
            color: SynckitColors.Gray[800],
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            mb: 2,
          }}
        >
          <CheckIcon sx={{ color: SynckitColors.Success["Default"], mr: 1 }} />{" "}
          Request accepted
        </Typography>
      );
    case REQUEST_STATUS_REJECTED:
      return (
        <Typography
          variant="body2"
          sx={{
            color: SynckitColors.Gray[800],
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            mb: 2,
          }}
        >
          <CloseIcon sx={{ color: SynckitColors.Error["Default"], mr: 1 }} />{" "}
          Request rejected
        </Typography>
      );
    case REQUEST_STATUS_WAITING_CONFIRM:
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button variant="secondary">Reufse</Button>
          {"　"}
          <Button variant="primary">Confirm</Button>
        </Box>
      );
    case REQUEST_STATUS_WAITING_PAY:
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button variant="primary">Pay</Button>
        </Box>
      );
    default:
      break;
  }
};

export const RenderNoMessagesSection = () => (
  <Box
    sx={{
      background: "white",
      width: "100%",
      height: 660,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <img src={NoMessagesImg} alt="" width="166" />
  </Box>
);

import React from "react";
import { Typography, Avatar } from "@mui/material";
import { styled } from "@mui/system";
import { SynckitColors } from "../../assets/SynckitColors";
import { IconClockLine } from "../../assets/SynckitIcons";
import { ImgOrganHeartActive2 } from "../../assets/SynckitImg";
import SynckitApoTypeLabel from "../../components/label/SynckitApoTypeLabel";
import { DemoRowData } from "../schedule/appointment/demolApoData";
import ConsultIndex from "../consult/ConsultIndex";
import { APO_TYPE_REMOTE } from "../../assets/constant";

const ApoList = styled("ul")(({ theme }) => ({}));

const ApoListItem = styled("li")(({ theme }) => ({
  background: "white",
  width: "100%",
  height: 104,
  borderRadius: 12,
  boxShadow: SynckitColors.Shadow[1],
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "16px 40px",
  marginBottom: "2%",
  transition: "0.3s",
  [theme.breakpoints.down(1000)]: {
    padding: "16px 2%",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "16px 40px",

    flexDirection: "column",
    height: 240,
  },
}));

const InfoWrap = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  margin: "0 1%",
  width: 500,
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const TypeWrap = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
}));

export default function UpcomingAppointment() {
  return (
    <div>
      <Typography
        variant="h5"
        sx={{ color: SynckitColors.Gray["core"], marginBottom: 2 }}
      >
        Upcoming Appointment
      </Typography>
      <ApoList> {DemoRowData.map((item) => renderListItem(item))}</ApoList>
    </div>
  );
}

const renderListItem = (itemData) => (
  <ApoListItem key={itemData.id}>
    <Avatar
      alt={itemData.patient}
      src={itemData.avatar}
      sx={{ width: 72, height: 72, marginRight: 2 }}
    />
    <InfoWrap>
      <Typography
        variant="subtitle1"
        sx={{ color: SynckitColors.Gray["core"] }}
      >
        {itemData.patient}
      </Typography>
      <Typography variant="body1" sx={{ color: SynckitColors.Gray["core"] }}>
        {itemData.doctor}
      </Typography>
      <Typography
        variant="body2"
        component={"div"}
        sx={{
          marginLeft: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          color: SynckitColors.Gray[800],
        }}
      >
        <IconClockLine />
        &nbsp; {itemData.date}
      </Typography>
    </InfoWrap>

    <TypeWrap>
      {SynckitApoTypeLabel(itemData.type)}
      {itemData.type === APO_TYPE_REMOTE && (
        <ImgOrganHeartActive2
          style={{ width: 40, height: 40, marginRight: "4%" }}
        />
      )}

      {itemData.id === 1 ? <ConsultIndex /> : <div style={{ width: 40 }}></div>}
    </TypeWrap>
  </ApoListItem>
);

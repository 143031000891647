import { Box, Button, styled, Typography } from "@mui/material";
import React from "react";
import { SynckitColors } from "../../../assets/SynckitColors";

const InfoCard = styled("div")({
  backgroundColor: SynckitColors.Primary[50],
  borderRadius: 12,
  padding: "24px 40px 32px 40px",
  marginBottom: "2%",
  boxShadow:
    "0px 27px 80px rgba(16, 57, 123, 0.05), 0px 13.6687px 34.875px rgba(16, 57, 123, 0.03375), 0px 5.4px 13px rgba(16, 57, 123, 0.025), 0px 1.18125px 4.625px rgba(16, 57, 123, 0.01625)",
});
const ContextParagraph = styled("div")(({ theme }) => ({
  fontWeight: 400,
  fontSize: 14,
  lineHeight: "20px",
  letterSpacing: "0.1px",
  margin: "16px 0px",
}));
export default function Eula() {
  return (
    <Box style={{ maxWidth: 600, textAlign: "center" }}>
      <InfoCard>
        <Typography variant="h5" color="initial" sx={{ textAlign: "center" }}>
          Authorization and Service Agreement for Common User
        </Typography>
        <ContextParagraph>
          Please read the following terms. Please sign your name and date in the
          appropriate signature line to signify your agreement. This statement
          is a legal document, therefore, it must be authorized and signed by
          the user or his/her legal representative. The user should sign his/her
          name by his/herself. If the situation is not permeable, the legal
          representative should sign his/herself name and provide agent
          certification or other legal documents.
        </ContextParagraph>
        <ContextParagraph>
          SyncVision Technology Corp. (“System Developer”) Person undertaking
          hereby[系統依初次建立病人基本資料的姓名帶入名稱](“User”)
        </ContextParagraph>
        <ContextParagraph>
          System developer provides the user with the SyncKit and SyncCloud (the
          cloud service platform for SyncKit system) system for service purpose.
          This agreement shall be effective from signature date to 31st,
          December in 2028, unless the expiration within a short period of time
          according to relevant laws. Two parties shall perform in accordance
          with the bellowed terms after the agreement.
        </ContextParagraph>
      </InfoCard>
    </Box>
  );
}

import {
  NOTIFICATIONS_STATUS_OFFLINE,
  NOTIFICATIONS_STATUS_ONLINE,
  NOTIFICATIONS_TYPE_APPOINTMENT,
  NOTIFICATIONS_TYPE_CONTACT,
  NOTIFICATIONS_TYPE_PAYMENT,
  REQUEST_STATUS_ACCEPTED,
  REQUEST_STATUS_WAITING_CONFIRM,
  REQUEST_STATUS_WAITING_PAY,
} from "../../assets/constant";

export const notificationDemoData = [
  {
    id: 1,
    title: "(Doctor) has sent you a Consultation Invitation. ",
    type: NOTIFICATIONS_TYPE_APPOINTMENT,
    datetime: "2021.10.22 10:30",
    status: NOTIFICATIONS_STATUS_OFFLINE,
    requestStatus: REQUEST_STATUS_WAITING_CONFIRM,
  },
  {
    id: 2,
    title:
      "(DoctorA) recommmend (DoctorB) as your attending doctor. Authorize him/her to view your information?",
    type: NOTIFICATIONS_TYPE_CONTACT,
    datetime: "2021.10.21 09:30",
    status: NOTIFICATIONS_STATUS_ONLINE,
    requestStatus: REQUEST_STATUS_WAITING_CONFIRM,
  },
  {
    id: 3,
    title: "(Doctor) has sent you a Consultation Invitation. ",
    type: NOTIFICATIONS_TYPE_APPOINTMENT,
    datetime: "2021.10.20 11:30",
    status: null,
    requestStatus: REQUEST_STATUS_ACCEPTED,
  },
  {
    id: 4,
    title: "We haven't receive your consultation fee yet. ",
    type: NOTIFICATIONS_TYPE_PAYMENT,
    datetime: "2021.10.20 11:30",
    status: null,
    requestStatus: REQUEST_STATUS_ACCEPTED,
  },
  {
    id: 5,
    title: "We haven't receive your consultation fee yet. ",
    type: NOTIFICATIONS_TYPE_PAYMENT,
    datetime: "2021.10.20 11:30",
    status: NOTIFICATIONS_STATUS_OFFLINE,
    requestStatus: REQUEST_STATUS_WAITING_PAY,
  },
];

export const latestNewsDemoData = [
  {
    id: 1,
    title: "App is scheduled for shutdown maintenance",
    type: NOTIFICATIONS_TYPE_APPOINTMENT,
    datetime: "2021.10.22 10:30",
    status: NOTIFICATIONS_STATUS_OFFLINE,
    requestStatus: REQUEST_STATUS_WAITING_CONFIRM,
  },
  {
    id: 2,
    title: "App is scheduled for shutdown maintenance",
    type: NOTIFICATIONS_TYPE_CONTACT,
    datetime: "2021.10.21 09:30",
    status: NOTIFICATIONS_STATUS_ONLINE,
    requestStatus: REQUEST_STATUS_WAITING_CONFIRM,
  },
];

import React from "react";
import Grid from "@mui/material/Grid";
import {
  Avatar,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/lab";
import { SynckitPanel } from "../../../components/SynckitPanel";
import { SynckitButton } from "../../../components/SynckitButton";

export default function BasicInfoForm() {
  return (
    <SynckitPanel>
      <Grid
        container
        spacing={1}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
      >
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: 170,
            alignItems: "center",
          }}
          xs={12}
          // md={6}
          lg={3}
        >
          <Avatar sx={{ width: 112, height: 112 }} />
          <SynckitButton variant="outlined">Upload</SynckitButton>
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: 130,
          }}
          xs={12}
          md={4}
          lg={3}
        >
          <TextField required label="First Name" size="small" />

          <DesktopDatePicker
            label="Date desktop"
            inputFormat="MM/dd/yyyy"
            onChange={() => {}}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: 130,
          }}
          xs={12}
          md={4}
          lg={3}
        >
          <TextField
            required
            label="Last Name"
            size="small"
            variant="outlined"
          />
          <TextField required label="NHI" size="small" variant="outlined" />
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: 130,
          }}
          xs={12}
          md={4}
          lg={3}
        >
          <FormControl component="fieldset">
            <FormLabel component="legend" required>
              Gender
            </FormLabel>
            <RadioGroup row aria-label="gender">
              <FormControlLabel value="male" control={<Radio />} label="Male" />
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="Female"
              />
            </RadioGroup>
          </FormControl>
          <TextField select label="Ethnicity" fullWidth required size="small">
            <MenuItem value={0}>Asian</MenuItem>
            <MenuItem value={1}>Black</MenuItem>
            <MenuItem value={3}>White</MenuItem>
          </TextField>
        </Grid>
      </Grid>
    </SynckitPanel>
  );
}

import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  SvgIcon,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as MoreSVG } from "../../assets/icon/general/icon_More Horizontal.svg";

import CloseIcon from "@mui/icons-material/Close";
import avatar_patient1 from "../../assets/images/demo/avatar_patient1.jpg";

import FemaleIcon from "@mui/icons-material/Female";
import { styled } from "@mui/system";

const PatientNameAndGender = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  height: 32,
  marginTop: 16,
  paddingLeft: 18,
});

const CustomizeCard = styled(Card)(({ theme }) => ({
  minWidth: 460,
  transition: "0.3s",
  overflowY: "auto",
  [theme.breakpoints.down("sm")]: {
    minWidth: "auto",
  },
}));
const CustomizeCardContent = styled(CardContent)(({ theme }) => ({
  transition: "0.3s",
  padding: "0px 8px 0px 22px",
  [theme.breakpoints.down("sm")]: {
    padding: "0px 10px",
  },
}));
const CustomizeCardHeader = styled(CardHeader)(({ theme }) => ({
  transition: "0.3s",
  [theme.breakpoints.down("sm")]: {
    "& .MuiTypography-root": {
      fontSize: "1em",
    },
  },
}));

const CustomizeListItem = styled(ListItem)(({ theme }) => ({
  padding: 0,
}));

export default function LocalApoStaticDialog({ open, setOpen, eventData }) {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const openMenu = Boolean(menuAnchorEl);

  return (
    <>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <CustomizeCard>
          <CustomizeCardHeader
            avatar={
              <IconButton onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
                <SvgIcon component={MoreSVG} />
              </IconButton>
            }
            title="Local Session Appointment"
            titleTypographyProps={{
              variant: "h5",
              sx: { textAlign: "center" },
            }}
            action={
              <IconButton sx={{ marginLeft: 1 }} onClick={() => setOpen(false)}>
                <CloseIcon />
              </IconButton>
            }
          />
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar
              alt=""
              src={avatar_patient1}
              sx={{ width: 60, height: 60 }}
            />
            <PatientNameAndGender>
              <Typography variant="subtitle1" color="initial">
                Cammy Lytle
              </Typography>
              &nbsp;
              <FemaleIcon color="error" />
            </PatientNameAndGender>
            <Typography variant="body2" color="initial">
              G3982958195
            </Typography>
          </CardContent>
          <Divider />
          <CustomizeCardContent>
            <List>
              <CustomizeListItem>
                <ListItemText
                  primary="Date"
                  secondary="2021/03/11"
                  primaryTypographyProps={{ variant: "subtitle1" }}
                  secondaryTypographyProps={{ variant: "body1" }}
                />
              </CustomizeListItem>
              <CustomizeListItem>
                <ListItemText
                  primary="Time"
                  secondary="9:30 ~ 10:00 ( 30 min. )"
                  primaryTypographyProps={{ variant: "subtitle1" }}
                  secondaryTypographyProps={{ variant: "body1" }}
                />
              </CustomizeListItem>
            </List>
          </CustomizeCardContent>
        </CustomizeCard>
      </Dialog>
      <Menu
        open={openMenu}
        anchorEl={menuAnchorEl}
        onClose={() => setMenuAnchorEl(false)}
      >
        <MenuItem onClick={() => setMenuAnchorEl(false)}>
          Examination Record
        </MenuItem>
        <MenuItem onClick={() => setMenuAnchorEl(false)}>Cancel</MenuItem>
      </Menu>
    </>
  );
}

import { Eventcalendar } from "@mobiscroll/react";
import { styled } from "@mui/system";
import { SynckitColors } from "../../assets/SynckitColors";

export const SynckitEventcalendar = styled(Eventcalendar)(({ theme }) => ({
  "&.mbsc-calendar": {
    borderRadius: 12,
    // filter: SynckitColors.Shadow[1],
  },

  "& .mbsc-calendar-text": {
    borderRadius: 6,
    border: `1px solid ${SynckitColors.Gray[500]}`,
    height: "2.0em",

    marginBottom: 4,
    overflow: "hidden",
  },
  "& .mbsc-calendar-text-more": {
    position: "absolute",
    left: "auto",
    right: 0,

    background: "white",
    textAlign: "center",
  },
}));

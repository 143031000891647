import { Grid, Typography, IconButton, TextField, Box } from "@mui/material";
import React, { useState } from "react";
import { SynckitColors } from "../../../assets/SynckitColors";
import { SynckitPanel } from "../../../components/SynckitPanel";
import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/system";
import RemoveIcon from "@mui/icons-material/Remove";
import { SynckitDivider } from "../../../components/SynckitDivider";

const PlusButton = styled(IconButton)({
  backgroundColor: SynckitColors.Primary[50],
  width: 24,
  height: 24,
  marginLeft: 16,
  "& .MuiSvgIcon-root": {
    color: SynckitColors.Primary[600],
    fontSize: 20,
  },
});
const MinusButton = styled(IconButton)({
  backgroundColor: SynckitColors.Error["Light"],
  width: 24,
  height: 24,
  marginLeft: 16,
  "& .MuiSvgIcon-root": {
    color: "white",
    fontSize: 20,
  },
});
const MedicalRecordInputWrap = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    height: 90,
  },
}));

export default function MedicalRecord() {
  const [medicalRecordAry, setMedicalRecordAry] = useState([
    { facility: "", mrn: "" },
  ]);
  const [medicationAry, setMedicationAry] = useState([
    { facility: "", mrn: "", frequency: "", route: "" },
  ]);
  const [medicalAry, setMedicalAry] = useState([{ allergic: "", drug: "" }]);

  return (
    <SynckitPanel>
      {/* Medical Record Number Start */}
      <Grid
        container
        spacing={3}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
      >
        <Grid item xs={12} sm={12} lg={4}>
          <Box sx={{ width: 216, display: "flex" }}>
            <Typography
              variant="subtitle1"
              sx={{ color: SynckitColors.Gray[800] }}
            >
              Medical Record Number
            </Typography>
            <PlusButton
              onClick={() => {
                let tempAry = medicalRecordAry;
                tempAry.push({});
                setMedicalRecordAry([...tempAry]);
              }}
            >
              <AddIcon />
            </PlusButton>
          </Box>
        </Grid>

        {medicalRecordAry.map((item, index) => (
          <>
            {index !== 0 && (
              <Grid item xs={2} sm={1} lg={4} key={index}>
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: 216,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <MinusButton
                    onClick={() => {
                      let tempAry = medicalRecordAry;
                      tempAry.pop({});
                      setMedicalRecordAry([...tempAry]);
                    }}
                  >
                    <RemoveIcon />
                  </MinusButton>
                </Box>
              </Grid>
            )}

            <Grid
              item
              xs={index === 0 ? 12 : 10}
              sm={index === 0 ? 12 : 11}
              lg={8}
            >
              <MedicalRecordInputWrap>
                <TextField
                  label="Facility"
                  fullWidth
                  size="small"
                  //   value={}
                  //   onChange={}
                  sx={{ marginRight: 3 }}
                />
                <TextField
                  label="MRN"
                  fullWidth
                  size="small"
                  //   value={}
                  //   onChange={}
                />
              </MedicalRecordInputWrap>
            </Grid>
          </>
        ))}
      </Grid>
      {/* Medical Record Number End */}

      <SynckitDivider />
      {/* Medication Start */}
      <Grid
        container
        spacing={3}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
      >
        <Grid item xs={12} sm={12} lg={4}>
          <Box
            sx={{
              width: 216,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{ color: SynckitColors.Gray[800] }}
            >
              Medication
            </Typography>
            <PlusButton
              onClick={() => {
                let tempAry = medicationAry;
                tempAry.push({});
                setMedicationAry([...tempAry]);
              }}
            >
              <AddIcon />
            </PlusButton>
          </Box>
        </Grid>

        {medicationAry.map((item, index) => (
          <>
            {index !== 0 && (
              <Grid item xs={2} sm={1} lg={4} key={index}>
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: 216,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <MinusButton
                    onClick={() => {
                      let tempAry = medicationAry;
                      tempAry.pop({});
                      setMedicationAry([...tempAry]);
                    }}
                  >
                    <RemoveIcon />
                  </MinusButton>
                </Box>
              </Grid>
            )}

            <Grid item xs={10} sm={11} lg={8}>
              <MedicalRecordInputWrap>
                <TextField
                  label="Facility"
                  fullWidth
                  size="small"
                  //   value={}
                  //   onChange={}
                  sx={{ marginRight: 3 }}
                />
                <TextField
                  label="MRN"
                  fullWidth
                  size="small"
                  //   value={}
                  //   onChange={}
                />
              </MedicalRecordInputWrap>
            </Grid>
            <Grid item xs={2} sm={1} lg={4}></Grid>
            <Grid item xs={10} sm={11} lg={8}>
              <MedicalRecordInputWrap>
                <TextField
                  label="Frequency"
                  fullWidth
                  size="small"
                  //   value={}
                  //   onChange={}
                  sx={{ marginRight: 3 }}
                />
                <TextField
                  label="Route"
                  fullWidth
                  size="small"
                  //   value={}
                  //   onChange={}
                />
              </MedicalRecordInputWrap>
            </Grid>
          </>
        ))}
      </Grid>
      {/* Medication End */}

      <SynckitDivider />

      {/* Medical Record Number Start */}
      <Grid
        container
        spacing={3}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
      >
        <Grid item xs={12} sm={12} lg={4}>
          <Box
            sx={{
              width: 216,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{ color: SynckitColors.Gray[800] }}
            >
              Allergic
            </Typography>
            <PlusButton
              onClick={() => {
                let tempAry = medicalAry;
                tempAry.push({});
                setMedicalAry([...tempAry]);
              }}
            >
              <AddIcon />
            </PlusButton>
          </Box>
        </Grid>

        {medicalAry.map((item, index) => (
          <>
            {index !== 0 && (
              <Grid item xs={2} sm={1} lg={4} key={index}>
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: 216,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <MinusButton
                    onClick={() => {
                      let tempAry = medicalAry;
                      tempAry.pop();
                      setMedicalAry([...tempAry]);
                    }}
                  >
                    <RemoveIcon />
                  </MinusButton>
                </Box>
              </Grid>
            )}
            <Grid
              item
              xs={index === 0 ? 12 : 10}
              sm={index === 0 ? 12 : 11}
              lg={8}
            >
              <MedicalRecordInputWrap>
                <TextField
                  label="Allergic"
                  fullWidth
                  size="small"
                  //   value={}
                  //   onChange={}
                  sx={{ marginRight: 3 }}
                />
                <TextField
                  label="Drug"
                  fullWidth
                  size="small"
                  //   value={}
                  //   onChange={}
                />
              </MedicalRecordInputWrap>
            </Grid>
          </>
        ))}
      </Grid>
      {/* Medical Record Number End */}
    </SynckitPanel>
  );
}

import {
  ORGAN_ABDOMINAL,
  ORGAN_ARTERY,
  ORGAN_EAR,
  ORGAN_HEART,
  ORGAN_LUNG,
  ORGAN_NOSE,
  ORGAN_SKIN,
  ORGAN_TEMPERATURE,
  ORGAN_THEETH,
  ORGAN_THROAT,
} from "../../assets/constant";
import { IconOrganTemperatureNormal } from "../../assets/SynckitIcons";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SensorsIcon from "@mui/icons-material/Sensors";
export const menuDataList = [
  {
    id: 1,
    title: "Temperature",
    icon: <IconOrganTemperatureNormal />,
  },
  {
    id: 2,
    title: "Visual",
    icon: <RemoveRedEyeIcon fontSize="small" />,
  },
  {
    id: 3,
    title: "Sound",
    icon: <SensorsIcon fontSize="small" />,
  },
];

export const organMenuList = [
  [{ id: 1, title: "B.T", organ: ORGAN_TEMPERATURE }],
  [
    { id: 2, title: "Skin&Wound", organ: ORGAN_SKIN },
    { id: 3, title: "Ear", organ: ORGAN_EAR },
    { id: 4, title: "Nose", organ: ORGAN_NOSE },
    { id: 5, title: "Throat", organ: ORGAN_THROAT },
    { id: 6, title: "Teeth", organ: ORGAN_THEETH },
  ],
  [
    { id: 7, title: "Heart", organ: ORGAN_HEART },
    { id: 8, title: "Artery", organ: ORGAN_ARTERY },
    { id: 9, title: "Abdominal", organ: ORGAN_ABDOMINAL },
    { id: 10, title: "Lung", organ: ORGAN_LUNG },
  ],
];

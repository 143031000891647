import React from "react";

import { styled } from "@mui/material";
import { SynckitColors } from "../../assets/SynckitColors";
import { APO_TYPE_REMOTE } from "../../assets/constant";

const TypeLabel = styled("span")({
  fontSize: 14,
  fontWeight: "bold",
  borderRadius: 100,

  "&.remote": {
    border: `1px solid ${SynckitColors.Gray[500]}`,
    color: SynckitColors.Gray[800],
    padding: "8px 32px",
  },
  "&.local": {
    background: SynckitColors.Gray[800],
    color: "white",
    padding: "8px 40px",
  },
});

export default function SynckitApoTypeLabel(type) {
  return (
    <TypeLabel className={type === APO_TYPE_REMOTE ? "remote" : "local"}>
      {type === APO_TYPE_REMOTE ? "Remote" : "Local"}
    </TypeLabel>
  );
}

import React from "react";
import Grid from "@mui/material/Grid";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  TextField,
} from "@mui/material";
import { SynckitPanel } from "../../../components/SynckitPanel";

export default function ResidenceInfoForm() {
  return (
    <SynckitPanel>
      <Grid
        container
        spacing={3}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
      >
        <Grid item xs={12} sm={6} lg={3}>
          <TextField select label="Country" fullWidth required size="small">
            <MenuItem value={0}>Taiwan</MenuItem>
            <MenuItem value={1}>USA</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <TextField select label="City" fullWidth required size="small">
            <MenuItem value={0}>Taipei</MenuItem>
            <MenuItem value={1}>Taichung</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
          <TextField required label="Address" size="small" fullWidth />
        </Grid>
        {/* <Grid item xs={12} sm={6} lg={4}>
          <TextField
            select
            label="Organization"
            fullWidth
            required
            size="small"
          >
            <MenuItem value={0}>Organization1</MenuItem>
            <MenuItem value={1}>Organization2</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} lg={8}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox />}
              label="Project based structure"
            />
          </FormGroup>
        </Grid> */}
      </Grid>
    </SynckitPanel>
  );
}

import React, { useState } from "react";
import PointsTransactionRecord from "./PointsTransactionRecord";
import InfoCardSection from "./InfoCardSection";
import MyPointsWithExpiration from "./MyPointsWithExpiration";
import PointsPurchaeList from "./PointsPurchaeList";
import MyPointsUnlimited from "./MyPointsUnlimited";

export default function MyPointsIndex() {
  const [pointsMode, setPointsMode] = useState("default");
  return (
    <div>
      {pointsMode === "default" && (
        <>
          <InfoCardSection setPointsMode={setPointsMode} />
          <PointsTransactionRecord />
        </>
      )}

      {pointsMode === "MyPointsWithExpiration" && (
        <MyPointsWithExpiration setPointsMode={setPointsMode} />
      )}
      {pointsMode === "MyPointsUnlimited" && (
        <MyPointsUnlimited setPointsMode={setPointsMode} />
      )}
      {pointsMode === "PointsPurchaeList" && (
        <PointsPurchaeList setPointsMode={setPointsMode} />
      )}
    </div>
  );
}

import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import Header from "./Header";

import {
  SIDMENU_WIDTH_MD,
  SIDMENU_WIDTH_NORMAL,
  SIDMENU_WIDTH_SM,
} from "../assets/constant";

import { useMediaQuery } from "@mui/material";

import SideMenuIndex from "./SideMenu/SideMenuIndex";
import SideMenuDrawer from "./SideMenu/SideMenuDrawer";
import SynckitSnackbar from "../assets/SynckitSnackbar";
import { useDispatch, useSelector } from "react-redux";
import { setOpenSnackbar, snackbarState } from "../redux/slice/snackbar.slice";
import ExamRecordEditorDialog from "../views/exam-record-editor/ExamRecordEditorDialog";
import {
  examRecordState,
  setOpenExamRecordEditor,
} from "../redux/slice/examRecord.slice";

const Root = styled("div")({
  width: "100%",
});

const MainContent = styled("main")(({ theme }) => ({
  width: `calc(100% - ${SIDMENU_WIDTH_NORMAL}px)`,
  height: "calc(100% - 80px)",
  position: "fixed",
  top: 80,
  left: SIDMENU_WIDTH_NORMAL,
  padding: "10px 4% 40px 4%",
  overflowY: "auto",
  transition: "0.3s",
  [theme.breakpoints.down("md")]: {
    left: SIDMENU_WIDTH_MD,
    width: `calc(100% - ${SIDMENU_WIDTH_MD}px)`,
  },
  [theme.breakpoints.down("sm")]: {
    left: SIDMENU_WIDTH_SM,
    width: `calc(100% - ${SIDMENU_WIDTH_SM}px)`,
  },
}));

export default function Layout({ children }) {
  const [sideMenuToggle, setSideMenuToggle] = useState(true);
  const isMediumScreen = useMediaQuery("(min-width:600px)");
  const { openSnackbar, alertTitle } = useSelector(snackbarState);
  const { openExamRecordEditor } = useSelector(examRecordState);
  const dispatch = useDispatch();
  useEffect(() => {
    setSideMenuToggle(isMediumScreen);
  }, [isMediumScreen]);
  return (
    <Root>
      {isMediumScreen ? (
        <SideMenuIndex
          sideMenuToggle={sideMenuToggle}
          setSideMenuToggle={setSideMenuToggle}
        />
      ) : (
        <SideMenuDrawer />
      )}

      <MainContent>
        <Header
          sideMenuToggle={sideMenuToggle}
          setSideMenuToggle={setSideMenuToggle}
        />
        {children}
      </MainContent>
      <SynckitSnackbar
        open={openSnackbar}
        setOpen={(val) => dispatch(setOpenSnackbar(val))}
        alertTitle={alertTitle}
      />
      <ExamRecordEditorDialog
        open={openExamRecordEditor}
        setOpen={(val) => dispatch(setOpenExamRecordEditor(val))}
      />
    </Root>
  );
}

import { Datepicker } from "@mobiscroll/react";
import { Box, IconButton, styled, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { SynckitColors } from "../../../assets/SynckitColors";
import { IconFileTextLine } from "../../../assets/SynckitIcons";
import { ImgCrediCardVisa, ImgMasterCard } from "../../../assets/SynckitImg";
import RemoteApoStaticDialog from "../../../components/dialog/RemoteApoStaticDialog";

const RecordTable = styled("table")(({ theme }) => ({
  width: 1005,
  borderRadius: 12,
  overflow: "hidden",
  borderSpacing: 0,
  thead: {
    tr: {
      height: 52,
      background: SynckitColors.Gray[200],
      th: { color: SynckitColors.Gray[700], textAlign: "left" },
    },
  },
  tbody: {
    tr: {
      height: 69,
      boxShadow: SynckitColors.Shadow["ButtomDivider"],
    },
  },
}));
export default function TransactionRecordIndex() {
  const [selectedDate, setSelectedDate] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  return (
    <Box sx={{ width: "100%", overflowX: "auto" }}>
      <Datepicker
        display="center"
        inputComponent={TextField}
        controls={["calendar"]}
        dateFormat="YYYY/MM"
        dateWheels="MMMM"
        touchUi={true}
        onChange={(e) => setSelectedDate(e.valueText)}
        inputProps={{
          label: "Date",
          size: "small",
          sx: { mt: 2, mb: 2 },
          value: selectedDate,
        }}
      />
      <RecordTable>
        <thead>
          <tr>
            {["　Date", "Description", "Credit Card", "Amount", "Details"].map(
              (item) => (
                <th key={item}>
                  <Typography variant="subtitle2">{item}</Typography>
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {demoDataList.map((item, index) => (
            <tr key={index}>
              <td>　{item.date}</td>
              <td>{item.description}</td>
              <td>{item.creditCard}</td>
              <td>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color:
                      item.amount > 0
                        ? SynckitColors.Success["Default"]
                        : SynckitColors.Error["Default"],
                  }}
                >
                  {`${item.amount > 0 ? "+" : "-"} $${Math.abs(item.amount)}`}
                </Typography>
              </td>
              <td>
                <IconButton onClick={() => setOpenDialog(true)}>
                  <IconFileTextLine />
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </RecordTable>
      <RemoteApoStaticDialog open={openDialog} setOpen={setOpenDialog} />
    </Box>
  );
}

const demoDataList = [
  {
    id: 1,
    date: "2021.10.23 18:30",
    description: "Appointment pre-paid",
    creditCard: (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <ImgMasterCard />
        　"*1234"
      </Box>
    ),
    amount: -25,
  },
  {
    id: 2,
    date: "2021.10.23 18:30",
    description: "Appointment pre-paid",
    creditCard: (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <ImgMasterCard />
        　"*1234"
      </Box>
    ),
    amount: 25,
  },
  {
    id: 3,
    date: "2021.10.23 18:30",
    description: "Appointment pre-paid",
    creditCard: (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <ImgCrediCardVisa />
        　"*1234"
      </Box>
    ),
    amount: -25,
  },

  {
    id: 4,
    date: "2021.10.23 18:30",
    description: "Appointment pre-paid",
    creditCard: (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <ImgCrediCardVisa />
        　"*1234"
      </Box>
    ),
    amount: 25,
  },
];

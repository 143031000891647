import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { SynckitColors } from "../../../assets/SynckitColors";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Countdown from "react-countdown";
import * as EmailValidator from "react-email-validator";

function ChangeEmail({ setAccountMode }) {
  const [isVertifying, setIsVertifying] = useState(false);
  const [emailVal, setEmailVal] = useState("");
  const [emailError, setEmailError] = useState(false);

  const handleVertify = () => {
    if (EmailValidator.validate(emailVal)) setIsVertifying(true);
    else {
      setEmailError(true);
      return;
    }
  };

  const renderCountdown = ({ minutes, seconds, completed }) => {
    if (completed) {
      setIsVertifying(false);
      return null;
    } else {
      return (
        <span>
          {minutes}:{seconds}
        </span>
      );
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        maxWidth: 330,
      }}
    >
      <Button
        sx={{ color: SynckitColors.Gray["core"] }}
        startIcon={<ArrowBackIosIcon />}
        onClick={() => setAccountMode("default")}
      >
        <Typography variant="subtitle1"> {"Change Email address"}</Typography>
      </Button>
      <Box sx={{ mt: 3, mb: 3, display: "flex", alignItems: "center" }}>
        <TextField
          label="New Email"
          size="small"
          value={emailVal}
          onChange={(e) => setEmailVal(e.target.value)}
          onClick={() => setEmailError(false)}
          error={emailError}
          helperText={emailError ? "Please check the email format!" : ""}
        />
        {"　"}
        <Button
          variant={"third"}
          onClick={handleVertify}
          disabled={isVertifying}
        >
          {isVertifying ? (
            <Countdown date={Date.now() + 300000} renderer={renderCountdown} />
          ) : (
            "Vertify"
          )}
        </Button>
      </Box>
      {isVertifying && (
        <>
          <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>
            Email has been sent!
          </Typography>
          <Typography variant="caption">
            Please check your inbox and click in the received link to continue
            sign up.Did not receive the email ? Check your spam filter, or
            search for Synckit.Or try again after 5 minutes.
          </Typography>
        </>
      )}
    </Box>
  );
}

export default ChangeEmail;

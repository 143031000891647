import { SynckitColors } from "../../../../assets/SynckitColors";

export const ChildTeethSVG = ({ currentPartId = "", handleClick }) => {
  return (
    <svg viewBox="3.5 -2.6 187 133.6" stroke={""} onClick={handleClick}>
      <path
        d="M67.5,29.9c0,0,1.5-2.1,1.8-3.1c0,0,1.5-4.8,6.7-2.2c0,0,3.8,1.3,2.1,5.8c0,0-1.3,2.4-1.8,3.9
		c0,0-1.2,3.9-5.7,2.5C70.5,36.8,64.8,34.3,67.5,29.9z"
        style={{
          fill:
            currentPartId === "teeth_child_54"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_child_54"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.2,
        }}
        id="teeth_child_54"
      />

      <path
        d="M96.6,9.9c0.6-3.4-5.7-2.1-5.7-2.1c-5.4,0.4-2.2,5-2.2,5c1.5,2.4,4.1,3.8,4.1,3.8s0.8,0.1,1.7-1.7
		C94.5,14.8,96.4,11.2,96.6,9.9z"
        style={{
          fill:
            currentPartId === "teeth_child_51"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_child_51"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.2,
        }}
        id="teeth_child_51"
      />
      <path
        d="M99.5,7.4c0,0-3,0.3-2.4,2c0,0,1,2.3,1.2,3.2c0,0,1.1,3.9,2.3,3.5c0,0,2.1,0.1,4-2.6c0,0,1.3-1.2,2-1.9
		c0,0,1-1.6-0.5-2.8C106,8.9,103.2,7.1,99.5,7.4z"
        style={{
          fill:
            currentPartId === "teeth_child_61"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_child_61"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.2,
        }}
        id="teeth_child_61"
      />
      <path
        d="M108.7,10.4c0,0-2.1,0-2,3.9c0,0-0.4,5.4,2.6,4.8c0,0,1.9-0.1,3.2-1.7c0,0,3.9-1.9,2.1-3.7
		C114.6,13.7,110.3,10.2,108.7,10.4z"
        style={{
          fill:
            currentPartId === "teeth_child_62"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_child_62"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.2,
        }}
        id="teeth_child_62"
      />
      <path
        d="M116.4,17.2c0,0-3.8-1.8-4.5,1.9c0,0-0.9,5.5,2.5,6c0,0,3.8,0.6,4.5-1.3c0,0,0.9-1.8,1.3-3c0,0,1.3-3-1.4-3.3
		C118.8,17.5,116.8,17.4,116.4,17.2z"
        style={{
          fill:
            currentPartId === "teeth_child_63"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_child_63"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.2,
        }}
        id="teeth_child_63"
      />
      <path
        d="M116.3,27.7c0,0,0-3,3.6-3.6c0,0,3.1-0.5,4,1.4c0,0,0.9,1.7,1.4,3.4c0,0,0.5,1,0.6,1.2c0,0,3.3,4.6-0.3,6.4
		c0,0-5.4,3.2-7-1.2c0,0-0.6-3.1-1.5-4.1C117,31,116.2,29.6,116.3,27.7z"
        style={{
          fill:
            currentPartId === "teeth_child_64"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_child_64"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.2,
        }}
        id="teeth_child_64"
      />
      <path
        d="M121.8,37.6c0,0-3.3,1.3-1.3,5.2c0,0,0.3,1.7,0,2.6c0,0-1.7,5.7,5.8,7c0,0,5.4,0.3,5-5.6c0,0,0.6-9-5.1-10.4
		L121.8,37.6z"
        style={{
          fill:
            currentPartId === "teeth_child_65"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_child_65"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.2,
        }}
        id="teeth_child_65"
      />
      <path
        d="M80.2,16c0,0-2.3-0.6-0.3-2.8c0,0,7-5.6,7.8-2.4c0,0,1.5,8.8-2.1,8.2L80.2,16z"
        style={{
          fill:
            currentPartId === "teeth_child_52"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_child_52"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.2,
        }}
        id="teeth_child_52"
      />
      <path
        d="M74.7,21c0,0-0.5-4.2,1.4-4.2c0,0,4.4-0.2,5.3,0.2c0,0,2.3,2.2,2.2,4.2c0,0-0.5,5-5.7,4.3
		C77.8,25.6,75.2,24.8,74.7,21z"
        style={{
          fill:
            currentPartId === "teeth_child_53"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_child_53"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.2,
        }}
        id="teeth_child_53"
      />

      <path
        d="M64.6,40.9c0,0,0.2-4.1,2.6-4.5c0,0,0.9-0.8,6.5,1.4c0,0,2.2,1.5,0.9,4.8c0,0-0.3,1,0,2.8c0,0,0.3,6-4.5,6.7
		c0,0-4.7,0.6-6.1-2.8c0,0-0.9-3.7,0.2-6.1C64.1,43.2,64.5,41.7,64.6,40.9z"
        style={{
          fill:
            currentPartId === "teeth_child_55"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_child_55"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.2,
        }}
        id="teeth_child_55"
      />
      <path
        d="M90.2,117.8c0,0,1.6-2,2-2.8c0,0,1.4-2.5,3.2,0.1c0,0,3.3,7.3,0,7.1C95.4,122.3,87.3,122.8,90.2,117.8z"
        style={{
          fill:
            currentPartId === "teeth_child_81"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_child_81"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.2,
        }}
        id="teeth_child_81"
      />
      <path
        d="M97.5,120.9l1.7-4.8c0,0,1-3.1,2.7-1.4l2.8,3.7c0,0,1.5,2.7-1.3,3.6C103.4,122.1,96.8,124.4,97.5,120.9z"
        style={{
          fill:
            currentPartId === "teeth_child_71"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_child_71"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.2,
        }}
        id="teeth_child_71"
      />
      <path
        d="M86.1,119.8c3.1,1.6,3.5-1.7,3.5-1.7c-0.1-4.9-2-6.1-2-6.1c-1.1-0.9-5,3-5,3S80,117.6,86.1,119.8z"
        style={{
          fill:
            currentPartId === "teeth_child_82"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_child_82"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.2,
        }}
        id="teeth_child_82"
      />
      <path
        d="M77.4,109.2c0,0,1.8-4.1,5.1-2.5c0,0,3.1,1.4,2.5,5.5c0,0-1.3,2-4.3,2.6c0,0-4.7,1.1-4.1-2.9
		C76.6,111.9,76.8,111.1,77.4,109.2z"
        style={{
          fill:
            currentPartId === "teeth_child_83"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_child_83"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.2,
        }}
        id="teeth_child_83"
      />
      <path
        d="M64.9,79.9c0-0.1,0.1-6,5.8-5.2c0,0,4.8,1.5,4.6,6.6c0,0-0.3,4.5,0.5,5.7c0,0,2.1,5.5-5.1,5.4c0,0-4.8-1-5.5-7
		C65.2,85.3,64.7,83.8,64.9,79.9z"
        style={{
          fill:
            currentPartId === "teeth_child_85"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_child_85"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.2,
        }}
        id="teeth_child_85"
      />
      <path
        d="M69.9,98.4c0,0-1.2-6.2,3.7-7.1c0,0,4.3-0.9,5.5,5.2c0,0,0.5,3.2,1.6,4.6c0,0,1.9,5.4-3.2,6.5
		c0,0-4.8,0.6-6.8-5.9L69.9,98.4z"
        style={{
          fill:
            currentPartId === "teeth_child_84"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_child_84"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.2,
        }}
        id="teeth_child_84"
      />
      <path
        d="M105.4,118.3c0,0-0.2-6.1,2.3-5.6c0,0,3.1,0.7,4.9,3.1c0,0,1,1.7-1.2,3.1c0,0-3.2,3-5.3,2.4
		C106,121.2,105.1,121.4,105.4,118.3z"
        style={{
          fill:
            currentPartId === "teeth_child_72"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_child_72"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.2,
        }}
        id="teeth_child_72"
      />
      <path
        d="M114.7,105.1c0,0-3.1,1.2-3.7,4.7c0,0-1.5,4.7,1.7,5c0,0,0.8,0.3,1.7,0c0,0,0.7,0,1,0.1c0,0,2.4,0.3,3-1.3
		c0,0,1.1-4.3-1.6-7.2C116.8,106.5,115.5,105.2,114.7,105.1z"
        style={{
          fill:
            currentPartId === "teeth_child_73"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_child_73"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.2,
        }}
        id="teeth_child_73"
      />
      <path
        d="M120.6,91c0,0-3.4,0.4-4.2,3.3c0,0-0.3,0.9-0.4,2.2c0,0-0.1,2.3-1.2,3.8c0,0-1.7,4.6,1.7,6l0.1,0.1
		c0,0,3.7,1.3,5.6-1.3c0,0,6.5-6.3,2.6-12.2C124.9,92.9,123.7,91,120.6,91z"
        style={{
          fill:
            currentPartId === "teeth_child_74"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_child_74"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.2,
        }}
        id="teeth_child_74"
      />
      <path
        d="M121.1,76.6c0,0-1.5,1.5-1.2,4.3c0,0,0.1,3.2,0,4.3c0,0-1.2,4.9,2.2,6c0,0,3.4,2.8,5.5-1.1c0,0,0.5-0.7,1.1-3
		c0,0,1.2-3.9,1.8-4.4c0,0,1.6-7.3-4.1-8.1C126.4,74.6,123.5,74,121.1,76.6z"
        style={{
          fill:
            currentPartId === "teeth_child_75"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_child_75"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.2,
        }}
        id="teeth_child_75"
      />
    </svg>
  );
};

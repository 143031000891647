import { styled } from "@mui/system";
import React from "react";
import { SynckitColors } from "../assets/SynckitColors";
import { IconSearch } from "../assets/SynckitIcons";

const SearchBar = styled("div")(({ theme }) => ({
  height: 48,
  width: "100%",

  maxWidth: 48,
  borderRadius: 12,
  display: "flex",
  alignItems: "center",
  transition: "0.3s",
  "& .MuiSvgIcon-root": {
    fill: SynckitColors.Primary[600],
    width: 50,
    fontSize: 30,
  },
  input: {
    width: "100%",
    height: 38,
    border: "none",
    borderRadius: 12,
    fontSize: 18,
    "&:focus": {
      outline: "none",
    },
  },
  "&:hover": {
    backgroundColor: "white",
    maxWidth: 530,
    boxShadow: SynckitColors.Shadow[2],
  },
}));

export default function SynckitSearchbarSimple() {
  return (
    <SearchBar>
      <IconSearch />
      <input type="text" placeholder="Search" />
    </SearchBar>
  );
}

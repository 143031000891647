import {
  IconButton,
  InputAdornment,
  Menu,
  TextField,
  Typography,
  Button,
  Box,
} from "@mui/material";
import React from "react";
import TuneIcon from "@mui/icons-material/Tune";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/system";
import { SynckitColors } from "../assets/SynckitColors";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { SynckitButton } from "./SynckitButton";
import {
  ImgOrganArteryActive2,
  ImgOrganArteryNormal,
  ImgOrganColonActive2,
  ImgOrganColonNormal,
  ImgOrganEarActive2,
  ImgOrganEarNormal,
  ImgOrganHeartActive2,
  ImgOrganHeartNormal,
  ImgOrganLiverActive2,
  ImgOrganLiverNormal,
  ImgOrganNoseActive2,
  ImgOrganNoseNormal,
  ImgOrganSkinActive2,
  ImgOrganSkinNormal,
  ImgOrganTemperatureActive2,
  ImgOrganTemperatureNormal,
  ImgOrganTheethActive2,
  ImgOrganTheethNormal,
  ImgOrganThroatActive2,
  ImgOrganThroatNormal,
} from "../assets/SynckitImg";
import {
  ORGAN_ABDOMINAL,
  ORGAN_ARTERY,
  ORGAN_EAR,
  ORGAN_HEART,
  ORGAN_LUNG,
  ORGAN_NOSE,
  ORGAN_SKIN,
  ORGAN_TEMPERATURE,
  ORGAN_THEETH,
  ORGAN_THROAT,
} from "../assets/constant";
import { useState } from "react";

const SearchBar = styled(TextField)(({ theme }) => ({
  paddingBottom: 16,
  "& .MuiOutlinedInput-root": {
    paddingTop: 2,
    paddingBottom: 2,
    borderRadius: 8,
    background: "white",
    width: "100%",

    "& fieldset": {
      border: `0px solid ${SynckitColors.Primary[300]}`,
    },
    "&:hover fieldset": {
      border: `1px solid ${SynckitColors.Primary[300]}`,
    },
    "&.Mui-focused fieldset": {
      border: `2px solid ${SynckitColors.Primary[300]}`,
    },
  },
}));
const FilterMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    padding: "12px 24px",
    maxWidth: 380,
  },
}));

export default function SynckitSearchBar2() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [subjectList, setSubjectList] = useState([
    {
      id: ORGAN_THEETH,
      normalImg: <ImgOrganTheethNormal style={{ width: 40, height: 40 }} />,
      activeImg: <ImgOrganTheethActive2 style={{ width: 40, height: 40 }} />,
      selected: false,
    },
    {
      id: ORGAN_EAR,
      normalImg: <ImgOrganEarNormal style={{ width: 40, height: 40 }} />,
      activeImg: <ImgOrganEarActive2 style={{ width: 40, height: 40 }} />,
      selected: false,
    },
    {
      id: ORGAN_TEMPERATURE,
      normalImg: (
        <ImgOrganTemperatureNormal style={{ width: 40, height: 40 }} />
      ),
      activeImg: (
        <ImgOrganTemperatureActive2 style={{ width: 40, height: 40 }} />
      ),
      selected: false,
    },
    {
      id: ORGAN_NOSE,
      normalImg: <ImgOrganNoseNormal style={{ width: 40, height: 40 }} />,
      activeImg: <ImgOrganNoseActive2 style={{ width: 40, height: 40 }} />,
      selected: false,
    },
    {
      id: ORGAN_THROAT,
      normalImg: <ImgOrganThroatNormal style={{ width: 40, height: 40 }} />,
      activeImg: <ImgOrganThroatActive2 style={{ width: 40, height: 40 }} />,
      selected: false,
    },
    {
      id: ORGAN_SKIN,
      normalImg: <ImgOrganSkinNormal style={{ width: 40, height: 40 }} />,
      activeImg: <ImgOrganSkinActive2 style={{ width: 40, height: 40 }} />,
      selected: false,
    },
    {
      id: ORGAN_LUNG,
      normalImg: <ImgOrganLiverNormal style={{ width: 40, height: 40 }} />,
      activeImg: <ImgOrganLiverActive2 style={{ width: 40, height: 40 }} />,
      selected: false,
    },
    {
      id: ORGAN_HEART,
      normalImg: <ImgOrganHeartNormal style={{ width: 40, height: 40 }} />,
      activeImg: <ImgOrganHeartActive2 style={{ width: 40, height: 40 }} />,
      selected: false,
    },
    {
      id: ORGAN_ABDOMINAL,
      normalImg: <ImgOrganColonNormal style={{ width: 40, height: 40 }} />,
      activeImg: <ImgOrganColonActive2 style={{ width: 40, height: 40 }} />,
      selected: false,
    },
    {
      id: ORGAN_ARTERY,
      normalImg: <ImgOrganArteryNormal style={{ width: 40, height: 40 }} />,
      activeImg: <ImgOrganArteryActive2 style={{ width: 40, height: 40 }} />,
      selected: false,
    },
  ]);

  const openFilterMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeFilterMenu = () => {
    setAnchorEl(null);
  };

  const handleSubjectSelect = (selectedId) => {
    let tempList = subjectList.map((item) =>
      item.id === selectedId ? { ...item, selected: !item.selected } : item
    );
    setSubjectList([...tempList]);
  };
  return (
    <>
      <SearchBar
        fullWidth
        variant="outlined"
        placeholder="Search"
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: SynckitColors.Gray[600] }} />
            </InputAdornment>
          ),
          endadornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClick}>
                <TuneIcon sx={{ color: SynckitColors.Gray[600] }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{ border: 0 }}
      />
      <FilterMenu
        anchorEl={anchorEl}
        open={openFilterMenu}
        onClose={closeFilterMenu}
      >
        <FormControl component="fieldset">
          <FormLabel component="legend">
            <Typography
              variant="small1"
              sx={{ color: SynckitColors.Gray[800] }}
            >
              Date
            </Typography>
          </FormLabel>
          <RadioGroup row>
            <FormControlLabel value="today" control={<Radio />} label="Today" />
            <FormControlLabel
              value="week"
              control={<Radio />}
              label="In a week"
            />
            <FormControlLabel
              value="month"
              control={<Radio />}
              label="In a Month"
            />
          </RadioGroup>
        </FormControl>
        <br />
        <br />

        <Typography variant="small1" sx={{ color: SynckitColors.Gray[800] }}>
          Subject filter
        </Typography>
        <Box>
          {subjectList.map((item, index) => (
            <Button
              onClick={() => handleSubjectSelect(item.id)}
              sx={{ padding: 1, borderRadius: 1 }}
              key={index}
            >
              {item.selected ? item.activeImg : item.normalImg}
            </Button>
          ))}
        </Box>
        <br />
        <FormControl component="fieldset">
          <FormLabel component="legend">
            <Typography
              variant="small1"
              sx={{ color: SynckitColors.Gray[800] }}
            >
              Type
            </Typography>
          </FormLabel>
          <RadioGroup row>
            <FormControlLabel
              value="consultation"
              control={<Radio />}
              label="Consultation"
            />
            <FormControlLabel
              value="examination"
              control={<Radio />}
              label="Examination"
            />
          </RadioGroup>
        </FormControl>
        <br />
        <br />
        <SynckitButton
          variant="contained"
          sx={{ width: "100%" }}
          onClick={closeFilterMenu}
        >
          Filter
        </SynckitButton>
      </FilterMenu>
    </>
  );
}

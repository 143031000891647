import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { SynckitColors } from "../../../assets/SynckitColors";
import avatar_doctor1 from "../../../assets/images/demo/avatar_doctor1.jpg";
import avatar_doctor2 from "../../../assets/images/demo/avatar_doctor2.jpg";
import avatar_doctor3 from "../../../assets/images/demo/avatar_doctor3.jpg";
import avatar_doctor4 from "../../../assets/images/demo/avatar_doctor4.jpg";
import avatar_doctor5 from "../../../assets/images/demo/avatar_doctor5.jpg";

import { Avatar, Button, TableFooter, TablePagination } from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import FemaleIcon from "@mui/icons-material/Female";
import MaleOutlinedIcon from "@mui/icons-material/MaleOutlined";
import { useNavigate } from "react-router-dom";
import {
  ORGAN_ABDOMINAL,
  ORGAN_ARTERY,
  ORGAN_HEART,
  ORGAN_NOSE,
} from "../../../assets/constant";
import { ImgOrganActive2List } from "../../../assets/SynckitImg";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: SynckitColors.Gray[200],
    color: SynckitColors.Gray[700],
    fontSize: 14,
    fontWeight: 700,
    height: 36,
    paddingTop: 8,
    paddingBottom: 8,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 700,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({}));
const StateLabel = styled("div")(({ theme }) => ({
  background: SynckitColors.Gray[500],
  borderRadius: 100,
  width: 85,
  height: 36,
  color: "white",
  fontSize: 14,
  fontWeight: 700,
  padding: "8px 20px",
}));

function createData(avatar, name, gender, item, state, date) {
  return { avatar, name, gender, item, state, date };
}

const rows = [
  createData(
    avatar_doctor1,
    "Kristin Watson",
    <FemaleIcon color="error" />,
    ORGAN_HEART,
    true,
    "2021/11/15"
  ),
  createData(
    avatar_doctor5,
    "Kristin Watson",
    <MaleOutlinedIcon sx={{ color: SynckitColors.Primary[400] }} />,
    ORGAN_NOSE,
    false,
    "2021/11/15"
  ),
  createData(
    avatar_doctor2,
    "Dwayne Fields",
    <FemaleIcon color="error" />,
    ORGAN_ABDOMINAL,
    true,
    "2021/11/15"
  ),
  createData(
    avatar_doctor3,
    "Erwin Utley",
    <FemaleIcon color="error" />,
    ORGAN_HEART,
    false,
    "2021/11/15"
  ),
  createData(
    avatar_doctor4,
    "Dallas Estrada",
    <FemaleIcon color="error" />,
    ORGAN_HEART,
    true,
    "2021/11/15"
  ),
];

const TableRoot = styled(TableContainer)({
  width: "100%",
  padding: 0,
  borderRadius: 12,
  backgroundColor: "white",
  marginBottom: 24,
  filter:
    "drop-shadow(0px 27px 80px rgba(16, 57, 123, 0.05)) drop-shadow(0px 13.6687px 34.875px rgba(16, 57, 123, 0.03375)) drop-shadow(0px 5.4px 13px rgba(16, 57, 123, 0.025)) drop-shadow(0px 1.18125px 4.625px rgba(16, 57, 123, 0.01625))",
});

export default function InvitingListTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableRoot>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ width: 290 }}>Doctor</StyledTableCell>
            <StyledTableCell>Gender</StyledTableCell>
            <StyledTableCell>Item</StyledTableCell>
            <StyledTableCell>State</StyledTableCell>
            <StyledTableCell>Date</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name} sx={{ height: 88 }} hover>
              <StyledTableCell>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Avatar sx={{ marginRight: 2 }} src={row.avatar} />
                  Dr.&nbsp;{row.name}
                </div>
              </StyledTableCell>
              <StyledTableCell>{row.gender}</StyledTableCell>
              <StyledTableCell>{ImgOrganActive2List[row.item]}</StyledTableCell>
              <StyledTableCell>
                {row.state ? <StateLabel>Invited</StateLabel> : null}
              </StyledTableCell>
              <StyledTableCell>{row.date}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={6}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableRoot>
  );
}

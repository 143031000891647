import {
  CalendarToday,
  SegmentedGroup,
  SegmentedItem,
  Button as MBButton,
  CalendarPrev,
  CalendarNext,
} from "@mobiscroll/react";
import { useCallback, useState } from "react";
import { styled } from "@mui/system";
import { SynckitColors } from "../../../assets/SynckitColors";
import { IconEditLine, IconPlusLine } from "../../../assets/SynckitIcons";
import {
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { DemoWorkingTimeList } from "./DemoEventList";
import { SynckitEventcalendar } from "../../../components/calendar/SynckitEventcalendar";
import { SynckitCalendarNav } from "../../../components/calendar/SynckitCalendarNav";
import AddNewShiftsDialog from "./add-new-shifts/AddNewShiftsDialog";
import { format } from "date-fns/esm";

const WorkingTimeLabel = styled("div")({
  background: SynckitColors.Primary[50],
  padding: "0px 8px",
  borderRadius: 6,
  fontSize: 13,
});
const WorkingTimeWrap = styled("div")({
  background: SynckitColors.Primary[50],
  padding: "0px 8px",
  borderRadius: 6,
  fontSize: 13,
  fontWeight: 700,
  color: "black",
  boxShadow: `0px 4px 8px rgba(118, 161, 210, 0.16)`,
  textAlign: "center",
});

const HeaderWrap = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "2%",
  [theme.breakpoints.down(1200)]: {
    flexDirection: "column",
  },
}));
const HeaderItem = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  transition: "0.3s",

  "&.selectday": {
    [theme.breakpoints.down(1200)]: {
      justifyContent: "space-between",
    },
  },
  "& .mbsc-ios.mbsc-segmented": {
    background: "white",
    borderRadius: 100,
    padding: 0,
    minWidth: 126,
  },
}));

const CustomizedSegmentedGroup = styled(SegmentedGroup)(({ theme }) => ({}));
const CustomizedSegmentedItem = styled(SegmentedItem)(({ theme }) => ({
  background: "none",
  width: 62,
  fontSize: 14,
  fontWeight: 700,
  letterSpacing: 0.2,
  color: SynckitColors.Gray[700],
  padding: "3px 8px",
  "& .mbsc-selected": {
    background: SynckitColors.Primary[600],
    borderRadius: 100,
    "&.mbsc-segmented-button": {
      transition: "0.3s",
      color: "white",
    },
  },
}));

const CalendarNavWrap = styled("div")(({ theme }) => ({
  display: "flex",
  "&.isDisabled ": {
    pointerEvents: "none",
  },
}));

const InfoCard = styled(Menu)(({ theme }) => ({
  "& .MuiList-root": {
    padding: 0,
    color: SynckitColors.Gray["core"],
    fontSize: 14,
  },
  "& .MuiMenu-paper": {
    boxShadow: SynckitColors.Shadow[2],
    borderRadius: 12,
  },

  "& .MuiMenuItem-root": {
    padding: "12px 20px",
  },
}));
const SynckitScheduleEvent = styled("div")(({ theme }) => ({
  background: SynckitColors.Primary[50],
  height: "100%",
  borderRadius: 8,
  boxShadow: SynckitColors.Shadow[2],
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  padding: 16,
  "& span": {
    color: SynckitColors.Gray["core"],
    fontSize: 12,
    fontWeight: "bold",
  },
}));

export default function ShiftsIndex() {
  const [openAddNewShiftsDialog, setOpenAddNewShiftsDialog] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [eventList, setEventList] = useState(DemoWorkingTimeList);
  const [currentEvent, setCurrentEvent] = useState({
    start: "2021-12-12T08:00:00.000Z",
    schedule: [],
  });
  const largeScreen = useMediaQuery("(min-width:600px)");
  const [unlockEdit, setUnlockEdit] = useState(false);
  const [view, setView] = useState("week");
  const [calView, setCalView] = useState({
    schedule: {
      type: "week",
      allDay: false,
    },
  });
  const [anchorEl, setAnchorEl] = useState(null);
  let open = Boolean(anchorEl);

  const [delMenuAnchorEl, setDelMenuAnchorEl] = useState(null);
  let openDelMenu = Boolean(delMenuAnchorEl);
  const hasOverlap = useCallback((args, inst) => {
    const ev = args.event;
    const events = inst
      .getEvents(ev.start, ev.end)
      .filter((e) => e.id !== ev.id);

    return events.length > 0;
  });
  const handleClick = (event) => {
    console.log(event.currentTarget);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteMenuClose = () => {
    setDelMenuAnchorEl(null);
  };

  const renderLabel = useCallback((data) => {
    return <WorkingTimeLabel>{data.start + " - " + data.end}</WorkingTimeLabel>;
  });
  const renderScheduleEvent = useCallback((data) => {
    return (
      <WorkingTimeWrap>
        <div>{data.start}</div> <div>{data.end}</div>
      </WorkingTimeWrap>
    );
  });
  const onEventUpdate = useCallback((args, inst) => {
    if (hasOverlap(args, inst)) {
      return false;
    }
  });

  const changeView = useCallback(
    (event) => {
      let calendarView;
      switch (event.target.value) {
        case "month":
          calendarView = {
            calendar: {
              labels: true,
            },
          };
          break;
        case "week":
          calendarView = {
            schedule: { type: "week", allDay: false },
          };
          break;
      }
      setView(event.target.value);
      setCalView(calendarView);
    },
    [setView, setCalView]
  );

  const CustomHeader = () => {
    return (
      <HeaderWrap>
        <HeaderItem className="selectday">
          <CalendarNavWrap className={unlockEdit && "isDisabled"}>
            <SynckitCalendarNav />

            <CalendarPrev />
            <CalendarToday />
            <CalendarNext />
          </CalendarNavWrap>
        </HeaderItem>
        <HeaderItem sx={{ justifyContent: "flex-end" }}>
          {largeScreen ? (
            <>
              <Button
                disabled={view === "month"}
                variant={unlockEdit ? "primary" : "third"}
                startIcon={<IconEditLine />}
                onClick={() => setUnlockEdit(!unlockEdit)}
              >
                {unlockEdit ? "Lock" : "Unlock edit"}
              </Button>
              &nbsp;&nbsp;
              <Button
                variant="third"
                startIcon={<IconPlusLine />}
                onClick={() => setOpenAddNewShiftsDialog(true)}
                disabled={unlockEdit}
              >
                Add new Shifts
              </Button>
            </>
          ) : (
            <>
              <IconButton
                variant={unlockEdit ? "primary" : "third"}
                onClick={() => setUnlockEdit(!unlockEdit)}
              >
                <IconEditLine />
              </IconButton>
              &nbsp;&nbsp;
              <IconButton
                variant="third"
                onClick={() => setOpenAddNewShiftsDialog(true)}
              >
                <IconPlusLine />
              </IconButton>
            </>
          )}
          <CustomizedSegmentedGroup
            value={view}
            onChange={changeView}
            disabled={unlockEdit}
          >
            <CustomizedSegmentedItem value="month">
              Month
            </CustomizedSegmentedItem>
            <CustomizedSegmentedItem value="week">Week</CustomizedSegmentedItem>
          </CustomizedSegmentedGroup>
        </HeaderItem>
      </HeaderWrap>
    );
  };

  const showInfoCard = (e) => {
    setTimeout(() => {
      setCurrentEvent(e.event);
      setAnchorEl(e.domEvent.target);
    }, 100);
  };

  const showDeleteMenu = (args) => {
    setTimeout(() => {
      setCurrentEvent({ ...args.event });
      setDelMenuAnchorEl(args.domEvent.target);
    }, 100);
  };
  const handleDeleteEvent = () => {
    setEventList(eventList.filter((item) => item.id !== currentEvent.id));
    setDelMenuAnchorEl(null);
  };

  return (
    <>
      <SynckitEventcalendar
        theme="ios"
        themeVariant="light"
        clickToCreate={unlockEdit ? "single" : false}
        dragToCreate={false}
        dragToMove={unlockEdit}
        dragToResize={unlockEdit}
        data={eventList}
        view={calView}
        renderHeader={CustomHeader}
        renderLabel={renderLabel}
        onEventUpdate={onEventUpdate}
        onEventClick={(e) => (unlockEdit ? showDeleteMenu(e) : showInfoCard(e))}
        renderScheduleEvent={(data) => {
          return (
            <SynckitScheduleEvent>
              <span>{data.start}</span>
              <span>{data.end}</span>
            </SynckitScheduleEvent>
          );
        }}
      />

      <InfoCard
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          disableGutters
          disableRipple
          disableTouchRipple
          sx={{ cursor: "initial" }}
        >
          <Typography variant="subtitle1">Day Shifts</Typography>
        </MenuItem>
        <Divider />
        <MenuItem
          disableGutters
          disableRipple
          disableTouchRipple
          sx={{ cursor: "initial" }}
        >
          <Typography variant="subtitle1">
            {format(new Date(currentEvent.start), "yyyy.MM.dd iii")}
          </Typography>
        </MenuItem>
        <MenuItem
          disableGutters
          disableRipple
          disableTouchRipple
          sx={{ cursor: "initial" }}
        >
          <Typography variant="body1" color="primary">
            {currentEvent?.schedule.toString()}
          </Typography>
        </MenuItem>
      </InfoCard>
      <InfoCard
        anchorEl={delMenuAnchorEl}
        open={openDelMenu}
        onClose={handleDeleteMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleDeleteEvent}>
          <Typography variant="body2" onClick={() => setAnchorEl(null)}>
            Delete
          </Typography>
        </MenuItem>
      </InfoCard>
      <AddNewShiftsDialog
        open={openAddNewShiftsDialog}
        setOpen={setOpenAddNewShiftsDialog}
      />
    </>
  );
}

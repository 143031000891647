import { Button } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import CheckIcon from "@mui/icons-material/Check";
import DoctorBasicInfo from "./DoctorBasicInfo";
import DeleteDialog from "../../patient/patient/profile/DeleteDialog";
import DoctorJobInfo from "./DoctorJobInfo";
import DoctorSpecialty from "./DoctorSpecialty";
import DoctorExperience from "./DoctorExperience";

export default function EditDoctorIndex() {
  const navigate = useNavigate();

  return (
    <>
      <Box sx={{ textAlign: "right", pt: 2, pb: 2 }}>
        <Button
          startIcon={<CheckIcon />}
          variant="secondary"
          sx={{ width: 136, mr: 1 }}
          onClick={() => navigate(-1)}
        >
          Save
        </Button>

        <DeleteDialog />
      </Box>
      <DoctorBasicInfo />
      <DoctorJobInfo />
      <DoctorSpecialty />
      <DoctorExperience />
    </>
  );
}

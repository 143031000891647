import React from "react";
import Grid from "@mui/material/Grid";
import ContactsIndex from "./contacts/ContactsIndex";
import QRcodeIndex from "./qr-code/QRcodeIndex";

export default function ShareIndex() {
  return (
    <Grid
      container
      spacing={4}
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item sm={12} md={8}>
        <ContactsIndex />
      </Grid>
      <Grid item sm={12} md={4}>
        <QRcodeIndex />
      </Grid>
    </Grid>
  );
}

import { Button, InputAdornment, styled, TextField } from "@mui/material";
import React from "react";
import { SynckitColors } from "../../../assets/SynckitColors";
import PublicDoctorTable from "./PublicDoctorTable";
import SearchIcon from "@mui/icons-material/Search";
import MenuBar from "./MenuBar";
import { useNavigate } from "react-router-dom";

const Toolbar = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 20,
}));
const SearchBar = styled(TextField)(({ theme }) => ({
  width: "100%",
  maxWidth: 777,

  "& .MuiOutlinedInput-root": {
    paddingTop: 2,
    paddingBottom: 2,
    borderRadius: 8,
    background: "white",
    "& fieldset": {
      border: `0px solid ${SynckitColors.Primary[300]}`,
    },
    "&:hover fieldset": {
      border: `1px solid ${SynckitColors.Primary[300]}`,
    },
    "&.Mui-focused fieldset": {
      border: `2px solid ${SynckitColors.Primary[300]}`,
    },
  },
}));
export default function PublicDoctorIndex() {
  const navigate = useNavigate();

  return (
    <div>
      <Toolbar>
        <SearchBar
          variant="outlined"
          placeholder="Search"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: SynckitColors.Gray[600] }} />
              </InputAdornment>
            ),
          }}
        />
        {"　"}
        <Button
          variant="primary"
          sx={{ minWidth: 115 }}
          onClick={() => navigate("/pd/il")}
        >
          Inviting List
        </Button>
      </Toolbar>
      <MenuBar />
      <PublicDoctorTable />
    </div>
  );
}

import React, { useState } from "react";
import CategoryIndex from "./category/CategoryIndex";

import Toolbar from "./Toolbar";

export default function MessagesIndex() {
  const [isEditMode, setIsEditMode] = useState(false);
  return (
    <>
      <Toolbar isEditMode={isEditMode} setIsEditMode={setIsEditMode} />
      <CategoryIndex isEditMode={isEditMode} />
    </>
  );
}

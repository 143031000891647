import React from "react";
import Grid from "@mui/material/Grid";
import { MenuItem, TextField } from "@mui/material";
import { SynckitPanel } from "../../../components/SynckitPanel";

export default function DoctorJobInfo() {
  return (
    <SynckitPanel>
      <Grid
        container
        spacing={3}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
      >
        <Grid item xs={12} sm={6} lg={3}>
          <TextField
            select
            label="Organization"
            fullWidth
            required
            size="small"
          >
            <MenuItem value={0}>Organization1</MenuItem>
            <MenuItem value={1}>Organization2</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <TextField select label="Division" fullWidth required size="small">
            <MenuItem value={0}>Division1</MenuItem>
            <MenuItem value={1}>Division2</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
          <TextField select label="Position" fullWidth required size="small">
            <MenuItem value={0}>Position1</MenuItem>
            <MenuItem value={1}>Position2</MenuItem>
          </TextField>{" "}
        </Grid>
      </Grid>
    </SynckitPanel>
  );
}

import { styled, Typography } from "@mui/material";
import React from "react";
import { SynckitColors } from "../../assets/SynckitColors";

const Root = styled("div")(({ theme }) => ({
  maxWidth: 460,
  minHeight: 353,
  background: "white",
  borderRadius: 8,
}));
const Header = styled("div")(({ theme }) => ({
  height: 56,
  padding: "16px 24px",
  boxShadow: SynckitColors.Shadow["ButtomDivider"],
}));
const Body = styled("div")(({ theme }) => ({
  padding: "16px 24px",
}));
export default function ChiefComplaint() {
  return (
    <Root>
      <Header>
        <Typography variant="subtitle1">Chief Complaint</Typography>
      </Header>
      <Body>
        <Typography variant="body1">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus,
          odio mollitia, quibusdam explicabo autem voluptates voluptas neque
          saepe earum dolores quos iste ducimus, quis esse inventore eligendi
          deleniti. Quo, ullam.
        </Typography>
      </Body>
    </Root>
  );
}

import { Drawer, IconButton, styled } from "@mui/material";
import React, { useState } from "react";
import SidemenuNavBG from "../../assets/images/sidemenu_nav_bg.png";
import MenuIcon from "@mui/icons-material/Menu";

import { SIDMENU_WIDTH_MD } from "../../assets/constant";
import SideMenu from "./SideMenu";
import { SynckitColors } from "../../assets/SynckitColors";
const DrawerRoot = styled(Drawer)(({ theme }) => ({
  "& .MuiPaper-root": {
    position: "fixed",
    top: 0,
    left: 0,
    backgroundImage: `url(${SidemenuNavBG})`,
    width: SIDMENU_WIDTH_MD,
    boxShadow: "0 6px 15px rgb(0 0 0 / 4%), 0 6px 6px rgb(0 0 0 / 4%)",
    height: "100%",
  },
}));
const ToggleButton = styled(IconButton)(({ theme }) => ({
  position: "fixed",
  display: "initial",
  zIndex: 200,
  top: 20,
  left: 18,
  width: 40,
  height: 40,

  "& .MuiSvgIcon-root": {
    color: SynckitColors.Primary[600],
    transition: "0.3s",
    "&.toggle": {
      color: "white",
    },
  },
}));

export default function SideMenuDrawer() {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <div>
      <ToggleButton onClick={() => setOpenDrawer(true)}>
        <MenuIcon />
      </ToggleButton>
      <DrawerRoot
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <SideMenu />
      </DrawerRoot>
    </div>
  );
}

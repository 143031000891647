import {
  Checkbox,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import React from "react";
import TuneIcon from "@mui/icons-material/Tune";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/system";
import { SynckitColors } from "../assets/SynckitColors";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { SynckitButton } from "./SynckitButton";

const SearchBar = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    paddingTop: 2,
    paddingBottom: 2,
    borderRadius: 8,
    background: "white",
    width: "100%",
    maxWidth: 777,

    "& fieldset": {
      border: `0px solid ${SynckitColors.Primary[300]}`,
    },
    "&:hover fieldset": {
      border: `1px solid ${SynckitColors.Primary[300]}`,
    },
    "&.Mui-focused fieldset": {
      border: `2px solid ${SynckitColors.Primary[300]}`,
    },
  },
}));
const FilterMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    padding: 12,
  },
}));

export default function SynckitSearchBar3() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openFilterMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeFilterMenu = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <SearchBar
        fullWidth
        variant="outlined"
        placeholder="Search"
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: SynckitColors.Gray[600] }} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClick}>
                <TuneIcon sx={{ color: SynckitColors.Gray[600] }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{ border: 0 }}
      />
      <FilterMenu
        anchorEl={anchorEl}
        open={openFilterMenu}
        onClose={closeFilterMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem disableRipple>
          <FormControl component="fieldset">
            <FormLabel component="legend">Sorting</FormLabel>
            <RadioGroup row aria-label="sorting" name="row-radio-buttons-group">
              <FormControlLabel
                value="a"
                control={<Radio />}
                label="Recently Consulted"
              />
              <FormControlLabel
                value="d"
                control={<Radio />}
                label="High frequency"
              />
            </RadioGroup>
          </FormControl>
        </MenuItem>
        <MenuItem disableRipple>
          <FormControl component="fieldset">
            <FormLabel component="legend">Gender</FormLabel>
            <RadioGroup row aria-label="gender" name="row-radio-buttons-group">
              <FormControlLabel value="male" control={<Radio />} label="Male" />
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="Female"
              />
            </RadioGroup>
          </FormControl>
        </MenuItem>
        <MenuItem disableRipple sx={{ pt: 2, pb: 2 }}>
          <TextField select label="Division" fullWidth size="small">
            <MenuItem value={0}>Division of Cardiology</MenuItem>
            <MenuItem value={1}>Division of Cardiology</MenuItem>
            <MenuItem value={3}>Division of Cardiology</MenuItem>
          </TextField>
        </MenuItem>
        <MenuItem disableRipple>
          <SynckitButton
            variant="contained"
            sx={{ width: "100%" }}
            onClick={closeFilterMenu}
          >
            Filter
          </SynckitButton>
        </MenuItem>
      </FilterMenu>
    </>
  );
}

import avatar_patient1 from "../../../assets/images/demo/avatar_patient1.jpg";
import avatar_patient2 from "../../../assets/images/demo/avatar_patient2.jpg";
import avatar_patient3 from "../../../assets/images/demo/avatar_patient3.jpg";
import avatar_patient4 from "../../../assets/images/demo/avatar_patient4.jpg";
import avatar_patient5 from "../../../assets/images/demo/avatar_patient5.jpg";

import {
  APO_TYPE_LOCAL,
  APO_TYPE_REMOTE,
  ORGAN_HEART,
  ORGAN_TEMPERATURE,
  ORGAN_THEETH,
  APO_CANCELLED_STATUS_NONE,
  APO_CANCELLED_STATUS_REACHED,
  APO_CANCELLED_STATUS_REFUSED,
  APO_CANCELLED_STATUS_WAITING,
} from "../../../assets/constant";

function createData(
  id,
  avatar,
  patient,
  doctor,
  date,
  divison,
  type,
  consulted,
  refusedStatus,
  recommendDoctor,
  cancelledStatus
) {
  return {
    id,
    avatar,
    patient,
    doctor,
    date,
    divison,
    type,
    consulted,
    refusedStatus,
    recommendDoctor,
    cancelledStatus,
  };
}

export const DemoRowData = [
  createData(
    1,
    avatar_patient1,
    "Jacob Jones",
    "Dr. Kristin Watson",
    "2021.10.22 · 10:30",
    ORGAN_HEART,
    APO_TYPE_REMOTE,
    false,
    "Not available",
    "/pm",
    APO_CANCELLED_STATUS_NONE
  ),
  createData(
    2,
    avatar_patient2,
    "Jacob Jones",
    "Dr. Kristin Watson",
    "2021.10.22 · 10:30",
    ORGAN_HEART,
    APO_TYPE_REMOTE,
    true,
    "Not available",
    null,
    APO_CANCELLED_STATUS_WAITING
  ),
  createData(
    3,
    avatar_patient5,
    "Jacob Jones",
    "Dr. Kristin Watson",
    "2021.10.22 · 10:30",
    null,
    APO_TYPE_LOCAL,
    false,
    "Not available",
    "/pm",
    APO_CANCELLED_STATUS_NONE
  ),

  createData(
    4,
    avatar_patient4,
    "Jacob Jones",
    "Dr. Kristin Watson",
    "2021.10.22 · 10:30",
    ORGAN_TEMPERATURE,
    APO_TYPE_REMOTE,
    true,
    "Unable to perform service",
    "/pm",
    APO_CANCELLED_STATUS_REACHED
  ),
  createData(
    5,
    avatar_patient3,
    "Jacob Jones",
    "Dr. Kristin Watson",
    "2021.10.22 · 10:30",
    null,
    APO_TYPE_LOCAL,
    false,
    "Unable to perform service",
    null,
    APO_CANCELLED_STATUS_REFUSED
  ),
  createData(
    6,
    avatar_patient1,
    "Jacob Jones",
    "Dr. Kristin Watson",
    "2021.10.22 · 10:30",
    ORGAN_THEETH,
    APO_TYPE_REMOTE,
    false,
    "Unable to perform service",
    null,
    APO_CANCELLED_STATUS_REFUSED
  ),
];

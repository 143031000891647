import { styled } from "@mui/material";
import React, { useContext } from "react";
import ImgModelBodyBack from "../../../../assets/images/body/model_body_back.png";
import { SynckitColors } from "../../../../assets/SynckitColors";
import { ConsultContext } from "../../ConsultIndex";
import { ModelBodyBack } from "./ModelBodyBack";
const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  position: "relative",
}));

const ImgModelBodyWrap = styled("div")(({ theme }) => ({
  position: "absolute",
  height: "90%",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",

  "& #bodyBack": {
    // marginTop: 8,
    // marginLeft: 3,
    height: "100%",
  },
}));
const SVGModelBodyWrap = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  height: "90%",
  "& svg": {
    height: "100%",
  },
  "& path": {
    fill: "rgba(255, 255, 255, 0.00009)",
    transition: "0.3s",
  },
  "& path:hover": {
    fill: SynckitColors.Yellow[200],
    cursor: "pointer",
  },
}));

export default function BodyBack() {
  const { currentPartId, setCurrentPartId } = useContext(ConsultContext);
  return (
    <Root>
      <ImgModelBodyWrap>
        <img src={ImgModelBodyBack} alt="synckit" id="bodyBack" />
      </ImgModelBodyWrap>
      <SVGModelBodyWrap>
        <ModelBodyBack
          handleClick={(e) => e.target.id && setCurrentPartId(e.target.id)}
          currentPartId={currentPartId}
        />
      </SVGModelBodyWrap>
    </Root>
  );
}

import { Button, TextField } from "@mui/material";
import { styled } from "@mui/system";
import React, { useContext, useState } from "react";
import { SynckitColors } from "../../assets/SynckitColors";
import EditIcon from "@mui/icons-material/Edit";
import { ConsultContext } from "./ConsultIndex";
import { ConsultThemeAry } from "./ConsultTheme";
import { OPERATION_VIEW_DEFAULT } from "../../assets/constant";

export default function CommentSection() {
  const [commentMode, setCommentMode] = useState("medical");
  const [doctorComment, setDoctorComment] = useState("");
  const [patientComment, setPatientComment] = useState("");
  const { currentExamItem, operationView } = useContext(ConsultContext);

  const Root = styled("div")(({ theme }) => ({
    bottom: 0,
    width: "100%",
    maxWidth: 670,
    pointerEvents: operationView === OPERATION_VIEW_DEFAULT ? "none" : "",

    "& .MuiOutlinedInput-root": {
      height: 155,
      background: "rgba(255, 255, 255, 0.8)",
      border: " 1px solid #E4ECF7",
      color: "#B5B5BD",
      paddingTop: 0,
      "& fieldset": {
        borderColor: SynckitColors.Gray[600],
      },
      "&:hover fieldset": {
        borderColor: SynckitColors.Gray[200],
      },
      "&.Mui-focused fieldset": {
        borderColor: SynckitColors.Gray[200],
      },
    },
  }));
  const ButtonWrap = styled("div")(({ theme }) => ({
    "& .MuiButton-root": {
      fontSize: 18,
      fontWeight: 500,
      color: "#AAACBD",
      "&.selectedMode": {
        color: ConsultThemeAry[currentExamItem]["primaryColor"],

        fontWeight: 700,
      },
    },
  }));
  return (
    <Root>
      <ButtonWrap>
        <Button
          onClick={() => setCommentMode("medical")}
          className={commentMode === "medical" ? "selectedMode" : null}
          startIcon={<EditIcon />}
          sx={{
            width: 157,
          }}
        >
          Medical Order
        </Button>
        |
        <Button
          onClick={() => setCommentMode("chief")}
          className={commentMode === "chief" ? "selectedMode" : null}
          sx={{ width: 150 }}
        >
          Chief Complaint
        </Button>
      </ButtonWrap>
      {commentMode === "medical" ? (
        <TextField
          multiline
          rows={4}
          color="gray"
          fullWidth
          placeholder={"Medical Order"}
          // value={doctorComment}
          onChange={(e) => setDoctorComment(e.target.value)}
          sx={{
            height: 147,
          }}
        />
      ) : (
        <TextField
          multiline
          rows={4}
          color="primary"
          fullWidth
          placeholder="Chief Complaint"
          // value={patientComment}
          onChange={(e) => setPatientComment(e.target.value)}
          sx={{
            height: 147,
          }}
        />
      )}
    </Root>
  );
}

import { styled } from "@mui/system";
import React, { useState } from "react";
import { Typography, Button, SvgIcon, Divider } from "@mui/material";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import { SynckitColors } from "../../../assets/SynckitColors";
import { SynckitButton } from "../../../components/SynckitButton";
import { ReactComponent as FileTextLineSVG } from "../../../assets/icon/icon_File Text_Line.svg";

import {
  ImgOrganEarActive2,
  ImgOrganHeartActive2,
  ImgOrganTheethActive2,
} from "../../../assets/SynckitImg";
import ExamRecordEditorDialog from "../../exam-record-editor/ExamRecordEditorDialog";
import { useDispatch } from "react-redux";
import { setOpenExamRecordEditor } from "../../../redux/slice/examRecord.slice";

const Root = styled("div")({
  width: "100%",
});
const TitleWrap = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: 50,
});
const ContentPanel = styled("div")({
  background: "white",
  boxShadow:
    "0px 27px 80px rgba(16, 57, 123, 0.05), 0px 13.6687px 34.875px rgba(16, 57, 123, 0.03375), 0px 5.4px 13px rgba(16, 57, 123, 0.025), 0px 1.18125px 4.625px rgba(16, 57, 123, 0.01625)",
  borderRadius: 12,
  padding: " 23px 24px",
  width: "100%",
  height: 322,
  overflow: "hidden",
});
const ContentHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 10,
  "& .OrganImg": {
    "& > img": {
      marginRight: 12,
    },
  },
});
const ContentBody = styled("div")({
  // backgroundColor: "red",
});
const ContentWrap = styled("div")({
  maxHeight: 96,
  overflow: "hidden",
});

const MoreButton = styled(Button)({
  color: SynckitColors.Gray[700],
  fontWeight: 700,
  fontSize: 14,
  "&:hover": {
    color: SynckitColors.Primary[600],
  },
});

export default function LatestExamination() {
  const display = useDispatch();
  return (
    <Root>
      <TitleWrap>
        <Typography variant="h5">The latest examintion</Typography>
        <MoreButton
          endIcon={<ArrowRightOutlinedIcon />}
          onClick={() => display(setOpenExamRecordEditor(true))}
        >
          See details
        </MoreButton>
      </TitleWrap>
      <ContentPanel>
        <ContentHeader>
          <div className="OrganImg">
            <ImgOrganHeartActive2 style={{ width: 40, height: 40 }} />
            <ImgOrganTheethActive2 style={{ width: 40, height: 40 }} />
            <ImgOrganEarActive2 style={{ width: 40, height: 40 }} />
          </div>
          <SynckitButton
            disableElevation
            variant="contained"
            startIcon={<SvgIcon component={FileTextLineSVG} />}
          >
            Report
          </SynckitButton>
        </ContentHeader>
        <ContentBody>
          <ContentWrap>
            <Typography
              variant="subtitle1"
              color="initial"
              sx={{ color: SynckitColors.Gray[700] }}
            >
              Chief complaint
            </Typography>
            <Typography variant="body1" color="initial">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Accusantium quod ut alias asperiores voluptates, porro facilis!
              Accusantium, quisquam autem. Numquam excepturi aperiam soluta
              perferendis incidunt optio, odit tempora molestiae error.
            </Typography>
          </ContentWrap>
          <Divider sx={{ margin: "12px 0px" }} />
          <ContentWrap>
            <Typography
              variant="subtitle1"
              color="initial"
              sx={{ color: SynckitColors.Gray[700] }}
            >
              Medical Order
            </Typography>
            <Typography variant="body1" color="initial">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Accusantium quod ut alias asperiores voluptates, porro facilis!
              Accusantium, quisquam autem. Numquam excepturi aperiam soluta
              perferendis incidunt optio, odit tempora molestiae error.
            </Typography>
          </ContentWrap>
        </ContentBody>
      </ContentPanel>
    </Root>
  );
}

import { Button, styled } from "@mui/material";
import React, { Fragment, useState } from "react";
import { IconTrash2Line } from "../../../../assets/SynckitIcons";
import ConfirmDialog from "../../../../components/dialog/ConfirmDialog";

const SaveButton = styled(Button)({
  background: "white",
  borderRadius: 100,
  padding: "6px 20px",
  fontSize: 14,
  fontWeight: 600,
  lineHeight: "23px",
  color: "#FFB54D",
});
export default function DeleteDialog() {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  return (
    <Fragment>
      <Button
        startIcon={<IconTrash2Line />}
        variant="fifth"
        sx={{ width: 136 }}
        onClick={() => setOpenConfirmDialog(true)}
      >
        Delete
      </Button>

      <ConfirmDialog
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        title={"Sure to delete?"}
        content={
          "You will no longer be able to access this patient’s profile and examination record. The other authorized user will still have the access."
        }
        confirmBtnTitle={"Delete"}
        confirmBtnHandleClick={() => {
          setOpenConfirmDialog(false);
        }}
      />
    </Fragment>
  );
}

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  styled,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { SynckitColors } from "../../../../assets/SynckitColors";
import { IconPinLine, IconPlusLine } from "../../../../assets/SynckitIcons";

const InfoCard = styled(Card)({
  background: "white",
  borderRadius: 12,
  boxShadow: SynckitColors.Shadow[3],
});

const InfoCardHeader = styled(CardHeader)({
  "& .MuiCardHeader-avatar": {},
});

export default function LocationCard() {
  return (
    <InfoCard>
      <CardHeader
        avatar={
          <IconPinLine
            style={{ width: 18, height: 18, color: SynckitColors.Primary[600] }}
          />
        }
        title="Location"
        titleTypographyProps={{
          fontSize: 16,
          fontWeight: "bold",
          color: SynckitColors.Primary[600],
        }}
      />
      <Divider />
      <CardContent sx={{ padding: "5%" }}>
        <Grid
          container
          spacing={4}
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid item xs={6}>
            <Typography variant="subtitle1">Country</Typography>
            <Typography variant="body1">Taiwan</Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="subtitle1">City</Typography>
            <Typography variant="body1">Taipei</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Address</Typography>
            <Typography variant="body1">Address</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Organization</Typography>
            <Typography variant="body1">Organization</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </InfoCard>
  );
}

const demoData = [{ id: 1, title: "Country", value: "Taiwan" }];

import React from "react";
import Grid from "@mui/material/Grid";
import { MenuItem, TextField } from "@mui/material";
import { SynckitPanel } from "../../../components/SynckitPanel";

export default function DoctorSpecialty() {
  return (
    <SynckitPanel>
      <Grid
        container
        spacing={3}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
      >
        <Grid item xs={12} sm={6} lg={3}>
          <TextField select label="Nationality" fullWidth size="small">
            <MenuItem value={0}>Nationality1</MenuItem>
            <MenuItem value={1}>Nationality2</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <TextField label="Languages" size="small" fullWidth />
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
          <TextField label="Specialty" size="small" fullWidth />
        </Grid>
      </Grid>
    </SynckitPanel>
  );
}

import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  SvgIcon,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as MoreSVG } from "../../assets/icon/general/icon_More Horizontal.svg";
import { ReactComponent as FiltLineSVG } from "../../assets/icon/general/icon_File Text_Line.svg";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import CloseIcon from "@mui/icons-material/Close";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import avatar_patient1 from "../../assets/images/demo/avatar_patient1.jpg";

import FemaleIcon from "@mui/icons-material/Female";
import { styled } from "@mui/system";
import { SynckitColors } from "../../assets/SynckitColors";
import {
  ImgOrganActive2List,
  ImgOrganActiveList,
  ImgOrganArteryActive,
  ImgOrganHeartActive2,
} from "../../assets/SynckitImg";
import {
  APO_CANCELLED_STATUS_NONE,
  APO_CANCELLED_STATUS_REACHED,
  APO_CANCELLED_STATUS_REFUSED,
  APO_CANCELLED_STATUS_WAITING,
} from "../../assets/constant";
import { format } from "date-fns";

const PatientNameAndGender = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  height: 32,
  marginTop: 16,
  paddingLeft: 18,
});

const CustomizeCard = styled(Card)(({ theme }) => ({
  minWidth: 460,
  transition: "0.3s",
  overflowY: "auto",
  [theme.breakpoints.down("sm")]: {
    minWidth: "auto",
  },
}));
const CustomizeCardContent = styled(CardContent)(({ theme }) => ({
  transition: "0.3s",
  padding: "0px 8px 0px 22px",
  [theme.breakpoints.down("sm")]: {
    padding: "0px 10px",
  },
}));
const CustomizeCardHeader = styled(CardHeader)(({ theme }) => ({
  transition: "0.3s",
  [theme.breakpoints.down("sm")]: {
    "& .MuiTypography-root": {
      fontSize: 14,
    },
  },
}));

const CustomizeListItem = styled(ListItem)(({ theme }) => ({
  padding: 0,
}));

const PaymentStatusLabel = styled("span")(({ theme }) => ({
  background: "#606060",
  fontSize: 14,
  fontWeight: "bold",
  color: "white",
  borderRadius: 12,
  padding: "4px 12px",
}));

export default function RemoteApoDialog({
  open,
  setOpen,
  apoInfo,
  isLoadingApoInfo,
  eventData = { cancelledStatus: false },
}) {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const openMenu = Boolean(menuAnchorEl);

  return (
    <>
      <Dialog onClose={() => setOpen(false)} open={open}>
        {isLoadingApoInfo ? (
          <DialogContent>
            <CircularProgress />
          </DialogContent>
        ) : (
          <CustomizeCard>
            <CustomizeCardHeader
              avatar={
                <IconButton onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
                  <SvgIcon component={MoreSVG} />
                </IconButton>
              }
              title="Remote Session Appointment"
              titleTypographyProps={{
                variant: "h5",
                sx: { textAlign: "center" },
              }}
              action={
                <IconButton
                  sx={{ marginLeft: 1 }}
                  onClick={() => setOpen(false)}
                >
                  <CloseIcon />
                </IconButton>
              }
            />
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar
                alt=""
                src={apoInfo.patient.avatar}
                sx={{ width: 60, height: 60 }}
              />
              <PatientNameAndGender>
                <Typography variant="subtitle1" color="initial">
                  {`${apoInfo.patient?.first_name} ${apoInfo.patient?.last_name}`}
                </Typography>
                &nbsp;
                <FemaleIcon color="error" />
              </PatientNameAndGender>
              <Typography variant="body2" color="initial">
                {apoInfo.patient.no}
              </Typography>
            </CardContent>
            <Divider />
            <CustomizeCardContent>
              <List>
                <CustomizeListItem>
                  <ListItemText
                    primary="Date"
                    secondary={format(
                      new Date(apoInfo.appointed_date),
                      "yyyy/MM/dd"
                    )}
                    primaryTypographyProps={{ variant: "subtitle1" }}
                    secondaryTypographyProps={{ variant: "body1" }}
                  />
                </CustomizeListItem>
                <CustomizeListItem>
                  <ListItemText
                    primary="Time"
                    secondary={
                      apoInfo.appointed_time_start.split(":", 2).join(":") +
                      " ~ " +
                      apoInfo.appointed_time_end.split(":", 2).join(":")
                    }
                    primaryTypographyProps={{ variant: "subtitle1" }}
                    secondaryTypographyProps={{ variant: "body1" }}
                  />
                </CustomizeListItem>
                <CustomizeListItem>
                  <ListItemText
                    primary="Doctor"
                    secondary={`${apoInfo.rpn_user.first_name} ${apoInfo.rpn_user.last_name}`}
                    primaryTypographyProps={{ variant: "subtitle1" }}
                    secondaryTypographyProps={{ variant: "body1" }}
                  />
                </CustomizeListItem>
                <CustomizeListItem>
                  <ListItemText
                    primary="Item"
                    // secondary={
                    //   <ImgOrganHeartActive2 style={{ width: 24, height: 24 }} />
                    // }
                    secondary={ImgOrganActive2List[apoInfo.subject_id]}
                    primaryTypographyProps={{
                      variant: "subtitle1",
                      sx: { marginBottom: 1 },
                    }}
                  />
                </CustomizeListItem>
                <CustomizeListItem>
                  <ListItemText
                    primary="Attached exmination record"
                    secondary={
                      <Button
                        variant="third"
                        startIcon={<SvgIcon component={FiltLineSVG} />}
                      >
                        {apoInfo.patient.no}
                      </Button>
                    }
                    primaryTypographyProps={{
                      variant: "subtitle1",
                      sx: { marginBottom: 1 },
                    }}
                  />
                </CustomizeListItem>
              </List>
            </CustomizeCardContent>
            <Divider />
            <CustomizeCardContent>
              <List>
                <CustomizeListItem>
                  <ListItemText
                    primary="Doctor’s plan"
                    primaryTypographyProps={{
                      variant: "subtitle2",
                      sx: {
                        fontWeight: 700,
                        color: SynckitColors.Primary[600],
                      },
                    }}
                  />
                </CustomizeListItem>
                <CustomizeListItem>
                  <ListItemIcon>
                    <SvgIcon component={AttachMoneyOutlinedIcon} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Estimated points cost"
                    secondary={`${apoInfo.point_cost} points/$ ${apoInfo.price_cost} USD`}
                    primaryTypographyProps={{ variant: "subtitle1" }}
                    secondaryTypographyProps={{ variant: "body1" }}
                  />
                </CustomizeListItem>
                <CustomizeListItem>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText
                    primary={`${apoInfo.plan.session_point} pts-${apoInfo.plan.session_price} $ USD / ${apoInfo.plan.session_time} mins`}
                    primaryTypographyProps={{
                      variant: "body1",
                      color: SynckitColors.Primary[600],
                    }}
                  />
                </CustomizeListItem>
              </List>
              <Divider />
              <List>
                <CustomizeListItem>
                  <ListItemText
                    primary="Payment"
                    primaryTypographyProps={{
                      variant: "subtitle2",
                      sx: {
                        fontWeight: 700,
                        color: SynckitColors.Primary[600],
                      },
                    }}
                  />
                </CustomizeListItem>
                <CustomizeListItem>
                  <ListItemIcon>
                    <CheckOutlinedIcon
                      sx={{ color: SynckitColors.Success["Default"] }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Appointment Pre-paid"
                    primaryTypographyProps={{
                      variant: "subtitle1",
                    }}
                  />
                </CustomizeListItem>

                {
                  //這個部份僅在 Cancelled Table中 狀態為「refused request of cancellation」時才會顯示資訊
                  eventData.cancelledStatus ===
                    APO_CANCELLED_STATUS_REFUSED && (
                    <CustomizeListItem>
                      <ListItemIcon>
                        <CheckOutlinedIcon
                          sx={{ color: SynckitColors.Success["Default"] }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Refund Completed ( 50% )"
                        primaryTypographyProps={{
                          variant: "subtitle1",
                        }}
                      />
                    </CustomizeListItem>
                  )
                }

                {
                  //這個部份僅在 Cancelled Table中 狀態為「無狀態或reached agreement of cancellation」時才會顯示資訊
                  (eventData.cancelledStatus === APO_CANCELLED_STATUS_NONE ||
                    eventData.cancelledStatus ===
                      APO_CANCELLED_STATUS_REACHED) && (
                    <CustomizeListItem>
                      <ListItemIcon>
                        <CheckOutlinedIcon
                          sx={{ color: SynckitColors.Success["Default"] }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Refund Completed (100% )"
                        primaryTypographyProps={{
                          variant: "subtitle1",
                        }}
                      />
                    </CustomizeListItem>
                  )
                }

                {
                  //這個部份僅在 Cancelled Table中 狀態為「Waiting for agreement」時才會顯示資訊
                  eventData.cancelledStatus ===
                    APO_CANCELLED_STATUS_WAITING && (
                    <CustomizeListItem
                      sx={{
                        justifyContent: "center",
                        paddingTop: 2,
                      }}
                    >
                      <Button variant="primary">appointment cancel</Button>
                    </CustomizeListItem>
                  )
                }
              </List>
            </CustomizeCardContent>
          </CustomizeCard>
        )}
        <Divider />
      </Dialog>
      <Menu
        open={openMenu}
        anchorEl={menuAnchorEl}
        onClose={() => setMenuAnchorEl(false)}
      >
        <MenuItem onClick={() => setMenuAnchorEl(false)}>
          Examination Record
        </MenuItem>
        <MenuItem onClick={() => setMenuAnchorEl(false)}>Cancel</MenuItem>
      </Menu>
    </>
  );
}

import { styled, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useContext, useState } from "react";
import { SynckitColors } from "../../../../assets/SynckitColors";
import { ConsultContext } from "../../ConsultIndex";
import AdultTeeth from "./AdultTeeth";
import ChildTeeth from "./ChildTeeth";
// import BodyFront from "./body-front/BodyFront";
// import BodyBack from "./body-back/BodyBack";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
  position: "relative",
}));
const ModeSwitch = styled(ToggleButtonGroup)(({ theme }) => ({
  position: "absolute",
  zIndex: 10,
  top: 72,
  right: "3%",
  padding: 4,
  borderRadius: 8,
  background: SynckitColors.Gray[200],
  width: 132,
  height: 44,
  "& .MuiToggleButton-root": {
    background: "none",
    border: "none",
    textTransform: "initial",
    padding: "8px 15px",
    fontSize: 14,
    fontWeight: 400,
    color: SynckitColors.Gray[700],
    borderRadius: "8px !important",

    "&:hover": {
      background: SynckitColors.Gray[400],
    },
  },
  "& .Mui-selected": {
    background: "white !important",
    fontWeight: 700,
    color: SynckitColors.Gray["core"],
  },
}));

export default function Teeth({
  isLoading,
  setIsLoading,
  teethMode,
  setTeethMode,
}) {
  const { setCurrentPartId } = useContext(ConsultContext);
  return (
    <Root>
      <ModeSwitch
        value={teethMode}
        exclusive
        onChange={(e) => {
          setTeethMode(e.target.value);
          setCurrentPartId("");
        }}
      >
        <ToggleButton value="adult">Adult</ToggleButton>
        <ToggleButton value="child">Child</ToggleButton>
      </ModeSwitch>
      {teethMode === "adult" ? <AdultTeeth /> : <ChildTeeth />}
    </Root>
  );
}

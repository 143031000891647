import {
  Badge,
  Box,
  Button,
  styled,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  ORGAN_ABDOMINAL,
  ORGAN_ARTERY,
  ORGAN_EAR,
  ORGAN_HEART,
  ORGAN_LUNG,
  ORGAN_NOSE,
  ORGAN_SKIN,
  ORGAN_TEMPERATURE,
  ORGAN_THEETH,
  ORGAN_THROAT,
} from "../../assets/constant";
import { OrganColorList, SynckitColors } from "../../assets/SynckitColors";
import {
  IconAlertCircleLine,
  IconFileTextLine,
  IconTrash2Line,
} from "../../assets/SynckitIcons";
import {
  ImgOrganActiveList,
  ImgOrganNormalList,
} from "../../assets/SynckitImg";
import RecordInfoDialog from "./RecordInfoDialog";

const Root = styled("div")(({ theme }) => ({
  background: "white",
  height: 148,
  boxShadow: SynckitColors.Shadow[3],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const ItemCard = styled("div")(({ theme }) => ({
  position: "relative",
  "& img": {
    width: 72,
  },
  "&  .MuiBadge-root": {
    position: "absolute",
    top: 4,
    right: 4,
    "&  .MuiBadge-badge": {
      background: SynckitColors.Error["Default"],
      color: "white",
      fontWeight: "bold",
      width: 24,
      height: 24,
      borderRadius: "50%",
    },
  },
}));

const CustomButton = styled(Button)(({ theme }) => ({
  borderRadius: 8,
  flexDirection: "column",
  width: 105,
  height: 90,
  padding: 0,

  "&:disabled": {
    background: "white",
    color: SynckitColors.Gray[700],
    border: `1px solid ${SynckitColors.Gray[500]}`,
    "& span": {
      color: SynckitColors.Gray[700],
    },
  },
  "& span": {
    marginTop: 2,
    fontSize: 14,
    fontWeight: 600,
    color: SynckitColors.Gray["core"],
  },
}));

export default function MenuBar() {
  const [selectedItem, setSelectedItem] = useState(organMenuList[0]);
  const [openRecordInfo, setOpenRecordInfo] = useState(false);

  return (
    <Root>
      <Tabs
        value={selectedItem.id}
        sx={{
          height: "100%",
          pt: 1.5,
          "& .MuiTabs-indicator": {
            backgroundColor: OrganColorList[selectedItem.organ],
            height: 3,
          },
        }}
      >
        {organMenuList.map((item, index) => (
          <Tab
            key={index}
            disabled={item.recordCount === 0}
            icon={
              <ItemCard>
                {item.recordCount !== 0 ? (
                  <>
                    <Badge badgeContent={item.recordCount}></Badge>
                    {ImgOrganActiveList[item.organ]}
                  </>
                ) : (
                  ImgOrganNormalList[item.organ]
                )}
              </ItemCard>
            }
            label={
              <Typography
                variant="body2"
                sx={{
                  textTransform: "none",
                  color:
                    index === selectedItem.id
                      ? SynckitColors.Gray["core"]
                      : SynckitColors.Gray[700],
                  fontWeight: index === selectedItem.id ? 700 : null,
                }}
              >
                {item.title}
              </Typography>
            }
            onClick={() => setSelectedItem(item)}
          />
        ))}
      </Tabs>
      {"　"}
      <Box
        sx={{ width: 350, display: "flex", justifyContent: "space-between" }}
      >
        <CustomButton variant="third" onClick={() => setOpenRecordInfo(true)}>
          <IconAlertCircleLine fontSize="large" />
          <span>Record Info.</span>
        </CustomButton>
        <CustomButton variant="third">
          <IconFileTextLine fontSize="large" />
          <span>Report</span>
        </CustomButton>
        <CustomButton variant="third" disabled>
          <IconTrash2Line fontSize="large" />
          <span>Delete</span>
        </CustomButton>
      </Box>
      <RecordInfoDialog open={openRecordInfo} setOpen={setOpenRecordInfo} />
    </Root>
  );
}

const organMenuList = [
  { id: 0, title: "Teeth", organ: ORGAN_THEETH, recordCount: 5 },
  { id: 1, title: "Ear", organ: ORGAN_EAR, recordCount: 0 },
  { id: 2, title: "B.T", organ: ORGAN_TEMPERATURE, recordCount: 2 },
  { id: 3, title: "Nose", organ: ORGAN_NOSE, recordCount: 0 },
  { id: 4, title: "Throat", organ: ORGAN_THROAT, recordCount: 3 },
  { id: 5, title: "Skin&Wound", organ: ORGAN_SKIN, recordCount: 0 },
  { id: 6, title: "Lung", organ: ORGAN_LUNG, recordCount: 0 },
  { id: 7, title: "Heart", organ: ORGAN_HEART, recordCount: 1 },
  { id: 8, title: "Abdominal", organ: ORGAN_ABDOMINAL, recordCount: 0 },
  { id: 9, title: "Artery", organ: ORGAN_ARTERY, recordCount: 0 },
];

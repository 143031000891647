import { Breadcrumbs, Typography, Divider, Link } from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { SynckitColors } from "../assets/SynckitColors";
import { SIDMENU_WIDTH_MD, SIDMENU_WIDTH_NORMAL } from "../assets/constant";
import { useLocation } from "react-router-dom";
import { synckitRoutes } from "../assets/SynckitRoutes";
import _ from "lodash";
import AvatarWithSettingMenu from "./AvatarWithSettingMenu";
import SynckitStatusLight from "../assets/SynckitStatusLight";
import NotificationsAlert from "../views/notifications/NotificationsAlert";
const HeaderRoot = styled("header")(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: SIDMENU_WIDTH_NORMAL,
  width: `calc(100% - ${SIDMENU_WIDTH_NORMAL}px)`,
  height: 80,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "22px 4%",
  transition: "0.3s",
  [theme.breakpoints.down("md")]: {
    left: SIDMENU_WIDTH_MD,
    width: `calc(100% - ${SIDMENU_WIDTH_MD}px)`,
  },
  [theme.breakpoints.down("sm")]: {
    left: 60,
    width: `calc(100% - 60px)`,
  },
}));

const UserInfo = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: 270,
  transition: "0.3s",
  [theme.breakpoints.down("md")]: {
    width: 140,
  },
}));

const StatusBox = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: 132,
  transition: "0.3s",
  [theme.breakpoints.down("md")]: {
    width: 26,
    "& .MuiTypography-root": {
      display: "none",
    },
  },
}));

export default function Header() {
  const location = useLocation();
  let breadcrumbsObj = {};
  breadcrumbsObj["schedule"] = "Schedule";
  const [pathList, setPathList] = useState([]);
  useEffect(() => {
    var tempPathList = location.pathname.split("/");
    setPathList([...tempPathList]);
  }, [location]);
  return (
    <HeaderRoot>
      <Breadcrumbs>
        {pathList.map((item, index) => {
          if (index < pathList.length - 1) {
            const tempPath = pathList.slice(0, index + 2).join("/");
            const currentRouteObj = _.filter(synckitRoutes, {
              path: tempPath,
            })[0];
            if (index === pathList.length - 2)
              return (
                <Typography key={index} variant="subtitle1" color="initial">
                  {currentRouteObj.breadcrumbName}
                </Typography>
              );
            else
              return (
                <Link
                  key={index}
                  underline="none"
                  color="inherit"
                  href={currentRouteObj["path"]}
                >
                  {currentRouteObj["breadcrumbName"]}
                </Link>
              );
          } else return null;
        })}
      </Breadcrumbs>
      <UserInfo>
        <StatusBox>
          <SynckitStatusLight />
          <Typography
            variant="body2"
            sx={{ color: SynckitColors.Gray["core"] }}
          >
            Online - Available
          </Typography>
        </StatusBox>
        <Divider orientation="vertical" variant="middle" flexItem />
        <NotificationsAlert />
        <AvatarWithSettingMenu />
      </UserInfo>
    </HeaderRoot>
  );
}

import { styled } from "@mui/material";
import React, { useContext } from "react";
import ImgModelMouthThroat from "../../../../assets/images/body/model_open_mouth.png";
import { SynckitColors } from "../../../../assets/SynckitColors";
import { ConsultContext } from "../../ConsultIndex";
import { HeadThroatSVG } from "./HeadThroatSVG";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  position: "relative",
}));

const ImgModelBodyWrap = styled("div")(({ theme }) => ({
  position: "absolute",
  height: "70%",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  "& img": {
    height: "100%",
  },
}));
const SVGModelBodyWrap = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  height: "60%",
  "& svg": {
    height: "100%",
  },
  "& path": {
    transition: "0.3s",
  },
  "& path:hover": {
    opacity: 0.5,
    cursor: "pointer",
  },
}));

export default function HeadThroat() {
  const { currentPartId, setCurrentPartId } = useContext(ConsultContext);
  return (
    <Root>
      <ImgModelBodyWrap>
        <img src={ImgModelMouthThroat} alt="synckit" />
      </ImgModelBodyWrap>
      <SVGModelBodyWrap>
        <HeadThroatSVG
          handleClick={(e) => e.target.id && setCurrentPartId(e.target.id)}
          currentPartId={currentPartId}
        />
      </SVGModelBodyWrap>
    </Root>
  );
}

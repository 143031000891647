import {
  Button,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { Fragment, useContext, useState } from "react";
import {
  ImgOrganActiveList,
  ImgOrganUnselectList,
} from "../../assets/SynckitImg";
import { menuDataList, organMenuList } from "./MenuDataList";
import { SynckitColors } from "../../assets/SynckitColors";
import { ConsultContext } from "./ConsultIndex";
import BodyFrontTop from "./body-card/body-front/BodyFrontTop";
import BodyFrontBottom from "./body-card/body-front/BodyFrontBottom";
import BodyFrontMid from "./body-card/body-front/BodyFrontMid";
import BodyBackTop from "./body-card/body-back/BodyBackTop";
import BodyBackMid from "./body-card/body-back/BodyBackMid";
import BodyBackBottom from "./body-card/body-back/BodyBackBottom";
import HeadNose from "./body-card/nose/HeadNose";
import HeadEar from "./body-card/ear/HeadEar";
import HeadThroat from "./body-card/throat/HeadThroat";
import {
  OPERATION_VIEW_BODY,
  OPERATION_VIEW_DEFAULT,
  ORGAN_DEFAULT,
} from "../../assets/constant";
import HeadBT from "./body-card/head-bt/HeadBT";
import AdultTeethUpper from "./body-card/teeth/AdultTeethUpper";
import AdultTeethLower from "./body-card/teeth/AdultTeethLower";
import ChildTeethUpper from "./body-card/teeth/ChildTeethUpper";
import ChildTeethLower from "./body-card/teeth/ChildTeethLower";
import Artery from "./body-card/artery/Artery";
import Heart from "./body-card/heart/Heart";
import LungFront from "./body-card/lung/LungFront";
import Abdominal from "./body-card/abdominal/Abdominal";
import LungBack from "./body-card/lung/LungBack";

const ExamMenuList = styled(MenuList)({
  height: 150,
  width: 190,
  padding: 0,
});
const ExamMenuItem = styled(MenuItem)({
  background: SynckitColors.Gray[200],
  height: 56,
  color: "#AAACBD",
  "& .MuiTypography-root": {
    fontSize: 18,
    fontWeight: "bold",
  },

  "& .MuiSvgIcon-root": {
    color: "#AAACBD",
  },
  "&.selectedItem": {
    background: "white",
    color: "#162A4B",
    "& .MuiSvgIcon-root": {
      color: "#162A4B",
    },
  },
});

const ExamItemBox = styled("div")(({ theme }) => ({
  width: 740,
  height: 168,
  padding: 20,
  background: "white",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
}));
const OragnViewBox = styled("div")(({ theme }) => ({
  boxShadow: "inset 1px 0px 0px #EDF2F7",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 167,
  padding: 16,
  pointerEvents: "none",
}));
const OragnButton = styled(Button)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: 100,
  "& img": {
    width: 76,
    height: 76,
    marginBottom: 3,
    filter:
      "drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.1)) drop-shadow(0px 10px 16px rgba(20, 37, 63, 0.06))",
  },
  "& span": {
    color: "#AAACBD",
    fontSize: 16,
    fontWeight: 400,
  },
}));

export default function ExamItemMenu() {
  const [categoryIndex, setCategoryIndex] = useState(1);
  const {
    setOperationView,
    operationView,
    currentExamItem,
    setCurrentExamItem,
    currentPartId,
  } = useContext(ConsultContext);

  const Root = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    borderRadius: 8,
    overflow: "hidden",
    background: "white",
    width: "100%",
    maxWidth: 1004,
    //Can't select item before "Start Examination"
    pointerEvents: operationView === OPERATION_VIEW_DEFAULT ? "none" : "",
  }));

  return (
    <Root>
      <ExamMenuList>
        {menuDataList.map((item, index) => (
          <ExamMenuItem
            key={index}
            className={categoryIndex === index ? "selectedItem" : null}
            onClick={() => setCategoryIndex(index)}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText>{item.title}</ListItemText>
          </ExamMenuItem>
        ))}
      </ExamMenuList>
      <ExamItemBox>
        {organMenuList[categoryIndex].map((item, index) => (
          <OragnButton
            key={index}
            onClick={() => {
              setOperationView(OPERATION_VIEW_BODY);
              setCurrentExamItem(item.organ);
            }}
          >
            {currentExamItem === item.organ ? (
              <Fragment>
                {ImgOrganActiveList[item.organ]}
                <span style={{ color: "#162A4B", fontWeight: 700 }}>
                  {item.title}
                </span>
              </Fragment>
            ) : (
              <Fragment>
                {ImgOrganUnselectList[item.organ]}
                <span>{item.title}</span>
              </Fragment>
            )}
          </OragnButton>
        ))}
      </ExamItemBox>
      <OragnViewBox>
        {booyFrontTopIdList.includes(currentPartId) && (
          <BodyFrontTop currentPartId={currentPartId} />
        )}
        {booyFrontMidIdList.includes(currentPartId) && (
          <BodyFrontMid currentPartId={currentPartId} />
        )}
        {booyFrontBottomIdList.includes(currentPartId) && (
          <BodyFrontBottom currentPartId={currentPartId} />
        )}
        {booyBackTopIdList.includes(currentPartId) && (
          <BodyBackTop currentPartId={currentPartId} />
        )}
        {booyBackMidIdList.includes(currentPartId) && (
          <BodyBackMid currentPartId={currentPartId} />
        )}
        {booyBackBottomIdList.includes(currentPartId) && (
          <BodyBackBottom currentPartId={currentPartId} />
        )}
        {earIdList.includes(currentPartId) && (
          <HeadEar currentPartId={currentPartId} />
        )}
        {noseIdList.includes(currentPartId) && (
          <HeadNose currentPartId={currentPartId} />
        )}
        {throatIdList.includes(currentPartId) && (
          <HeadThroat currentPartId={currentPartId} />
        )}
        {btIdList.includes(currentPartId) && <HeadBT />}
        {teethAdultUpperIdList.includes(currentPartId) && <AdultTeethUpper />}
        {teethAdultLowerIdList.includes(currentPartId) && <AdultTeethLower />}
        {teethChildUpperIdList.includes(currentPartId) && <ChildTeethUpper />}
        {teethChildLowerIdList.includes(currentPartId) && <ChildTeethLower />}
        {heartIdList.includes(currentPartId) && <Heart />}
        {arteryIdList.includes(currentPartId) && <Artery />}
        {lungFrontIdList.includes(currentPartId) && <LungFront />}
        {lungBackIdList.includes(currentPartId) && <LungBack />}
        {abdominalIdList.includes(currentPartId) && <Abdominal />}
      </OragnViewBox>
    </Root>
  );
}

const booyFrontTopIdList = ["f1", "f2", "f3", "f6", "f14"];
const booyFrontMidIdList = [
  "f4",
  "f5",
  "f10",
  "f18",
  "f7",
  "f8",
  "f9",
  "f15",
  "f16",
  "f17",
];
const booyFrontBottomIdList = ["f11", "f12", "f13", "f19", "f20", "f21"];

const booyBackTopIdList = ["r1", "r2", "r3", "r6", "r14"];
const booyBackMidIdList = [
  "r4",
  "r5",
  "r10",
  "r18",
  "r7",
  "r8",
  "r9",
  "r15",
  "r16",
  "r17",
];
const booyBackBottomIdList = ["r11", "r12", "r13", "r19", "r20", "r21"];
const earIdList = ["ear1", "ear2"];
const noseIdList = ["nose1", "nose2"];
const throatIdList = ["throat_pu", "throat_left", "throat_right"];
const btIdList = ["bt"];
const teethAdultUpperIdList = [
  "teeth_adult_11",
  "teeth_adult_12",
  "teeth_adult_13",
  "teeth_adult_14",
  "teeth_adult_15",
  "teeth_adult_16",
  "teeth_adult_17",
  "teeth_adult_18",
  "teeth_adult_21",
  "teeth_adult_22",
  "teeth_adult_23",
  "teeth_adult_24",
  "teeth_adult_25",
  "teeth_adult_26",
  "teeth_adult_27",
  "teeth_adult_28",
];
const teethAdultLowerIdList = [
  "teeth_adult_31",
  "teeth_adult_32",
  "teeth_adult_33",
  "teeth_adult_34",
  "teeth_adult_35",
  "teeth_adult_36",
  "teeth_adult_37",
  "teeth_adult_38",
  "teeth_adult_41",
  "teeth_adult_42",
  "teeth_adult_43",
  "teeth_adult_44",
  "teeth_adult_45",
  "teeth_adult_46",
  "teeth_adult_47",
  "teeth_adult_48",
];

const teethChildUpperIdList = [
  "teeth_child_51",
  "teeth_child_52",
  "teeth_child_53",
  "teeth_child_54",
  "teeth_child_55",
  "teeth_child_61",
  "teeth_child_62",
  "teeth_child_63",
  "teeth_child_64",
  "teeth_child_65",
];

const teethChildLowerIdList = [
  "teeth_child_71",
  "teeth_child_72",
  "teeth_child_73",
  "teeth_child_74",
  "teeth_child_75",
  "teeth_child_81",
  "teeth_child_82",
  "teeth_child_83",
  "teeth_child_84",
  "teeth_child_85",
];
const heartIdList = ["heart_a", "heart_p", "heart_e", "heart_t", "heart_m"];

const arteryIdList = [
  "artery_a",
  "artery_rr",
  "artery_lr",
  "artery_rf",
  "artery_lf",
  "artery_li",
  "artery_ri  ",
];

const lungFrontIdList = [
  "lung_front_m1",
  "lung_front_r1",
  "lung_front_r2",
  "lung_front_r3",
  "lung_front_l1",
  "lung_front_l2",
  "lung_front_l3",
];

const lungBackIdList = [
  "lung_back_rl1",
  "lung_back_rl2",
  "lung_back_rl3",
  "lung_back_rl4",
  "lung_back_rr1",
  "lung_back_rr2",
  "lung_back_rr3",
  "lung_back_rr4",
];

const abdominalIdList = [
  "abdominal_ruq",
  "abdominal_rlq",
  "abdominal_luq",
  "abdominal_llq",
];

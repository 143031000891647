import { TableCell, TableRow, styled, Avatar } from "@mui/material";
import React, { Fragment, useState } from "react";
import { SynckitColors } from "../../../assets/SynckitColors";

import { ImgOrganActive2List } from "../../../assets/SynckitImg";
import { APO_TYPE_LOCAL, APO_TYPE_REMOTE } from "../../../assets/constant";
import ApoCancelDialog from "../../../components/dialog/ApoCancelDialog";

import { DemoRowData } from "./demolApoData";
import SynckitTable from "../../../components/table/SynckitTable";
import LocalApoStaticDialog from "../../../components/dialog/LocalApoStaticDialog";
import RemoteApoStaticDialog from "../../../components/dialog/RemoteApoStaticDialog";

const TypeLabel = styled("span")({
  fontSize: 14,
  fontWeight: "bold",
  borderRadius: 100,

  "&.remote": {
    border: `1px solid ${SynckitColors.Gray[500]}`,
    color: SynckitColors.Gray[800],
    padding: "8px 32px",
  },
  "&.local": {
    background: SynckitColors.Gray[800],
    color: "white",
    padding: "8px 40px",
  },
});

const columns = [
  {
    id: 0,
    title: "Patient",
    field: "patient",
    renderCell: (rowData) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Avatar
          src={rowData.avatar}
          sx={{ margin: "0px 12px", width: 48, height: 48 }}
        />
        {rowData.patient}
      </div>
    ),
  },
  {
    id: 1,
    title: "Doctor",
    field: "doctor",
  },
  {
    id: 2,
    title: "Date",
    field: "date",
  },
  {
    id: 3,
    title: "Type",
    field: "type",
    renderCell: (rowData) => (
      <TypeLabel
        className={rowData.type === APO_TYPE_REMOTE ? "remote" : "local"}
      >
        {rowData.type === APO_TYPE_REMOTE ? "Remote" : "Local"}
      </TypeLabel>
    ),
  },
  {
    id: 4,
    title: "Divison",
    renderCell: (rowData) =>
      rowData.divison !== null && ImgOrganActive2List[rowData.divison],
  },
  {
    id: 5,
    title: "Cancel",
    width: 80,
    renderCell: (rowData) => <ApoCancelDialog />,
  },
];

export default function Appointment() {
  const [rows, setRows] = useState(DemoRowData);
  const [openLocalApoDialog, setOpenLocalApoDialog] = useState(false);
  const [openRemoteApoDialog, setOpenRemoteApoDialog] = useState(false);

  return (
    <Fragment>
      {/* <SynckitTable columns={columns} rows={rows} /> */}
      <SynckitTable columns={columns} rows={rows}>
        {rows.map((row, index) => (
          <TableRow
            key={index}
            sx={{
              background: row.consulted ? SynckitColors.Gray[300] : "none",
            }}
          >
            <TableCell
              onClick={() =>
                row.type === APO_TYPE_LOCAL
                  ? setOpenLocalApoDialog(true)
                  : setOpenRemoteApoDialog(true)
              }
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() =>
                  row.type === APO_TYPE_LOCAL
                    ? setOpenLocalApoDialog(true)
                    : setOpenRemoteApoDialog(true)
                }
              >
                <Avatar
                  src={row.avatar}
                  sx={{ margin: "0px 12px", width: 48, height: 48 }}
                />
                {row.patient}
              </div>
            </TableCell>
            <TableCell
              onClick={() =>
                row.type === APO_TYPE_LOCAL
                  ? setOpenLocalApoDialog(true)
                  : setOpenRemoteApoDialog(true)
              }
            >
              {row.doctor}
            </TableCell>
            <TableCell
              onClick={() =>
                row.type === APO_TYPE_LOCAL
                  ? setOpenLocalApoDialog(true)
                  : setOpenRemoteApoDialog(true)
              }
            >
              {row.date}
            </TableCell>
            <TableCell
              onClick={() =>
                row.type === APO_TYPE_LOCAL
                  ? setOpenLocalApoDialog(true)
                  : setOpenRemoteApoDialog(true)
              }
            >
              {
                <TypeLabel
                  className={row.type === APO_TYPE_REMOTE ? "remote" : "local"}
                >
                  {row.type === APO_TYPE_REMOTE ? "Remote" : "Local"}
                </TypeLabel>
              }
            </TableCell>
            <TableCell
              onClick={() =>
                row.type === APO_TYPE_LOCAL
                  ? setOpenLocalApoDialog(true)
                  : setOpenRemoteApoDialog(true)
              }
            >
              {row.divison !== null && ImgOrganActive2List[row.divison]}
            </TableCell>
            <TableCell>
              <ApoCancelDialog />
            </TableCell>
          </TableRow>
        ))}
      </SynckitTable>
      <LocalApoStaticDialog
        open={openLocalApoDialog}
        setOpen={setOpenLocalApoDialog}
      />
      <RemoteApoStaticDialog
        open={openRemoteApoDialog}
        setOpen={setOpenRemoteApoDialog}
      />
    </Fragment>
  );
}

import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SynckitColors } from "../../assets/SynckitColors";

import { IconArrowiOSForward } from "../../assets/SynckitIcons";

import { createContext } from "react";
import ConsultFullScreenDialog from "./ConsultFullScreenDialog";
import { OPERATION_VIEW_DEFAULT, ORGAN_DEFAULT } from "../../assets/constant";

export const ConsultContext = createContext();
export default function ConsultIndex() {
  const [open, setOpen] = useState(true);
  const [currentExamItem, setCurrentExamItem] = useState(ORGAN_DEFAULT);
  const [currentPartId, setCurrentPartId] = useState("");
  const [operationView, setOperationView] = useState(OPERATION_VIEW_DEFAULT); //default→body→intructions→video

  useEffect(() => {
    if (open) {
      setCurrentExamItem(ORGAN_DEFAULT);
      setCurrentPartId("");
    }
  }, [open]);
  useEffect(() => {
    setCurrentPartId("");
  }, [currentExamItem]);
  return (
    <ConsultContext.Provider
      value={{
        currentExamItem,
        setCurrentExamItem,
        currentPartId,
        setCurrentPartId,
        operationView,
        setOperationView,
      }}
    >
      <IconButton onClick={() => setOpen(true)}>
        <IconArrowiOSForward sx={{ color: SynckitColors.Gray[600] }} />
      </IconButton>
      <ConsultFullScreenDialog open={open} setOpen={setOpen} />
    </ConsultContext.Provider>
  );
}

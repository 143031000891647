import { SynckitColors } from "../../../../assets/SynckitColors";

export const HeadEarSVG = ({ currentPartId = "", handleClick }) => {
  return (
    <svg viewBox="3 0 186 130" stroke="" onClick={handleClick}>
      <path
        d="M129.8,51.7l-3.7,15.5l-0.8,4.9c0,0,0.6,0,1.1,0c0.5,0,1.9,0.2,3.1-0.9c1.1-1.1,2.8-2.5,3.8-5.1
			c1-2.6,1.1-5.2,1-5.4c-0.1-0.2-0.1-3.4-0.4-4.6v-1.7c0,0-0.3-3.4-2.2-3.2L129.8,51.7z"
        id="ear1"
        style={{
          fill: currentPartId === "ear1" ? SynckitColors.Orange[200] : "",
        }}
      />
      <path
        d="M66.8,51.8c0,0,0.6,8.7,1.7,10.4c0,0,1.7,4.7,2.3,6.9l0.6,3.2c0,0-1.3-0.4-1.7,0c0,0-2.1,0.6-4.8-3.4
			c0,0-2.7-4.6-2.4-9l0.6-6c0,0,0.2-3,2.2-2.4L66.8,51.8z"
        id="ear2"
        style={{
          fill: currentPartId === "ear2" ? SynckitColors.Orange[200] : "",
        }}
      />
    </svg>
  );
};

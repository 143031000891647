import { Grid } from "@mui/material";
import React from "react";
import ExamRecordList from "./ExamRecordList";
import ReportCard from "./ReportCard";

const Examrecord = () => {
  return (
    <Grid
      container
      spacing={4}
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      sx={{ marginTop: 2 }}
    >
      <Grid item md={12} lg={8}>
        <ExamRecordList />
      </Grid>
      <Grid item md={12} lg={4}>
        <ReportCard />
      </Grid>
    </Grid>
  );
};

export default Examrecord;

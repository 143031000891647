import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import { SynckitColors } from "../../assets/SynckitColors";

const MainTableHead = styled(TableHead)({
  background: SynckitColors.Gray[200],
  "& .MuiTableCell-root": {
    color: SynckitColors.Gray[700],
    fontSize: 14,
    fontWeight: 700,
  },
});
export default function SynckitTable({ columns, rows, children }) {
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <Table sx={{ background: "white" }}>
      <MainTableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell key={column.id} sx={{ width: column.width }}>
              {column.title}
            </TableCell>
          ))}
        </TableRow>
      </MainTableHead>
      <TableBody>
        {/* {rows.map((row, rIndex) => (
          <TableRow key={rIndex}>
            {columns.map((col, cIndex) => (
              <TableCell key={cIndex}>
                {row.renderCell ? row.renderCell(row) : row[col.field]}
              </TableCell>
            ))}
          </TableRow>
        ))} */}
        {children}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell colSpan={6} sx={{ padding: 0 }}>
            <TablePagination
              rowsPerPageOptions={[8, 16, 32]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
}

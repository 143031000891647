import { Box, Button, styled, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  ORGAN_ABDOMINAL,
  ORGAN_ARTERY,
  ORGAN_EAR,
  ORGAN_HEART,
  ORGAN_LUNG,
  ORGAN_NOSE,
  ORGAN_SKIN,
  ORGAN_TEMPERATURE,
  ORGAN_THEETH,
  ORGAN_THROAT,
} from "../../../assets/constant";
import { OrganColorList, SynckitColors } from "../../../assets/SynckitColors";

import {
  ImgOrganActiveList,
  ImgOrganNormalList,
} from "../../../assets/SynckitImg";

const Root = styled("div")(({ theme }) => ({
  background: "white",
  width: "100%",
  height: 148,
  boxShadow: SynckitColors.Shadow[3],
  borderRadius: 8,
  marginBottom: 20,
  padding: 25,
  [theme.breakpoints.down(1200)]: {
    overflowX: "scroll",
  },
}));

const MenuBox = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  minWidth: 1090,
}));
const ItemCard = styled("div")(({ theme }) => ({
  "& img": {
    width: 72,
  },
  "&  .MuiBadge-root": {
    top: 4,
    right: 4,
    "&  .MuiBadge-badge": {
      background: SynckitColors.Error["Default"],
      color: "white",
      fontWeight: "bold",
      width: 24,
      height: 24,
      borderRadius: "50%",
    },
  },
}));

export default function MenuBar() {
  const [selectedList, setSelectedList] = useState([]);

  return (
    <Root>
      <MenuBox>
        {organMenuList.map((item, index) => (
          <Button
            key={index}
            onClick={() => {
              if (selectedList.includes(item.id)) {
                let tempList = selectedList.filter((val) => val !== item.id);
                setSelectedList([...tempList]);
              } else {
                let tempList = selectedList;
                tempList.push(item.id);
                setSelectedList([...tempList]);
              }
            }}
          >
            <ItemCard>
              {selectedList.includes(item.id)
                ? ImgOrganActiveList[item.organ]
                : ImgOrganNormalList[item.organ]}
              <Typography
                variant="body2"
                sx={{
                  textTransform: "none",
                  color: selectedList.includes(item.id)
                    ? SynckitColors.Gray["core"]
                    : SynckitColors.Gray[700],
                  fontWeight: selectedList.includes(item.id) ? 700 : null,
                }}
              >
                {item.title}
              </Typography>
            </ItemCard>
          </Button>
        ))}
      </MenuBox>
    </Root>
  );
}

const organMenuList = [
  {
    id: 0,
    title: "Teeth",
    organ: ORGAN_THEETH,
    recordCount: 5,
    selected: true,
  },
  { id: 1, title: "Ear", organ: ORGAN_EAR, recordCount: 0, selected: false },
  {
    id: 2,
    title: "B.T",
    organ: ORGAN_TEMPERATURE,
    recordCount: 2,
    selected: true,
  },
  { id: 3, title: "Nose", organ: ORGAN_NOSE, recordCount: 0, selected: false },
  {
    id: 4,
    title: "Throat",
    organ: ORGAN_THROAT,
    recordCount: 3,
    selected: false,
  },
  {
    id: 5,
    title: "Skin&Wound",
    organ: ORGAN_SKIN,
    recordCount: 0,
    selected: false,
  },
  { id: 6, title: "Lung", organ: ORGAN_LUNG, recordCount: 0, selected: false },
  {
    id: 7,
    title: "Heart",
    organ: ORGAN_HEART,
    recordCount: 1,
    selected: false,
  },
  {
    id: 8,
    title: "Abdominal",
    organ: ORGAN_ABDOMINAL,
    recordCount: 0,
    selected: false,
  },
  {
    id: 9,
    title: "Artery",
    organ: ORGAN_ARTERY,
    recordCount: 0,
    selected: false,
  },
];

import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { SynckitColors } from "../../../assets/SynckitColors";
import { IconEditLine, IconTrash2Line } from "../../../assets/SynckitIcons";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import AvatarImg from "../../../assets/images/demo/avatar_doctor1.jpg";
import FemaleIcon from "@mui/icons-material/Female";
import ConfirmDialog from "../../../components/dialog/ConfirmDialog";
import QRcodeDialog from "../../../components/dialog/QRcodeDialog";
import { useNavigate } from "react-router-dom";

const Root = styled("div")(({ theme }) => ({
  minWidth: 320,
  borderRadius: 12,
  minHeight: "fit-content",
  background: "white",
  filter: SynckitColors.Shadow[3],
  padding: "12px 24px",
}));

const ButtonBar = styled("div")(({ theme }) => ({
  textAlign: "right",
  "& svg": { width: 20, height: 20, color: SynckitColors.Gray[700] },
}));

const AvatarCard = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "8% 0",
  height: 160,
}));
export default function ProfileCard() {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openQRcodeDialog, setOpenQRcodeDialog] = useState(false);
  const navigate = useNavigate();

  return (
    <Root>
      <ButtonBar>
        <IconButton onClick={() => setOpenQRcodeDialog(true)}>
          <QrCode2Icon />
        </IconButton>
        <IconButton onClick={() => navigate("/contacts/df/edit")}>
          <IconEditLine />
        </IconButton>
        <IconButton onClick={() => setOpenConfirmDialog(true)}>
          <IconTrash2Line />
        </IconButton>
      </ButtonBar>

      <AvatarCard>
        <Avatar
          src={AvatarImg}
          sx={{
            width: 96,
            height: 96,
          }}
          alt="synckit"
        />
        <Typography variant="h5">Cammy Lytle</Typography>
        <Typography variant="body2">
          G3982958195{" "}
          <FemaleIcon color="error" sx={{ width: 18, height: 18 }} />
        </Typography>
      </AvatarCard>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box
        sx={{ display: "flex", justifyContent: "space-between", mt: 2, mb: 2 }}
      >
        <Typography variant="subtitle2" sx={{ color: SynckitColors.Gray[700] }}>
          Organization
        </Typography>
        <Typography variant="body1">Organization</Typography>
      </Box>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", mt: 2, mb: 2 }}
      >
        <Typography variant="subtitle2" sx={{ color: SynckitColors.Gray[700] }}>
          Division
        </Typography>
        <Typography variant="body1">Division of Cardiology</Typography>
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Typography variant="subtitle2" sx={{ color: SynckitColors.Gray[700] }}>
        Doctor‘s plan
      </Typography>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography variant="subtitle1">Option A</Typography>
        <Typography variant="body2" sx={{ color: SynckitColors.Primary[600] }}>
          50 pts-10 $ USD / a session
        </Typography>
        <Typography variant="body2" sx={{ color: SynckitColors.Gray["core"] }}>
          Single session, 10 min
        </Typography>
      </Box>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography variant="subtitle1">Option B</Typography>
        <Typography variant="body2" sx={{ color: SynckitColors.Primary[600] }}>
          25 pts-5 $ USD / per 5 min.{" "}
        </Typography>
        <Typography variant="body2" sx={{ color: SynckitColors.Gray["core"] }}>
          Usage sensitive pricing
        </Typography>
      </Box>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography variant="subtitle1">Option C</Typography>
        <Typography variant="body2" sx={{ color: SynckitColors.Primary[600] }}>
          200 pts-40 $ USD / a session
        </Typography>
        <Typography variant="body2" sx={{ color: SynckitColors.Gray["core"] }}>
          Single session, unlimited time.
        </Typography>
      </Box>
      <Button variant="primary" fullWidth sx={{ mt: 1, mb: 2 }}>
        Inviting
      </Button>
      <ConfirmDialog
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        title="Sure to delete?"
        content="You will no longer be able to access this doctor’s profile and schedule. The other authorized user will still have the access."
        confirmBtnTitle={"Delete"}
        confirmBtnHandleClick={() => setOpenConfirmDialog(false)}
      />
      <QRcodeDialog open={openQRcodeDialog} setOpen={setOpenQRcodeDialog} />
    </Root>
  );
}

import {
  Avatar,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import React from "react";
import { SynckitDialog } from "./SynckitDialog";
import CloseIcon from "@mui/icons-material/Close";
import { SynckitColors } from "../../assets/SynckitColors";
import AvatarImg from "../../assets/images/demo/avatar_doctor1.jpg";
import QRCodeImg from "../../assets/images/demo/qrcode.png";

const AvatarCard = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "8% 0",
  height: 160,
}));
export default function QRcodeDialog({ open, setOpen }) {
  return (
    <SynckitDialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle
        sx={{
          padding: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span> </span>
        <Typography variant="h5">Scan QR code</Typography>

        <IconButton onClick={() => setOpen(false)}>
          <CloseIcon sx={{ fill: SynckitColors.Gray[600] }} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "10px 30px" }}>
        <AvatarCard>
          <Avatar
            src={AvatarImg}
            sx={{
              width: 96,
              height: 96,
            }}
            alt="synckit"
          />
          <Typography variant="h5">Cammy Lytle</Typography>
          <Typography variant="body2">G3982958195 </Typography>
        </AvatarCard>
        <img src={QRCodeImg} alt="" style={{ maxWidth: 280 }} />
      </DialogContent>
    </SynckitDialog>
  );
}

import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { SynckitColors } from "../../../assets/SynckitColors";
import avatar_patient1 from "../../../assets/images/demo/avatar_patient1.jpg";
import avatar_patient2 from "../../../assets/images/demo/avatar_patient2.jpg";
import avatar_patient3 from "../../../assets/images/demo/avatar_patient3.jpg";
import avatar_patient4 from "../../../assets/images/demo/avatar_patient4.jpg";
import avatar_patient5 from "../../../assets/images/demo/avatar_patient5.jpg";

import {
  Avatar,
  IconButton,
  TableFooter,
  TablePagination,
} from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { IconStarFill, IconStarLine } from "../../../assets/SynckitIcons";
import FemaleIcon from "@mui/icons-material/Female";
import MaleOutlinedIcon from "@mui/icons-material/MaleOutlined";
import { APO_TYPE_REMOTE } from "../../../assets/constant";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: SynckitColors.Gray[200],
    color: SynckitColors.Gray[700],
    fontSize: 14,
    fontWeight: 700,
    height: 36,
    paddingTop: 8,
    paddingBottom: 8,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // "&:nth-of-type(odd)": {
  //   backgroundColor: theme.palette.action.hover,
  // },
  // hide last border
  "&:last-child td, &:last-child th": {
    // border: 0,
  },
}));

function createData(avatar, name, doctor, date, type, divison) {
  return { avatar, name, doctor, date, type, divison };
}

const rows = [
  createData(
    avatar_patient1,
    "Jacob Jones",
    "Dr. Kristin Watson",
    "2021.10.22 · 10:30",
    APO_TYPE_REMOTE,
    "Heart"
  ),
];

const TableRoot = styled(TableContainer)({
  width: "100%",
  padding: 0,
  borderRadius: 12,
  backgroundColor: "white",
  marginBottom: 24,
  filter:
    "drop-shadow(0px 27px 80px rgba(16, 57, 123, 0.05)) drop-shadow(0px 13.6687px 34.875px rgba(16, 57, 123, 0.03375)) drop-shadow(0px 5.4px 13px rgba(16, 57, 123, 0.025)) drop-shadow(0px 1.18125px 4.625px rgba(16, 57, 123, 0.01625))",
});

export default function PatientTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <TableRoot>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ width: 290 }}>Patient</StyledTableCell>
            <StyledTableCell> Doctor</StyledTableCell>
            <StyledTableCell>Date</StyledTableCell>
            <StyledTableCell>Type</StyledTableCell>
            <StyledTableCell>Divison</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name} sx={{ height: 88 }}>
              <StyledTableCell>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Avatar sx={{ marginRight: 2 }} src={row.avatar} />
                  {row.name}
                </div>
              </StyledTableCell>
              <StyledTableCell>{row.doctor}</StyledTableCell>
              <StyledTableCell>{row.date}</StyledTableCell>
              <StyledTableCell>{row.type}</StyledTableCell>
              <StyledTableCell>{row.divison}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={6}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableRoot>
  );
}

import {
  Checkbox,
  FormGroup,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import React from "react";
import TuneIcon from "@mui/icons-material/Tune";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/system";
import { SynckitColors } from "../../assets/SynckitColors";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { SynckitButton } from "../../components/SynckitButton";

const SearchBar = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    paddingTop: 2,
    paddingBottom: 2,
    borderRadius: 8,
    background: "white",
    width: "100%",
    maxWidth: 777,

    "& fieldset": {
      border: `0px solid ${SynckitColors.Primary[300]}`,
    },
    "&:hover fieldset": {
      border: `1px solid ${SynckitColors.Primary[300]}`,
    },
    "&.Mui-focused fieldset": {
      border: `2px solid ${SynckitColors.Primary[300]}`,
    },
  },
}));
const FilterMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    padding: 12,
  },
}));

export default function MessagesSearchBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openFilterMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeFilterMenu = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <SearchBar
        fullWidth
        variant="outlined"
        placeholder="Search"
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: SynckitColors.Gray[600] }} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClick}>
                <TuneIcon sx={{ color: SynckitColors.Gray[600] }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{ border: 0 }}
      />
      <FilterMenu
        anchorEl={anchorEl}
        open={openFilterMenu}
        onClose={closeFilterMenu}
      >
        <MenuItem disableRipple>
          <FormControl component="fieldset">
            <RadioGroup row aria-label="sorting" name="row-radio-buttons-group">
              <FormControlLabel
                value="unread"
                control={<Radio />}
                label="Unread"
              />
              <FormControlLabel value="read" control={<Radio />} label="Read" />
            </RadioGroup>
          </FormControl>
        </MenuItem>
        <MenuItem disableRipple>
          <FormControl component="fieldset">
            <FormLabel component="legend">Type</FormLabel>
            <FormGroup row>
              <FormControlLabel control={<Checkbox />} label="Appointment" />
              <FormControlLabel control={<Checkbox />} label="Contacts" />
              <FormControlLabel control={<Checkbox />} label="Payment" />
              <FormControlLabel control={<Checkbox />} label="Record" />
            </FormGroup>
          </FormControl>
        </MenuItem>

        <MenuItem disableRipple>
          <SynckitButton
            variant="contained"
            sx={{ width: "100%" }}
            onClick={closeFilterMenu}
          >
            Filter
          </SynckitButton>
        </MenuItem>
      </FilterMenu>
    </>
  );
}

import { Box, Button } from "@mui/material";
import React, { useState } from "react";

import { styled } from "@mui/system";

import { IconEditLine, IconTrash2Line } from "../../assets/SynckitIcons";
import MessagesSearchBar from "./MessagesSearchBar";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  marginBottom: 20,
  alignItems: "center",
  [theme.breakpoints.down(1030)]: {
    flexDirection: "column",
    height: 90,
    justifyContent: "space-between",
  },
}));

export default function Toolbar({ isEditMode, setIsEditMode }) {
  const [isSelectAll, setIsSelectAll] = useState(false);
  return (
    <Root>
      <MessagesSearchBar />

      {isEditMode ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Button
            variant="secondary"
            startIcon={
              isSelectAll ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
            }
            onClick={() => setIsSelectAll(!isSelectAll)}
          >
            Selectt all
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="secondary"
            startIcon={<IconTrash2Line />}
            onClick={() => setIsEditMode(false)}
          >
            Delete
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="fifth"
            startIcon={<CheckOutlinedIcon />}
            onClick={() => setIsEditMode(false)}
          >
            Save
          </Button>
        </Box>
      ) : (
        <Button
          variant="primary"
          startIcon={<IconEditLine />}
          onClick={() => setIsEditMode(true)}
        >
          Edit
        </Button>
      )}
    </Root>
  );
}

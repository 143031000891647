import React from "react";
import Typography from "@mui/material/Typography";
import { Box, Button, Divider, styled, Switch } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { SynckitColors } from "../../../assets/SynckitColors";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";

const PlanList = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: 24,
  border: `1px solid ${SynckitColors.Gray[400]}`,
  borderRadius: 8,
  marginBottom: 10,
  maxWidth: 660,
  overflowWrap: "break-word",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const RevenueList = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  maxWidth: 420,
  marginTop: 10,
  marginBottom: 10,
}));

export default function MyService({ setServiceMode }) {
  return (
    <div>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="subtitle1">Public mode</Typography>
        {"　"}
        <Switch />
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Typography variant="subtitle1">My service plan</Typography>
        {"　"}
        <Button
          endIcon={<ArrowForwardIosIcon sx={{ width: 12 }} />}
          sx={{ fontWeight: 700 }}
          onClick={() => setServiceMode("service_plan")}
        >
          Edit
        </Button>
      </Box>
      {planList.map((item) => (
        <PlanList key={item.id}>
          <Box>
            <Typography variant="subtitle1">{item.title}</Typography>
            <Typography variant="body2">{item.subTitle}</Typography>
          </Box>

          <Typography
            variant="body2"
            sx={{ color: SynckitColors.Primary[600] }}
          >
            {item.price}
          </Typography>
        </PlanList>
      ))}

      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="subtitle1">Revenue</Typography>
        {"　"}
        <Button
          endIcon={<ArrowForwardIosIcon sx={{ width: 12 }} />}
          sx={{ fontWeight: 700 }}
        >
          See details
        </Button>
      </Box>
      <RevenueList>
        <Typography variant="body1">Total revenue</Typography>
        <Typography variant="subtitle1"> $250</Typography>
      </RevenueList>
      <RevenueList>
        <Typography variant="body1">Accumulated points revenue</Typography>
        <Box display="flex">
          <PaidOutlinedIcon sx={{ color: SynckitColors.Primary[600], mr: 1 }} />
          <Typography variant="subtitle1">50</Typography>
        </Box>
      </RevenueList>
    </div>
  );
}

const planList = [
  {
    id: 1,
    title: "Examination",
    subTitle: "Single session, 30 min",
    price: "50 pts-10 $ USD / 30 min",
  },
  {
    id: 2,
    title: "Examination+Consultation",
    subTitle: "Usage sensitive pricing",
    price: "25 pts-5 $ USD / per 5 min.",
  },
  {
    id: 3,
    title: "Examination+Consultation+Prescription",
    subTitle: "Single session, unlimited time",
    price: "200 pts-40 $ USD / a session",
  },
];

import IconOrganHeartActive2 from "./images/organ/icon_Organ_heart_active2.png";
import IconOrganTheethActive2 from "./images/organ/icon_Organ_theeth_active2.png";
import IconOrganEarActive2 from "./images/organ/icon_Organ_ear_active2.png";
import IconOrganTemperatureActive2 from "./images/organ/icon_Organ_temperature_active2.png";
import IconOrganNoseActive2 from "./images/organ/icon_Organ_nose_active2.png";
import IconOrganThroatActive2 from "./images/organ/icon_Organ_throat_active2.png";
import IconOrganSkinActive2 from "./images/organ/icon_Organ_skin_active2.png";
import IconOrganLiverActive2 from "./images/organ/icon_Organ_liver_active2.png";
import IconOrganColonActive2 from "./images/organ/icon_Organ_colon_active2.png";
import IconOrganArteryActive2 from "./images/organ/icon_Organ_artery_active2.png";

import IconOrganArteryActive from "./images/organ/icon_Organ_artery_active.png";
import IconOrganArteryNormal from "./images/organ/icon_Organ_artery_normal.png";
import IconOrganArteryUnselect from "./images/organ/icon_Organ_artery_unselect.png";
import IconOrganColonActive from "./images/organ/icon_Organ_colon_active.png";
import IconOrganColonNormal from "./images/organ/icon_Organ_colon_normal.png";
import IconOrganColonUnselect from "./images/organ/icon_Organ_colon_unselect.png";
import IconOrganEarActive from "./images/organ/icon_Organ_ear_active.png";
import IconOrganEarNormal from "./images/organ/icon_Organ_ear_normal.png";
import IconOrganEarUnselect from "./images/organ/icon_Organ_ear_unselect.png";
import IconOrganHeartActive from "./images/organ/icon_Organ_heart_active.png";
import IconOrganHeartNormal from "./images/organ/icon_Organ_heart_normal.png";
import IconOrganHeartUnselect from "./images/organ/icon_Organ_heart_unselect.png";
import IconOrganLiverActive from "./images/organ/icon_Organ_liver_active.png";
import IconOrganLiverNormal from "./images/organ/icon_Organ_liver_normal.png";
import IconOrganLiverUnselect from "./images/organ/icon_Organ_liver_unselect.png";
import IconOrganNoseActive from "./images/organ/icon_Organ_nose_active.png";
import IconOrganNoseNormal from "./images/organ/icon_Organ_nose_normal.png";
import IconOrganNoseUnselect from "./images/organ/icon_Organ_nose_unselect.png";
import IconOrganSkinActive from "./images/organ/icon_Organ_skin_active.png";
import IconOrganSkinNormal from "./images/organ/icon_Organ_skin_normal.png";
import IconOrganSkinUnselect from "./images/organ/icon_Organ_skin_unselect.png";
import IconOrganTemperatureActive from "./images/organ/icon_Organ_temperature_active.png";
import IconOrganTemperatureNormal from "./images/organ/icon_Organ_temperature_normal.png";
import IconOrganTemperatureUnselect from "./images/organ/icon_Organ_temperature_unselect.png";
import IconOrganTheethActive from "./images/organ/icon_Organ_theeth_active.png";
import IconOrganTheethNormal from "./images/organ/icon_Organ_theeth_normal.png";
import IconOrganTheethUnselect from "./images/organ/icon_Organ_theeth_unselect.png";
import IconOrganThroatActive from "./images/organ/icon_Organ_throat_active.png";
import IconOrganThroatNormal from "./images/organ/icon_Organ_throat_normal.png";
import IconOrganThroatUnselect from "./images/organ/icon_Organ_throat_unselect.png";
import IconModalFoot from "./images/organ/modal_foot.png";
import CreditCard from "./images/common/credit_card.png";
import CreditCardVisa from "./images/common/credit_card_visa.png";
import ShoppingCard from "./images/common/shopping_card.png";
import MasterCard from "./images/common/mastercard.png";

export const ImgMasterCard = (props) => (
  <img src={MasterCard} alt="Synckit" {...props} />
);

export const ImgShoppingCard = (props) => (
  <img src={ShoppingCard} alt="Synckit" {...props} />
);

export const ImgCrediCardVisa = (props) => (
  <img src={CreditCardVisa} alt="Synckit" {...props} />
);

export const ImgCrediCard = (props) => (
  <img src={CreditCard} alt="Synckit" {...props} />
);

export const ImgOrganTheethActive2 = (props) => (
  <img src={IconOrganTheethActive2} alt="Synckit" {...props} />
);

export const ImgOrganEarActive2 = (props) => (
  <img src={IconOrganEarActive2} alt="Synckit" {...props} />
);
export const ImgOrganTemperatureActive2 = (props) => (
  <img src={IconOrganTemperatureActive2} alt="Synckit" {...props} />
);
export const ImgOrganNoseActive2 = (props) => (
  <img src={IconOrganNoseActive2} alt="Synckit" {...props} />
);
export const ImgOrganThroatActive2 = (props) => (
  <img src={IconOrganThroatActive2} alt="Synckit" {...props} />
);
export const ImgOrganSkinActive2 = (props) => (
  <img src={IconOrganSkinActive2} alt="Synckit" {...props} />
);
export const ImgOrganLiverActive2 = (props) => (
  <img src={IconOrganLiverActive2} alt="Synckit" {...props} />
);

export const ImgOrganHeartActive2 = (props) => (
  <img src={IconOrganHeartActive2} alt="Synckit" {...props} />
);
export const ImgOrganColonActive2 = (props) => (
  <img src={IconOrganColonActive2} alt="Synckit" {...props} />
);
export const ImgOrganArteryActive2 = (props) => (
  <img src={IconOrganArteryActive2} alt="Synckit" {...props} />
);
export const ImgOrganArteryActive = (props) => (
  <img src={IconOrganArteryActive} alt="Synckit" {...props} />
);

export const ImgOrganColonActive = (props) => (
  <img src={IconOrganColonActive} alt="Synckit" {...props} />
);
export const ImgOrganEarActive = (props) => (
  <img src={IconOrganEarActive} alt="Synckit" {...props} />
);

export const ImgOrganHeartActive = (props) => (
  <img src={IconOrganHeartActive} alt="Synckit" {...props} />
);
export const ImgOrganLiverActive = (props) => (
  <img src={IconOrganLiverActive} alt="Synckit" {...props} />
);

export const ImgOrganNoseActive = (props) => (
  <img src={IconOrganNoseActive} alt="Synckit" {...props} />
);
export const ImgOrganSkinActive = (props) => (
  <img src={IconOrganSkinActive} alt="Synckit" {...props} />
);
export const ImgOrganTemperatureActive = (props) => (
  <img src={IconOrganTemperatureActive} alt="Synckit" {...props} />
);
export const ImgOrganTheethActive = (props) => (
  <img src={IconOrganTheethActive} alt="Synckit" {...props} />
);
export const ImgOrganThroatActive = (props) => (
  <img src={IconOrganThroatActive} alt="Synckit" {...props} />
);

export const ImgOrganArteryNormal = (props) => (
  <img src={IconOrganArteryNormal} alt="Synckit" {...props} />
);

export const ImgOrganColonNormal = (props) => (
  <img src={IconOrganColonNormal} alt="Synckit" {...props} />
);
export const ImgOrganEarNormal = (props) => (
  <img src={IconOrganEarNormal} alt="Synckit" {...props} />
);

export const ImgOrganArteryUnselect = (props) => (
  <img src={IconOrganArteryUnselect} alt="Synckit" {...props} />
);
export const ImgOrganColonUnselect = (props) => (
  <img src={IconOrganColonUnselect} alt="Synckit" {...props} />
);

export const ImgOrganEarUnselect = (props) => (
  <img src={IconOrganEarUnselect} alt="Synckit" {...props} />
);
export const ImgOrganHeartNormal = (props) => (
  <img src={IconOrganHeartNormal} alt="Synckit" {...props} />
);
export const ImgOrganHeartUnselect = (props) => (
  <img src={IconOrganHeartUnselect} alt="Synckit" {...props} />
);
export const ImgOrganLiverNormal = (props) => (
  <img src={IconOrganLiverNormal} alt="Synckit" {...props} />
);
export const ImgOrganLiverUnselect = (props) => (
  <img src={IconOrganLiverUnselect} alt="Synckit" {...props} />
);
export const ImgOrganNoseNormal = (props) => (
  <img src={IconOrganNoseNormal} alt="Synckit" {...props} />
);
export const ImgOrganNoseUnselect = (props) => (
  <img src={IconOrganNoseUnselect} alt="Synckit" {...props} />
);
export const ImgOrganSkinNormal = (props) => (
  <img src={IconOrganSkinNormal} alt="Synckit" {...props} />
);
export const ImgOrganSkinUnselect = (props) => (
  <img src={IconOrganSkinUnselect} alt="Synckit" {...props} />
);
export const ImgOrganTemperatureNormal = (props) => (
  <img src={IconOrganTemperatureNormal} alt="Synckit" {...props} />
);
export const ImgOrganTemperatureUnselect = (props) => (
  <img src={IconOrganTemperatureUnselect} alt="Synckit" {...props} />
);
export const ImgOrganTheethNormal = (props) => (
  <img src={IconOrganTheethNormal} alt="Synckit" {...props} />
);
export const ImgOrganTheethUnselect = (props) => (
  <img src={IconOrganTheethUnselect} alt="Synckit" {...props} />
);
export const ImgOrganThroatNormal = (props) => (
  <img src={IconOrganThroatNormal} alt="Synckit" {...props} />
);
export const ImgOrganThroatUnselect = (props) => (
  <img src={IconOrganThroatUnselect} alt="Synckit" {...props} />
);
export const ImgModalFoot = (props) => (
  <img src={IconModalFoot} alt="Synckit" {...props} />
);

// 依這些constant值定義索引
// export const ORGAN_THEETH = 0;
// export const ORGAN_EAR = 1;
// export const ORGAN_TEMPERATURE = 2;
// export const ORGAN_NOSE = 3;
// export const ORGAN_THROAT = 4;
// export const ORGAN_SKIN = 5;
// export const ORGAN_LUNG = 6;
// export const ORGAN_HEART = 7;
// export const ORGAN_ABDOMINAL = 8;
// export const ORGAN_ARTERY = 9;

export const ImgOrganActive2List = [
  <ImgOrganTheethActive2 style={{ width: 40, height: 40 }} />,
  <ImgOrganEarActive2 style={{ width: 40, height: 40 }} />,
  <ImgOrganTemperatureActive2 style={{ width: 40, height: 40 }} />,
  <ImgOrganNoseActive2 style={{ width: 40, height: 40 }} />,
  <ImgOrganThroatActive2 style={{ width: 40, height: 40 }} />,
  <ImgOrganSkinActive2 style={{ width: 40, height: 40 }} />,
  <ImgOrganLiverActive2 style={{ width: 40, height: 40 }} />,
  <ImgOrganHeartActive2 style={{ width: 40, height: 40 }} />,
  <ImgOrganArteryActive2 style={{ width: 40, height: 40 }} />,
];

export const ImgOrganActiveList = [
  <ImgOrganTheethActive />,
  <ImgOrganEarActive />,
  <ImgOrganTemperatureActive />,
  <ImgOrganNoseActive />,
  <ImgOrganThroatActive />,
  <ImgOrganSkinActive />,
  <ImgOrganLiverActive />,
  <ImgOrganHeartActive />,
  <ImgOrganColonActive />,

  <ImgOrganArteryActive />,
];

export const ImgOrganNormalList = [
  <ImgOrganTheethNormal />,
  <ImgOrganEarNormal />,
  <ImgOrganTemperatureNormal />,
  <ImgOrganNoseNormal />,
  <ImgOrganThroatNormal />,
  <ImgOrganSkinNormal />,
  <ImgOrganLiverNormal />,
  <ImgOrganHeartNormal />,
  <ImgOrganColonNormal />,
  <ImgOrganArteryNormal />,
];

export const ImgOrganUnselectList = [
  <ImgOrganTheethUnselect />,
  <ImgOrganEarUnselect />,
  <ImgOrganTemperatureUnselect />,
  <ImgOrganNoseUnselect />,
  <ImgOrganThroatUnselect />,
  <ImgOrganSkinUnselect />,
  <ImgOrganLiverUnselect />,
  <ImgOrganHeartUnselect />,
  <ImgOrganColonUnselect />,

  <ImgOrganArteryUnselect />,
];

import { IconButton } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import DemoSkinExamination from "../../../assets/demo/skin_examination.png";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  borderRadius: 8,
  overflow: "hidden",
  position: "relative",
}));

const RecordButton = styled(IconButton)({
  position: "absolute",
  right: 12,
  bottom: 72,
  background: "rgba(255, 255, 255, 0.5)",
  padding: 0,

  "& .MuiIconButton-root": {
    padding: 0,
  },
});
const CaptureButton = styled(IconButton)({
  position: "absolute",
  right: 12,
  bottom: 16,
  background: "rgba(255, 255, 255, 0.5)",
  width: 48,
  height: 48,
});

export default function EquipmentView() {
  return (
    <Root>
      <figure>
        <img
          src={DemoSkinExamination}
          alt="Synckit"
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
      </figure>

      <RecordButton>
        <RadioButtonCheckedIcon
          sx={{ width: 48, height: 48, color: "#F16063" }}
        />
      </RecordButton>
      <CaptureButton>
        <PhotoCameraIcon />
      </CaptureButton>
    </Root>
  );
}

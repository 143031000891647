import { Datepicker, Input } from "@mobiscroll/react";
import { Button, styled, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { SynckitColors } from "../../../../assets/SynckitColors";

const Toolbar = styled("div")(({ theme }) => ({
  maxWidth: 360,

  margin: "0px auto 16px",
  padding: "0px 8px",
}));

const ApplyBox = styled("div")(({ theme }) => ({
  background: "white",
  width: 360,
  maxWidth: 980,
  height: 760,
  margin: "0px auto",
  padding: "0px 20px",
  textAlign: "center",
  filter: SynckitColors.Shadow[1],
  borderRadius: 12,
  overflow: "hidden",
  [theme.breakpoints.down(1350)]: {
    width: "100%",
    height: "auto",
  },
}));

const DatetimeWrap = styled("div")(({ theme }) => ({
  margin: "0px auto",

  [theme.breakpoints.down(1350)]: {
    display: "flex",
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const InputWrap = styled("div")(({ theme }) => ({
  marginTop: 32,
  minWidth: 300,
  height: 180,
  padding: "10px 0px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-around",

  "& .demo-date-filtering-range-input": {
    margin: 0,
  },
  "& .mbsc-textfield-box-stacked": {
    border: `1px solid ${SynckitColors.Gray[300]}`,
  },

  "& .mbsc-label-stacked": {
    fontSize: 14,
    fontWeight: "bold",
  },
}));

export default function ApplyPanel() {
  const [start, startRef] = useState(null);
  const [end, endRef] = useState(null);

  return (
    <div>
      <Toolbar>
        <Typography variant="h5" color="initial" sx={{ lineHeight: "42px" }}>
          Apply to
        </Typography>
      </Toolbar>
      <ApplyBox>
        <DatetimeWrap>
          <div>
            {/* <Datepicker
                select="range"
                display="inline"
                touchUi={true}
                colors={[
                  {
                    date: new Date(2021, 11, 12),
                    highlight: SynckitColors.Gray[300],
                  },
                  {
                    date: new Date(2021, 11, 22),
                    highlight: SynckitColors.Gray[300],
                  },
                ]}
                showRangeLabels={false}
                startInput={start}
                endInput={end}
              /> */}
            <Datepicker
              controls={["calendar"]}
              select="range"
              display="inline"
              showRangeLabels={false}
              startInput={start}
              endInput={end}
              returnFormat="iso8601"
              showOnClick={false}
              showOnFocus={false}
              // value={selectedDate}
              // onChange={onDateChange}
              dateFormat="YYYY/MM/DD"
            ></Datepicker>
          </div>
          <InputWrap>
            <Input
              ref={startRef}
              label="Start Date"
              labelStyle="stacked"
              inputStyle="box"
              className="demo-date-filtering-range-input"
              placeholder="YYYY/MM/DD"
            ></Input>
            <Input
              ref={endRef}
              // disabled={disabledInput}
              label="End Date"
              labelStyle="stacked"
              inputStyle="box"
              className="demo-date-filtering-range-input"
              placeholder="YYYY/MM/DD"
            ></Input>
          </InputWrap>
        </DatetimeWrap>
        <Button
          variant="primary"
          fullWidth
          sx={{ margin: "10px 0px", maxWidth: 300 }}
        >
          Add new shift
        </Button>
      </ApplyBox>
    </div>
  );
}

export const SynckitColors = {
  Background: "#F0F0F0",
  Primary: {
    BGC: "#F5F9FB",
    50: "#E3F2FD",
    100: "#BADFFC",
    200: "#8FCCFA",
    300: "#61B7F8",
    400: "#3AA8F7",
    500: "#0599F6",
    600: "#008BE8",
    700: "#0079D5",
    800: "#0068C3",
    900: "#004AA5",
    Dark: "#199AFB",
  },
  MainBackground: "#F5F9FB",

  Gray: {
    100: "#F6F8FB",
    200: "#F6F6F6",
    300: "#F2F2F2",
    400: "#E8E8E8",
    500: "#DBDDE0",
    600: "#CACCCF",
    700: "#A0A4A8",
    800: "#909090",
    900: "#52575C",
    core: "rgba(37, 40, 43, 1)",
  },
  Orange: {
    50: "#FFF3E0",
    100: "#FFDFB2",
    200: "#FFCB80",
    300: "#FFB54D",
    400: "#FFA526",
    500: "#FF9602",
    600: "#FB8A02",
    700: "#F57A03",
    800: "#EF6903",
    900: "#E64E03",
    Gradient: "linear-gradient(95.62deg, #FFF5E8 0%, #FEDCD0 100%)",
  },
  Green: {
    50: "#D8F6F1",
    100: "#9EE9DB",
    200: "#4ADBC4",
    300: "#00C9AA",
    400: "#00BA97",
    500: "#00AB85",
    600: "#009D78",
    700: "#008C67",
    800: "#007C58",
    900: "#005F3B",
    Gradient: "linear-gradient(95.62deg, #EAFBF8 0%, #C0FDF0 100%)",
  },
  Yellow: {
    50: "#FFF8DF",
    100: "#FFEBAF",
    200: "#FFDF7B",
    300: "#FFD442",
    400: "#FFC802",
    500: "#FFBF00",
    600: "#FFB000",
    700: "#FF9D00",
    800: "#FF8B00",
    900: "#FF6900",
    Gradient: "linear-gradient(95.62deg, #FDF9ED 0%, #FEF0C4 100%)",
  },
  Purple: {
    50: "#F3E8FD",
    100: "#DDC6FA",
    200: "#C79FF8",
    300: "#B174F6",
    400: "#9E50F3",
    500: "#8A25EF",
    600: "#7F1FE8",
    700: "#6F11E0",
    800: "#6103DA",
    900: "#4700D3",
    Gradient: "linear-gradient(95.62deg, #F8F0FF 0%, #EDDFFF 100%)",
  },
  Tertiary: {
    100: "#9EE9DB",
    600: "#009D78",
  },
  secondary: {
    300: "#FFB54D",
  },
  Success: {
    Light: "#34EEAE",
    Default: "#16D090",
    Dark: "#03B575",
  },
  Warning: {
    Light: "#FCDF98",
    Default: "#FAC032",
    Dark: "#EFAD0A",
  },
  Error: {
    Light: "#FF7E77",
    Default: "#FF6760",
    Dark: "#E74F48",
  },
  Shadow: {
    1: "drop-shadow(0px 27px 80px rgba(16, 57, 123, 0.05)) drop-shadow(0px 13.6687px 34.875px rgba(16, 57, 123, 0.03375)) drop-shadow(0px 5.4px 13px rgba(16, 57, 123, 0.025)) drop-shadow(0px 1.18125px 4.625px rgba(16, 57, 123, 0.01625))",
    2: " 0px 4px 8px rgba(118, 161, 210, 0.16)",
    3: "0px 27px 80px rgba(16, 57, 123, 0.05), 0px 13.6687px 34.875px rgba(16, 57, 123, 0.03375), 0px 5.4px 13px rgba(16, 57, 123, 0.025), 0px 1.18125px 4.625px rgba(16, 57, 123, 0.01625);",
    4: "0px 0px 1px rgba(255, 150, 2, 0.05), 0px 16px 24px rgba(255, 150, 2, 0.1)",
    Orange: "0px 16px 24px rgba(255, 150, 2, 0.1)",
    Green: "0px 16px 24px rgba(0, 212, 173, 0.1)",
    Yellow: "0px 16px 24px rgba(255, 150, 2, 0.1)",
    Purple: "0px 16px 24px rgba(138, 37, 239, 0.1)",
    ButtomDivider: "inset 0px -1px 0px rgba(133, 133, 133, 0.1)",
  },
  ButtonDiver: "inset 0px -1px 0px rgba(133, 133, 133, 0.1)",
};

// export const ORGAN_THEETH = 0;
// export const ORGAN_EAR = 1;
// export const ORGAN_TEMPERATURE = 2;
// export const ORGAN_NOSE = 3;
// export const ORGAN_THROAT = 4;
// export const ORGAN_SKIN = 5;
// export const ORGAN_LUNG = 6;
// export const ORGAN_HEART = 7;
// export const ORGAN_ABDOMINAL = 8;
// export const ORGAN_ARTERY = 9;
// export const ORGAN_DEFAULT = 10;
// export const EXAM_INSTRUCTION = 11;
export const OrganColorList = [
  "#4A68FF",
  "#FF8B00",
  "#00BB8A",
  "#00BB8A",
  "#00BB8A",
  "#FFD442",
  "#9E50F3",
  "#9E50F3",
  "#9E50F3",
  "#9E50F3",
];

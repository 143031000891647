import {
  Collapse,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
  Dialog,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { SynckitColors } from "../../../../../assets/SynckitColors";

const SynckitDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 12,
    boxShadow: "none",
    margin: 0,
  },
}));
const SynckitDialogTitle = styled(DialogTitle)({
  display: "flex",
  justifyContent: "space-between",
});

const MainTableHead = styled(TableHead)({
  background: "none",
  "& .MuiTableCell-root": {
    color: SynckitColors.Gray[700],
    fontSize: 14,
    fontWeight: 700,
  },
});
const SubTableHead = styled(TableRow)({
  background: "white",
  "&.open": {
    background: SynckitColors.Primary[50],
  },
  "& .MuiTableCell-root": {
    color: SynckitColors.Gray["core"],
    fontSize: 14,
    fontWeight: 700,
  },
});
const TableContent = styled(TableRow)({
  background: SynckitColors.Primary["BGC"],
  width: "100%",
  "& .MuiTableCell-root": {
    // border: "none",
  },
});

const originalRowData = [
  createData(1, "Template 1", "2021/11/08", "Monday, Tuesday, Friday"),
  createData(2, "Template 2", "2021/12/13", "Monday, Tuesday, Friday"),
  createData(3, "Template 3", "2022/01/06", "Monday, Wednesday, Friday"),
  createData(4, "Template 4", "2022/02/08", "Monday, Wednesday, Saturday"),
  createData(5, "Template 5", "2022/03/03", "Monday, Tuesday, Saturday"),
];

export default function TemplateDialog({ setSelectedTemplate }) {
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState(originalRowData);
  const handleSelectTemplate = (rowData) => {
    setOpen(false);
    setSelectedTemplate(rowData);
  };
  const handleDeleteTemplate = (rowId) => {
    let tempRows = rows.filter((rowData) => rowData.id !== rowId);
    setRows([...tempRows]);
  };
  return (
    <Fragment>
      <Button variant="primary" onClick={() => setOpen(true)}>
        Template
      </Button>
      <SynckitDialog open={open} onClose={() => setOpen(false)}>
        <SynckitDialogTitle>
          <Typography variant="h4" color="initial">
            Template
          </Typography>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </SynckitDialogTitle>
        <DialogContent>
          <Table>
            <MainTableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Establish Time</TableCell>
                <TableCell>Chosen day</TableCell>
              </TableRow>
            </MainTableHead>
            <TableBody>
              {rows.map((row) => (
                <Row
                  key={row.name}
                  rowData={row}
                  handleSelectTemplate={() => handleSelectTemplate(row)}
                  handleDeleteTemplate={() => handleDeleteTemplate(row.id)}
                />
              ))}
            </TableBody>
          </Table>
        </DialogContent>
      </SynckitDialog>
    </Fragment>
  );
}

function Row({ rowData, handleSelectTemplate, handleDeleteTemplate }) {
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <SubTableHead
        hover={true}
        sx={{ "& > *": { borderBottom: "unset" } }}
        onClick={() => setOpen(!open)}
        className={open ? "open" : null}
      >
        <TableCell component="th" scope="row">
          {rowData.name}
        </TableCell>
        <TableCell>{rowData.establishTime}</TableCell>
        <TableCell>{rowData.chosenDay}</TableCell>
      </SubTableHead>
      <TableContent>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List>
              {rowData.workTime.map((item) => (
                <ListItem key={item.id}>
                  <ListItemText primary={item.day} secondary={item.interval} />
                </ListItem>
              ))}

              <ListItem sx={{ justifyContent: "flex-end" }}>
                <Button
                  variant="third"
                  sx={{ marginRight: 1 }}
                  onClick={handleDeleteTemplate}
                >
                  Delete
                </Button>
                <Button variant="primary" onClick={handleSelectTemplate}>
                  Use this template
                </Button>
              </ListItem>
            </List>
          </Collapse>
        </TableCell>
      </TableContent>
    </Fragment>
  );
}

function createData(id, name, establishTime, chosenDay) {
  return {
    id,
    name,
    establishTime,
    chosenDay,
    workTime: [
      {
        day: "Monday",
        interval: "08:30-12:00, 13:30-17:30",
      },
      {
        day: "Tuesday",
        interval: "13:30-17:30, 08:30-21:30",
      },
    ],
  };
}

import { Button } from "@mui/material";
import { styled } from "@mui/system";

export const SynckitButton = styled(Button)({
  fontWeight: 700,
  fontSize: 14,
  letterSpacing: 0.2,
  borderRadius: 100,
  width: "fit-content",
  padding: "6px 26px",
  textTransform: "none",
  boxShadow: "none",
});

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import React, { useState, useRef } from "react";
import ImgDemo1 from "../../assets/demo/demo1.jpg";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import { SynckitColors } from "../../assets/SynckitColors";
import {
  IconEditLine,
  IconRefresh2,
  IconTrash2Line,
} from "../../assets/SynckitIcons";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CanvasDraw from "react-canvas-draw";

const Root = styled("div")(({ theme }) => ({
  width: 875,
  height: 516,
  borderRadius: 8,
  overflow: "hidden",
  position: "relative",
  // marginBottom: 24,
}));

const ButtonBox = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "1em",
  right: "1em",
  width: 130,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  zIndex: 20,
}));

const BodyButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "0.5em",
  right: "3.8em",
  zIndex: 10,

  background: "rgba(22, 42, 75, 0.2)",
  width: 48,
  height: 48,
}));

const AccordionButtonBox = styled(Accordion)(({ theme }) => ({
  position: "absolute",
  top: "0.7em",
  right: "0.8em",
  zIndex: 10,
  "&.MuiAccordion-root": {
    background: "rgba(22, 42, 75, 0.2)",
    border: "none",
    boxShadow: "none",
    borderRadius: 100,
    width: 70,
    // height: 48,
    padding: 0,
  },
}));

const PreviewImg = styled("img")(({ theme }) => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
}));

const CommentSection = styled("div")(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  width: "100%",
  padding: "0em 1em 1em 1em",
  "& .MuiOutlinedInput-root": {
    background: "rgba(0, 0, 0, 0.2)",
    color: SynckitColors.Gray[600],
    "& fieldset": {
      borderColor: SynckitColors.Gray[600],
    },
    "&:hover fieldset": {
      borderColor: SynckitColors.Gray[200],
    },
    "&.Mui-focused fieldset": {
      borderColor: SynckitColors.Gray[200],
    },
  },
}));
const ButtonWrap = styled("div")(({ theme }) => ({
  color: SynckitColors.Gray[600],
  "& .MuiButton-root": {
    color: SynckitColors.Gray[600],
    "&.selectedMode": {
      color: SynckitColors.Gray[200],
    },
  },
}));

const CanvasDrawBox = styled(CanvasDraw)(({ theme }) => ({
  background: "red",
  position: "absolute",
}));

export default function EditorSection({ setShowPart }) {
  const [commentMode, setCommentMode] = useState("doctor");
  const [doctorComment, setDoctorComment] = useState("");
  const [patientComment, setPatientComment] = useState("");
  const [drawMode, setDrawMode] = useState(false);
  const [drawRef, setDrawRef] = useState();

  return (
    <Root>
      {/* <ButtonBox> */}
      <BodyButton onClick={() => setShowPart(true)}>
        <AccessibilityNewIcon sx={{ color: "white" }} fontSize="small" />
      </BodyButton>
      <AccordionButtonBox expanded={drawMode}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
          onClick={() => setDrawMode(true)}
        >
          <IconEditLine sx={{ color: "white" }} />
        </AccordionSummary>
        <AccordionDetails>
          <IconButton onClick={() => drawRef.undo()}>
            <IconRefresh2 sx={{ color: "white" }} />
          </IconButton>
          <IconButton
            onClick={() => {
              setDrawMode(false);
              drawRef.clear();
            }}
          >
            <IconTrash2Line sx={{ color: "white" }} />
          </IconButton>
        </AccordionDetails>
      </AccordionButtonBox>
      {/* </ButtonBox> */}
      {drawMode ? (
        <CanvasDrawBox
          brushColor={SynckitColors.Error["Dark"]}
          brushRadius={2}
          hideGrid={true}
          canvasWidth={880}
          canvasHeight={570}
          style={{ background: "rgba(255,255,255,0)" }}
          ref={(e) => setDrawRef(e)}
        />
      ) : (
        <CommentSection>
          <ButtonWrap>
            <Button
              onClick={() => setCommentMode("doctor")}
              className={commentMode === "doctor" ? "selectedMode" : null}
            >
              Doctor
            </Button>
            |
            <Button
              onClick={() => setCommentMode("patient")}
              className={commentMode === "patient" ? "selectedMode" : null}
            >
              Patient
            </Button>
          </ButtonWrap>
          {commentMode === "doctor" ? (
            <TextField
              multiline
              rows={3}
              color="gray"
              fullWidth
              placeholder="Doctor's comment"
              value={doctorComment}
              onChange={(e) => setDoctorComment(e.target.value)}
            />
          ) : (
            <TextField
              multiline
              rows={3}
              color="primary"
              fullWidth
              placeholder="Patient's comment"
              value={patientComment}
              onChange={(e) => setPatientComment(e.target.value)}
            />
          )}
        </CommentSection>
      )}

      <PreviewImg src={ImgDemo1} />
    </Root>
  );
}

import { createSlice } from "@reduxjs/toolkit";
// import { ORGAN_DEFAULT } from "../../assets/constant";

const { actions, reducer: WsData } = createSlice({
  name: "ws",
  initialState: {
    btData: "-",
  },
  reducers: {
    setBtData(state, action) {
      console.log("action", action);
      state.btData = action.payload;
    },
  },
});

export const wsDataState = (state) => state.ws;
export const { setBtData } = actions;
export default WsData;

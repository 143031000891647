import React, { Fragment, useState } from "react";
import { Typography, Divider, Radio, Button, RadioGroup } from "@mui/material";
import { Box, styled } from "@mui/system";
import { SynckitColors } from "../../../../../assets/SynckitColors";
import { IconDoctor } from "../../../../../assets/SynckitIcons";

import SynckitSearchBar2 from "../../../../../components/SynckitSearchBar2";
import ExamRecordEditorDialog from "../../../../exam-record-editor/ExamRecordEditorDialog";
import { useDispatch } from "react-redux";
import { setOpenExamRecordEditor } from "../../../../../redux/slice/examRecord.slice";

const TitleWrap = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: 60,
});

const ERList = styled("ul")(({ theme }) => ({
  background: "white",
  filter: SynckitColors.Shadow[1],
  borderRadius: 8,
  overflow: "hidden",
}));

const ERListItem = styled("li")(({ theme }) => ({
  padding: "20px 24px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "white",
  transition: "0.3s",
  "&:hover": {
    backgroundColor: SynckitColors.Primary[50],
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

export default function SingleReportList() {
  const [selectedId, setSelectedId] = useState(false);

  const dispatch = useDispatch();

  return (
    <Box>
      <TitleWrap>
        <Typography variant="h5">Generate single Record</Typography>
      </TitleWrap>
      <SynckitSearchBar2 />

      <ERList>
        <RadioGroup>
          {Array.from(Array(8), (e, i) => (
            <ERListItem
              key={i}
              onClick={() => dispatch(setOpenExamRecordEditor(true))}
            >
              <Radio
                value={i}
                onClick={(e) => {
                  setSelectedId(i);
                }}
              />
              <IconDoctor color="primary" />
              <Typography variant="body2">G7S24620</Typography>
              <Typography variant="body2">Multiple</Typography>
              <Typography variant="body2">2021.10.22</Typography>
              <Typography variant="body2">10:00</Typography>
            </ERListItem>
          ))}
          <ERListItem sx={{ justifyContent: "center" }}>
            <Button
              variant="primary"
              disabled={!selectedId}
              sx={{ maxWidth: 288, width: "100%" }}
            >
              Export
            </Button>
          </ERListItem>
        </RadioGroup>
      </ERList>
    </Box>
  );
}

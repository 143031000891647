import { Box, Button, MenuItem, TextField, Typography } from "@mui/material";
import React from "react";
import { SettingList } from "./SystemIndex";

export default function Language() {
  return (
    <Box style={{ maxWidth: 600, textAlign: "center" }}>
      <SettingList>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle1">Language</Typography>
          <TextField
            defaultValue={"English"}
            // label="Language"
            select
            size="small"
            sx={{ mt: 3, mb: 2, width: 177 }}
          >
            {languageList.map((item, index) => (
              <MenuItem key={index} value={item.title}>
                {item.title}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </SettingList>
      <Button variant="primary" sx={{ mt: 3 }}>
        Save
      </Button>
    </Box>
  );
}
const languageList = [
  { id: 1, title: "English" },
  { id: 2, title: "简体中文" },
  { id: 3, title: "繁體中文" },
  { id: 4, title: "日本語" },
];

import { IconButton } from "@mui/material";
import { styled } from "@mui/system";
import React, { useContext, useEffect } from "react";
import DemoConsultationDoctor from "../../../assets/demo/consultation_doctor.png";
import VideocamIcon from "@mui/icons-material/Videocam";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import MicOffIcon from "@mui/icons-material/MicOff";
import CropFreeIcon from "@mui/icons-material/CropFree";
import { ConsultContext } from "../ConsultIndex";
import {
  OPERATION_VIEW_DEFAULT,
  OPERATION_VIEW_VIDEO,
} from "../../../assets/constant";

const Root = styled("div")(({ theme }) => ({
  width: 410,
  height: 236,
  borderRadius: 8,
  overflow: "hidden",
  position: "relative",
  background: "black",
}));
const ButtonWrap = styled("div")(({ theme }) => ({
  position: "absolute",
  right: 12,
  bottom: 10,
  height: 216,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
}));

const FunctionButton = styled(IconButton)({
  background: "rgba(255, 255, 255, 0.5)",
  width: 48,
  height: 48,
});

export default function DoctorView() {
  const { operationView } = useContext(ConsultContext);

  const Root = styled("div")(({ theme }) => ({
    width: 410,
    height: 236,
    borderRadius: 8,
    overflow: "hidden",
    position: "relative",
    background: "black",
    pointerEvents: operationView === OPERATION_VIEW_DEFAULT ? "none" : "",
  }));

  const handleVideo = () => {
    console.log("Doctor view handleVideo");
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        const _config = {
          iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
        };
        let _pc = new RTCPeerConnection(_config);
        _pc.ontrack = (_event) => {
          console.log(" _pc.ontrack");
          if (_event.track.kind === "audio") {
            return;
          }
          let doctorVideoEl = document.getElementById("doctorVideo");
          doctorVideoEl.srcObject = _event.streams[0];
          doctorVideoEl.autoplay = "true";
          doctorVideoEl.controls = "true";
        };
      });
  };
  useEffect(() => {
    if (operationView === OPERATION_VIEW_VIDEO) handleVideo();
  }, [operationView]);
  return (
    <Root>
      {/* <figure> */}
      {/* <img
          src={DemoConsultationDoctor}
          alt="Synckit"
          style={{ width: 410, height: 236, objectFit: "cover" }}
        /> */}
      {/* </figure> */}
      <video id="doctorVideo" width="100%" height="100%"></video>
      {/* <ButtonWrap>
        <FunctionButton>
          <VideocamIcon />
        </FunctionButton>
        <FunctionButton>
          <VolumeOffIcon />
        </FunctionButton>
        <FunctionButton>
          <MicOffIcon />
        </FunctionButton>
        <FunctionButton>
          <CropFreeIcon />
        </FunctionButton>
      </ButtonWrap> */}
    </Root>
  );
}

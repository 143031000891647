import { SynckitColors } from "../../../../assets/SynckitColors";

export const ModelBodyFrontMid = ({ currentPartId = "" }) => {
  return (
    <svg viewBox="73 293 194 800" stroke="">
      <path
        d="M210.41 342.229C208.94 336.009 207.47 312.649 207.47 312.649C207.26 306.009 209.15 300.059 209.15 300.059L211.61 293.029H129.06C131.01 299.459 133.08 305.939 133.08 305.939C134.48 309.109 132.34 325.239 132.34 325.239C130.66 334.659 129.73 346.679 129.73 346.679C127.02 353.019 127.12 360.759 124.79 368.309C124.23 370.139 123.51 373.699 122.75 378.029H216.87C214.28 362.419 210.41 342.229 210.41 342.229Z"
        stroke=""
        style={{
          fill: currentPartId === "f4" ? SynckitColors.Yellow[200] : "",
        }}
        id="f4"
      />
      <path
        d="M166.81 423.171C167.06 416.951 165.69 413.481 165.69 413.481C165.41 412.121 167.59 410.641 167.59 410.641C169.06 411.461 173.42 410.341 173.42 410.341C173.89 410.181 174.04 410.541 174.04 410.541C174.14 411.121 174.61 411.511 174.61 411.511C175.17 412.031 175.01 412.721 175.01 412.721C174.59 412.911 173.73 417.221 173.73 417.221C173.18 420.141 173.39 424.831 173.82 429.381H222.6C222.18 422.341 221.09 411.451 221.09 411.451C220.44 406.321 218.53 388.891 218.53 388.891C218.13 385.881 217.54 382.121 216.87 378.051H122.75C120.37 391.611 117.56 412.801 117.56 412.801C117.19 413.991 116.4 423.201 115.91 429.381H166.09C166.56 425.661 166.81 423.171 166.81 423.171Z"
        stroke=""
        style={{
          fill: currentPartId === "f5" ? SynckitColors.Yellow[200] : "",
        }}
        id="f5"
      />
      <path
        d="M113.031 218.859C103.371 224.199 100.831 235.249 100.831 235.249C97.4714 245.319 98.1014 257.699 98.1014 257.699C98.3114 259.519 99.5014 278.049 99.5014 278.049C99.7114 284.339 97.2614 296.509 97.2614 296.509C96.3514 301.609 93.2814 321.329 93.2814 321.329C93.2814 322.799 91.3914 329.259 91.3914 329.259C91.3314 329.459 91.2614 329.659 91.1914 329.879H114.361C114.361 322.179 117.401 308.659 117.401 308.659C118.891 304.089 124.391 282.739 124.391 282.739C124.741 280.159 125.211 280.309 125.501 280.779C125.651 267.249 124.471 238.429 113.031 218.859Z"
        stroke=""
        style={{
          fill: currentPartId === "f6" ? SynckitColors.Yellow[200] : "",
        }}
        id="f6"
      />
      <path
        d="M86.5494 344.909C86.1994 346.599 85.8794 349.139 85.6094 352.029H113.299C115.499 342.149 114.419 331.579 114.419 331.579C114.379 331.049 114.359 330.479 114.359 329.869H91.1994C89.5594 335.029 86.5494 344.909 86.5494 344.909Z"
        stroke=""
        style={{
          fill: currentPartId === "f7" ? SynckitColors.Yellow[200] : "",
        }}
        id="f7"
      />
      <path
        d="M84.2183 374.559C83.9383 380.809 82.1483 397.399 82.1483 397.399C81.8483 399.169 81.2583 405.769 81.2583 405.769C81.2283 406.259 81.2283 406.869 81.2483 407.509H95.7883C95.9483 406.149 96.2483 405.319 96.2483 405.319C96.7483 402.319 103.238 382.759 103.238 382.759L111.438 358.049C112.228 356.139 112.838 354.099 113.298 352.029H85.6083C84.7083 361.429 84.2183 374.559 84.2183 374.559Z"
        stroke=""
        style={{
          fill: currentPartId === "f8" ? SynckitColors.Yellow[200] : "",
        }}
        id="f8"
      />
      <path
        d="M81.6717 413.199C81.8317 414.499 82.0317 417.999 82.0317 417.999L81.8717 427.949C81.6117 430.719 81.6817 434.899 81.6817 434.899C81.8717 437.209 82.1017 438.349 82.1017 438.349C82.3317 438.769 82.5217 441.029 82.5217 441.029L82.9417 448.089C83.0317 449.319 83.3117 450.329 83.3117 450.329L85.2917 457.839C85.5717 459.169 86.3117 460.839 86.3117 460.839C86.9617 462.329 87.5517 463.709 87.5517 463.709C87.7617 464.249 88.0917 465.229 88.0917 465.229C88.8617 466.719 90.1617 464.059 90.1617 464.059C90.5517 463.119 91.0417 463.869 91.0417 463.869C91.1117 464.029 92.2517 466.019 92.2517 466.019C92.7917 467.379 93.2517 467.769 93.2517 467.769C94.8917 468.379 94.8917 465.109 94.8917 465.109C94.8217 463.489 92.8117 460.009 92.8117 460.009C92.0217 458.649 92.0617 457.509 92.0617 457.509C92.2717 456.739 92.7417 457.319 92.7417 457.319C93.0017 458.789 94.8617 460.199 94.8617 460.199C95.0217 460.199 95.0517 460.269 95.0517 460.269C95.8917 461.769 97.2917 463.309 97.2917 463.309C98.3917 464.509 98.8417 462.049 98.8417 462.049C99.0717 459.759 96.2717 456.209 96.2717 456.209C95.8217 455.759 95.7117 455.489 95.7117 455.489C95.1517 452.759 93.6117 449.479 93.6117 449.479C93.1917 448.549 93.6117 448.219 93.6117 448.219C94.4817 448.049 94.6317 448.959 94.6317 448.959C96.0517 451.849 99.5217 455.629 99.5217 455.629C100.922 457.079 101.252 455.169 101.252 455.169C101.692 453.489 99.1817 449.969 99.1817 449.969C98.8517 449.639 98.3717 448.879 98.3717 448.879C97.5317 444.819 96.1317 443.329 96.1317 443.329C96.3217 441.419 95.6617 434.239 95.6617 434.239C95.5717 432.559 95.7517 432.519 95.7517 432.519C96.1717 431.539 96.9417 427.419 96.9417 427.419C98.6917 417.909 96.0617 410.389 96.0617 410.389C95.7517 409.649 95.7117 409.059 95.7117 409.059C95.7117 408.499 95.7517 407.979 95.8017 407.529H81.2617C81.3217 410.029 81.6717 413.199 81.6717 413.199Z"
        stroke=""
        style={{
          fill: currentPartId === "f9" ? SynckitColors.Yellow[200] : "",
        }}
        id="f9"
      />
      <path
        d="M150.69 500.699C151.44 495.109 154.42 482.329 154.42 482.329L161.6 452.779C163.72 445.659 165.24 435.719 166.07 429.369H115.89C115.63 432.609 115.45 435.019 115.45 435.019C115.4 440.009 115.73 460.189 115.73 460.189C115.54 463.869 117.69 484.009 117.69 484.009C118.39 489.789 120.3 501.159 120.3 501.159C120.7 503.749 120.67 506.139 120.51 508.029H150.23C150.48 504.249 150.69 500.699 150.69 500.699Z"
        stroke=""
        style={{
          fill: currentPartId === "f10" ? SynckitColors.Yellow[200] : "",
        }}
        id="f10"
      />
      <path
        d="M215.819 280.99C216.549 279.25 216.979 281.89 216.979 281.89L219.289 292.1C221.599 299.02 225.579 323.35 225.579 323.35C226.119 325.91 226.309 328.1 226.329 329.87H248.829C247.389 325.42 246.479 318.88 246.479 318.88L242.709 287.34C241.589 280.21 242.219 273.85 242.219 273.85C243.479 267.91 243.969 257 243.969 257C244.949 242.1 240.679 232.6 240.679 232.6C237.399 224.61 232.369 220.63 228.649 218.67C216.459 239.73 215.669 269.95 215.819 280.99Z"
        stroke=""
        style={{
          fill: currentPartId === "f14" ? SynckitColors.Yellow[200] : "",
        }}
        id="f14"
      />
      <path
        d="M225.929 334.609C224.799 340.449 225.599 347.199 226.529 352.039H253.639C253.569 350.959 253.519 350.319 253.519 350.319C253.149 340.719 249.139 330.769 249.139 330.769C249.039 330.479 248.929 330.179 248.829 329.869H226.329C226.369 332.879 225.929 334.609 225.929 334.609Z"
        stroke=""
        style={{
          fill: currentPartId === "f15" ? SynckitColors.Yellow[200] : "",
        }}
        id="f15"
      />
      <path
        d="M228.101 358.519C229.151 363.269 236.631 384.459 236.631 384.459L243.441 403.849C243.881 404.949 244.151 406.519 244.291 407.509H257.071C257.031 404.949 256.891 402.849 256.891 402.849C256.981 398.929 255.121 386.489 255.121 386.489C254.841 383.739 254.611 379.849 254.611 379.849C254.961 372.179 254.001 357.099 253.661 352.029H226.551C227.271 355.879 228.101 358.519 228.101 358.519Z"
        stroke=""
        style={{
          fill: currentPartId === "f16" ? SynckitColors.Yellow[200] : "",
        }}
        id="f16"
      />
      <path
        d="M244.399 408.42C244.459 409.08 244.029 410.29 244.029 410.29C242.059 414.06 240.439 419.08 240.439 419.08C239.219 423.42 239.359 432.62 239.359 432.62C239.309 434.44 239.569 439.99 239.569 439.99C239.269 442.11 239.409 445.07 239.409 445.07C239.529 447.4 240.649 449.36 240.649 449.36C240.509 450.78 238.879 452.95 238.879 452.95C238.059 454.14 237.719 455.18 237.719 455.18C237.069 457.24 238.159 457.68 238.159 457.68C238.629 457.87 239.019 457.75 239.109 457.47C239.199 457.19 239.599 456.7 239.599 456.7L241.019 454.77C241.419 454.09 241.839 454.28 241.839 454.28C242.119 455.17 241.419 456.33 241.419 456.33C240.859 457.75 239.209 459.85 239.209 459.85C238.039 461.67 237.969 462.9 237.969 462.9C237.639 464.95 239.299 465.07 239.299 465.07C240.049 465.11 239.699 465.75 239.699 465.75C239.119 467.62 239.899 468.58 239.899 468.58C241.039 470.03 243.099 465.66 243.099 465.66C243.269 465.34 243.629 464.74 243.629 464.74C244.159 464.39 244.369 464.89 244.369 464.89C244.339 466.57 245.109 466.88 245.109 466.88C246.469 468.42 247.239 464.47 247.309 464.01C247.379 463.56 247.799 462.37 247.799 462.37C249.369 460.41 250.559 453 250.559 453C251.259 451.64 251.429 449.75 251.429 449.75C251.459 448.7 251.319 446.99 251.539 446.01C251.749 445.03 252.799 442.02 252.799 442.02C254.689 436.43 255.129 423 255.129 423C255.359 420.06 256.529 415.31 256.529 415.31C257.029 413.48 257.119 410.24 257.079 407.52H244.299C244.359 408.05 244.399 408.42 244.399 408.42Z"
        stroke=""
        style={{
          fill: currentPartId === "f17" ? SynckitColors.Yellow[200] : "",
        }}
        id="f17"
      />
      <path
        d="M181.63 474.811L185.16 492.121C185.83 494.011 186.73 506.141 186.73 506.141C186.75 506.631 186.79 507.291 186.83 508.041H215.8C215.4 503.871 216.13 499.921 216.13 499.921C217.67 494.601 218.93 486.071 218.93 486.071C220.89 476.981 222.01 458.521 222.01 458.521C222.92 450.901 222.76 433.351 222.76 433.351C222.75 432.331 222.69 430.951 222.6 429.381H173.82C174.46 436.001 175.59 442.301 175.59 442.301C176.91 449.351 181.63 474.811 181.63 474.811Z"
        stroke=""
        style={{
          fill: currentPartId === "f18" ? SynckitColors.Yellow[200] : "",
        }}
        id="f18"
      />
    </svg>
  );
};

import { APO_TYPE_LOCAL, APO_TYPE_REMOTE } from "../../../assets/constant";
import { SynckitColors } from "../../../assets/SynckitColors";

export const DemoEventDataList = [
  {
    title: "Local",
    start: "2022-01-12T09:00:00.000Z",
    end: "2022-01-12T10:00:00.000Z",
    id: "mbsc_319",
    color: SynckitColors.Gray[800],
    type: APO_TYPE_REMOTE,
  },
  {
    start: "2022-01-12T10:00:00.000Z",
    end: "2022-01-12T11:00:00.000Z",
    id: "mbsc_329",
    color: SynckitColors.Gray[800],
    title: "Local",
    val: "Local",

    type: APO_TYPE_REMOTE,
  },
  {
    start: "2022-01-12T11:00:00.000Z",
    end: "2022-01-12T12:00:00.000Z",
    id: "mbsc_320",
    color: SynckitColors.Gray[800],
    title: "Local",
    type: APO_TYPE_REMOTE,
  },
  {
    start: "2022-01-12T13:00:00.000Z",
    end: "2022-01-12T14:00:00.000Z",
    id: "mbsc_342",
    color: "white",
    title: "Remote",

    type: APO_TYPE_LOCAL,
  },
  {
    start: "2022-01-12T15:00:00.000Z",
    end: "2022-01-12T16:00:00.000Z",
    id: "mbsc_353",
    color: SynckitColors.Gray[800],
    title: "Local",
    type: APO_TYPE_REMOTE,
  },
  {
    start: "2022-01-15T15:00:00.000Z",
    end: "2022-01-15T16:00:00.000Z",
    id: "mbsc_344",
    color: SynckitColors.Gray[800],
    title: "Local",
    type: APO_TYPE_REMOTE,
  },
  {
    start: "2022-01-15T17:00:00.000Z",
    end: "2022-01-15T18:00:00.000Z",
    id: "mbsc_35",
    color: "white",
    title: "Remote",

    type: APO_TYPE_LOCAL,
  },
  {
    start: "2022-01-16T11:00:00.000Z",
    end: "2022-01-16T12:00:00.000Z",
    id: "mbsc_31",
    color: "white",
    title: "Remote",

    type: APO_TYPE_LOCAL,
  },
  {
    start: "2022-01-23T08:00:00.000Z",
    end: "2022-01-23T09:00:00.000Z",
    id: "mbsc_63",
    color: "white",
    title: "Remote",
    type: APO_TYPE_LOCAL,
  },
  {
    start: "2022-01-23T10:00:00.000Z",
    end: "2022-01-23T11:00:00.000Z",
    id: "mbsc_73",
    color: "white",
    title: "Remote",

    type: APO_TYPE_LOCAL,
  },
  {
    start: "2022-01-23T11:00:00.000Z",
    end: "2022-01-23T12:00:00.000Z",
    id: "mbsc_83",
    color: "white",
    title: "Remote",

    type: APO_TYPE_LOCAL,
  },
];

export const DemoWorkingTimeList = [
  {
    start: "2022-01-12T08:00:00.000Z",
    end: "2022-01-12T12:00:00.000Z",
    id: "mbsc_319",
    type: APO_TYPE_REMOTE,
    schedule: ["08:30-10:00", "11:00-12:00"],
  },
  {
    start: "2022-01-12T13:00:00.000Z",
    end: "2022-01-12T17:00:00.000Z",
    id: "mbsc_329",
    type: APO_TYPE_REMOTE,
    schedule: ["08:30-10:00", "11:00-12:00"],
  },
  {
    start: "2022-01-13T18:00:00.000Z",
    end: "2022-01-13T21:00:00.000Z",
    id: "mbsc_30",
    type: APO_TYPE_REMOTE,
    schedule: ["08:30-10:00", "11:00-12:00"],
  },
  {
    start: "2022-01-15T13:30:00.000Z",
    end: "2022-01-15T17:30:00.000Z",
    id: "mbsc_80",
    type: APO_TYPE_REMOTE,
    schedule: ["08:30-10:00", "11:00-12:00"],
  },

  {
    start: "2022-01-23T13:30:00.000Z",
    end: "2022-01-23T17:30:00.000Z",
    id: "mbsc_33",
    type: APO_TYPE_REMOTE,
    schedule: ["08:30-10:00", "11:00-12:00"],
  },
  {
    start: "2022-01-23T18:30:00.000Z",
    end: "2022-01-23T21:30:00.000Z",
    id: "mbsc_34",
    type: APO_TYPE_REMOTE,
    schedule: ["08:30-10:00", "11:00-12:00"],
  },
  {
    start: "2022-01-24T13:30:00.000Z",
    end: "2022-01-24T17:30:00.000Z",
    id: "mbsc_35",
    type: APO_TYPE_REMOTE,
    schedule: ["08:30-10:00", "11:00-12:00"],
  },
  {
    start: "2022-01-25T18:30:00.000Z",
    end: "2022-01-25T21:00:00.000Z",
    id: "mbsc_36",
    type: APO_TYPE_REMOTE,
    schedule: ["08:30-10:00", "11:00-12:00"],
  },
];

export const DemoWorkingDays = [
  {
    start: "2022-01-12T08:00:00.000Z",
    end: "2022-01-12T17:00:00.000Z",
    background: SynckitColors.Primary[50],
  },
  {
    start: "2022-01-15T13:30:00.000Z",
    end: "2022-01-15T17:30:00.000Z",
    background: SynckitColors.Primary[50],
  },
  {
    start: "2022-01-16T08:00:00.000Z",
    end: "2022-01-16T17:00:00.000Z",
    background: SynckitColors.Primary[50],
  },
  {
    start: "2022-01-20T08:00:00.000Z",
    end: "2022-01-20T17:00:00.000Z",
    background: SynckitColors.Primary[50],
  },
  {
    start: "2022-01-23T08:00:00.000Z",
    end: "2022-01-23T12:00:00.000Z",
    background: SynckitColors.Primary[50],
  },
];

// export const DemoWeeklyShiftsList = [
//   { id: 1, day: "Mon", shifts:[ "08:00AM - 11:30AM",
//    shifts: "13:30PM - 17:30PM" ,
//    shifts: "18:30PM - 21:30PM" ]},
//   { id: 2, day: "Tue", shifts:[ {"08:00AM - 11:30AM" },
//   { shifts: "13:30PM - 17:30PM" },
//   { shifts: "18:30PM - 21:30PM" }]},
//   { id: 3, day: "Wed", shifts:[ {"08:00AM - 11:30AM" },
//   { shifts: "13:30PM - 17:30PM" },
//   { shifts: "18:30PM - 21:30PM" }]},
//   { id: 4, day: "Thu",shifts:[ {"08:00AM - 11:30AM" },
//   { shifts: "13:30PM - 17:30PM" },
//   { shifts: "18:30PM - 21:30PM" }] },
//   { id: 5, day: "Fri", shifts:[ {"08:00AM - 11:30AM" },
//   { shifts: "13:30PM - 17:30PM" },
//   { shifts: "18:30PM - 21:30PM" }] },
//   { id: 6, day: "Sat", shifts:[ {"08:00AM - 11:30AM" },
//   { shifts: "13:30PM - 17:30PM" },
//   { shifts: "18:30PM - 21:30PM" }] },
//   { id: 7, day: "Sun", shifts:[ {"08:00AM - 11:30AM" },
//   { shifts: "13:30PM - 17:30PM" },
//   { shifts: "18:30PM - 21:30PM" }] },
// ];

import { SynckitColors } from "../../../../assets/SynckitColors";

export const HeadNoseSVG = ({ currentPartId = "", handleClick }) => {
  return (
    <svg
      viewBox="6 -70 186 268"
      stroke={SynckitColors.Green[600]}
      onClick={handleClick}
    >
      <path
        d="M99,37.6v40.9c0,0,6.1,2,9.6,0c3.4-2,5.2-10.3,0.6-13.2c0,0-6.9-14-7.5-26.1C101.7,39.2,101,37,99,37.6z"
        id="nose1"
        style={{
          fill: currentPartId === "nose1" ? SynckitColors.Green[200] : "",
        }}
      />
      <path
        d="M99,37.6v40.9c0,0-6.1,2-9.6,0c-3.4-2-5.2-10.3-0.6-13.2c0,0,6.9-14.4,7.5-26.5C96.3,38.9,96.9,37.1,99,37.6z"
        id="nose2"
        style={{
          fill: currentPartId === "nose2" ? SynckitColors.Green[200] : "",
        }}
      />
    </svg>
  );
};

import { createSlice } from "@reduxjs/toolkit";
import { ORGAN_DEFAULT } from "../../assets/constant";

const { actions, reducer: ConsultReducer } = createSlice({
  name: "consult",
  initialState: {
    currentExamItem: ORGAN_DEFAULT,
    currentPartId: "f21",
    isLoading: false,
  },
  reducers: {
    setCurrentExamItem(state, action) {
      state.isLoading = true;
      state.currentExamItem = action.payload;
    },
    setCurrentPartId(state, action) {
      state.currentPartId = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
});

export const consultState = (state) => state.consult;
export const { setIsLoading, setCurrentExamItem, setCurrentPartId } = actions;
export default ConsultReducer;

import { SvgIcon } from "@mui/material";
import { ReactComponent as StarFill } from "./icon/general/icon_Star _Fill.svg";
import { ReactComponent as StarLine } from "./icon/general/icon_Star _Line.svg";
import { ReactComponent as Search } from "./icon/general/icon_Search.svg";
import { ReactComponent as ArrowiOSBackward } from "./icon/general/icon_Arrow iOS Backward.svg";
import { ReactComponent as ArrowiOSForward } from "./icon/general/icon_Arrow iOS Forward.svg";
import { ReactComponent as EditLine } from "./icon/general/icon_Edit_Line.svg";
import { ReactComponent as PlusLine } from "./icon/general/icon_Plus.svg";
import { ReactComponent as Trash2Line } from "./icon/general/icon_Trash 2 _Line.svg";
import { ReactComponent as SaveFill } from "./icon/general/icon_Save _Fill.svg";
import { ReactComponent as SaveLine } from "./icon/general/icon_Save _Line.svg";
import { ReactComponent as AlertCircleLine } from "./icon/general/icon_Alert Circle_Line.svg";
import { ReactComponent as MoreVertical } from "./icon/general/icon_More Vertical.svg";
import { ReactComponent as MessageSquareLine } from "./icon/general/icon_Message Square_Line.svg";
import { ReactComponent as CloseCircleLine } from "./icon/general/icon_Close Circle_Line.svg";
import { ReactComponent as FileTextLine } from "./icon/general/icon_File Text_Line.svg";
import { ReactComponent as OrganTemperatureNormal } from "./icon/organ/icon_Organ_temperature_normal.svg";
import { ReactComponent as HomeLine } from "./icon/general/icon_Home_Line.svg";
import { ReactComponent as CalendarLIne } from "./icon/general/icon_Calendar_Line.svg";
import { ReactComponent as Patient } from "./icon/general/icon_patient.svg";
import { ReactComponent as PeopleLine } from "./icon/general/icon_People_Line.svg";
import { ReactComponent as Doctor } from "./icon/general/icon_doctor.svg";
import { ReactComponent as Settings2 } from "./icon/general/icon_Settings 2 _Line.svg";
import { ReactComponent as Appointment } from "./icon/general/appoinment.svg";
import { ReactComponent as Shifts } from "./icon/general/shifts.svg";
import { ReactComponent as ClockLine } from "./icon/general/icon_Clock_Line.svg";
import { ReactComponent as Leave } from "./icon/general/icon_leave.svg";
import { ReactComponent as PinLine } from "./icon/general/icon_Pin_Line.svg";
import { ReactComponent as DownloadSVG } from "./icon/general/icon_Download.svg";
import { ReactComponent as UploadSVG } from "./icon/general/icon_Upload.svg";
import { ReactComponent as VideoFillSVG } from "./icon/general/icon_Video_Fill.svg";
import { ReactComponent as Refresh2SVG } from "./icon/general/icon_Refresh2.svg";
import { ReactComponent as PersonFillSVG } from "./icon/general/icon_Person_Fill.svg";
import { ReactComponent as AwardFillSVG } from "./icon/general/icon_Award_Fill.svg";
import { ReactComponent as PhoneFillSVG } from "./icon/general/icon_Phone_Fill.svg";
import { ReactComponent as BriefcaseFillSVG } from "./icon/general/icon_Briefcase_Fill.svg";
import { ReactComponent as MedcialSVG } from "./icon/general/icon_medcial.svg";
import { ReactComponent as LogInSVG } from "./icon/general/icon_Log In.svg";
import { ReactComponent as LogOutSVG } from "./icon/general/icon_Log Out.svg";
import { ReactComponent as EyeOffSVG } from "./icon/general/icon_Eye Off 2.svg";
import { ReactComponent as PaperPlaneSVG } from "./icon/general/icon_Paper Plane.svg";

export const IconPaperPlane = (props) => (
  <SvgIcon component={PaperPlaneSVG} {...props} />
);

export const IconEyeOff = (props) => (
  <SvgIcon component={EyeOffSVG} {...props} />
);

export const IconOut = (props) => <SvgIcon component={LogOutSVG} {...props} />;

export const IconLogIn = (props) => <SvgIcon component={LogInSVG} {...props} />;
export const IconMedcial = (props) => (
  <SvgIcon component={MedcialSVG} {...props} />
);
export const IconBriefcaseFill = (props) => (
  <SvgIcon component={BriefcaseFillSVG} {...props} />
);

export const IconPhoneFill = (props) => (
  <SvgIcon component={PhoneFillSVG} {...props} />
);

export const IconAwardFill = (props) => (
  <SvgIcon component={AwardFillSVG} {...props} />
);

export const IconClockLine = (props) => (
  <SvgIcon component={ClockLine} {...props} />
);
export const IconStarFill = (props) => (
  <SvgIcon component={StarFill} {...props} />
);
export const IconStarLine = (props) => (
  <SvgIcon component={StarLine} {...props} />
);
export const IconSearch = (props) => <SvgIcon component={Search} {...props} />;

export const IconArrowiOSBackward = (props) => (
  <SvgIcon component={ArrowiOSBackward} {...props} />
);
export const IconArrowiOSForward = (props) => (
  <SvgIcon component={ArrowiOSForward} {...props} />
);
export const IconEditLine = (props) => (
  <SvgIcon component={EditLine} {...props} />
);
export const IconPlusLine = (props) => (
  <SvgIcon component={PlusLine} {...props} />
);

export const IconTrash2Line = (props) => (
  <SvgIcon component={Trash2Line} {...props} />
);

export const IconSaveFill = (props) => (
  <SvgIcon component={SaveFill} {...props} />
);

export const IconSaveLine = (props) => (
  <SvgIcon component={SaveLine} {...props} />
);

export const IconAlertCircleLine = (props) => (
  <SvgIcon component={AlertCircleLine} {...props} />
);

export const IconMoreVertical = (props) => (
  <SvgIcon component={MoreVertical} {...props} />
);

export const IconMessageSquareLine = (props) => (
  <SvgIcon component={MessageSquareLine} {...props} />
);

export const IconCloseCircleLine = (props) => (
  <SvgIcon component={CloseCircleLine} {...props} />
);
export const IconFileTextLine = (props) => (
  <SvgIcon component={FileTextLine} {...props} />
);
export const IconOrganTemperatureNormal = (props) => (
  <SvgIcon
    component={OrganTemperatureNormal}
    {...props}
    viewBox={"0 0 41 41"}
  />
);

export const IconHomeLine = (props) => (
  <SvgIcon component={HomeLine} {...props} />
);
export const IconCalendarLine = (props) => (
  <SvgIcon component={CalendarLIne} {...props} />
);
export const IconPatient = (props) => (
  <SvgIcon component={Patient} {...props} />
);
export const IconPeopleLine = (props) => (
  <SvgIcon component={PeopleLine} {...props} />
);
export const IconDoctor = (props) => <SvgIcon component={Doctor} {...props} />;
export const IconSettings2 = (props) => (
  <SvgIcon component={Settings2} {...props} />
);
export const IconAppointment = (props) => (
  <SvgIcon component={Appointment} {...props} />
);
export const IconShifts = (props) => <SvgIcon component={Shifts} {...props} />;

export const IconLeave = (props) => (
  <SvgIcon component={Leave} {...props} viewBox={"0 0 16 16"} />
);
export const IconPinLine = (props) => (
  <SvgIcon component={PinLine} {...props} />
);
export const IconDownload = (props) => (
  <SvgIcon component={DownloadSVG} {...props} />
);
export const IconUpload = (props) => (
  <SvgIcon component={UploadSVG} {...props} />
);
export const IconVideoFill = (props) => (
  <SvgIcon component={VideoFillSVG} {...props} />
);
export const IconRefresh2 = (props) => (
  <SvgIcon component={Refresh2SVG} {...props} />
);
export const IconPersonFill = (props) => (
  <SvgIcon component={PersonFillSVG} {...props} />
);

import styled from "@emotion/styled";
import { Box, Typography, Grid } from "@mui/material";
import React, { useState } from "react";
import {
  CR_MODE_PREVIEW_IMG,
  CR_MODE_PREVIEW_VIDEO,
  CR_MODE_PREVIEW_VIDEO_DEFAULT,
} from "../../../../../assets/constant";
import { demoImgList } from "../../../../../assets/demoData";
import { SynckitColors } from "../../../../../assets/SynckitColors";
import { IconVideoFill } from "../../../../../assets/SynckitIcons";

const Root = styled("div")(({ theme }) => ({
  width: 290,
  height: 840,
  borderRadius: 8,
  boxShadow: SynckitColors.Shadow[3],
  overflow: "hidden",
}));

const TitleSection = styled("div")(({ theme }) => ({
  background: "white",
  height: 58,
  padding: "16px 24px",
  boxShadow: SynckitColors.Shadow["ButtomDivider"],
}));
const GallerySection = styled("div")(({ theme }) => ({
  padding: "16px 24px",
  background: "rgba(255, 255, 255, 0.8)",
  height: 782,
  width: 290,
  overflowY: "scroll",
  overflowX: "hidden",
}));

const GalleryCard = styled("div")(({ theme }) => ({
  marginBottom: 16,
}));

const GalleryCardHeader = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  height: 32,
}));
const GalleryCardBody = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "flex-start",
  width: 242,
}));
const ImgCard = styled("div")({
  width: 72,
  height: 72,
  borderRadius: 8,
  marginRight: 8,
  marginBottom: 8,
  position: "relative",
  cursor: "pointer",
  outline: `3px solid white`,
  transition: "0.3s",
  "&.selected": {
    outline: `3px solid ${SynckitColors.Primary[600]}`,
  },
  "& div": {
    position: "absolute",
    color: "white",
    bottom: 4,
    right: 8,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: 52,
  },
});
export default function RecordGallery({ setDisplayMode }) {
  const [recordCount, setRecordCount] = useState(5);
  const [selectId, setSelectId] = useState(1);
  return (
    <Root>
      <TitleSection>
        <Typography varient="h5" sx={{ fontWeight: 700 }}>
          {`Record（${recordCount}）`}
        </Typography>
      </TitleSection>
      <GallerySection>
        {Array.from(Array(5), (e, i) => (
          <GalleryCard key={i}>
            <GalleryCardHeader>
              <Typography variant="subtitle1">2021.10.22 10:00</Typography>
              <Typography variant="body2">G7S24620</Typography>
            </GalleryCardHeader>
            <GalleryCardBody>
              {demoImgList
                .slice(0, demoImgList.length - i)
                .map((item, index) => (
                  <ImgCard
                    key={index}
                    className={selectId === `${i}.${index}` ? "selected" : null}
                    onClick={() => {
                      setSelectId(`${i}.${index}`);
                      item.duration
                        ? setDisplayMode(CR_MODE_PREVIEW_VIDEO_DEFAULT)
                        : setDisplayMode(CR_MODE_PREVIEW_IMG);
                    }}
                  >
                    <figure>
                      <img
                        src={item.imgUrl}
                        alt={item.id}
                        style={{
                          width: 72,
                          height: 72,
                          objectFit: "cover",
                        }}
                      />
                    </figure>
                    {item.duration && (
                      <div>
                        <IconVideoFill fontSize="10px" />
                        <Typography variant="small2">
                          {item.duration}
                        </Typography>
                      </div>
                    )}
                  </ImgCard>
                ))}
            </GalleryCardBody>
          </GalleryCard>
        ))}
      </GallerySection>
    </Root>
  );
}

import { ThemeProvider } from "@mui/material";
import { SynckitTheme } from "./assets/SynckitTheme";
import DateAdapter from "@mui/lab/AdapterDateFns";
import Layout from "./layout/Layout";
import { LocalizationProvider } from "@mui/lab";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import { synckitRoutes } from "./assets/SynckitRoutes";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";

function App() {
  return (
    <div>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <ThemeProvider theme={SynckitTheme}>
            <BrowserRouter>
              <Layout>
                <Routes>
                  {synckitRoutes.map((item, index) => (
                    <Route
                      key={index}
                      path={item.path}
                      element={item.component}
                    />
                  ))}
                </Routes>
              </Layout>
            </BrowserRouter>
          </ThemeProvider>
        </LocalizationProvider>
      </Provider>
    </div>
  );
}

export default App;

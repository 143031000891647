import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  SvgIcon,
  Typography,
  CircularProgress,
  DialogContent,
} from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as MoreSVG } from "../../assets/icon/general/icon_More Horizontal.svg";

import CloseIcon from "@mui/icons-material/Close";
import avatar_patient1 from "../../assets/images/demo/avatar_patient1.jpg";

import FemaleIcon from "@mui/icons-material/Female";
import { styled } from "@mui/system";
import { format } from "date-fns";

const PatientNameAndGender = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  height: 32,
  marginTop: 16,
  paddingLeft: 18,
});

const CustomizeCard = styled(Card)(({ theme }) => ({
  minWidth: 460,
  transition: "0.3s",
  overflowY: "auto",
  [theme.breakpoints.down("sm")]: {
    minWidth: "auto",
  },
}));
const CustomizeCardContent = styled(CardContent)(({ theme }) => ({
  transition: "0.3s",
  padding: "0px 8px 0px 22px",
  [theme.breakpoints.down("sm")]: {
    padding: "0px 10px",
  },
}));
const CustomizeCardHeader = styled(CardHeader)(({ theme }) => ({
  transition: "0.3s",
  [theme.breakpoints.down("sm")]: {
    "& .MuiTypography-root": {
      fontSize: "1em",
    },
  },
}));

const CustomizeListItem = styled(ListItem)(({ theme }) => ({
  padding: 0,
}));

export default function LocalApoDialog({
  open,
  setOpen,
  apoInfo,
  isLoadingApoInfo,
}) {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const openMenu = Boolean(menuAnchorEl);

  return (
    <>
      <Dialog onClose={() => setOpen(false)} open={open}>
        {isLoadingApoInfo ? (
          <DialogContent>
            <CircularProgress />
          </DialogContent>
        ) : (
          <CustomizeCard>
            <CustomizeCardHeader
              avatar={
                <IconButton onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
                  <SvgIcon component={MoreSVG} />
                </IconButton>
              }
              title="Local Session Appointment"
              titleTypographyProps={{
                variant: "h5",
                sx: { textAlign: "center" },
              }}
              action={
                <IconButton
                  sx={{ marginLeft: 1 }}
                  onClick={() => setOpen(false)}
                >
                  <CloseIcon />
                </IconButton>
              }
            />
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar
                alt=""
                src={apoInfo.patient.avatar}
                sx={{ width: 60, height: 60 }}
              />
              <PatientNameAndGender>
                <Typography variant="subtitle1" color="initial">
                  {`${apoInfo.patient?.first_name} ${apoInfo.patient?.last_name}`}
                </Typography>
                &nbsp;
                <FemaleIcon color="error" />
              </PatientNameAndGender>
              <Typography variant="body2" color="initial">
                {apoInfo.patient.no}
              </Typography>
            </CardContent>
            <Divider />
            <CustomizeCardContent>
              <List>
                <CustomizeListItem>
                  <ListItemText
                    primary="Date"
                    secondary={format(
                      new Date(apoInfo.appointed_date),
                      "yyyy/MM/dd"
                    )}
                    primaryTypographyProps={{ variant: "subtitle1" }}
                    secondaryTypographyProps={{ variant: "body1" }}
                  />
                </CustomizeListItem>
                <CustomizeListItem>
                  <ListItemText
                    primary="Time"
                    secondary={
                      apoInfo.appointed_time_start.split(":", 2).join(":") +
                      " ~ " +
                      apoInfo.appointed_time_end.split(":", 2).join(":")
                    }
                    // "9:30 ~ 10:00 ( 30 min. )"
                    primaryTypographyProps={{ variant: "subtitle1" }}
                    secondaryTypographyProps={{ variant: "body1" }}
                  />
                </CustomizeListItem>
              </List>
            </CustomizeCardContent>
          </CustomizeCard>
        )}
      </Dialog>
      <Menu
        open={openMenu}
        anchorEl={menuAnchorEl}
        onClose={() => setMenuAnchorEl(false)}
      >
        <MenuItem onClick={() => setMenuAnchorEl(false)}>
          Examination Record
        </MenuItem>
        <MenuItem onClick={() => setMenuAnchorEl(false)}>Cancel</MenuItem>
      </Menu>
    </>
  );
}

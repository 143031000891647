import React from "react";
import InfoCard from "./InfoCard";
import LatestExamination from "./LatestExamination";
import Grid from "@mui/material/Grid";

import Toolbar from "../management/Toolbar";
import ApoIndex from "./apo/ApoIndex";
import ExamRecordIndex from "./exam-record/ExamRecordIndex";

export default function PatientIndex() {
  return (
    <Grid
      container
      spacing={3}
      direction="row"
      justify="center"
      alignItems="center"
      alignContent="center"
      sx={{ marginBottom: 3 }}
    >
      <Grid item xs={12}>
        <Toolbar />
      </Grid>
      <Grid item xs={12} lg={4}>
        <InfoCard />
      </Grid>
      <Grid item xs={12} lg={8}>
        <LatestExamination />
      </Grid>
      <Grid item xs={12}>
        <ApoIndex />
      </Grid>
      <Grid item xs={12}>
        <ExamRecordIndex />
      </Grid>
    </Grid>
  );
}

import { Box } from "@mui/material";
import React, { Fragment, useContext } from "react";
import { useEffect } from "react";
import {
  OPERATION_VIEW_BODY,
  OPERATION_VIEW_INSTRUCTIONS,
  OPERATION_VIEW_VIDEO,
  ORGAN_ABDOMINAL,
  ORGAN_ARTERY,
  ORGAN_EAR,
  ORGAN_HEART,
  ORGAN_LUNG,
  ORGAN_NOSE,
  ORGAN_SKIN,
  ORGAN_TEMPERATURE,
  ORGAN_THEETH,
  ORGAN_THROAT,
} from "../../../assets/constant";
import { ConsultContext } from "../ConsultIndex";
import InstructionNav from "../operation-section/InstructionNav";
import InstructionsDefault from "./InstructionsDefault";
import InstructionsDentalProbe from "./InstructionsDentalProbe";
import InstructionsDepressor from "./InstructionsDepressor";
import InstructionsDermatoscope from "./InstructionsDermatoscope";
import InstructionsEarSpecula from "./InstructionsEarSpecula";
import InstructionsStethoscope from "./InstructionsStethoscope";

export default function InstructionsIndex() {
  const { operationView, setOperationView, currentExamItem } =
    useContext(ConsultContext);
  useEffect(() => {
    if (operationView === OPERATION_VIEW_INSTRUCTIONS)
      setTimeout(() => setOperationView(OPERATION_VIEW_VIDEO), 2000);
  }, [operationView]);
  return (
    <Fragment>
      {operationView === OPERATION_VIEW_BODY && <InstructionNav />}
      {operationView === OPERATION_VIEW_INSTRUCTIONS && (
        <Box sx={{ width: "100%", height: "100%" }}>
          {currentExamItem === ORGAN_SKIN && <InstructionsDermatoscope />}
          {currentExamItem === ORGAN_EAR && <InstructionsEarSpecula />}
          {[ORGAN_THROAT, ORGAN_NOSE].includes(currentExamItem) && (
            <InstructionsDepressor />
          )}
          {currentExamItem === ORGAN_THEETH && <InstructionsDentalProbe />}

          {[ORGAN_HEART, ORGAN_ARTERY, ORGAN_ABDOMINAL, ORGAN_LUNG].includes(
            currentExamItem
          ) && <InstructionsStethoscope />}
          {currentExamItem === ORGAN_TEMPERATURE && <InstructionsDefault />}
        </Box>
      )}
    </Fragment>
  );
}

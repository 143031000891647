import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { SynckitColors } from "../../../../assets/SynckitColors";
import avatar_patient1 from "../../../../assets/images/demo/avatar_patient1.jpg";

import { Avatar, IconButton } from "@mui/material";
import { IconStarFill } from "../../../../assets/SynckitIcons";
import FemaleIcon from "@mui/icons-material/Female";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: SynckitColors.Gray[200],
    color: SynckitColors.Gray[700],
    fontSize: 14,
    fontWeight: 700,
    height: 36,
    paddingTop: 8,
    paddingBottom: 8,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // "&:nth-of-type(odd)": {
  //   backgroundColor: theme.palette.action.hover,
  // },
  // hide last border
  "&:last-child td, &:last-child th": {
    // border: 0,
  },
}));

function createData(favorite, avatar, name, gender, birthday, pid, nhi) {
  return { favorite, avatar, name, gender, birthday, pid, nhi };
}

const rows = [
  createData(
    <IconButton>
      <IconStarFill sx={{ color: SynckitColors.secondary[300] }} />
    </IconButton>,
    avatar_patient1,
    "Jacob Jones",
    <FemaleIcon color="error" />,
    "1982.07.12",
    "G1233447890",
    "A158692830"
  ),
];

const TableRoot = styled(TableContainer)({
  width: "100%",
  padding: 0,
  borderRadius: 12,
  backgroundColor: "white",
  marginBottom: 24,
  filter:
    "drop-shadow(0px 27px 80px rgba(16, 57, 123, 0.05)) drop-shadow(0px 13.6687px 34.875px rgba(16, 57, 123, 0.03375)) drop-shadow(0px 5.4px 13px rgba(16, 57, 123, 0.025)) drop-shadow(0px 1.18125px 4.625px rgba(16, 57, 123, 0.01625))",
});

export default function OwnTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const navigate = useNavigate();

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <TableRoot>
      <Table>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow
              key={row.name}
              sx={{ height: 88 }}
              onClick={() => navigate("/pm/patient")}
              hover
            >
              <StyledTableCell sx={{ width: 84 }}>
                {row.favorite}
              </StyledTableCell>

              <StyledTableCell sx={{ width: 290 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Avatar sx={{ marginRight: 2 }} src={row.avatar} />
                  {row.name}
                </div>
              </StyledTableCell>
              <StyledTableCell>{row.gender}</StyledTableCell>
              <StyledTableCell>{row.birthday}</StyledTableCell>
              <StyledTableCell>{row.pid}</StyledTableCell>
              <StyledTableCell>{row.nhi}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableRoot>
  );
}

import { SynckitColors } from "../../../../assets/SynckitColors";

export const AbdominalSVG = ({ currentPartId = "", handleClick }) => {
  return (
    <svg
      viewBox="0 -11 106 116"
      stroke="#8A25EF"
      stroke-width="0.2"
      onClick={handleClick}
    >
      <path
        d="M90 44.3299C89.18 38.9499 88.39 33.3299 88.39 33.3299C88 28.9399 87 17.0799 87 17.0799C86.23 6.45988 88.69 0.379883 88.69 0.379883H49.38V44.3299H90Z"
        id="abdominal_luq"
        style={{ fillOpacity: currentPartId === "abdominal_luq" ? 0.5 : null }}
      />
      <path
        d="M49.3599 0.379883H10.7999L11.7099 3.47988C12.5699 5.84988 13.2999 12.3699 13.2999 12.3699C13.5899 17.8299 9.88994 41.3699 9.88994 41.3699C9.75994 42.3699 9.59994 43.3699 9.43994 44.3699H49.3599V0.379883Z"
        id="abdominal_ruq"
        style={{ fillOpacity: currentPartId === "abdominal_ruq" ? 0.5 : null }}
      />
      <path
        d="M91.48 53.0799C91.14 51.5799 90.57 48.0799 90.01 44.3799H49.38V89.9999H97.65C97.4 83.6199 91.48 53.0799 91.48 53.0799Z"
        id="abdominal_llq"
        style={{ fillOpacity: currentPartId === "abdominal_llq" ? 0.5 : null }}
      />
      <path
        d="M9.5,44.4c-0.4,2.7-0.9,5-0.9,5C7.1,54.3,0.9,90,0.9,90h48.4V44.4H9.5z"
        id="abdominal_rlq"
        style={{ fillOpacity: currentPartId === "abdominal_rlq" ? 0.5 : null }}
      />
    </svg>
  );
};

import { SynckitColors } from "../../../../assets/SynckitColors";

export const AdultTeethSVGLower = ({ currentPartId = "" }) => {
  return (
    <svg viewBox="4 63 186 65" stroke={""}>
      <path
        d="M87,116.6c0.2-0.9,0.1-1.8-0.3-2.6c-0.7-1.4-2.1-1.6-3.2-0.4c-0.9,1-1.4,2.2-1.7,3.5c-0.1,0.5,0.1,0.9,0.6,1
		c0.4,0.1,0.8,0.1,1.2,0.1c0.9,0,1.7-0.1,2.5-0.5C86.5,117.4,86.9,117.1,87,116.6z"
        style={{
          fill:
            currentPartId === "teeth_adult_43"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_adult_43"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_adult_43"
      />
      <path
        d="M83.9,110.9c-0.4-1-0.8-1.9-1.3-2.8c-1.1-1.8-2.6-2.1-4.4-1c-0.9,0.6-1.3,1.3-1.1,2.4c0.2,1.3,0.7,2.4,1.5,3.5
		c0.5,0.7,1.2,1.1,2.1,1.1c1.1-0.1,1.9-0.7,2.7-1.4C84,112.2,84.2,111.6,83.9,110.9z"
        style={{
          fill:
            currentPartId === "teeth_adult_44"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_adult_44"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_adult_44"
      />
      <path
        d="M96,118.9c-0.2-0.5-0.3-1-0.6-1.5c-0.4-0.5-0.8-0.5-1.2-0.1c-0.3,0.3-0.5,0.6-0.7,0.9
		c-0.5,0.8-0.9,1.7-1.5,2.4c-0.4,0.4-0.3,0.7,0.2,0.9c0.9,0.5,1.8,0.7,2.8,0.7c0.6,0,1.1-0.1,1.7-0.3c0.3-0.1,0.5-0.3,0.3-0.6
		C96.5,120.6,96.2,119.7,96,118.9z"
        style={{
          fill:
            currentPartId === "teeth_adult_41"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_adult_41"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_adult_41"
      />
      <path
        d="M90.8,118.3c0-0.6,0-1.2-0.2-1.8c-0.2-0.7-0.7-1-1.4-0.8c-0.5,0.1-0.8,0.5-1.1,0.8c-0.5,0.6-1.1,1.2-1.6,1.8
		c-0.4,0.5-0.5,0.7,0,1.1c1.1,1,2.4,1.5,3.7,1.6c1,0,1-0.1,0.9-0.8C90.9,119.6,90.8,119,90.8,118.3z"
        style={{
          fill:
            currentPartId === "teeth_adult_42"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_adult_42"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_adult_42"
      />
      <path
        d="M73,99.5c0.6-0.3,1.2-0.5,1.9-0.6c0.9-0.1,1.1-0.4,1.2-1.3c0-0.3,0-0.5,0-0.8c0,0,0,0,0,0
		c-0.1-0.8-0.2-1.7-0.2-2.5c-0.1-1.7-2.5-3.6-4.4-2.7c-0.5,0.3-1.2,0.3-1.7,0.5c-0.6,0.2-1.1,0.6-1.2,1.3c-0.1,0.8,0,1.7,0.5,2.4
		c0.3,0.4,0.5,0.9,0.6,1.4c0.1,0.5,0.2,1,0.3,1.4c0.3,1.2,1,1.5,2.2,1.3C72.4,99.7,72.7,99.6,73,99.5z"
        style={{
          fill:
            currentPartId === "teeth_adult_46"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_adult_46"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_adult_46"
      />
      <path
        d="M68.7,82.3c1.3-0.2,2.7-0.3,4-0.1c0.1,0,0.3,0,0.4,0c0.9-0.5,1.5-1.1,1.5-2.2c0-0.6,0-1.3,0-1.9
		c0-0.5-0.1-0.9-0.1-1.3c-0.3-1.6-1.1-2.4-3.4-2.7c-1-0.1-2,0-3,0.1c-0.9,0.1-1.5,0.7-1.5,1.7c0,0.6,0,1.3,0,2c0,0.6,0.1,1.3,0.1,2
		c0.1,1,0.5,1.8,1.4,2.4C68.3,82.3,68.5,82.4,68.7,82.3z"
        style={{
          fill:
            currentPartId === "teeth_adult_48"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_adult_48"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_adult_48"
      />
      <path
        d="M100.9,117.9c-0.3-0.4-0.5-0.9-1.1-0.8c-0.6,0.1-0.7,0.6-0.9,1.1c-0.4,1.1-0.6,2.3-1.2,3.4
		c-0.1,0.3,0.1,0.3,0.2,0.4c0.6,0.3,1.2,0.3,1.8,0.3c1,0,1.9-0.2,2.8-0.7c0.2-0.1,0.6-0.3,0.3-0.7C102,120,101.6,118.9,100.9,117.9z
		"
        style={{
          fill:
            currentPartId === "teeth_adult_31"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_adult_31"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_adult_31"
      />
      <path
        d="M69.2,91.6c0.8-0.3,1.7-0.5,2.5-0.8c0.4-0.1,0.8-0.3,1.2-0.2c0.4,0.1,0.6-0.1,0.7-0.5c0.3-1,0.5-2.1,0.4-3.1
		c0-1,0.1-1.9-0.1-2.9c-0.2-1-0.6-1.4-1.6-1.5c-1.1-0.1-2.2,0-3.2,0.1c-0.5,0.1-1,0.2-1.2,0.6c-0.5,0.9-0.9,1.9-0.9,2.9
		c0,0.3,0.1,0.5,0.2,0.8c0.4,0.6,0.6,1.2,0.6,1.9c0,0.9,0.3,1.6,0.9,2.3C68.8,91.6,68.9,91.7,69.2,91.6z"
        style={{
          fill:
            currentPartId === "teeth_adult_47"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_adult_47"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_adult_47"
      />
      <path
        d="M78.2,106.5c0.8-0.2,0.9-0.7,0.9-1.4c0-0.2,0-0.5,0-0.7c0.1-1.7-0.6-3-1.8-4.1c-0.4-0.4-0.9-0.7-1.5-0.8
		c-1.1-0.2-2.1,0.1-3,0.7c-1.5,1.1-1.8,2.2-0.9,3.8c0.5,0.8,0.9,1.7,1.5,2.5c0.4,0.6,0.9,1.1,1.5,1.4c0.8,0.4,1.4,0.2,2-0.5
		C77.3,107,77.7,106.6,78.2,106.5z"
        style={{
          fill:
            currentPartId === "teeth_adult_45"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_adult_45"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_adult_45"
      />
      <path
        d="M128.1,76c0-1.1-0.5-1.6-1.5-1.8c-1.1-0.2-2.2-0.3-3.2-0.1c-2.5,0.5-3,1.3-3.2,3.4c0,0.5,0,0.9,0,1.4
		c0,0,0,0,0,0c0,0.5,0,1,0,1.5c0.1,0.9,1.1,2.1,1.8,2c1.4-0.2,2.7-0.1,4.1,0.1c0.2,0,0.3,0,0.5-0.1c0.7-0.5,1.3-1.2,1.4-2.1
		C128.1,78.7,128.1,77.3,128.1,76z"
        style={{
          fill:
            currentPartId === "teeth_adult_38"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_adult_38"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_adult_38"
      />
      <path
        d="M125.8,82.9c-1.2-0.2-2.3-0.2-3.5-0.1c-0.8,0.1-1.2,0.5-1.4,1.3c-0.2,0.8-0.1,1.7-0.2,2.2c0,1.5,0,2.6,0.3,3.8
		c0.1,0.4,0.2,0.7,0.8,0.6c0.3-0.1,0.6,0,0.9,0.1c0.9,0.3,1.8,0.5,2.7,0.8c0.3,0.1,0.5,0.1,0.7-0.2c0.5-0.6,0.8-1.3,0.8-2.1
		c0-0.6,0-1.2,0.4-1.7c0.5-0.7,0.5-1.4,0.3-2.2c-0.1-0.5-0.3-0.9-0.5-1.4C127,83.4,126.5,83,125.8,82.9z"
        style={{
          fill:
            currentPartId === "teeth_adult_37"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_adult_37"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_adult_37"
      />
      <path
        d="M125.1,92.1c-0.9-0.3-1.7-0.5-2.6-0.7c-0.2-0.1-0.5-0.1-0.7,0c-1.4,0.4-2.6,1.2-2.9,2.7
		c-0.2,0.8-0.2,1.7-0.3,2.6c0,0.4,0,0.9,0.1,1.3c0.1,0.4,0.2,0.9,0.8,0.9c0.9,0.1,1.8,0.4,2.6,0.8c1.5,0.7,2.4,0.2,2.8-1.3
		c0.1-0.2,0.1-0.5,0.2-0.7c0.1-0.7,0.3-1.3,0.8-1.9c0.5-0.6,0.6-1.4,0.4-2.2C126.1,92.7,125.7,92.3,125.1,92.1z"
        style={{
          fill:
            currentPartId === "teeth_adult_36"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_adult_36"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_adult_36"
      />
      <path
        d="M112.6,107.5c-0.9,1.1-1.5,2.4-1.9,3.7c-0.2,0.5,0,0.9,0.3,1.3c0.5,0.5,1,1,1.7,1.3c0.9,0.5,1.9,0.5,2.7-0.1
		c1.4-1.1,2-2.7,2.2-4.5c0-0.4-0.1-0.8-0.3-1.1C116.5,106.4,113.8,106.1,112.6,107.5z"
        style={{
          fill:
            currentPartId === "teeth_adult_34"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_adult_34"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_adult_34"
      />
      <path
        d="M108,114.1c-0.3,0.7-0.3,1.4-0.4,2.1c0,0.8,0.4,1.2,1,1.6c0.8,0.4,1.6,0.5,2.4,0.5c0.3,0,0.6,0,0.9-0.1
		c1-0.1,1.2-0.4,0.9-1.3c-0.3-1.2-0.8-2.3-1.6-3.2C110.1,112.4,108.7,112.6,108,114.1z"
        style={{
          fill:
            currentPartId === "teeth_adult_33"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_adult_33"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_adult_33"
      />
      <path
        d="M107.4,117.4c-0.4-0.5-0.8-1.1-1.4-1.4c-1-0.7-1.9-0.3-2,0.9c-0.1,1.1-0.1,2.3-0.3,3.5
		c-0.2,0.7-0.1,0.7,0.8,0.8c1.3-0.1,2.6-0.6,3.7-1.6c0.5-0.5,0.5-0.7,0-1.2C107.9,118,107.6,117.7,107.4,117.4z"
        style={{
          fill:
            currentPartId === "teeth_adult_32"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_adult_32"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_adult_32"
      />
      <path
        d="M119.5,99.4c-1.6-0.1-2.4,0.8-3.4,2.4c-0.7,1.1-0.6,2.5-0.4,3.8c0,0.3,0.2,0.5,0.5,0.7
		c0.6,0.2,1.2,0.6,1.5,1.1c0.3,0.5,0.7,0.6,1.3,0.6c0.7,0,1.2-0.3,1.6-0.8c1-1.3,1.8-2.7,2.5-4.2c0.3-0.7,0.1-1.4-0.4-2
		C121.8,100,120.7,99.5,119.5,99.4z"
        style={{
          fill:
            currentPartId === "teeth_adult_35"
              ? SynckitColors.Primary["Dark"]
              : "",
          stroke:
            currentPartId === "teeth_adult_35"
              ? `${SynckitColors.Primary[800]}`
              : "",
          opacity: 0.5,
          strokeWidth: 0.5,
        }}
        id="teeth_adult_35"
      />
    </svg>
  );
};

import {
  Avatar,
  Box,
  Button,
  Divider,
  styled,
  Typography,
  Grid,
} from "@mui/material";
import React from "react";
import { SynckitColors } from "../../../../assets/SynckitColors";
import { IconEditLine } from "../../../../assets/SynckitIcons";
import AvatarImg from "../../../../assets/images/demo/avatar_patient.png";
import FemaleIcon from "@mui/icons-material/Female";
import LocationCard from "./LocationCard";
import MedicalCard from "./MedicalCard";
import DeleteDialog from "./DeleteDialog";
import { useNavigate } from "react-router-dom";

const ToolBar = styled("div")({
  textAlign: "right",
  marginBottom: 20,
});

const PaitentInfoCard = styled("div")(({ theme }) => ({
  background: "white",
  borderRadius: 12,
  width: "100%",
  height: 176,
  boxShadow: SynckitColors.Shadow[3],
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0px 3%",
  [theme.breakpoints.down("md")]: {
    padding: "3%",
    flexDirection: "column",
    height: 320,
  },
  "& #wrap": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    maxWidth: 300,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      height: 180,
    },
  },
}));

const PatientNameAndGender = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  height: 32,
});

const InfoWrap = styled("div")({
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "33.33%",
});
export default function ProfileIndex() {
  let navigate = useNavigate();

  return (
    <div>
      <ToolBar>
        <Button
          variant="primary"
          startIcon={<IconEditLine />}
          onClick={() => navigate("/pm/patient/edit")}
          sx={{ width: 136, mr: 1 }}
        >
          Edit
        </Button>
        <DeleteDialog />
      </ToolBar>
      <PaitentInfoCard>
        <div id="wrap">
          <Avatar
            sx={{
              width: 112,
              height: 112,
            }}
            src={AvatarImg}
          />
          <Box sx={{ textAlign: "left" }}>
            <PatientNameAndGender>
              <Typography variant="h5">Cammy Lytle</Typography>
              &nbsp;
              <FemaleIcon color="error" />
            </PatientNameAndGender>
            <Typography variant="body2" color="initial">
              G3982958195
            </Typography>
          </Box>
        </div>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            height: 56,
            width: "100%",
            maxWidth: 600,
          }}
        >
          <InfoWrap>
            <Typography variant="subtitle1" color="initial">
              Birthday
            </Typography>
            <Typography variant="body2" color="initial">
              1954.02.08
            </Typography>
          </InfoWrap>
          <Divider orientation="vertical" />
          <InfoWrap>
            <Typography variant="subtitle1" color="initial">
              NHI
            </Typography>
            <Typography variant="body2" color="initial">
              00000000
            </Typography>
          </InfoWrap>
          <Divider orientation="vertical" />
          <InfoWrap>
            <Typography variant="subtitle1" color="initial">
              Ethnicity
            </Typography>
            <Typography variant="body2" color="initial">
              Asia
            </Typography>
          </InfoWrap>
        </Box>
      </PaitentInfoCard>
      <br />
      <Grid container spacing={3} direction="row" alignItems="flex-start">
        <Grid item xs={12} md={4}>
          <LocationCard />
        </Grid>
        <Grid item xs={12} md={8}>
          <MedicalCard />
        </Grid>
      </Grid>
    </div>
  );
}

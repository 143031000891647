import { Button, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { IconArrowiOSForward } from "../../assets/SynckitIcons";
import { SynckitColors } from "../../assets/SynckitColors";
const Root = styled("div")(({ theme }) => ({ marginBottom: "4%" }));
const HeaderWrap = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: 20,
}));
const ContentCard = styled("div")(({ theme }) => ({
  background: "white",
  width: "100%",
  height: 96,
  boxShadow: SynckitColors.Shadow[1],
  borderRadius: 12,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "10px 24px",
}));

const TitleWrap = styled("div")(({ theme }) => ({
  minWidth: 140,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

export default function MessageSection() {
  return (
    <Root>
      <HeaderWrap>
        <Typography variant="h5" color="initial">
          Message
        </Typography>
        <Button
          endIcon={<IconArrowiOSForward />}
          size="small"
          sx={{ fontWeight: 700 }}
        >
          See all
        </Button>
      </HeaderWrap>
      <ContentCard>
        <TitleWrap>
          <Typography variant="subtitle1" color="initial">
            Announcement
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: SynckitColors.Gray[800], lineHeight: 2 }}
          >
            2022/01/22 10:00
          </Typography>
        </TitleWrap>
        <Typography
          variant="body1"
          sx={{
            color: SynckitColors.Gray["core"],
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          The system will be temporarily closed for maintenance work on 107/4/30
          (Mon) 10:00-12:00, which will cause inconvenience. Sorry for the
          inconvenience!
        </Typography>
      </ContentCard>
    </Root>
  );
}

import { Box, Button, Switch, Typography } from "@mui/material";
import React from "react";
import { SettingList } from "./SystemIndex";

export default function Privacy() {
  return (
    <Box style={{ maxWidth: 600, textAlign: "center" }}>
      <SettingList>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 2,
          }}
        >
          <Typography variant="subtitle1">Camera</Typography>
          <Switch />
        </Box>
      </SettingList>
      <SettingList>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 2,
          }}
        >
          <Typography variant="subtitle1">Album</Typography>
          <Switch />
        </Box>
      </SettingList>
      <SettingList>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 2,
          }}
        >
          <Typography variant="subtitle1">Microphone</Typography>
          <Switch />
        </Box>
      </SettingList>
      <Button variant="primary" sx={{ mt: 3 }}>
        Save
      </Button>
    </Box>
  );
}

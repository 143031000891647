import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { SynckitColors } from "../../../assets/SynckitColors";
import avatar_doctor1 from "../../../assets/images/demo/avatar_doctor1.jpg";
import avatar_doctor2 from "../../../assets/images/demo/avatar_doctor2.jpg";
import avatar_doctor3 from "../../../assets/images/demo/avatar_doctor3.jpg";
import avatar_doctor4 from "../../../assets/images/demo/avatar_doctor4.jpg";
import avatar_doctor5 from "../../../assets/images/demo/avatar_doctor5.jpg";

import {
  Avatar,
  IconButton,
  TableFooter,
  TablePagination,
} from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import FemaleIcon from "@mui/icons-material/Female";
import MaleOutlinedIcon from "@mui/icons-material/MaleOutlined";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: SynckitColors.Gray[200],
    color: SynckitColors.Gray[700],
    fontSize: 14,
    fontWeight: 700,
    height: 36,
    paddingTop: 8,
    paddingBottom: 8,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 700,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({}));

function createData(avatar, name, gender, cid, organization, division) {
  return { avatar, name, gender, cid, organization, division };
}

const rows = [
  createData(
    avatar_doctor1,
    "Jacob Jones",
    <FemaleIcon color="error" />,
    "G1233447890",
    "Organization",
    "Division of Cardiology"
  ),
  createData(
    avatar_doctor5,
    "Thad Eddings",
    <MaleOutlinedIcon sx={{ color: SynckitColors.Primary[400] }} />,
    "1970.11.09",
    "G4292206548",
    "A158692830"
  ),
  createData(
    avatar_doctor2,
    "Aileen Fullbright",
    <FemaleIcon color="error" />,
    "1954.02.08",
    "G3982958195",
    "A158692830"
  ),
  createData(
    avatar_doctor3,
    "Johnsie Jock",
    <FemaleIcon color="error" />,
    "1983.02.10",
    "G6292206548",
    "A158692830"
  ),
  createData(
    avatar_doctor4,
    "Augustina Midgett",
    <FemaleIcon color="error" />,
    "1989.04.06",
    "G8102138520",
    "A158692830"
  ),
];

const TableRoot = styled(TableContainer)({
  width: "100%",
  padding: 0,
  borderRadius: 12,
  backgroundColor: "white",
  marginBottom: 24,
  filter:
    "drop-shadow(0px 27px 80px rgba(16, 57, 123, 0.05)) drop-shadow(0px 13.6687px 34.875px rgba(16, 57, 123, 0.03375)) drop-shadow(0px 5.4px 13px rgba(16, 57, 123, 0.025)) drop-shadow(0px 1.18125px 4.625px rgba(16, 57, 123, 0.01625))",
});

export default function PublicDoctorTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableRoot>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ width: 290 }}>Doctor</StyledTableCell>
            <StyledTableCell>Gender</StyledTableCell>
            <StyledTableCell>ID</StyledTableCell>
            <StyledTableCell>Organization</StyledTableCell>
            <StyledTableCell>Division</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow
              key={row.name}
              sx={{ height: 88 }}
              onClick={() => navigate("/pd/df")}
              hover
            >
              <StyledTableCell>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Avatar sx={{ marginRight: 2 }} src={row.avatar} />
                  Dr.&nbsp;{row.name}
                </div>
              </StyledTableCell>
              <StyledTableCell>{row.gender}</StyledTableCell>
              <StyledTableCell>{row.cid}</StyledTableCell>
              <StyledTableCell>{row.organization}</StyledTableCell>
              <StyledTableCell>{row.division}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={6}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableRoot>
  );
}

import { IconButton, useMediaQuery } from "@mui/material";
import React from "react";
import { SynckitButton } from "../../../components/SynckitButton";
import SynckitSearchBar from "../../../components/SynckitSearchBar";
import AddIcon from "@mui/icons-material/Add";
import { SynckitColors } from "../../../assets/SynckitColors";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  marginBottom: "3%",
}));

export default function Toolbar() {
  const isMediumScreen = useMediaQuery("(max-width:960px)");
  let navigate = useNavigate();
  return (
    <Root>
      <SynckitSearchBar />
      {"　"}
      {isMediumScreen ? (
        <IconButton
          sx={{
            backgroundColor: SynckitColors.Primary[600],
            width: 44,
          }}
          onClick={() => navigate("/pm/add")}
        >
          <AddIcon sx={{ color: "white" }} />
        </IconButton>
      ) : (
        <SynckitButton
          disableElevation
          startIcon={<AddIcon />}
          variant="contained"
          sx={{ minWidth: 190, height: 36 }}
          onClick={() => navigate("/pm/add")}
        >
          Add new Patient
        </SynckitButton>
      )}
    </Root>
  );
}

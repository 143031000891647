import { Dialog, IconButton, Slide, Typography } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Logo from "../../../../assets/images/logo/logo.png";
import LogoSmall from "../../../../assets/images/logo/logo_samll.png";

import { SynckitColors } from "../../../../assets/SynckitColors";
import { styled } from "@mui/system";
import WeeklyPanel from "./WeeklyPanel";
import ApplyPanel from "./ApplyPanel";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogRoot = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    background: SynckitColors.Background,
  },
}));

const HeaderBar = styled("div")(({ theme }) => ({
  background: SynckitColors.Primary[600],
  width: "100%",
  height: 92,
  lineHeight: "92px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0px 1%",
  marginBottom: "2%",
  transition: "0.3s",
  [theme.breakpoints.down("md")]: {
    height: 40,
    lineHeight: "40px",
  },
}));

const ContentWrap = styled("div")(({ theme }) => ({
  maxWidth: 1440,
  width: "100%",
  margin: "0px auto",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  [theme.breakpoints.down(1350)]: {
    flexDirection: "column",
    width: "100%",
  },
}));

const LogoImg = styled("img")(({ theme }) => ({
  content: `url(${Logo})`,
  width: 177,
  transition: "0.3s",
  [theme.breakpoints.down("md")]: {
    marginLeft: 10,
    width: 40,
    content: `url(${LogoSmall})`,
  },
}));
const ButtonWrap = styled("div")(({ theme }) => ({
  width: 177,
  textAlign: "right",

  [theme.breakpoints.down("sm")]: {
    width: 40,
  },
}));

function AddNewShiftsDialog({ open, setOpen }) {
  return (
    <div>
      <DialogRoot
        fullScreen
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
      >
        <HeaderBar>
          <LogoImg alt="Synckit" />
          <Typography variant="h5" sx={{ color: "white" }}>
            Weekly Shift
          </Typography>
          <ButtonWrap>
            <IconButton
              edge="start"
              onClick={() => setOpen(false)}
              sx={{ color: "white" }}
            >
              <CloseIcon />
            </IconButton>
          </ButtonWrap>
        </HeaderBar>

        <ContentWrap>
          <WeeklyPanel />
          <ApplyPanel />
        </ContentWrap>
      </DialogRoot>
    </div>
  );
}

export default AddNewShiftsDialog;

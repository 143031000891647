import { Box, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import React, { useRef, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Slider from "react-slick";
import DemoSkinRecord01 from "../../assets/demo/skin_record01.png";
import DemoSkinRecord02 from "../../assets/demo/skin_record02.png";
import DemoSkinRecord03 from "../../assets/demo/skin_record03.png";
import DemoSkinRecord04 from "../../assets/demo/skin_record04.png";
import DemoSkinRecord05 from "../../assets/demo/skin_record05.png";
import DemoSkinRecord06 from "../../assets/demo/skin_record06.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SynckitColors } from "../../assets/SynckitColors";
import { IconVideoFill } from "../../assets/SynckitIcons";

const RecordHeader = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "16px 20px",
  height: 56,
  boxShadow: SynckitColors.Shadow["ButtomDivider"],
  "& span": {
    fontWeight: 700,
    fontSize: 18,
  },
  "& .MuiIconButton-root": {
    border: "1px solid #EBF2FA",
    borderRadius: 100,
    width: 28,
    height: 28,
  },
}));

const ImgCard = styled("div")({
  width: 98,
  height: 98,
  borderRadius: 8,
  overflow: "hidden",
  position: "relative",
  outline: "4px solid rgba(255,255,255,0)",
  transition: "0.3s",
  //show minute & second for video
  "& .timestamp": {
    width: "80%",
    fontSize: 12,
    fontWeight: 700,
    position: "absolute",
    color: "white",
    bottom: 4,
    right: 8,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& img": { width: "100%", height: "100%", objectFit: "cover" },
  "&:hover": {
    outline: `4px solid ${SynckitColors.Primary[600]}`,
  },
  "&.selected": {
    outline: `4px solid ${SynckitColors.Primary[600]}`,
  },
});
const ButtonWrap = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: 68,
  "& .MuiIconButton-root": {
    border: "1px solid #EBF2FA",
    borderRadius: 100,
    width: 28,
    height: 28,
  },
}));
const RecordContent = styled("div")(({ theme }) => ({
  padding: "12px 24px",
}));
const settings = {
  dots: false,
  infinite: true,
  draggable: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  arrows: false,
};
export default function DataGallery() {
  const slickRef = useRef(null);
  const [selectedId, setSelectedId] = useState(0);

  const Root = styled("div")(({ theme }) => ({
    width: 875,
    height: 191,
    background: "rgba(255, 255, 255, 0.8)",
    borderRadius: 8,
  }));
  return (
    <Root>
      <RecordHeader>
        <span>Data</span>
        <ButtonWrap>
          <IconButton onClick={() => slickRef.current.slickPrev()}>
            <ArrowBackIosIcon fontSize="small" sx={{ marginLeft: 1 }} />
          </IconButton>
          <IconButton onClick={() => slickRef.current.slickNext()}>
            <ArrowForwardIosIcon fontSize="small" />
          </IconButton>
        </ButtonWrap>
      </RecordHeader>
      <RecordContent>
        <Slider {...settings} ref={slickRef}>
          {demoImgList.map((item, index) => (
            <Box key={index} sx={{ padding: 1, marginRight: 2 }}>
              <ImgCard
                className={index === selectedId ? "selected" : null}
                onClick={() => setSelectedId(index)}
              >
                <figure>
                  <img src={item.imgUrl} alt={item.id} />
                </figure>
                {item.duration && (
                  <div className="timestamp">
                    <IconVideoFill sx={{ fontSize: 15 }} />
                    <span>{item.duration}</span>
                  </div>
                )}
              </ImgCard>
            </Box>
          ))}
        </Slider>
      </RecordContent>
    </Root>
  );
}

const demoImgList = [
  { id: 1, imgUrl: DemoSkinRecord01 },
  { id: 2, imgUrl: DemoSkinRecord02 },
  { id: 3, imgUrl: DemoSkinRecord03 },
  { id: 4, imgUrl: DemoSkinRecord04, duration: "0:14" },
  { id: 5, imgUrl: DemoSkinRecord05, duration: "0:05" },
  { id: 6, imgUrl: DemoSkinRecord06 },
  // { id: 7, imgUrl: DemoSkinRecord01 },
  // { id: 8, imgUrl: DemoSkinRecord02 },
];

import React, { useState } from "react";
import {
  Typography,
  Avatar,
  IconButton,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import { SynckitColors } from "../../../../assets/SynckitColors";
import { IconClockLine } from "../../../../assets/SynckitIcons";
import { ImgOrganHeartActive2 } from "../../../../assets/SynckitImg";
import SynckitApoTypeLabel from "../../../../components/label/SynckitApoTypeLabel";
import { DemoRowData } from "../../../schedule/appointment/demolApoData";
import { APO_TYPE_REMOTE } from "../../../../assets/constant";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import { useNavigate } from "react-router-dom";
import MoreMenuButton from "./MoreMenuButton";

const ApoList = styled("ul")(({ theme }) => ({}));

const ApoListItem = styled("li")(({ theme }) => ({
  background: "white",
  width: "100%",
  height: 96,
  borderRadius: 12,
  boxShadow: SynckitColors.Shadow[1],
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 40px",
  marginBottom: "2%",
  transition: "0.3s",
  [theme.breakpoints.down(1000)]: {
    padding: "16px 2%",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "16px 40px",

    flexDirection: "column",
    height: 240,
  },
}));

const InfoWrap = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  margin: "0 1%",
  width: 210,
}));

const TypeWrap = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: 230,
}));

const TitleWrap = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: 60,
});

const MoreButton = styled(Button)({
  color: SynckitColors.Gray[700],
  fontWeight: 700,
  fontSize: 14,
  "&:hover": {
    color: SynckitColors.Primary[600],
  },
});

export default function ApoIndex() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <div>
      <TitleWrap>
        <Typography variant="h5">Appointment</Typography>
        <MoreButton
          endIcon={<ArrowRightOutlinedIcon />}
          onClick={() => navigate("/pm/patient/apo")}
        >
          See all
        </MoreButton>
      </TitleWrap>
      <ApoList>
        {" "}
        {renderListItem(DemoRowData[0], anchorEl, setAnchorEl)}
      </ApoList>
    </div>
  );
}

const renderListItem = (itemData, anchorEl, setAnchorEl) => (
  <ApoListItem key={itemData.id}>
    <Box sx={{ display: "flex" }}>
      <Avatar
        alt={itemData.patient}
        src={itemData.avatar}
        sx={{ width: 48, height: 48, marginRight: 2 }}
      />
      <InfoWrap>
        <Typography
          variant="subtitle1"
          sx={{ color: SynckitColors.Gray["core"] }}
        >
          {itemData.patient}
        </Typography>

        <Typography
          variant="body2"
          component={"div"}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            color: SynckitColors.Gray[800],
          }}
        >
          <IconClockLine sx={{ width: 13, heigth: 13 }} />
          &nbsp; {itemData.date}
        </Typography>
      </InfoWrap>
    </Box>

    <TypeWrap>
      {SynckitApoTypeLabel(itemData.type)}
      {itemData.type === APO_TYPE_REMOTE && (
        <ImgOrganHeartActive2 style={{ width: 40, height: 40 }} />
      )}
      <MoreMenuButton />
    </TypeWrap>
  </ApoListItem>
);

import { styled, Tabs, Tab, Typography } from "@mui/material";
import React, { useState } from "react";
import { SynckitColors } from "../../../assets/SynckitColors";

import Refused from "./Refused";
import { Box } from "@mui/system";
import Cancelled from "./Cancelled";
import Appointment from "./Appointment";
import Inviting from "./Inviting";
import SynckitSearchBarSimple2 from "../../../components/SynckitSearchBarSimple2";

const AppointmentRoot = styled("div")({
  filter: SynckitColors.Shadow[1],
  borderRadius: 8,
  overflow: "hidden",
});
const FilterTabs = styled(Tabs)({
  background: SynckitColors.Primary[50],
  height: 52,
  "& .MuiTab-root": {
    color: SynckitColors.Gray[900],
    fontSize: 14,
    fontWeight: 700,
    textTransform: "initial",
    letterSpacing: 0.1,

    "&:hover": {
      transition: "0.3s",
      background: SynckitColors.Primary[100],
    },
    "&.Mui-selected": {
      color: SynckitColors.Primary[600],
    },
  },
});

function TablePanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div hidden={value !== index} {...other}>
      {value === index && (
        <Box sx={{ overflowX: "auto" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
export default function AppointmentIndex() {
  const [filterItem, setFilterItem] = useState(0);

  return (
    <AppointmentRoot>
      <SynckitSearchBarSimple2 />
      <FilterTabs
        value={filterItem}
        onChange={(event, newValue) => setFilterItem(newValue)}
        variant="scrollable"
      >
        <Tab label="Appointment" />
        <Tab label="Inviting" />
        <Tab label="Refused" />
        <Tab label="Cancelled" />
      </FilterTabs>
      <TablePanel value={filterItem} index={0}>
        <Appointment />
      </TablePanel>
      <TablePanel value={filterItem} index={1}>
        <Inviting />
      </TablePanel>
      <TablePanel value={filterItem} index={2}>
        <Refused />
      </TablePanel>
      <TablePanel value={filterItem} index={3}>
        <Cancelled />
      </TablePanel>
    </AppointmentRoot>
  );
}

import { styled } from "@mui/material";
import React from "react";
import { NOTIFICATIONS_STATUS_ONLINE } from "./constant";
import { SynckitColors } from "./SynckitColors";

const StatusLight = styled("div")({
  backgroundColor: SynckitColors.Success["Default"],
  width: 10,
  height: 10,
  borderRadius: "50%",
});
export default function SynckitStatusLight({
  status = NOTIFICATIONS_STATUS_ONLINE,
}) {
  return (
    <StatusLight
      sx={{
        background:
          status === NOTIFICATIONS_STATUS_ONLINE
            ? SynckitColors.Success["Default"]
            : SynckitColors.Error["Default"],
      }}
    ></StatusLight>
  );
}

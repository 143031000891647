import { SynckitColors } from "../../../../assets/SynckitColors";

export const LungBackSVG = ({ currentPartId = "", handleClick }) => {
  return (
    <svg viewBox="-7 -16 117 340" stroke={""} onClick={handleClick}>
      <path
        d="M11.1816 21.6201H17.2461V23H9.48535V10.2031H11.1816V21.6201ZM24.0928 23H22.458V12.1631L19.1797 13.3672V11.8906L23.8379 10.1416H24.0928V23Z"
        style={{ fill: "black" }}
      />
      <path
        d="M11.1816 62.6201H17.2461V64H9.48535V51.2031H11.1816V62.6201ZM27.1338 64H18.749V62.8311L23.1787 57.9092C23.835 57.165 24.2861 56.5615 24.5322 56.0986C24.7842 55.6299 24.9102 55.1465 24.9102 54.6484C24.9102 53.9805 24.708 53.4326 24.3037 53.0049C23.8994 52.5771 23.3604 52.3633 22.6865 52.3633C21.8779 52.3633 21.248 52.5947 20.7969 53.0576C20.3516 53.5146 20.1289 54.1533 20.1289 54.9736H18.5029C18.5029 53.7959 18.8809 52.8438 19.6367 52.1172C20.3984 51.3906 21.415 51.0273 22.6865 51.0273C23.876 51.0273 24.8164 51.3408 25.5078 51.9678C26.1992 52.5889 26.5449 53.418 26.5449 54.4551C26.5449 55.7148 25.7422 57.2148 24.1367 58.9551L20.709 62.6729H27.1338V64Z"
        style={{ fill: "black" }}
      />
      <path
        d="M11.1816 146.62H17.2461V148H9.48535V135.203H11.1816V146.62ZM21.1133 140.811H22.335C23.1025 140.799 23.7061 140.597 24.1455 140.204C24.585 139.812 24.8047 139.281 24.8047 138.613C24.8047 137.113 24.0576 136.363 22.5635 136.363C21.8604 136.363 21.2979 136.565 20.876 136.97C20.46 137.368 20.252 137.898 20.252 138.561H18.626C18.626 137.547 18.9951 136.706 19.7334 136.038C20.4775 135.364 21.4209 135.027 22.5635 135.027C23.7705 135.027 24.7168 135.347 25.4023 135.985C26.0879 136.624 26.4307 137.512 26.4307 138.648C26.4307 139.205 26.249 139.744 25.8857 140.266C25.5283 140.787 25.0391 141.177 24.418 141.435C25.1211 141.657 25.6631 142.026 26.0439 142.542C26.4307 143.058 26.624 143.688 26.624 144.432C26.624 145.58 26.249 146.491 25.499 147.165C24.749 147.839 23.7734 148.176 22.5723 148.176C21.3711 148.176 20.3926 147.851 19.6367 147.2C18.8867 146.55 18.5117 145.691 18.5117 144.625H20.1465C20.1465 145.299 20.3662 145.838 20.8057 146.242C21.2451 146.646 21.834 146.849 22.5723 146.849C23.3574 146.849 23.958 146.644 24.374 146.233C24.79 145.823 24.998 145.234 24.998 144.467C24.998 143.723 24.7695 143.151 24.3125 142.753C23.8555 142.354 23.1963 142.149 22.335 142.138H21.1133V140.811Z"
        style={{ fill: "black" }}
      />
      <path
        d="M11.1816 211.62H17.2461V213H9.48535V200.203H11.1816V211.62ZM25.6133 208.702H27.3887V210.029H25.6133V213H23.9785V210.029H18.1514V209.071L23.8818 200.203H25.6133V208.702ZM19.9971 208.702H23.9785V202.427L23.7852 202.778L19.9971 208.702Z"
        style={{ fill: "black" }}
      />
      <path
        d="M88.1787 17.8232H85.1729V23H83.4766V10.2031H87.7129C89.1543 10.2031 90.2617 10.5312 91.0352 11.1875C91.8145 11.8438 92.2041 12.7988 92.2041 14.0527C92.2041 14.8496 91.9873 15.5439 91.5537 16.1357C91.126 16.7275 90.5283 17.1699 89.7607 17.4629L92.7666 22.8945V23H90.9561L88.1787 17.8232ZM85.1729 16.4434H87.7656C88.6035 16.4434 89.2686 16.2266 89.7607 15.793C90.2588 15.3594 90.5078 14.7793 90.5078 14.0527C90.5078 13.2617 90.2705 12.6553 89.7959 12.2334C89.3271 11.8115 88.6475 11.5977 87.7568 11.5918H85.1729V16.4434ZM99.499 23H97.8643V12.1631L94.5859 13.3672V11.8906L99.2441 10.1416H99.499V23Z"
        style={{ fill: "black" }}
      />
      <path
        d="M88.1787 58.8232H85.1729V64H83.4766V51.2031H87.7129C89.1543 51.2031 90.2617 51.5312 91.0352 52.1875C91.8145 52.8438 92.2041 53.7988 92.2041 55.0527C92.2041 55.8496 91.9873 56.5439 91.5537 57.1357C91.126 57.7275 90.5283 58.1699 89.7607 58.4629L92.7666 63.8945V64H90.9561L88.1787 58.8232ZM85.1729 57.4434H87.7656C88.6035 57.4434 89.2686 57.2266 89.7607 56.793C90.2588 56.3594 90.5078 55.7793 90.5078 55.0527C90.5078 54.2617 90.2705 53.6553 89.7959 53.2334C89.3271 52.8115 88.6475 52.5977 87.7568 52.5918H85.1729V57.4434ZM102.54 64H94.1553V62.8311L98.585 57.9092C99.2412 57.165 99.6924 56.5615 99.9385 56.0986C100.19 55.6299 100.316 55.1465 100.316 54.6484C100.316 53.9805 100.114 53.4326 99.71 53.0049C99.3057 52.5771 98.7666 52.3633 98.0928 52.3633C97.2842 52.3633 96.6543 52.5947 96.2031 53.0576C95.7578 53.5146 95.5352 54.1533 95.5352 54.9736H93.9092C93.9092 53.7959 94.2871 52.8438 95.043 52.1172C95.8047 51.3906 96.8213 51.0273 98.0928 51.0273C99.2822 51.0273 100.223 51.3408 100.914 51.9678C101.605 52.5889 101.951 53.418 101.951 54.4551C101.951 55.7148 101.148 57.2148 99.543 58.9551L96.1152 62.6729H102.54V64Z"
        style={{ fill: "black" }}
      />
      <path
        d="M88.1787 142.823H85.1729V148H83.4766V135.203H87.7129C89.1543 135.203 90.2617 135.531 91.0352 136.188C91.8145 136.844 92.2041 137.799 92.2041 139.053C92.2041 139.85 91.9873 140.544 91.5537 141.136C91.126 141.728 90.5283 142.17 89.7607 142.463L92.7666 147.895V148H90.9561L88.1787 142.823ZM85.1729 141.443H87.7656C88.6035 141.443 89.2686 141.227 89.7607 140.793C90.2588 140.359 90.5078 139.779 90.5078 139.053C90.5078 138.262 90.2705 137.655 89.7959 137.233C89.3271 136.812 88.6475 136.598 87.7568 136.592H85.1729V141.443ZM96.5195 140.811H97.7412C98.5088 140.799 99.1123 140.597 99.5518 140.204C99.9912 139.812 100.211 139.281 100.211 138.613C100.211 137.113 99.4639 136.363 97.9697 136.363C97.2666 136.363 96.7041 136.565 96.2822 136.97C95.8662 137.368 95.6582 137.898 95.6582 138.561H94.0322C94.0322 137.547 94.4014 136.706 95.1396 136.038C95.8838 135.364 96.8271 135.027 97.9697 135.027C99.1768 135.027 100.123 135.347 100.809 135.985C101.494 136.624 101.837 137.512 101.837 138.648C101.837 139.205 101.655 139.744 101.292 140.266C100.935 140.787 100.445 141.177 99.8242 141.435C100.527 141.657 101.069 142.026 101.45 142.542C101.837 143.058 102.03 143.688 102.03 144.432C102.03 145.58 101.655 146.491 100.905 147.165C100.155 147.839 99.1797 148.176 97.9785 148.176C96.7773 148.176 95.7988 147.851 95.043 147.2C94.293 146.55 93.918 145.691 93.918 144.625H95.5527C95.5527 145.299 95.7725 145.838 96.2119 146.242C96.6514 146.646 97.2402 146.849 97.9785 146.849C98.7637 146.849 99.3643 146.644 99.7803 146.233C100.196 145.823 100.404 145.234 100.404 144.467C100.404 143.723 100.176 143.151 99.7188 142.753C99.2617 142.354 98.6025 142.149 97.7412 142.138H96.5195V140.811Z"
        style={{ fill: "black" }}
      />
      <path
        d="M88.1787 207.823H85.1729V213H83.4766V200.203H87.7129C89.1543 200.203 90.2617 200.531 91.0352 201.188C91.8145 201.844 92.2041 202.799 92.2041 204.053C92.2041 204.85 91.9873 205.544 91.5537 206.136C91.126 206.728 90.5283 207.17 89.7607 207.463L92.7666 212.895V213H90.9561L88.1787 207.823ZM85.1729 206.443H87.7656C88.6035 206.443 89.2686 206.227 89.7607 205.793C90.2588 205.359 90.5078 204.779 90.5078 204.053C90.5078 203.262 90.2705 202.655 89.7959 202.233C89.3271 201.812 88.6475 201.598 87.7568 201.592H85.1729V206.443ZM101.02 208.702H102.795V210.029H101.02V213H99.3848V210.029H93.5576V209.071L99.2881 200.203H101.02V208.702ZM95.4033 208.702H99.3848V202.427L99.1914 202.778L95.4033 208.702Z"
        style={{ fill: "black" }}
      />
      <circle
        cx="17.0365"
        cy="17.0365"
        r="16.5365"
        id="lung_back_rl1"
        style={{ fillOpacity: currentPartId === "lung_back_rl1" ? 0.8 : null }}
      />
      <circle
        cx="17.0365"
        cy="58.0365"
        r="16.5365"
        id="lung_back_rl2"
        style={{ fillOpacity: currentPartId === "lung_back_rl2" ? 0.8 : null }}
      />
      <circle
        cx="17.0365"
        cy="142.036"
        r="16.5365"
        id="lung_back_rl3"
        style={{ fillOpacity: currentPartId === "lung_back_rl3" ? 0.8 : null }}
      />
      <circle
        cx="17.0365"
        cy="207.036"
        r="16.5365"
        id="lung_back_rl4"
        style={{ fillOpacity: currentPartId === "lung_back_rl4" ? 0.8 : null }}
      />
      <circle
        cx="92.0365"
        cy="17.0365"
        r="16.5365"
        id="lung_back_rr1"
        style={{ fillOpacity: currentPartId === "lung_back_rr1" ? 0.8 : null }}
      />
      <circle
        cx="92.0365"
        cy="58.0365"
        r="16.5365"
        id="lung_back_rr2"
        style={{ fillOpacity: currentPartId === "lung_back_rr2" ? 0.8 : null }}
      />
      <circle
        cx="92.0365"
        cy="142.036"
        r="16.5365"
        id="lung_back_rr3"
        style={{ fillOpacity: currentPartId === "lung_back_rr3" ? 0.8 : null }}
      />
      <circle
        cx="92.0365"
        cy="207.036"
        r="16.5365"
        id="lung_back_rr4"
        style={{ fillOpacity: currentPartId === "lung_back_rr4" ? 0.8 : null }}
      />
    </svg>
  );
};

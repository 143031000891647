import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  Grid,
  DialogActions,
  styled,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseIcon from "@mui/icons-material/Close";
import NumberFormat from "react-number-format";
import { ImgCrediCard, ImgCrediCardVisa } from "../../../assets/SynckitImg";
import { Datepicker } from "@mobiscroll/react";
import { SynckitColors } from "../../../assets/SynckitColors";
import _ from "lodash";

const CreditList = styled("div")(({ theme }) => ({
  border: `1px solid ${SynckitColors.Gray[500]}`,
  borderRadius: 4,
  marginBottom: 20,
  overflow: "hidden",
  "& .header": {
    padding: "8px 16px",
    background: SynckitColors.Gray[800],
    color: "white",
    fontWeight: 700,
    fontSize: 16,
  },
  "& .content": {
    padding: 16,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      // height: "auto",
      "& .btnWrap": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "space-around",
        height: 100,
      },
    },
  },
}));
export default function PaymentSetting() {
  const [openDialog, setOpenDialog] = useState(false);
  const [expirationDate, setExpirationDate] = useState(null);
  const [paymentDataList, setPaymentDataList] = useState([
    { id: 1, cardNumber: "4556 09** **** 1111", default: true },
    { id: 2, cardNumber: "4556 09** **** 2222", default: false },
    { id: 3, cardNumber: "4556 09** **** 3333", default: false },
  ]);
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSetDefaultPayment = (_id) => {
    let tempList = paymentDataList.map((item) => ({
      ...item,
      default: item.id === _id ? true : false,
    }));
    setPaymentDataList([...tempList]);
  };
  const handleDelete = (_id) => {
    let tempList = paymentDataList.filter((item) => item.id !== _id);
    setPaymentDataList([...tempList]);
  };
  return (
    <>
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        Payment setting
      </Typography>
      {_.orderBy(paymentDataList, "default", "desc").map((item) => (
        <CreditList key={item.id}>
          <div
            className="header "
            variant="subtitle1"
            style={{ display: item.default ? "block" : "none" }}
          >
            Default Payment
          </div>

          <div className="content">
            <Box sx={{ display: "flex", alignItems: "center", width: 220 }}>
              <ImgCrediCardVisa style={{ marginRight: 6 }} />
              <Typography variant="subtitle1">{item.cardNumber}</Typography>
            </Box>
            <div
              className="btnWrap"
              style={{ display: item.default ? "none" : "flex" }}
            >
              <Button
                variant={"third"}
                onClick={() => handleSetDefaultPayment(item.id)}
              >
                Set it to a default payment
              </Button>

              <Button
                variant={"sixth"}
                onClick={() => handleDelete(item.id)}
                sx={{ ml: 1, mr: 1 }}
              >
                Delete
              </Button>
            </div>
          </div>
        </CreditList>
      ))}

      <Button
        variant={"primary"}
        startIcon={<AddOutlinedIcon />}
        sx={{ mt: 3, mb: 3 }}
        onClick={() => setOpenDialog(true)}
      >
        Connect Credit Card
      </Button>
      <Typography variant="body1" sx={{ color: SynckitColors.Gray[700] }}>
        Complete your payment settings for remote service.
      </Typography>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span></span>
          <Typography variant="h5">Connect Credit Card</Typography>
          <IconButton onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={3}
            direction="row"
            justify="center"
            alignItems="center"
            alignContent="center"
          >
            <Grid item xs={12}>
              <NumberFormat
                customInput={TextField}
                label="Card Number"
                format="#### #### #### ####"
                fullWidth
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <ImgCrediCard />
                    </InputAdornment>
                  ),
                }}
                sx={{ mt: 1 }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Datepicker
                // controls={["date"]}
                inputComponent={TextField}
                dateFormat="MM/YYYY"
                dateWheels="|MMMM YYYY|"
                touchUi={true}
                onChange={(val) => setExpirationDate(val.valueText)}
                inputProps={{
                  label: "Expiration date",
                  value: expirationDate,
                  size: "small",
                  InputLabelProps: { shrink: true },
                  fullWidth: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <NumberFormat
                customInput={TextField}
                label="CVV"
                format="###"
                fullWidth
                size="small"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Cardholder’s Name"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Billing Address"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            variant="primary"
            sx={{ width: 177, mb: 2 }}
            onClick={handleCloseDialog}
          >
            Connect
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import { Box, styled, Typography } from "@mui/material";
import React from "react";
import ImgAttachmentDepressor from "../../../assets/images/intructions/attachment_depressor.png";
import ImgAttachment from "../../../assets/images/intructions/attachment.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { SynckitColors } from "../../../assets/SynckitColors";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "center",
}));

const ImgWrap = styled("div")(({ theme }) => ({
  height: 180,
  "& img": {
    width: "auto",
    height: 150,
    objectFit: "contain",
  },
}));

export default function InstructionsDepressor() {
  return (
    <Root>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "90%",
        }}
      >
        <Typography variant="h4" sx={{ fontSize: 28 }}>
          A
        </Typography>
        <Typography variant="h4">Instructions</Typography>
        <Typography variant="h3">{"　"}</Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <ImgWrap sx={{ textAlign: "center" }}>
            <img src={ImgAttachment} alt="synckit" />
          </ImgWrap>
          <Typography
            variant="h6"
            sx={{ lineHeight: 3, color: SynckitColors.Gray[900] }}
          >
            Please remove the other attachments.
          </Typography>
        </Box>
        {"　　"}
        <ArrowForwardIcon />
        {"　　　　"}
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <ImgWrap>
            <img src={ImgAttachmentDepressor} alt="synckit" />
          </ImgWrap>
          <Typography
            variant="h6"
            sx={{ lineHeight: 3, color: SynckitColors.Gray[900] }}
          >
            {"　"}
          </Typography>
        </Box>
      </Box>
    </Root>
  );
}

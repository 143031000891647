import { createSlice } from "@reduxjs/toolkit";

const { actions, reducer: ExamRecordReducer } = createSlice({
  name: "exam.record",
  initialState: {
    openExamRecordEditor: false,
  },
  reducers: {
    setOpenExamRecordEditor(state, action) {
      state.openExamRecordEditor = action.payload;
    },
  },
});

export const examRecordState = (state) => state.examRecord;
export const { setOpenExamRecordEditor } = actions;
export default ExamRecordReducer;

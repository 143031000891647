import React from "react";
import { Typography, Button } from "@mui/material";
import { Box, styled } from "@mui/system";
import { SynckitColors } from "../../../../assets/SynckitColors";
import { IconDoctor } from "../../../../assets/SynckitIcons";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setOpenExamRecordEditor } from "../../../../redux/slice/examRecord.slice";

const ApoList = styled("ul")(({ theme }) => ({
  background: "white",
  width: "100%",
  borderRadius: 12,
  padding: "16px 24px 26px ",
  boxShadow: SynckitColors.Shadow[1],
}));

const ExamListItem = styled("li")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  transition: "0.3s",
  borderBottom: `1px solid ${SynckitColors.Gray[300]}`,
  padding: "16px 9px",
  "&:hover": {
    background: SynckitColors.Primary[50],
    cursor: "pointer",
  },
}));

const TitleWrap = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: 60,
});

const MoreButton = styled(Button)({
  color: SynckitColors.Gray[700],
  fontWeight: 700,
  fontSize: 14,
  "&:hover": {
    color: SynckitColors.Primary[600],
  },
});

export default function ExamRecordIndex() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div>
      <TitleWrap>
        <Typography variant="h5">Examination record</Typography>
        <MoreButton
          endIcon={<ArrowRightOutlinedIcon />}
          onClick={() => navigate("/pm/patient/er")}
        >
          See all
        </MoreButton>
      </TitleWrap>
      <ApoList>
        {" "}
        {demoData.map((item) =>
          renderExamRecordItem(item, (val) =>
            dispatch(setOpenExamRecordEditor(true))
          )
        )}
      </ApoList>
    </div>
  );
}

const renderExamRecordItem = (itemData, setOpenExamRecordEditor) => (
  <ExamListItem key={itemData.id} onClick={() => setOpenExamRecordEditor(true)}>
    <Box sx={{ display: "flex" }}>
      {itemData.doctor ? (
        <IconDoctor sx={{ color: SynckitColors.Primary[600] }} />
      ) : (
        <span style={{ width: 24 }}></span>
      )}

      {"　"}
      <Typography variant="body1" component={"div"}>
        {itemData.date}
      </Typography>
      {"　　"}
      <Typography variant="body1" component={"div"}>
        {itemData.item}
      </Typography>
    </Box>
    <Box>
      <Typography variant="body1" component={"div"}>
        {itemData.recordId}
      </Typography>
    </Box>
  </ExamListItem>
);

const demoData = [
  {
    id: 1,
    doctor: true,
    date: "2022.02.22",
    item: "Mutiple",
    recordId: "G7524680",
  },
  {
    id: 2,
    doctor: false,
    date: "2022.02.10",
    item: "Teeth",
    recordId: "G7524626",
  },
  {
    id: 3,
    doctor: true,
    date: "2022.02.08",
    item: "Heart",
    recordId: "G7524620",
  },
];

import Homeindex from "../views/home/HomeIndex";
import HomeRoom from "../views/home/HomeRoom";
import AddNewPatientIndex from "../views/patient/add-new-patient/AddNewPatientIndex";
import EditPatientIndex from "../views/patient/edit-patient/EditPatientIndex";
import ComparisonReportIndex from "../views/patient/patient/report/comparison-report/ComparisonReportIndex";
import ManagementIndex from "../views/patient/management/ManagementIndex";
import AposIndex from "../views/patient/patient/apo/AposIndex";
import Examrecord from "../views/patient/patient/exam-record/ExamRecord";
import PatientIndex from "../views/patient/patient/PatientIndex";
import ProfileIndex from "../views/patient/patient/profile/ProfileIndex";
import ShareIndex from "../views/patient/patient/share/ShareIndex";
import AppointmentIndex from "../views/schedule/appointment/AppointmentIndex";
import ScheduleIndex from "../views/schedule/ScheduleIndex";
import SearchIndex from "../views/schedule/search/SearchIndex";
import Edit from "../views/schedule/shifts/Edit";
import ShiftsIndex from "../views/schedule/shifts/ShiftsIndex";
import BrowseReport from "../views/patient/patient/report/browse-report/BrowseReport";
import SingleReport from "../views/patient/patient/report/single-report/SingleReport";
import ContactsIndex from "../views/contacts/ContactsIndex";
import DoctorProfileIndex from "../views/contacts/doctor-profile/DoctorProfileIndex";
import EditDoctorIndex from "../views/contacts/edit-doctor/EditDoctorIndex";
import PublicDoctorIndex from "../views/contacts/public-doctor/PublicDoctorIndex";
import InvitingListIndex from "../views/contacts/inviting-list/InvitingListIndex";
import SettingIndex from "../views/setting/SettingIndex";
import MessagesIndex from "../views/notifications/MessagesIndex";

export const synckitRoutes = [
  {
    path: "",
    component: <Homeindex />,
    exact: true,
    breadcrumbName: "",
  },
  {
    path: "/",
    component: <Homeindex />,
    exact: true,
    breadcrumbName: "Home",
  },
  {
    path: "/home",
    component: <Homeindex />,
    exact: true,
    breadcrumbName: "Home",
  },
  {
    path: "/home/messages",
    component: <MessagesIndex />,
    exact: true,
    breadcrumbName: "Messages",
  },
  {
    path: "/schedule",
    component: <ScheduleIndex />,
    exact: true,
    breadcrumbName: "Schedule",
  },
  {
    path: "/schedule/search",
    component: <SearchIndex />,
    exact: true,
    breadcrumbName: "Search",
  },
  {
    path: "/schedule/shifts",
    component: <ShiftsIndex />,
    exact: true,
    breadcrumbName: "Shifts",
  },
  {
    path: "/schedule/edit",
    component: <Edit />,
    exact: true,
    breadcrumbName: "Edit",
  },

  {
    path: "/schedule/appointment",
    component: <AppointmentIndex />,
    exact: true,
    breadcrumbName: "Appointment",
  },

  {
    path: "/pm",
    component: <ManagementIndex />,
    exact: true,
    breadcrumbName: "Patient management",
  },
  {
    path: "/pm/add",
    component: <AddNewPatientIndex />,
    exact: true,
    breadcrumbName: "Add new patient",
  },
  {
    path: "/pm/patient",
    component: <PatientIndex />,
    exact: true,
    breadcrumbName: "Patient",
  },
  {
    path: "/pm/patient/share",
    component: <ShareIndex />,
    exact: true,
    breadcrumbName: "Share",
  },
  {
    path: "/pm/patient/profile",
    component: <ProfileIndex />,
    exact: true,
    breadcrumbName: "Profile",
  },

  {
    path: "/pm/patient/apo",
    component: <AposIndex />,
    exact: true,
    breadcrumbName: "Appointment",
  },
  {
    path: "/pm/patient/edit",
    component: <EditPatientIndex />,
    exact: true,
    breadcrumbName: "Edit",
  },
  {
    path: "/pm/patient/er",
    component: <Examrecord />,
    exact: true,
    breadcrumbName: "Exam Record",
  },
  {
    path: "/pm/patient/er/sr",
    component: <SingleReport />,
    exact: true,
    breadcrumbName: "Single Report",
  },
  {
    path: "/pm/patient/er/cr",
    component: <ComparisonReportIndex />,
    exact: true,
    breadcrumbName: "Comparison Record",
  },
  {
    path: "/pm/patient/er/br",
    component: <BrowseReport />,
    exact: true,
    breadcrumbName: "Browse Report",
  },
  {
    path: "/contacts",
    component: <ContactsIndex />,
    exact: true,
    breadcrumbName: "Contacts",
  },
  {
    path: "/contacts/df",
    component: <DoctorProfileIndex />,
    exact: true,
    breadcrumbName: "Doctor's profile",
  },
  {
    path: "/contacts/df/edit",
    component: <EditDoctorIndex />,
    exact: true,
    breadcrumbName: "Edit",
  },
  {
    path: "/pd",
    component: <PublicDoctorIndex />,
    exact: true,
    breadcrumbName: "Public Doctor",
  },
  {
    path: "/pd/df",
    component: <DoctorProfileIndex />,
    exact: true,
    breadcrumbName: "Doctor's profile",
  },
  {
    path: "/pd/il",
    component: <InvitingListIndex />,
    exact: true,
    breadcrumbName: "Inviting List",
  },
  {
    path: "/setting",
    component: <SettingIndex />,
    exact: true,
    breadcrumbName: "Setting",
  },
  {
    path: "/room",
    component: <HomeRoom />,
    exact: true,
    breadcrumbName: "Home",
  },
];

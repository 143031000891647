import { styled } from "@mui/material";
import React from "react";
import ImgMid from "../../../../assets/images/body/model_body_front_mid.png";
import { ModelBodyFrontMid } from "./ModelBodyFrontMid";

const SVGWrap = styled("div")(({ theme }) => ({
  margin: "0px auto",
  position: "relative",
  width: "100%",
  height: "100%",
  "& img": {
    // background: "red",
    position: "absolute",
    height: "100%",
    left: "50%",
    transform: "translateX(-50%)",
  },
  "& svg": {
    position: "absolute",
    "& path": {
      fill: "rgba(255, 255, 255, 0.00009)",
      transition: "0.3s",
    },
    // "& path:hover": {
    //   fill: SynckitColors.Yellow[200],
    //   cursor: "pointer",
    // },
  },
}));

export default function BodyFrontMid({ currentPartId }) {
  return (
    <SVGWrap>
      <img src={ImgMid} alt="synckit" />
      <ModelBodyFrontMid currentPartId={currentPartId} />
    </SVGWrap>
  );
}

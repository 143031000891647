import { styled } from "@mui/material";
import React, { useContext } from "react";
import ImgModelMouthAdultUpper from "../../../../assets/images/body/model_mouth_adult_upper.png";
import { ConsultContext } from "../../ConsultIndex";
import { AdultTeethSVGUpper } from "./AdultTeethSVGUpper";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  position: "relative",
}));

const ImgModelBodyWrap = styled("div")(({ theme }) => ({
  position: "absolute",
  height: "90%",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  "& img": {
    height: "100%",
  },
}));
const SVGModelBodyWrap = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  height: "100%",
  "& svg": {
    height: "99%",
  },
  "& path": {
    fill: "rgba(255, 255, 255, 0.00009)",
    transition: "0.3s",
  },
}));

export default function AdultTeethUpper() {
  const { currentPartId, setCurrentPartId } = useContext(ConsultContext);
  return (
    <Root>
      <ImgModelBodyWrap>
        <img src={ImgModelMouthAdultUpper} alt="synckit" />
      </ImgModelBodyWrap>
      <SVGModelBodyWrap>
        <AdultTeethSVGUpper currentPartId={currentPartId} />
      </SVGModelBodyWrap>
    </Root>
  );
}

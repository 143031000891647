import {
  Avatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import avatarImg from "../assets/images/demo/avatar.png";
import { userMenuList } from "./userMenuList";

const CMenu = styled(Menu)(({ theme }) => ({
  borderRadius: 8,
}));
export default function AvatarWithSettingMenu() {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const isOpen = Boolean(menuAnchorEl);
  const handleClick = (e) => {
    setMenuAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setMenuAnchorEl(null);
  };
  return (
    <Fragment>
      <Avatar
        src={avatarImg}
        sx={{ width: 48, height: 48 }}
        onClick={handleClick}
      />
      <CMenu anchorEl={menuAnchorEl} open={isOpen} onClose={handleClose}>
        {userMenuList.map((item) => (
          <MenuItem sx={{ padding: 1 }} key={item.id}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText>{item.title}</ListItemText>
          </MenuItem>
        ))}
      </CMenu>
    </Fragment>
  );
}

import {
  CalendarToday,
  SegmentedGroup,
  SegmentedItem,
  CalendarNext,
  CalendarPrev,
  setOptions,
} from "@mobiscroll/react";
import { useCallback, useEffect, useState } from "react";
import {
  ApoTypeAry,
  APO_TYPE_LOCAL,
  APO_TYPE_REMOTE,
} from "../../assets/constant";
import { styled } from "@mui/system";
import { SynckitColors } from "../../assets/SynckitColors";
import { IconSearch } from "../../assets/SynckitIcons";
import { useNavigate } from "react-router-dom";
import { DemoWorkingDays } from "./shifts/DemoEventList";
import { SynckitEventcalendar } from "../../components/calendar/SynckitEventcalendar";
import { SynckitCalendarNav } from "../../components/calendar/SynckitCalendarNav";
import LocalApoDialog from "../../components/dialog/LocalApoDialog";
import RemoteApoDialog from "../../components/dialog/RemoteApoDialog";
import axios from "axios";

setOptions({
  theme: "ios",
  themeVariant: "light",
});
const EventLabelLocal = styled("div")({
  background: SynckitColors.Gray[800],
  padding: "0px 0px 6px 6px",
  fontSize: 14,
  color: "white",
});
const EventLabelRemote = styled("div")({
  background: "white",
  padding: "0px 0px 6px 6px",
  fontSize: 14,
  color: "#909090",
  boxShadow: `1px solid ${SynckitColors.Gray[500]} !important`,
});

const HeaderWrap = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "2%",
  [theme.breakpoints.down(1000)]: {
    flexDirection: "column",
  },
}));
const HeaderItem = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: 660,
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  transition: "0.3s",

  "&.selectday": {
    [theme.breakpoints.down(1000)]: {
      justifyContent: "space-between",
    },
  },
  "& .mbsc-ios.mbsc-segmented": {
    background: "white",
    borderRadius: 100,
    padding: 0,
    minWidth: 126,
  },
}));

const SearchBar = styled("div")(({ theme }) => ({
  height: 48,
  width: 22,

  borderRadius: 12,
  display: "flex",
  alignItems: "center",
  transition: "0.3s",
  "& .MuiSvgIcon-root": {
    fill: SynckitColors.Primary[600],
    fontSize: 30,
    margin: "10px 8px",
  },
  input: {
    margin: "0px 3px",
    width: "100%",
    height: 38,
    border: "none",
    borderRadius: 12,
    fontSize: 18,
    "&:focus": {
      outline: "none",
    },
  },
  "&:hover": {
    backgroundColor: "white",
    width: "100%",
    maxWidth: 530,
    boxShadow: SynckitColors.Shadow[2],
  },
}));

const CustomizedSegmentedGroup = styled(SegmentedGroup)(({ theme }) => ({}));
const CustomizedSegmentedItem = styled(SegmentedItem)(({ theme }) => ({
  background: "none",
  width: 62,
  fontSize: 14,
  fontWeight: 700,
  letterSpacing: 0.2,
  color: SynckitColors.Gray[700],
  padding: "3px 8px",
  "& .mbsc-selected": {
    background: SynckitColors.Primary[600],
    borderRadius: 100,
    "&.mbsc-segmented-button": {
      transition: "0.3s",
      color: "white",
    },
  },
}));

export default function ScheduleIndex() {
  const [openLocalApoDialog, setOpenLocalApoDialog] = useState(false);
  const [openRemoteApoDialog, setOpenRemoteApoDialog] = useState(false);
  const navigate = useNavigate();
  const [selectedEvent, setSelectedEvent] = useState({});
  const [appointments, setAppointments] = useState([]);
  const [view, setView] = useState("calendar");
  const [isLoadingApoInfo, setIsLoadingApoInfo] = useState(true);
  const [apoInfo, setApoInfo] = useState({});
  const [calView, setCalView] = useState({
    calendar: {
      labels: true,
    },
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await axios({
      method: "get",
      url: "https://synckit.viuto-aiot.com/v1/app/schedule/appointments?filter_status=4",
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NDE3MjAxOTYsInRva2VuX3R5cGUiOiJyZWZyZXNoIiwidXNlcl9pZCI6MjZ9.hxjn0rdrf9DMf49OibnmklSV6vOEySvjJdbgegO-nVwI6GJluju4q7R_m_Lkle1d41BHPevlfaRNBNDxmJeS8Q",
      },
    })
      .then((res) => {
        let tempList = res.data.data.map((item) => ({
          id: item.appointment_id,
          start: item.appointed_date,
          title: item.appointment_type,
          // type: item.appointment_type,
          ...item,
        }));
        setAppointments([...tempList]);
        console.log(res.data.data);
      })
      .catch(() => console.log("can't get appointments data."));
  };

  const fetchApoIfno = async (apoId, apoType) => {
    setIsLoadingApoInfo(true);
    await axios({
      method: "get",
      url: `https://synckit.viuto-aiot.com/v1/app/schedule/appointment/info?appointment_id=${apoId}`,
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NDE3MjAxOTYsInRva2VuX3R5cGUiOiJyZWZyZXNoIiwidXNlcl9pZCI6MjZ9.hxjn0rdrf9DMf49OibnmklSV6vOEySvjJdbgegO-nVwI6GJluju4q7R_m_Lkle1d41BHPevlfaRNBNDxmJeS8Q",
      },
    })
      .then((res) => {
        console.log("apo info res:", res.data.data);
        setTimeout(() => {
          setApoInfo(res.data.data || {});
          setIsLoadingApoInfo(false);
        });
      })
      .catch(() => console.log("Can't get ApoInfo"));
  };

  const onEventClick = useCallback((event) => {
    console.log("onEventClick event:", event);
    fetchApoIfno(event.event.id, event.event.appointment_type);
    setSelectedEvent(event);
    switch (event.event.appointment_type) {
      case APO_TYPE_REMOTE:
        setOpenRemoteApoDialog(true);
        break;
      case APO_TYPE_LOCAL:
        setOpenLocalApoDialog(true);
        break;
      default:
        break;
    }
  }, []);

  const renderLabel = useCallback((data) => {
    if (data.original.appointment_type === APO_TYPE_LOCAL) {
      return (
        <EventLabelLocal>
          {
            ApoTypeAry[data.original.appointment_type] +
              " " +
              data.original.appointed_time.split(":", 2).join(":")
            // format(new Date(data.original.appointed_time), "HH:mm")
          }
        </EventLabelLocal>
      );
    } else {
      return (
        <EventLabelRemote>
          {
            ApoTypeAry[data.original.appointment_type] +
              " " +
              data.original.appointed_time.split(":", 2).join(":")

            // format(new Date(data.original.start), "HH:mm")
          }{" "}
        </EventLabelRemote>
      );
    }
  });

  const changeView = useCallback(
    (event) => {
      let calendarView;
      switch (event.target.value) {
        case "calendar":
          calendarView = {
            calendar: {
              labels: true,
            },
          };
          break;
        case "week":
          calendarView = {
            schedule: {
              type: "week",
              labels: true,
              allDay: false,
            },
          };
          break;
      }
      setView(event.target.value);
      setCalView(calendarView);
    },
    [setView, setCalView]
  );

  const CustomHeader = () => {
    return (
      <HeaderWrap>
        <HeaderItem className="selectday">
          <SynckitCalendarNav />
          <div>
            <CalendarPrev />
            <CalendarToday />
            <CalendarNext />
          </div>
        </HeaderItem>
        <HeaderItem sx={{ justifyContent: "flex-end" }}>
          <SearchBar>
            <IconSearch style={{ width: 18, height: 18 }} />
            <input
              type="text"
              placeholder="Search"
              onKeyUp={(e) => e.keyCode === 13 && navigate("/schedule/search")}
            />
          </SearchBar>
          <CustomizedSegmentedGroup value={view} onChange={changeView}>
            <CustomizedSegmentedItem value="calendar">
              Month
            </CustomizedSegmentedItem>
            <CustomizedSegmentedItem value="week">Week</CustomizedSegmentedItem>
          </CustomizedSegmentedGroup>
        </HeaderItem>
      </HeaderWrap>
    );
  };

  return (
    <>
      <SynckitEventcalendar
        theme="ios"
        themeVariant="light"
        clickToCreate={false}
        dragToCreate={false}
        dragToMove={false}
        dragToResize={false}
        data={appointments}
        view={calView}
        renderHeader={CustomHeader}
        onEventClick={onEventClick}
        renderLabel={renderLabel}
        // renderEventContent={"123"}
        // selectedDate={currentDate}
        colors={DemoWorkingDays}
        actionableEvents={false}
        showEventTooltip={false}
      />
      {openRemoteApoDialog && (
        <RemoteApoDialog
          open={openRemoteApoDialog}
          setOpen={setOpenRemoteApoDialog}
          // eventData={selectedEvent}
          apoInfo={apoInfo}
          isLoadingApoInfo={isLoadingApoInfo}
        />
      )}
      {openLocalApoDialog && (
        <LocalApoDialog
          open={openLocalApoDialog}
          setOpen={setOpenLocalApoDialog}
          // eventData={selectedEvent}
          apoInfo={apoInfo}
          isLoadingApoInfo={isLoadingApoInfo}
        />
      )}
    </>
  );
}

import { Button, TextField } from "@mui/material";
import { styled } from "@mui/styles";
import React, { useState } from "react";
import { SynckitColors } from "../../../../../assets/SynckitColors";

const Root = styled("div")(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  width: "100%",
  padding: "0em 1em 1em 1em",
  zIndex: 99,
  "& .MuiOutlinedInput-root": {
    background: "rgba(0, 0, 0, 0.2)",
    color: SynckitColors.Gray[600],
    "& fieldset": {
      borderColor: SynckitColors.Gray[600],
    },
    "&:hover fieldset": {
      borderColor: SynckitColors.Gray[200],
    },
    "&.Mui-focused fieldset": {
      borderColor: SynckitColors.Gray[200],
    },
  },
}));
const ButtonWrap = styled("div")(({ theme }) => ({
  color: SynckitColors.Gray[600],
  "& .MuiButton-root": {
    color: SynckitColors.Gray[600],
    "&.selectedMode": {
      color: SynckitColors.Gray[200],
    },
  },
}));

export default function CommentSection({ setDisplayMode }) {
  const [commentMode, setCommentMode] = useState("doctor");
  const [doctorComment, setDoctorComment] = useState("");
  const [patientComment, setPatientComment] = useState("");

  return (
    <Root>
      <ButtonWrap>
        <Button
          onClick={() => setCommentMode("doctor")}
          className={commentMode === "doctor" ? "selectedMode" : null}
        >
          Doctor
        </Button>
        |
        <Button
          onClick={() => setCommentMode("patient")}
          className={commentMode === "patient" ? "selectedMode" : null}
        >
          Patient
        </Button>
      </ButtonWrap>
      {commentMode === "doctor" ? (
        <TextField
          multiline
          rows={3}
          color="gray"
          fullWidth
          placeholder="Doctor's comment"
          value={doctorComment}
          onChange={(e) => setDoctorComment(e.target.value)}
        />
      ) : (
        <TextField
          multiline
          rows={3}
          color="primary"
          fullWidth
          placeholder="Patient's comment"
          value={patientComment}
          onChange={(e) => setPatientComment(e.target.value)}
        />
      )}
    </Root>
  );
}

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  TextField,
} from "@mui/material";
import React from "react";
import { SynckitDialog } from "../../../../components/dialog/SynckitDialog";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import _ from "lodash";
import { useEffect } from "react";
import {
  TIME_SELECTER_ADD_MODE,
  TIME_SELECTER_EDIT_MODE,
} from "../../../../assets/constant";

export default function TimeSelecterDialog({
  open,
  setOpen,
  selectedDayId = null,
  selectedShift = null,
  dataList = [],
  setDataList = null,
  mode = TIME_SELECTER_ADD_MODE,
}) {
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [startTimeOptions, setStartTimeOptions] = useState(OriginTimeList);
  const [endTimeOptions, setEndTimeOptions] = useState(OriginTimeList);

  useEffect(() => {
    //每次開啟，先去除目前已排時程（因為時段不會重複）
    //將篩選過得清單設定給startTime的選項
    if (open) {
      setStartTimeOptions(OriginTimeList);
      setEndTimeOptions(OriginTimeList);
      let shifts = _.filter(dataList, { id: selectedDayId })[0]["shifts"];
      let tempList = OriginTimeList;

      switch (mode) {
        case TIME_SELECTER_EDIT_MODE: //編輯（更新）模式，先代入原先時間
          setStartTime(selectedShift.start);
          setEndTime(selectedShift.end);

          //排除已選的時段(不含目前更新的時間)
          shifts.forEach((shift) => {
            if (shift.start !== selectedShift.start) {
              tempList = tempList.filter(
                (time) => time < shift.start || time > shift.end
              );
            }
          });
          setStartTimeOptions([...tempList]);
          break;
        case TIME_SELECTER_ADD_MODE:
        default:
          setStartTime(null);
          setEndTime(null);
          //目前已有排程（shifts不為0）才需要排除
          if (shifts.length !== 0) {
            let tempList = OriginTimeList;

            //排除已選的時段
            shifts.forEach(
              (shift) =>
                (tempList = tempList.filter(
                  (time) => time < shift.start || time > shift.end
                ))
            );

            setStartTimeOptions([...tempList]);
          }
          break;
      }
    }
  }, [open]);

  const handleStartTimeChange = (e) => {
    let currentStartTime = e.target.value;
    setStartTime(currentStartTime);
    //startTime選定變更之後，依其值去設定EndTime清單
    setEndTime(null);

    let shifts = _.filter(dataList, { id: selectedDayId })[0]["shifts"];
    //endtime 必須大於 目前所選starttime的時間
    let tempOptions = startTimeOptions.filter(
      (option) => option > currentStartTime
    );

    //以目前所選starttime的時間為參考，去除後面已選時段
    shifts.forEach((shift) => {
      if (shift.start > currentStartTime) {
        tempOptions = tempOptions.filter((option) => option < shift.start);
      }
    });

    setEndTimeOptions([...tempOptions]);
  };

  const handleAddShift = () => {
    _.filter(dataList, { id: selectedDayId })[0]["shifts"].push({
      start: startTime,
      end: endTime,
    });
    setOpen(false);
  };

  const handleUpdate = () => {
    let tempList = dataList.map((day) =>
      day.id !== selectedDayId
        ? day
        : {
            ...day,
            shifts: day.shifts.map((shift) =>
              shift.start !== selectedShift.start
                ? shift
                : { start: startTime, end: endTime }
            ),
          }
    );
    setDataList([...tempList]);
    setOpen(false);
  };

  return (
    <SynckitDialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle sx={{ textAlign: "right" }}>
        <IconButton onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          margin: 0,
          width: 356,
          display: "flex",
          "&.MuiDialogContent-root": { paddingTop: 1 },
        }}
      >
        <TextField
          select
          label="Start Time"
          fullWidth
          size="small"
          value={startTime}
          onChange={(e) => handleStartTimeChange(e)}
        >
          {startTimeOptions.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </TextField>
        {"　"}
        <TextField
          select
          label="End Time"
          fullWidth
          value={endTime}
          onChange={(e) => setEndTime(e.target.value)}
          size="small"
          disabled={!startTime}
        >
          {endTimeOptions.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        {mode === TIME_SELECTER_ADD_MODE && (
          <Button
            variant="primary"
            sx={{ width: 140, margin: "0px auto 14px" }}
            onClick={handleAddShift}
            disabled={!startTime || !endTime}
          >
            Add
          </Button>
        )}
        {mode === TIME_SELECTER_EDIT_MODE && (
          <Button
            variant="primary"
            sx={{ width: 140, margin: "0px auto 14px" }}
            onClick={handleUpdate}
            disabled={!startTime || !endTime}
          >
            Save
          </Button>
        )}
      </DialogActions>
    </SynckitDialog>
  );
}

const OriginTimeList = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
  "24:00",
];

import { Box } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import { SynckitButton } from "../../../components/SynckitButton";
import BasicInfoForm from "./BasicInfoForm";
import MedicalRecord from "./MedicalRecord";
import ResidenceInfoForm from "./ResidenceInfoForm";
import TermsAndConditions from "./TermsAndConditions";

export default function AddNewPatientIndex() {
  const navigate = useNavigate();

  return (
    <>
      <BasicInfoForm />
      <ResidenceInfoForm />
      <MedicalRecord />
      <TermsAndConditions />
      <Box sx={{ textAlign: "center" }}>
        <SynckitButton
          variant="contained"
          sx={{ width: 257, margin: "0px auto" }}
          onClick={() => {
            // setOpenSnackbar(true);
            navigate("/pm");
          }}
        >
          Submit
        </SynckitButton>
      </Box>
    </>
  );
}

import { Typography } from "@mui/material";
import React from "react";
import { SynckitColors } from "../../../../../assets/SynckitColors";
import ContactsTable from "./ContactsTable";

export default function ContactsIndex() {
  return (
    <div>
      <Typography
        variant="h5"
        sx={{ color: SynckitColors.Gray["core"], marginBottom: 2 }}
      >
        Share to contacts
      </Typography>
      <ContactsTable />
    </div>
  );
}

import { Box, IconButton, styled, Typography } from "@mui/material";
import React, { useState } from "react";
import { SynckitColors } from "../../assets/SynckitColors";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Root = styled("div")(({ theme }) => ({
  minHeight: 72,
  background: SynckitColors.Primary[600],
  padding: "0px 24px",
  boxShadow: SynckitColors.Shadow[4],
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));
export default function HeaderBar({ setOpen }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentItem, setCurrentItem] = useState(dataList[0]);

  const handleChangeItem = (num) => {
    let tempNum = currentIndex + num;

    if (tempNum < 0) tempNum = dataList.length - 1;
    else if (tempNum >= dataList.length) tempNum = 0;

    setTimeout(() => {
      setCurrentIndex(tempNum);
      setCurrentItem({ ...dataList[tempNum] });
    }, 100);
  };

  return (
    <Root>
      <Typography variant="h5" sx={{ color: "white" }}>
        Examination record
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton
          size="small"
          sx={{ background: SynckitColors.Primary[300] }}
          onClick={() => handleChangeItem(-1)}
        >
          <ArrowBackIcon sx={{ color: SynckitColors.Primary[100] }} />
        </IconButton>
        <Box sx={{ textAlign: "center", width: 200 }}>
          <Typography variant="h5" sx={{ color: "white" }}>
            {currentItem.id}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ color: SynckitColors.Primary[200] }}
          >
            {currentItem.datetime}
          </Typography>
        </Box>
        <IconButton
          size="small"
          sx={{ background: SynckitColors.Primary[300] }}
          onClick={() => handleChangeItem(1)}
        >
          <ArrowForwardIcon sx={{ color: SynckitColors.Primary[100] }} />
        </IconButton>
      </Box>
      <IconButton onClick={() => setOpen(false)}>
        <CloseIcon sx={{ color: "white" }} />
      </IconButton>
    </Root>
  );
}

const dataList = [
  {
    id: "G7S4620",
    datetime: "2022.03.23．09:00",
  },
  {
    id: "G7S4621",
    datetime: "2022.03.24．10:00",
  },
  {
    id: "G7S4622",
    datetime: "2022.03.25．11:00",
  },
  {
    id: "G7S4623",
    datetime: "2022.03.26．12:00",
  },
  {
    id: "G7S4624",
    datetime: "2022.03.27．13:00",
  },
  {
    id: "G7S4625",
    datetime: "2022.03.28．14:00",
  },
];

import { SynckitColors } from "../../../../assets/SynckitColors";

export const ModelBodyBackTop = ({ currentPartId = "" }) => {
  return (
    <svg viewBox="58 112 196 215" fill="none">
      <path
        d="M169.458 179.069C171.588 175.749 174.918 166.539 174.918 166.539C175.018 165.949 175.408 166.149 175.408 166.149C178.088 166.679 179.818 163.999 179.818 163.999C183.038 159.419 181.848 152.289 181.848 152.289C181.428 148.439 179.368 149.809 179.368 149.809C178.738 150.159 178.598 149.389 178.598 149.389C179.648 139.979 175.938 132.029 175.938 132.029C172.508 121.659 164.258 118.599 164.258 118.599C157.178 115.309 149.198 117.129 149.198 117.129C140.338 118.479 136.048 123.749 136.048 123.749C133.908 126.099 133.448 127.189 131.628 130.609C129.808 134.039 129.008 138.659 129.008 138.659C128.118 142.199 128.638 147.419 128.638 147.419C128.718 148.469 129.008 150.119 129.008 150.119C128.908 150.869 128.208 150.279 128.208 150.279C127.788 149.409 126.908 149.859 126.908 149.859C123.348 152.029 125.978 160.999 125.978 160.999C127.238 166.219 131.858 166.289 131.858 166.289C132.098 166.329 132.288 166.409 132.438 166.509C132.898 166.809 132.998 167.269 132.998 167.269C133.978 171.809 136.908 177.199 136.908 177.199C137.288 177.819 137.768 179.019 138.168 180.079H169.168C169.298 179.409 169.458 179.069 169.458 179.069Z"
        stroke=""
        id="r1"
        style={{
          fill: currentPartId === "r1" ? SynckitColors.Yellow[200] : "",
        }}
      />
      <path
        d="M172.06 198.85C168.66 194.91 168.75 188.64 168.75 188.64L168.97 182.22C168.97 181.3 169.06 180.61 169.16 180.1H138.16C138.56 181.18 138.87 182.11 138.87 182.11C139.85 191.48 136.7 199.31 136.7 199.31C135.31 202.06 131.12 204.68 127.18 206.66H181.42C175.41 202.99 172.06 198.85 172.06 198.85Z"
        stroke=""
        id="r2"
        style={{
          fill: currentPartId === "r2" ? SynckitColors.Yellow[200] : "",
        }}
      />
      <path
        d="M202.04 215.58C197.05 213.62 189.41 210.41 189.41 210.41C186.38 209.4 183.72 208.06 181.44 206.67H127.2C123.02 208.77 119.12 210.13 119.12 210.13C114.65 211.62 107.7 214.88 107.7 214.88C104.77 216.02 102.27 217.07 100.25 217.96C101.15 230.18 103.91 264.05 107.96 281.72C108.8 280.35 109.56 282.61 109.56 282.61L113.19 292.99H197.36C199.23 287.3 200.84 282.23 200.84 282.23C201.34 280.78 201.76 280.51 202.07 280.68C205.94 263 208.59 230.86 209.52 218.47C205.93 216.95 202.04 215.58 202.04 215.58Z"
        stroke=""
        id="r3"
        style={{
          fill: currentPartId === "r3" ? SynckitColors.Yellow[200] : "",
        }}
      />
      <path
        d="M116.169 301.54C118.039 306.11 117.099 317.02 117.099 317.02C117.189 320 113.649 344.8 113.649 344.8C112.439 349.65 108.519 369.97 108.519 369.97C108.219 371.6 107.749 374.46 107.199 377.83H200.359C199.469 372.27 198.539 366.57 198.539 366.57C198.169 362.47 195.479 349.93 195.479 349.93C194.609 347.69 194.049 344.13 194.049 344.13C194.049 338.12 191.879 319.03 191.879 319.03C190.969 313.58 192.159 307.98 192.159 307.98C193.099 305.82 195.369 299.05 197.349 293H113.179L116.169 301.54Z"
        stroke=""
        id="r4"
        style={{
          fill: currentPartId === "r4" ? SynckitColors.Yellow[200] : "",
        }}
      />
      <path
        d="M101.44 307.701L107.41 283.181C107.59 282.491 107.78 282.031 107.96 281.731C103.9 264.051 101.15 230.191 100.25 217.971C96.5596 219.601 94.5096 220.671 94.5096 220.671C84.3496 226.381 81.7596 236.521 81.7596 236.521C79.9396 243.161 79.9696 254.331 79.9696 254.331C79.9696 257.221 80.9496 271.671 80.9496 271.671C81.8796 280.951 81.4197 286.631 81.4197 286.631C81.3297 288.451 79.1797 305.881 79.1797 305.881C79.0397 309.701 75.7797 326.111 75.7797 326.111C75.5797 327.081 75.2697 328.341 74.9297 329.671H97.4896C97.8796 321.701 101.44 307.701 101.44 307.701Z"
        stroke=""
        id="r6"
        style={{
          fill: currentPartId === "r6" ? SynckitColors.Yellow[200] : "",
        }}
      />
      <path
        d="M231.239 309.51C230.889 303.15 228.719 282.94 228.719 282.94C228.159 277.34 229.209 266.79 229.209 266.79C231.659 251.97 228.579 242.11 228.579 242.11C225.499 225.89 214.659 221.13 214.659 221.13C213.649 220.36 211.629 219.39 209.489 218.48C208.569 230.87 205.919 263.01 202.039 280.69C202.459 280.92 202.699 281.96 202.699 281.96L208.719 305.31C209.349 307.83 212.979 328.8 212.979 328.8C213.019 329.1 213.059 329.37 213.099 329.65H234.639C233.239 323.08 231.239 309.51 231.239 309.51Z"
        stroke=""
        id="r14"
        style={{
          fill: currentPartId === "r14" ? SynckitColors.Yellow[200] : "",
        }}
      />
    </svg>
  );
};

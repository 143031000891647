import { SynckitColors } from "../../../../assets/SynckitColors";

export const ModelBodyBack = ({ currentPartId = "", handleClick }) => {
  return (
    <svg viewBox="-5 113 320 582" onClick={handleClick}>
      <path
        d="M169.458 179.069C171.588 175.749 174.918 166.539 174.918 166.539C175.018 165.949 175.408 166.149 175.408 166.149C178.088 166.679 179.818 163.999 179.818 163.999C183.038 159.419 181.848 152.289 181.848 152.289C181.428 148.439 179.368 149.809 179.368 149.809C178.738 150.159 178.598 149.389 178.598 149.389C179.648 139.979 175.938 132.029 175.938 132.029C172.508 121.659 164.258 118.599 164.258 118.599C157.178 115.309 149.198 117.129 149.198 117.129C140.338 118.479 136.048 123.749 136.048 123.749C133.908 126.099 133.448 127.189 131.628 130.609C129.808 134.039 129.008 138.659 129.008 138.659C128.118 142.199 128.638 147.419 128.638 147.419C128.718 148.469 129.008 150.119 129.008 150.119C128.908 150.869 128.208 150.279 128.208 150.279C127.788 149.409 126.908 149.859 126.908 149.859C123.348 152.029 125.978 160.999 125.978 160.999C127.238 166.219 131.858 166.289 131.858 166.289C132.098 166.329 132.288 166.409 132.438 166.509C132.898 166.809 132.998 167.269 132.998 167.269C133.978 171.809 136.908 177.199 136.908 177.199C137.288 177.819 137.768 179.019 138.168 180.079H169.168C169.298 179.409 169.458 179.069 169.458 179.069Z"
        stroke=""
        style={{
          fill: currentPartId === "r1" ? SynckitColors.Yellow[200] : "",
        }}
        id="r1"
      />
      <path
        d="M172.06 198.85C168.66 194.91 168.75 188.64 168.75 188.64L168.97 182.22C168.97 181.3 169.06 180.61 169.16 180.1H138.16C138.56 181.18 138.87 182.11 138.87 182.11C139.85 191.48 136.7 199.31 136.7 199.31C135.31 202.06 131.12 204.68 127.18 206.66H181.42C175.41 202.99 172.06 198.85 172.06 198.85Z"
        stroke=""
        style={{
          fill: currentPartId === "r2" ? SynckitColors.Yellow[200] : "",
        }}
        id="r2"
      />
      <path
        d="M202.04 215.58C197.05 213.62 189.41 210.41 189.41 210.41C186.38 209.4 183.72 208.06 181.44 206.67H127.2C123.02 208.77 119.12 210.13 119.12 210.13C114.65 211.62 107.7 214.88 107.7 214.88C104.77 216.02 102.27 217.07 100.25 217.96C101.15 230.18 103.91 264.05 107.96 281.72C108.8 280.35 109.56 282.61 109.56 282.61L113.19 292.99H197.36C199.23 287.3 200.84 282.23 200.84 282.23C201.34 280.78 201.76 280.51 202.07 280.68C205.94 263 208.59 230.86 209.52 218.47C205.93 216.95 202.04 215.58 202.04 215.58Z"
        stroke=""
        style={{
          fill: currentPartId === "r3" ? SynckitColors.Yellow[200] : "",
        }}
        id="r3"
      />
      <path
        d="M116.169 301.54C118.039 306.11 117.099 317.02 117.099 317.02C117.189 320 113.649 344.8 113.649 344.8C112.439 349.65 108.519 369.97 108.519 369.97C108.219 371.6 107.749 374.46 107.199 377.83H200.359C199.469 372.27 198.539 366.57 198.539 366.57C198.169 362.47 195.479 349.93 195.479 349.93C194.609 347.69 194.049 344.13 194.049 344.13C194.049 338.12 191.879 319.03 191.879 319.03C190.969 313.58 192.159 307.98 192.159 307.98C193.099 305.82 195.369 299.05 197.349 293H113.179L116.169 301.54Z"
        stroke=""
        style={{
          fill: currentPartId === "r4" ? SynckitColors.Yellow[200] : "",
        }}
        id="r4"
      />
      <path
        d="M103.21 403.17C101.69 410.38 100.64 420.85 99.9805 429.17H150.49C150.91 422.64 149.57 419.79 149.57 419.79C148.63 417.62 149.531 417.31 149.531 417.31C150.371 416.3 151.171 413.81 151.171 413.81C151.311 412.9 152.08 412.9 152.08 412.9C152.64 413.32 154.281 412.97 154.281 412.97C155.081 412.9 155.26 413.46 155.26 413.46C155.72 414.93 157.95 418.28 157.95 418.28C158.44 419.01 158.271 419.54 158.271 419.54C157.321 421.89 157.181 425.88 157.271 429.17H206.94C206.59 424.73 206.281 421.25 206.281 421.25C205.791 413.7 202.46 391.65 201.71 386.52C201.43 384.57 200.9 381.23 200.36 377.83H107.2C105.54 388.1 103.21 403.17 103.21 403.17Z"
        stroke=""
        style={{
          fill: currentPartId === "r5" ? SynckitColors.Yellow[200] : "",
        }}
        id="r5"
      />
      <path
        d="M101.44 307.701L107.41 283.181C107.59 282.491 107.78 282.031 107.96 281.731C103.9 264.051 101.15 230.191 100.25 217.971C96.5596 219.601 94.5096 220.671 94.5096 220.671C84.3496 226.381 81.7596 236.521 81.7596 236.521C79.9396 243.161 79.9696 254.331 79.9696 254.331C79.9696 257.221 80.9496 271.671 80.9496 271.671C81.8796 280.951 81.4197 286.631 81.4197 286.631C81.3297 288.451 79.1797 305.881 79.1797 305.881C79.0397 309.701 75.7797 326.111 75.7797 326.111C75.5797 327.081 75.2697 328.341 74.9297 329.671H97.4896C97.8796 321.701 101.44 307.701 101.44 307.701Z"
        stroke=""
        style={{
          fill: currentPartId === "r6" ? SynckitColors.Yellow[200] : "",
        }}
        id="r6"
      />
      <path
        d="M97.5295 332.96C97.4095 332.08 97.3995 330.95 97.4595 329.67H74.8995C73.9795 333.24 72.8195 337.25 72.8195 337.25C70.5795 343.64 69.7395 350.21 69.7395 350.21C69.6595 350.65 69.5895 351.2 69.5195 351.83H97.2996C99.1296 342.12 97.5295 332.96 97.5295 332.96Z"
        stroke=""
        style={{
          fill: currentPartId === "r7" ? SynckitColors.Yellow[200] : "",
        }}
        id="r7"
      />
      <path
        d="M80.1419 401.18C81.3319 397.75 84.2018 389.92 84.2018 389.92C86.1618 385.72 95.2018 359.15 95.2018 359.15C96.1818 356.76 96.8519 354.28 97.3119 351.82H69.5319C68.8019 358.57 68.4919 375.23 68.4919 375.23C68.3519 379.66 67.3218 393.36 67.3218 393.36C66.9018 395.92 66.5818 405.25 66.5818 405.25C66.5018 405.96 66.5019 406.65 66.5419 407.3H79.9518C78.9318 404.44 80.1419 401.18 80.1419 401.18Z"
        stroke=""
        style={{
          fill: currentPartId === "r8" ? SynckitColors.Yellow[200] : "",
        }}
        id="r8"
      />
      <path
        d="M68.3885 419.76C68.7185 423.16 69.5586 431.04 69.5586 431.04C70.0286 436.45 73.3985 447.77 73.4985 448.4C73.5985 449.03 74.0186 449.54 74.0186 449.54C74.1986 449.87 74.9685 451.66 74.9685 451.66L77.4885 459.33C77.8185 460.15 78.4186 459.14 78.4186 459.14C78.9286 458.56 80.0685 459.63 80.0685 459.63L81.9985 462.64C82.2985 463.24 82.3285 463.18 82.3285 463.18C83.3285 463.23 83.4286 461.39 83.4286 461.39C83.7986 461.34 84.0386 461.16 84.0386 461.16C85.2286 460.34 83.9685 457.34 83.9685 457.34C83.3885 456.57 83.7385 456.08 83.7385 456.08C84.5785 456.31 84.8185 455.28 84.8185 455.28C85.1585 453.64 82.8485 450.74 82.8485 450.74C82.4985 450.18 82.0486 449.06 82.0486 449.06C81.6286 446.9 80.3385 444.83 80.2285 444.59C80.1185 444.34 79.5685 443.19 79.5685 443.19C79.4685 441.69 78.8685 437.53 78.8685 437.53C78.4485 436.06 78.6586 435.75 78.6586 435.75C79.4986 434.77 79.8086 436.59 79.8086 436.59C80.8086 441.11 82.4685 440.51 82.4685 440.51C85.1485 440.04 84.5985 434.18 84.5985 434.18C84.5285 433.55 84.4286 431.7 84.4286 431.7C84.8486 429.04 84.2886 423.03 84.2886 423.03C84.6386 412.96 80.8585 408.98 80.8585 408.98C80.4485 408.47 80.1685 407.91 79.9685 407.35H66.5586C66.6886 409.4 67.2485 410.99 67.2485 410.99C67.5485 412.72 68.3885 419.76 68.3885 419.76Z"
        stroke=""
        style={{
          fill: currentPartId === "r9" ? SynckitColors.Yellow[200] : "",
        }}
        id="r9"
      />
      <path
        d="M100.51 474.77C100.98 481.48 104.61 503.3 104.61 503.3C104.83 504.83 104.95 506.36 105 507.84H135.37C135.37 507.46 135.36 507.14 135.33 506.89C135.14 504.79 136.54 498.08 136.54 498.08C136.54 498.08 140.64 478.87 141.57 476.17C142.5 473.47 145.53 457.76 146.84 453.37C148.14 448.99 150.1 432.86 150.1 432.86C150.29 431.51 150.41 430.29 150.48 429.17H99.9702C99.3802 436.67 99.1002 442.42 99.1002 442.42C98.6402 452.39 100.51 474.77 100.51 474.77Z"
        stroke=""
        style={{
          fill: currentPartId === "r10" ? SynckitColors.Yellow[200] : "",
        }}
        id="r10"
      />
      <path
        d="M134.49 518.68C134.9 517.12 135.39 510.74 135.36 507.83H105C105.25 515.09 103.77 521.24 103.77 521.24C101.81 528.86 99.9297 541.03 99.9297 541.03C99.6197 542.47 99.3797 543.78 99.1797 545H129.69C129.98 543.95 130.28 543.48 130.28 543.48C134.05 538.45 134.02 520.45 134.49 518.68Z"
        stroke=""
        style={{
          fill: currentPartId === "r11" ? SynckitColors.Yellow[200] : "",
        }}
        id="r11"
      />
      <path
        d="M127.962 591.59C130.062 582.92 129.992 571.45 129.992 571.45C129.922 563.13 129.172 550.43 129.172 550.43C129.072 547.87 129.372 546.11 129.672 545H99.162C98.252 550.45 98.1819 554.12 97.3319 560.12C96.2819 567.46 96.422 575.64 96.422 575.64C96.282 579.55 98.032 598.72 98.032 598.72C98.242 602.63 101.322 626.2 101.322 626.2C101.332 626.25 101.342 626.32 101.352 626.38H120.782C121.262 620.73 121.912 615.11 122.782 612.01C124.362 606.41 127.962 591.59 127.962 591.59Z"
        stroke=""
        style={{
          fill: currentPartId === "r12" ? SynckitColors.Yellow[200] : "",
        }}
        id="r12"
      />
      <path
        d="M102.511 640.081C102.651 644.871 101.021 652.861 101.021 652.861L99.8113 657.381C99.8413 657.681 99.7112 657.761 99.7112 657.761C97.0112 657.571 96.0712 658.961 96.0712 658.961C95.9412 659.211 95.6213 659.131 95.6213 659.131C91.9513 658.741 91.1113 660.681 91.1113 660.681C91.0713 661.001 90.7012 660.941 90.7012 660.941C86.8812 661.141 87.7813 663.921 87.7813 663.921C87.8113 664.351 87.4113 664.431 87.4113 664.431C86.0713 664.341 85.1613 664.841 85.1613 664.841C83.2913 666.331 83.6012 668.421 83.6012 668.421C83.6012 669.451 84.3913 669.911 84.3913 669.911C84.6913 670.281 84.6713 670.291 84.6713 670.291C86.1013 675.111 92.2212 677.281 92.2212 677.281C93.2312 677.591 94.4213 678.121 94.4213 678.121L99.6713 683.471C105.301 689.101 111.331 688.131 111.331 688.131C119.581 688.411 120.351 682.961 120.351 682.961C121.891 681.091 120.211 667.251 120.211 667.251C120.121 665.531 120.301 665.291 120.301 665.291C121.421 662.911 121.021 656.621 121.021 656.621C120.391 651.731 119.971 638.021 119.971 638.021C120.151 634.821 120.411 630.591 120.771 626.381H101.341C101.881 629.341 102.511 640.081 102.511 640.081Z"
        stroke=""
        style={{
          fill: currentPartId === "r13" ? SynckitColors.Yellow[200] : "",
        }}
        id="r13"
      />
      <path
        d="M231.239 309.51C230.889 303.15 228.719 282.94 228.719 282.94C228.159 277.34 229.209 266.79 229.209 266.79C231.659 251.97 228.579 242.11 228.579 242.11C225.499 225.89 214.659 221.13 214.659 221.13C213.649 220.36 211.629 219.39 209.489 218.48C208.569 230.87 205.919 263.01 202.039 280.69C202.459 280.92 202.699 281.96 202.699 281.96L208.719 305.31C209.349 307.83 212.979 328.8 212.979 328.8C213.019 329.1 213.059 329.37 213.099 329.65H234.639C233.239 323.08 231.239 309.51 231.239 309.51Z"
        stroke=""
        style={{
          fill: currentPartId === "r14" ? SynckitColors.Yellow[200] : "",
        }}
        id="r14"
      />
      <path
        d="M235.301 332.31C235.111 331.7 234.891 330.78 234.651 329.67H213.111C214.001 336.23 213.601 337.25 213.601 337.25C212.731 342.85 212.641 347.79 212.891 351.83H239.851C238.591 343.05 235.301 332.31 235.301 332.31Z"
        stroke=""
        style={{
          fill: currentPartId === "r15" ? SynckitColors.Yellow[200] : "",
        }}
        id="r15"
      />
      <path
        d="M240.699 405.52C241.069 395.59 240.699 391.89 240.699 391.89C240.089 385.52 240.399 376.72 240.399 376.72C241.239 366.44 240.049 353.43 240.049 353.43C239.989 352.91 239.919 352.38 239.839 351.84H212.879C213.319 359.04 214.809 363.36 214.809 363.36L219.279 378.74L225.339 399.02C225.339 399.02 227.329 405.75 227.709 406.25C227.709 406.25 227.809 406.68 227.889 407.31H240.759C240.749 406.23 240.699 405.52 240.699 405.52Z"
        stroke=""
        style={{
          fill: currentPartId === "r16" ? SynckitColors.Yellow[200] : "",
        }}
        id="r16"
      />
      <path
        d="M227.892 407.311C228.012 408.251 228.102 409.651 227.772 410.811C224.082 414.911 224.792 424.421 224.792 424.421C224.702 427.081 224.392 433.991 224.392 433.991C224.202 435.301 224.022 436.491 224.022 436.491C223.642 438.901 224.042 441.541 224.042 441.541C223.952 443.511 226.802 443.111 226.802 443.111C227.202 443.131 227.292 443.351 227.292 443.351C227.252 444.031 227.032 445.531 227.032 445.531C226.922 446.231 226.122 447.651 226.122 447.651C225.072 449.561 224.762 451.141 224.762 451.141C224.742 451.491 224.362 452.101 224.362 452.101C222.922 453.591 222.062 455.341 222.062 455.341C221.082 457.221 221.932 458.021 221.932 458.021C222.822 458.651 224.282 456.331 224.282 456.331C225.292 455.041 226.342 454.681 226.342 454.681C227.212 454.891 225.862 456.641 225.862 456.641C225.032 457.641 223.602 459.701 223.602 459.701C222.262 461.841 223.272 463.561 223.272 463.561C224.162 464.831 225.242 462.741 225.242 462.741C225.832 462.371 225.782 463.011 225.782 463.011C225.532 465.591 226.232 465.771 226.232 465.771C227.282 466.821 229.132 462.441 229.132 462.441C229.702 461.681 230.432 461.951 230.432 461.951C230.942 462.041 231.552 460.741 231.552 460.741C231.812 460.211 232.372 458.621 232.372 458.621C234.332 456.101 235.842 449.391 235.842 449.391C236.312 448.131 236.822 444.341 236.822 444.341C236.802 442.891 237.172 441.541 237.172 441.541C238.152 439.611 238.222 437.621 238.272 436.581C238.322 435.531 238.292 434.321 238.292 434.321C238.762 431.041 239.272 422.641 239.272 422.641C239.202 418.441 239.622 416.141 239.622 416.141C240.652 413.371 240.782 409.531 240.752 407.301H227.892V407.311Z"
        stroke=""
        style={{
          fill: currentPartId === "r17" ? SynckitColors.Yellow[200] : "",
        }}
        id="r17"
      />
      <path
        d="M157.612 434.61C157.612 439.5 163.412 466.22 163.412 466.22C165.792 475.24 170.472 496.08 170.472 496.08C171.752 499.62 172.402 504.16 172.732 507.84H202.102C202.122 507.38 202.142 507.13 202.142 507.13C201.932 503.92 203.892 494.9 203.892 494.9C205.012 492.1 206.342 472.87 206.342 472.87C207.462 466.86 207.882 444.69 207.882 444.69C207.802 440.88 207.342 434.37 206.932 429.18H157.262C157.352 432.19 157.612 434.61 157.612 434.61Z"
        stroke=""
        style={{
          fill: currentPartId === "r18" ? SynckitColors.Yellow[200] : "",
        }}
        id="r18"
      />
      <path
        d="M173.068 514.43C172.648 523.24 175.308 538.1 175.308 538.1C175.578 539.48 176.438 542.78 177.028 545H207.628C206.718 538.55 205.438 531.24 205.438 531.24C205.298 525.37 203.618 520.33 203.618 520.33C201.998 514.59 202.028 509.62 202.108 507.83H172.738C173.068 511.57 173.068 514.43 173.068 514.43Z"
        stroke=""
        style={{
          fill: currentPartId === "r19" ? SynckitColors.Yellow[200] : "",
        }}
        id="r19"
      />
      <path
        d="M177.61 547.18C178.59 551.24 177.14 569.02 177.14 569.02C175.37 581.98 181.62 602.31 181.62 602.31C183.11 606.97 186.42 622.68 186.42 622.68C186.66 623.77 186.84 625.03 186.98 626.37H205.09C205.55 621.64 206.14 617.41 206.14 617.41C207.63 608.55 209.68 584.5 209.68 584.5C211.08 571.26 208.51 552.99 208.51 552.99C208.44 551.22 208.08 548.22 207.62 545H177.02C177.36 546.27 177.61 547.18 177.61 547.18Z"
        stroke=""
        style={{
          fill: currentPartId === "r20" ? SynckitColors.Yellow[200] : "",
        }}
        id="r20"
      />
      <path
        d="M187.311 640.629C187.361 643.989 186.571 655.079 186.571 655.079C186.011 660.949 186.681 662.889 186.681 662.889C186.891 663.539 187.261 664.729 187.261 664.729L186.981 673.069C187.031 678.809 186.911 682.349 186.911 682.349C186.871 689.059 193.761 688.679 193.761 688.679C198.551 689.129 202.081 687.979 202.081 687.979C206.061 686.409 210.851 679.549 210.851 679.549C211.871 677.699 212.881 677.309 212.881 677.309C215.331 676.609 218.371 673.809 218.371 673.809C220.261 671.989 220.151 669.689 220.151 669.689C220.011 668.949 220.111 668.949 220.111 668.949C220.741 668.289 221.051 667.129 221.051 667.129C221.091 663.459 218.151 663.669 218.151 663.669C216.821 663.849 216.931 662.799 216.931 662.799C216.791 660.209 214.341 660.459 214.341 660.459C213.471 660.499 213.331 660.249 213.331 660.249C212.351 658.079 209.831 659.379 209.831 659.379C209.131 659.589 209.131 659.379 209.131 659.379C208.811 658.329 207.451 658.049 207.451 658.049C206.191 657.659 205.591 653.009 205.591 653.009C205.101 648.569 204.861 644.859 204.861 644.859C204.161 642.339 204.511 635.279 204.511 635.279C204.511 632.789 204.761 629.469 205.071 626.359H186.961C187.621 632.629 187.311 640.629 187.311 640.629Z"
        stroke=""
        style={{
          fill: currentPartId === "r21" ? SynckitColors.Yellow[200] : "",
        }}
        id="r21"
      />
    </svg>
  );
};

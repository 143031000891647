import React from "react";
import SynckitSearchBar3 from "../../components/SynckitSearchBar3";
import ContactTable from "./ContactTable";
import OwnTable from "./OwnTable";

export default function ContactsIndex() {
  return (
    <div>
      <SynckitSearchBar3 />
      <OwnTable />
      <ContactTable />
    </div>
  );
}

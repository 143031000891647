import { Button, styled, Typography, TextField } from "@mui/material";
import React, { useState } from "react";
import { SynckitColors } from "../../assets/SynckitColors";
import { IconEditLine } from "../../assets/SynckitIcons";
import CheckIcon from "@mui/icons-material/Check";

const Root = styled("div")(({ theme }) => ({
  maxWidth: 460,
  minHeight: 353.5,
  background: "white",
  borderRadius: 8,
}));
const Header = styled("div")(({ theme }) => ({
  height: 56,
  padding: "16px 24px",
  boxShadow: SynckitColors.Shadow["ButtomDivider"],
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));
const Body = styled("div")(({ theme }) => ({
  padding: "16px 24px",
}));
export default function MedicalOrder() {
  const [editMode, setEditMode] = useState(false);
  const [text, setText] = useState(
    " Lorem ipsum dolor sit amet consectetur adipisicing elit.  Voluptatibus, odio mollitia, quibusdam explicabo autem voluptates  voluptas neque saepe earum dolores quos iste ducimus, quis esse   inventore eligendi deleniti. Quo, ullam."
  );
  return (
    <Root>
      <Header>
        <Typography variant="subtitle1">Medical Order</Typography>

        {editMode ? (
          <Button
            variant="secondary"
            endIcon={<CheckIcon />}
            sx={{ width: 76, height: 28 }}
            onClick={() => setEditMode(false)}
          >
            Done
          </Button>
        ) : (
          <Button
            variant="primary"
            endIcon={<IconEditLine />}
            sx={{ width: 76, height: 28 }}
            onClick={() => setEditMode(true)}
          >
            Edit
          </Button>
        )}
      </Header>
      <Body>
        {editMode ? (
          <TextField
            fullWidth
            multiline
            rows={10}
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        ) : (
          <Typography variant="body1">{text}</Typography>
        )}
      </Body>
    </Root>
  );
}

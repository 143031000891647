import { IconButton } from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect } from "react";
import DemoConsultationPatient from "../../../assets/demo/consultation_patient.png";
import VideocamIcon from "@mui/icons-material/Videocam";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import MicOffIcon from "@mui/icons-material/MicOff";
import CropFreeIcon from "@mui/icons-material/CropFree";
import axios from "axios";

// const demoRoomId = "bb56abdf-1fc1-4dbf-bb22-a90e30f9abe3";
// const demoRoomId = "bb56abdf-1fc1-4dbf-bb22-a90e30f9abe0";

const Root = styled("div")(({ theme }) => ({
  width: 260,
  height: 190,
  borderRadius: 8,
  overflow: "hidden",
  position: "relative",
}));
const ButtonWrap = styled("div")(({ theme }) => ({
  position: "absolute",
  right: 12,
  bottom: 15,
  height: 216,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
}));

const FunctionButton = styled(IconButton)({
  background: "rgba(255, 255, 255, 0.5)",
  width: 48,
  height: 48,
});

const RemoteViewWrap = styled("div")({
  // background: "red",
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  "& iframe": {
    // width: 410,
    // height: 500,
    // transform: "rotate(90deg)",
    // overflow: "hidden",
  },
});

export default function PatientViewDemo3() {
  var idx = 1;
  navigator.mediaDevices
    .getUserMedia({ video: true, audio: true })
    .then((stream) => {
      const configuration = {
        iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
      };
      let pc = new RTCPeerConnection(configuration);
      pc.ontrack = function (event) {
        if (event.track.kind === "audio") {
          return;
        }

        let el = document.createElement(event.track.kind);
        el.srcObject = event.streams[0];
        el.autoplay = true;
        el.controls = true;
        el.width = 532;
        el.height = 294;

        if (idx == 1) {
          el.style = "object-fit:cover;margin-top:150px";
          // document.getElementById("remoteVideos").appendChild(el);
        } else if (idx == 2) {
          el.style = "object-fit:cover;margin-top:213px";
          // document.getElementById("remoteVideos2").appendChild(el);
        } else {
          return;
        }

        idx++;
        event.track.onmute = function (event) {
          el.play();
        };

        event.streams[0].onremovetrack = ({ track }) => {
          if (el.parentNode) {
            el.parentNode.removeChild(el);
          }
        };
      };

      document.getElementById("localVideo").srcObject = stream;
      stream.getTracks().forEach((track) => pc.addTrack(track, stream));

      var url =
        //"wss://test.viuto-aiot.com/room/bb56abdf-1fc1-4dbf-bb22-a90e30f9abe3/webSocket";
        "wss://synckit.viuto-aiot.com/room/bb56abdf-1fc1-4dbf-bb22-a90e30f9abe0/webSocket";
      // document.getElementById("execurl").textContent = url;
      let ws = new WebSocket(url);
      pc.onicecandidate = (e) => {
        if (!e.candidate) {
          return;
        }

        ws.send(
          JSON.stringify({
            event: "candidate",
            data: JSON.stringify(e.candidate),
          })
        );
      };

      ws.onclose = function (evt) {
        window.alert("Websocket has closed");
      };

      ws.onmessage = function (evt) {
        if (evt.data == "keep alive") return;
        let msg = JSON.parse(evt.data);
        if (!msg) {
          return console.log("failed to parse msg");
        }

        switch (msg.event) {
          case "offer":
            let offer = JSON.parse(msg.data);
            if (!offer) {
              return console.log("failed to parse answer");
            }
            pc.setRemoteDescription(offer);
            pc.createAnswer().then((answer) => {
              pc.setLocalDescription(answer);
              ws.send(
                JSON.stringify({
                  event: "answer",
                  data: JSON.stringify(answer),
                })
              );
            });
            return;

          case "candidate":
            let candidate = JSON.parse(msg.data);
            if (!candidate) {
              return console.log("failed to parse candidate");
            }

            pc.addIceCandidate(candidate);
        }
      };

      ws.onerror = function (evt) {
        console.log("ERROR: " + evt.data);
      };
    })
    .catch(window.alert);

  return (
    <Root>
      {/* <figure>
        <img
          src={DemoConsultationPatient}
          alt="Synckit"
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
      </figure> */}
      <RemoteViewWrap>
        <div id="localVideoDiv">
          <video
            id="localVideo"
            style={{ objectFit: "cover" }}
            width="260"
            height="200"
            autoplay=""
          ></video>
        </div>
        {/* <div id="remoteVideos2" width="300"></div>
        <div id="remoteVideos" width="532"></div> */}
      </RemoteViewWrap>
    </Root>
  );
}

import { SynckitColors } from "../../../../assets/SynckitColors";

export const ArterySVG = ({ currentPartId = "", handleClick }) => {
  return (
    <svg viewBox="6 9 491 415" fill="" stroke="" onClick={handleClick}>
      <circle
        cx="290.201"
        cy="100.195"
        r="19.4576"
        stroke="#9E50F3"
        stroke-width="1.55661"
        id="artery_lr"
        style={{
          fill: currentPartId === "artery_lr" ? SynckitColors.Purple[100] : "",
        }}
      />
      <path
        d="M291.477 106.865V108.774H282.624V106.865H291.477ZM283.086 91.0675V108.774H280.739V91.0675H283.086ZM296.074 97.6831V108.774H293.824V95.6157H296.013L296.074 97.6831ZM300.184 95.5428L300.172 97.6345C299.986 97.5939 299.807 97.5696 299.637 97.5615C299.475 97.5453 299.288 97.5372 299.078 97.5372C298.559 97.5372 298.101 97.6183 297.703 97.7804C297.306 97.9425 296.97 98.1696 296.694 98.4614C296.418 98.7533 296.199 99.1019 296.037 99.5073C295.883 99.9045 295.782 100.342 295.733 100.821L295.101 101.185C295.101 100.391 295.178 99.6451 295.332 98.9479C295.494 98.2506 295.741 97.6345 296.074 97.0994C296.406 96.5562 296.828 96.1346 297.339 95.8346C297.857 95.5266 298.474 95.3725 299.187 95.3725C299.349 95.3725 299.536 95.3928 299.746 95.4333C299.957 95.4658 300.103 95.5022 300.184 95.5428Z"
        style={{ fill: "black" }}
      />
      <circle
        cx="249.724"
        cy="47.2708"
        r="19.4576"
        stroke="#9E50F3"
        stroke-width="1.55661"
        id="artery_a"
        style={{
          fill: currentPartId === "artery_a" ? SynckitColors.Purple[100] : "",
        }}
      />
      <path
        d="M250.313 38.1535L244.452 54.2911H242.056L248.805 36.5848H250.35L250.313 38.1535ZM255.226 54.2911L249.352 38.1535L249.316 36.5848H250.86L257.634 54.2911H255.226ZM254.922 47.7364V49.6578H244.974V47.7364H254.922Z"
        style={{ fill: "black" }}
      />
      <circle
        cx="221.709"
        cy="232.507"
        r="19.4576"
        stroke="#9E50F3"
        stroke-width="1.55661"
        id="artery_ri"
        style={{
          fill: currentPartId === "artery_ri" ? SynckitColors.Purple[100] : "",
        }}
      />
      <path
        d="M212.836 223.379H218.698C220.028 223.379 221.151 223.582 222.067 223.987C222.991 224.392 223.692 224.992 224.17 225.787C224.657 226.573 224.9 227.542 224.9 228.693C224.9 229.504 224.734 230.246 224.402 230.919C224.077 231.584 223.607 232.151 222.991 232.621C222.383 233.084 221.653 233.428 220.802 233.655L220.145 233.91H214.636L214.612 232.001H218.771C219.614 232.001 220.315 231.855 220.875 231.563C221.434 231.263 221.856 230.862 222.14 230.359C222.423 229.857 222.565 229.301 222.565 228.693C222.565 228.012 222.431 227.417 222.164 226.906C221.896 226.395 221.475 226.002 220.899 225.726C220.332 225.442 219.598 225.3 218.698 225.3H215.184V241.085H212.836V223.379ZM223.185 241.085L218.88 233.059L221.325 233.047L225.691 240.94V241.085H223.185ZM230.287 227.927V241.085H228.026V227.927H230.287ZM227.855 224.437C227.855 224.072 227.965 223.764 228.184 223.513C228.411 223.262 228.743 223.136 229.181 223.136C229.61 223.136 229.939 223.262 230.166 223.513C230.401 223.764 230.519 224.072 230.519 224.437C230.519 224.786 230.401 225.086 230.166 225.337C229.939 225.58 229.61 225.702 229.181 225.702C228.743 225.702 228.411 225.58 228.184 225.337C227.965 225.086 227.855 224.786 227.855 224.437Z"
        style={{ fill: "black" }}
      />
      <circle
        cx="282.416"
        cy="232.507"
        r="19.4576"
        stroke="#9E50F3"
        stroke-width="1.55661"
        id="artery_li"
        style={{
          fill: currentPartId === "artery_li" ? SynckitColors.Purple[100] : "",
        }}
      />
      <path
        d="M285.246 239.176V241.085H276.393V239.176H285.246ZM276.855 223.379V241.085H274.508V223.379H276.855ZM290.038 227.927V241.085H287.776V227.927H290.038ZM287.606 224.437C287.606 224.072 287.715 223.764 287.934 223.513C288.161 223.261 288.493 223.136 288.931 223.136C289.361 223.136 289.689 223.261 289.916 223.513C290.151 223.764 290.269 224.072 290.269 224.437C290.269 224.786 290.151 225.086 289.916 225.337C289.689 225.58 289.361 225.702 288.931 225.702C288.493 225.702 288.161 225.58 287.934 225.337C287.715 225.086 287.606 224.786 287.606 224.437Z"
        style={{ fill: "black" }}
      />
      <circle
        cx="313.541"
        cy="358.592"
        r="19.4576"
        stroke="#9E50F3"
        stroke-width="1.55661"
        id="artery_lf"
        style={{
          fill: currentPartId === "artery_lf" ? SynckitColors.Purple[100] : "",
        }}
      />
      <path
        d="M314.817 366.23V368.139H305.964V366.23H314.817ZM306.426 350.432V368.139H304.079V350.432H306.426ZM320.52 368.139H318.271V353.594C318.271 352.646 318.441 351.847 318.781 351.199C319.13 350.542 319.628 350.047 320.277 349.715C320.926 349.374 321.696 349.204 322.588 349.204C322.847 349.204 323.107 349.22 323.366 349.253C323.633 349.285 323.893 349.334 324.144 349.399L324.023 351.235C323.852 351.194 323.658 351.166 323.439 351.15C323.228 351.134 323.017 351.126 322.807 351.126C322.328 351.126 321.915 351.223 321.566 351.417C321.226 351.604 320.966 351.88 320.788 352.244C320.609 352.609 320.52 353.059 320.52 353.594V368.139ZM323.317 354.981V356.707H316.191V354.981H323.317Z"
        style={{ fill: "black" }}
      />
      <circle
        cx="182.791"
        cy="358.592"
        r="19.4576"
        stroke="#9E50F3"
        stroke-width="1.55661"
        id="artery_rf"
        style={{
          fill: currentPartId === "artery_rf" ? SynckitColors.Purple[100] : "",
        }}
      />
      <path
        d="M172.364 349.464H178.225C179.555 349.464 180.678 349.667 181.594 350.072C182.518 350.477 183.219 351.077 183.698 351.872C184.184 352.658 184.427 353.627 184.427 354.778C184.427 355.589 184.261 356.331 183.929 357.004C183.605 357.669 183.134 358.236 182.518 358.706C181.91 359.168 181.181 359.513 180.329 359.74L179.673 359.995H174.164L174.139 358.086H178.298C179.142 358.086 179.843 357.94 180.402 357.648C180.962 357.348 181.383 356.947 181.667 356.444C181.951 355.942 182.093 355.386 182.093 354.778C182.093 354.097 181.959 353.501 181.691 352.991C181.424 352.48 181.002 352.087 180.427 351.811C179.859 351.527 179.125 351.385 178.225 351.385H174.711V367.17H172.364V349.464ZM182.713 367.17L178.408 359.144L180.852 359.132L185.218 367.024V367.17H182.713ZM190.727 367.17H188.477V352.626C188.477 351.677 188.647 350.879 188.988 350.23C189.336 349.573 189.835 349.079 190.484 348.747C191.132 348.406 191.902 348.236 192.794 348.236C193.054 348.236 193.313 348.252 193.573 348.284C193.84 348.317 194.1 348.365 194.351 348.43L194.229 350.267C194.059 350.226 193.864 350.198 193.646 350.182C193.435 350.165 193.224 350.157 193.013 350.157C192.535 350.157 192.121 350.254 191.773 350.449C191.432 350.636 191.173 350.911 190.994 351.276C190.816 351.641 190.727 352.091 190.727 352.626V367.17ZM193.524 354.012V355.739H186.398V354.012H193.524Z"
        style={{ fill: "black" }}
      />
      <circle
        cx="209.255"
        cy="100.195"
        r="19.4576"
        stroke="#9E50F3"
        stroke-width="1.55661"
        id="artery_rr"
        style={{
          fill: currentPartId === "artery_rr" ? SynckitColors.Purple[100] : "",
        }}
      />
      <path
        d="M198.829 91.0675H204.69C206.02 91.0675 207.143 91.2702 208.059 91.6756C208.983 92.081 209.684 92.6809 210.163 93.4754C210.649 94.2618 210.892 95.2306 210.892 96.3819C210.892 97.1926 210.726 97.9344 210.394 98.6073C210.069 99.2721 209.599 99.8397 208.983 100.31C208.375 100.772 207.645 101.117 206.794 101.344L206.137 101.599H200.628L200.604 99.6897H204.763C205.606 99.6897 206.308 99.5437 206.867 99.2519C207.426 98.9519 207.848 98.5506 208.132 98.0479C208.416 97.5453 208.557 96.9899 208.557 96.3819C208.557 95.7009 208.424 95.105 208.156 94.5942C207.889 94.0835 207.467 93.6903 206.891 93.4146C206.324 93.1309 205.59 92.989 204.69 92.989H201.176V108.774H198.829V91.0675ZM209.178 108.774L204.873 100.748L207.317 100.736L211.683 108.628V108.774H209.178ZM216.085 97.6831V108.774H213.835V95.6157H216.024L216.085 97.6831ZM220.195 95.5428L220.183 97.6345C219.997 97.5939 219.818 97.5696 219.648 97.5615C219.486 97.5453 219.3 97.5372 219.089 97.5372C218.57 97.5372 218.112 97.6183 217.715 97.7804C217.317 97.9425 216.981 98.1696 216.705 98.4614C216.43 98.7533 216.211 99.1019 216.049 99.5073C215.895 99.9045 215.793 100.342 215.745 100.821L215.112 101.185C215.112 100.391 215.189 99.6451 215.343 98.9479C215.505 98.2506 215.753 97.6345 216.085 97.0994C216.417 96.5562 216.839 96.1346 217.35 95.8346C217.869 95.5266 218.485 95.3725 219.198 95.3725C219.36 95.3725 219.547 95.3928 219.758 95.4333C219.968 95.4658 220.114 95.5022 220.195 95.5428Z"
        style={{ fill: "black" }}
      />
    </svg>
  );
};

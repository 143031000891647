import { styled, Tabs, Tab } from "@mui/material";
import React, { useState } from "react";

import { Box } from "@mui/system";
import { SynckitColors } from "../../../assets/SynckitColors";
import Notification from "./Notification";
import { RenderNoMessagesSection } from "../RenderCommonSection";
import LatestNews from "./LatestNews";

const AppointmentRoot = styled("div")({
  filter: SynckitColors.Shadow[1],
  borderRadius: 8,
  overflow: "hidden",
});
const CategoryTabs = styled(Tabs)({
  background: SynckitColors.Primary[50],
  height: 52,
  "& .MuiTab-root": {
    color: SynckitColors.Gray[900],
    fontSize: 14,
    fontWeight: 700,
    textTransform: "initial",
    letterSpacing: 0.1,

    "&:hover": {
      transition: "0.3s",
      background: SynckitColors.Primary[100],
    },
    "&.Mui-selected": {
      color: SynckitColors.Primary[600],
    },
  },
});

function RenderPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div hidden={value !== index} {...other}>
      {value === index && <Box sx={{ overflowX: "auto" }}>{children}</Box>}
    </div>
  );
}
export default function CategoryIndex({ isEditMode }) {
  const [categoryIndex, setCategoryIndex] = useState(0);
  return (
    <AppointmentRoot>
      <CategoryTabs
        value={categoryIndex}
        onChange={(event, newValue) => setCategoryIndex(newValue)}
        variant="scrollable"
      >
        <Tab label="Notification" />
        <Tab label="Latest News" />
        <Tab label="Announment" />
      </CategoryTabs>
      <RenderPanel value={categoryIndex} index={0}>
        <Notification isEditMode={isEditMode} />
      </RenderPanel>
      <RenderPanel value={categoryIndex} index={1}>
        <RenderNoMessagesSection />
      </RenderPanel>
      <RenderPanel value={categoryIndex} index={2}>
        <LatestNews />
      </RenderPanel>
    </AppointmentRoot>
  );
}

import {
  Grid,
  Typography,
  IconButton,
  TextField,
  Box,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import { SynckitColors } from "../../../assets/SynckitColors";
import { SynckitPanel } from "../../../components/SynckitPanel";
import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/system";
import RemoveIcon from "@mui/icons-material/Remove";
import { SynckitDivider } from "../../../components/SynckitDivider";
import { Datepicker } from "@mobiscroll/react";

const PlusButton = styled(IconButton)({
  backgroundColor: SynckitColors.Primary[50],
  width: 24,
  height: 24,
  marginLeft: 16,
  "& .MuiSvgIcon-root": {
    color: SynckitColors.Primary[600],
    fontSize: 20,
  },
});
const MinusButton = styled(IconButton)({
  backgroundColor: SynckitColors.Error["Light"],
  width: 24,
  height: 24,
  marginLeft: 16,
  "& .MuiSvgIcon-root": {
    color: "white",
    fontSize: 20,
  },
});
const ExperienceWrap = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    // height: 90,
  },
}));

export default function DoctorExperience() {
  const [experienceAry, setExperienceAry] = useState([
    { year: "", organization: "", division: "", position: "" },
  ]);
  const [intervalYears, setIntervalYears] = useState("");
  return (
    <SynckitPanel>
      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
      >
        <Grid item xs={12} sm={12} lg={12}>
          <TextField
            label="Experience"
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">years</InputAdornment>
              ),
            }}
            type="number"
          />
        </Grid>
      </Grid>

      <SynckitDivider />
      {/* Experience Start */}
      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
      >
        <Grid item xs={12} sm={12} lg={4}>
          <Box
            sx={{
              width: 216,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{ color: SynckitColors.Gray[800] }}
            >
              Experience
            </Typography>
            <PlusButton
              onClick={() => {
                let tempAry = experienceAry;
                tempAry.push({});
                setExperienceAry([...tempAry]);
              }}
            >
              <AddIcon />
            </PlusButton>
          </Box>
        </Grid>

        {experienceAry.map((exp, index) => (
          <Fragment key={index}>
            {/* index === 0 ( 第一筆的)  */}
            {index !== 0 && (
              <Grid item xs={2} sm={1} lg={4}>
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: 216,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <MinusButton
                    onClick={() => {
                      let tempAry = experienceAry;
                      tempAry.pop({});
                      setExperienceAry([...tempAry]);
                    }}
                  >
                    <RemoveIcon />
                  </MinusButton>
                </Box>
              </Grid>
            )}

            <Grid item xs={10} sm={11} lg={8}>
              <Datepicker
                controls={["date"]}
                select="range"
                display="anchored"
                dateFormat="YYYY"
                dateWheels="|YYYY|"
                inputComponent={TextField}
                touchUi={true}
                inputProps={{
                  // label: "Year",
                  // defaultValue: intervalYears,
                  size: "small",
                }}
                placeholder={intervalYears}
                onChange={(e) => setIntervalYears(e.valueText)}
              />
            </Grid>
            <Grid item xs={2} sm={1} lg={4}></Grid>
            <Grid item xs={12} sm={11} lg={8}>
              <ExperienceWrap>
                <TextField select label="Organization" fullWidth size="small">
                  <MenuItem value={0}>Organization1</MenuItem>
                  <MenuItem value={1}>Organization2</MenuItem>
                </TextField>
                {"　"}
                <TextField select label="Division" fullWidth size="small">
                  <MenuItem value={0}>Division1</MenuItem>
                  <MenuItem value={1}>Division2</MenuItem>
                </TextField>
                {"　"}
                <TextField select label="Position" fullWidth size="small">
                  <MenuItem value={0}>Position1</MenuItem>
                  <MenuItem value={1}>Position2</MenuItem>
                </TextField>{" "}
              </ExperienceWrap>
            </Grid>
          </Fragment>
        ))}
      </Grid>
    </SynckitPanel>
  );
}

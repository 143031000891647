import avatar_patient1 from "./images/demo/avatar_patient1.jpg";
import avatar_patient2 from "./images/demo/avatar_patient2.jpg";
import avatar_patient3 from "./images/demo/avatar_patient3.jpg";
import avatar_patient4 from "./images/demo/avatar_patient4.jpg";
import avatar_patient5 from "./images/demo/avatar_patient5.jpg";

import avatarDoctor1 from "./images/demo/avatar_doctor1.jpg";
import avatarDoctor2 from "./images/demo/avatar_doctor2.jpg";
import avatarDoctor3 from "./images/demo/avatar_doctor3.jpg";
import avatarDoctor4 from "./images/demo/avatar_doctor4.jpg";
import avatarDoctor5 from "./images/demo/avatar_doctor5.jpg";
import avatarDoctor6 from "./images/demo/avatar_doctor6.jpg";
import avatarDoctor7 from "./images/demo/avatar_doctor7.jpg";

import DemoSkinRecord01 from "./demo/skin_record01.png";
import DemoSkinRecord02 from "./demo/skin_record02.png";
import DemoSkinRecord03 from "./demo/skin_record03.png";
import DemoSkinRecord04 from "./demo/skin_record04.png";
import DemoSkinRecord05 from "./demo/skin_record05.png";
import DemoSkinRecord06 from "./demo/skin_record06.png";

import {
  APO_TYPE_LOCAL,
  APO_TYPE_REMOTE,
  ORGAN_HEART,
  ORGAN_TEMPERATURE,
  ORGAN_THEETH,
  APO_CANCELLED_STATUS_NONE,
  APO_CANCELLED_STATUS_REACHED,
  APO_CANCELLED_STATUS_REFUSED,
  APO_CANCELLED_STATUS_WAITING,
  ORGAN_SKIN,
  ORGAN_ABDOMINAL,
  ORGAN_ARTERY,
  ORGAN_LUNG,
  ORGAN_EAR,
} from "./constant";

function createPatientData(
  id,
  avatar,
  patient,
  doctor,
  date,
  divison,
  type,
  consulted,
  refusedStatus,
  recommendDoctor,
  cancelledStatus
) {
  return {
    id,
    avatar,
    patient,
    doctor,
    date,
    divison,
    type,
    consulted,
    refusedStatus,
    recommendDoctor,
    cancelledStatus,
  };
}

export const demoPatientList = [
  createPatientData(
    1,
    avatar_patient1,
    "Jacob Jones",
    "Dr. Kristin Watson",
    "2021.10.22 · 10:30",
    ORGAN_HEART,
    APO_TYPE_REMOTE,
    false,
    "Not available",
    "/pm",
    APO_CANCELLED_STATUS_NONE
  ),
  createPatientData(
    2,
    avatar_patient2,
    "Jacob Jones",
    "Dr. Kristin Watson",
    "2021.10.22 · 10:30",
    ORGAN_HEART,
    APO_TYPE_REMOTE,
    true,
    "Not available",
    null,
    APO_CANCELLED_STATUS_WAITING
  ),
  createPatientData(
    3,
    avatar_patient5,
    "Jacob Jones",
    "Dr. Kristin Watson",
    "2021.10.22 · 10:30",
    null,
    APO_TYPE_LOCAL,
    false,
    "Not available",
    "/pm",
    APO_CANCELLED_STATUS_NONE
  ),

  createPatientData(
    4,
    avatar_patient4,
    "Jacob Jones",
    "Dr. Kristin Watson",
    "2021.10.22 · 10:30",
    ORGAN_TEMPERATURE,
    APO_TYPE_REMOTE,
    true,
    "Unable to perform service",
    "/pm",
    APO_CANCELLED_STATUS_REACHED
  ),
  createPatientData(
    5,
    avatar_patient3,
    "Jacob Jones",
    "Dr. Kristin Watson",
    "2021.10.22 · 10:30",
    null,
    APO_TYPE_LOCAL,
    false,
    "Unable to perform service",
    null,
    APO_CANCELLED_STATUS_REFUSED
  ),
  createPatientData(
    6,
    avatar_patient1,
    "Jacob Jones",
    "Dr. Kristin Watson",
    "2021.10.22 · 10:30",
    ORGAN_THEETH,
    APO_TYPE_REMOTE,
    false,
    "Unable to perform service",
    null,
    APO_CANCELLED_STATUS_REFUSED
  ),
];

function createDoctorData(
  favorite,
  avatar,
  name,
  gender,
  drId,
  organization,
  division,
  divison,
  type,
  apoDatetime
) {
  return {
    favorite,
    avatar,
    name,
    gender,
    drId,
    organization,
    division,
    divison,
    type,
    apoDatetime,
  };
}

export const demoDoctorList = [
  createDoctorData(
    true,
    avatarDoctor1,
    "Dr. Kristin Watson",
    "female",
    "G5608160757",
    "Organization",
    "Division of Cardiology",
    ORGAN_THEETH,
    APO_TYPE_REMOTE,
    "2021.02.22 · 12:20"
  ),
  createDoctorData(
    true,
    avatarDoctor2,
    "Dr. Dwayne Fields",
    "male",
    "G5608160757",
    "Organization",
    "Division of Cardiology",
    ORGAN_HEART,
    APO_TYPE_REMOTE,
    "2021.02.20 · 12:20"
  ),
  createDoctorData(
    true,
    avatarDoctor3,
    "Dr. Dallas Estrada",
    "male",
    "G5608160757",
    "Organization",
    "Division of Cardiology",
    ORGAN_SKIN,
    APO_TYPE_REMOTE,
    "2021.02.18 · 12:20"
  ),
  createDoctorData(
    false,
    avatarDoctor4,
    "Dr. Laverne Martinez",
    "male",
    "G5608160757",
    "Organization",
    "Division of Cardiology",
    ORGAN_ABDOMINAL,
    APO_TYPE_REMOTE,
    "2021.02.10 · 12:20"
  ),
  createDoctorData(
    true,
    avatarDoctor5,
    "Dr. Nicole Gonzalez",
    "female",
    "G5608160757",
    "Organization",
    "Division of Cardiology",
    ORGAN_ARTERY,
    APO_TYPE_REMOTE,
    "2021.02.08 · 12:20"
  ),
  createDoctorData(
    true,
    avatarDoctor6,
    "Dr. Melissa Davis",
    "female",
    "G5608160757",
    "Organization",
    "Division of Cardiology",
    ORGAN_LUNG,
    APO_TYPE_REMOTE,
    "2021.02.08 · 12:20"
  ),
  createDoctorData(
    true,
    avatarDoctor7,
    "Dr. Erwin Utley",
    "female",
    "G5608160757",
    "Organization",
    "Division of Cardiology",
    ORGAN_EAR,
    APO_TYPE_REMOTE,
    "2021.02.08 · 12:20"
  ),
];

export const demoImgList = [
  { id: 1, imgUrl: DemoSkinRecord01 },
  { id: 2, imgUrl: DemoSkinRecord02 },
  { id: 3, imgUrl: DemoSkinRecord03 },
  { id: 4, imgUrl: DemoSkinRecord04, duration: "0:14" },
  { id: 5, imgUrl: DemoSkinRecord05, duration: "0:05" },
  { id: 6, imgUrl: DemoSkinRecord06 },
];

import { SynckitColors } from "../../assets/SynckitColors";

export const DefaultTheme = {
  primaryColor: SynckitColors.Primary[600],
  mainBackground: "#F0F0F0",
  headerBackground: SynckitColors.Primary[500],
  headerBoxShadow: SynckitColors.Shadow[4],
  headerColor: "white",
  toggleMenuColor: "white",
  controlStatusLabelStyle: {
    border: `1px solid white`,
    color: "white",
  },
  saveBtnStyle: {
    background: "white",
    color: SynckitColors.Primary[600],
    "&:hover": {
      background: SynckitColors.Primary[700],
      color: "white",
    },
  },
};

export const YellowTheme = {
  primaryColor: SynckitColors.Yellow[700],
  mainBackground: SynckitColors.Yellow["Gradient"],
  headerBackground: "rgba(255, 255, 255, 0.8)",
  headerBoxShadow: " 0px 16px 24px rgba(255, 150, 2, 0.1)",
  headerColor: SynckitColors.Yellow[700],
  toggleMenuColor: SynckitColors.Gray[800],
  controlStatusLabelStyle: {
    border: "none",
    color: SynckitColors.Gray[700],
    background: SynckitColors.Gray[200],
  },
  saveBtnStyle: {
    background: SynckitColors.Yellow[700],
    color: "white",
    "&:hover": {
      background: SynckitColors.Yellow[500],
      color: "white",
    },
  },
};

export const OrangeTheme = {
  primaryColor: SynckitColors.Orange[700],
  mainBackground: SynckitColors.Orange["Gradient"],
  headerBackground: "rgba(255, 255, 255, 0.8)",
  headerBoxShadow: "0px 16px 24px rgba(255, 150, 2, 0.1)",
  headerColor: SynckitColors.Orange[700],
  toggleMenuColor: SynckitColors.Gray[800],
  controlStatusLabelStyle: {
    border: "none",
    color: SynckitColors.Gray[700],
    background: SynckitColors.Gray[200],
  },
  saveBtnStyle: {
    background: SynckitColors.Orange[700],
    color: "white",
    "&:hover": {
      background: SynckitColors.Orange[500],
      color: "white",
    },
  },
};

export const GreenTheme = {
  primaryColor: SynckitColors.Green[700],
  mainBackground: SynckitColors.Green["Gradient"],
  headerBackground: "rgba(255, 255, 255, 0.8)",
  headerBoxShadow: "0px 16px 24px rgba(0, 212, 173, 0.1)",
  headerColor: SynckitColors.Green[700],
  toggleMenuColor: SynckitColors.Gray[800],
  controlStatusLabelStyle: {
    border: "none",
    color: SynckitColors.Gray[700],
    background: SynckitColors.Gray[200],
  },
  saveBtnStyle: {
    background: SynckitColors.Green[700],
    color: "white",
    "&:hover": {
      background: SynckitColors.Green[500],
      color: "white",
    },
  },
};

export const BlueTheme = {
  primaryColor: SynckitColors.Primary[600],
  mainBackground: SynckitColors.Primary[50],
  headerBackground: "rgba(255, 255, 255, 0.8)",
  headerBoxShadow: SynckitColors.Shadow[1],
  headerColor: SynckitColors.Primary[600],
  toggleMenuColor: SynckitColors.Gray[800],
  controlStatusLabelStyle: {
    border: "none",
    color: SynckitColors.Gray[700],
    background: SynckitColors.Gray[200],
  },
  saveBtnStyle: {
    background: SynckitColors.Primary[600],
    color: "white",
    "&:hover": {
      background: SynckitColors.Primary[400],
      color: "white",
    },
  },
};

export const PurpleTheme = {
  primaryColor: SynckitColors.Purple[700],
  mainBackground: SynckitColors.Purple["Gradient"],
  headerBackground: "rgba(255, 255, 255, 0.8)",
  headerBoxShadow: "0px 16px 24px rgba(138, 37, 239, 0.1)",
  headerColor: SynckitColors.Purple[700],
  toggleMenuColor: SynckitColors.Gray[800],
  controlStatusLabelStyle: {
    border: "none",
    color: SynckitColors.Gray[700],
    background: SynckitColors.Gray[200],
  },
  saveBtnStyle: {
    background: SynckitColors.Purple[700],
    color: "white",
    "&:hover": {
      background: SynckitColors.Purple[500],
      color: "white",
    },
  },
};

export const ConsultThemeAry = [
  BlueTheme, //ORGAN_THEETH = 0;
  OrangeTheme, //ORGAN_EAR = 1;
  GreenTheme, //ORGAN_TEMPERATURE = 2;
  GreenTheme, //ORGAN_NOSE = 3;
  GreenTheme, //ORGAN_THROAT = 4;
  YellowTheme, //ORGAN_SKIN = 5;
  PurpleTheme, //ORGAN_LUNG = 6;
  PurpleTheme, //ORGAN_HEART = 7;
  PurpleTheme, //ORGAN_ABDOMINAL = 8;
  PurpleTheme, //ORGAN_ARTERY = 9;
  DefaultTheme, //default = 10;
  DefaultTheme, //EXAM_MODE = 11;
];

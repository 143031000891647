import { styled } from "@mui/material";
import React, { useContext } from "react";
import ImgModelLungFront from "../../../../assets/images/body/model_lung_front.png";
import { SynckitColors } from "../../../../assets/SynckitColors";
import { ConsultContext } from "../../ConsultIndex";
import { LungFrontSVG } from "./LungFrontSVG";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  position: "relative",
}));

const ImgModelBodyWrap = styled("div")(({ theme }) => ({
  position: "absolute",
  height: "80%",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  "& img": {
    height: "100%",
  },
}));
const SVGModelBodyWrap = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  height: "80%",
  "& svg": {
    height: "100%",
  },
  "& circle": {
    fill: "white",
    fillOpacity: "0.2",
    transition: "0.3s",
    stroke: "#9E50F3",
  },
  "& circle:hover": {
    fillOpacity: "0.4",
    cursor: "pointer",
  },
}));

export default function LungFront() {
  const { currentPartId, setCurrentPartId } = useContext(ConsultContext);
  return (
    <Root>
      <ImgModelBodyWrap>
        <img src={ImgModelLungFront} alt="synckit" />
      </ImgModelBodyWrap>
      <SVGModelBodyWrap>
        <LungFrontSVG
          handleClick={(e) => e.target.id && setCurrentPartId(e.target.id)}
          currentPartId={currentPartId}
        />
      </SVGModelBodyWrap>
    </Root>
  );
}

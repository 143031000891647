import { Avatar, Box, styled, Typography } from "@mui/material";
import React from "react";
import { SynckitColors } from "../../../../../assets/SynckitColors";
import qrcode from "../../../../../assets/images/demo/qrcode.png";
import AvatarImg from "../../../../../assets/images/demo/avatar_patient3.jpg";

const QRcodeCard = styled("div")({
  width: "100%",
  background: SynckitColors.Primary[50],
  borderRadius: 12,
  height: 617,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  "& #qrcode": {
    maxWidth: "60%",
  },
});

export default function QRcodeIndex() {
  return (
    <div>
      <Typography
        variant="h5"
        sx={{ color: SynckitColors.Gray["core"], marginBottom: 2 }}
      >
        Scan QR code
      </Typography>

      <QRcodeCard>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "8% 0",
            height: 160,
          }}
        >
          <Avatar
            src={AvatarImg}
            sx={{
              width: 96,
              height: 96,
            }}
            alt="synckit"
          />
          <Typography variant="h5">Cammy Lytle</Typography>
          <Typography variant="body2">G3982958195</Typography>
        </Box>
        <img id="qrcode" src={qrcode} alt="synckit" />
      </QRcodeCard>
    </div>
  );
}

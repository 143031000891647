import { Box, Button, styled, Typography, Grid } from "@mui/material";
import React from "react";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import { SynckitColors } from "../../../assets/SynckitColors";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { ImgShoppingCard } from "../../../assets/SynckitImg";

const InfoCard = styled("div")(({ theme }) => ({
  maxWidth: 323,
  height: 142,
  border: `1px solid ${SynckitColors.Gray[400]}`,
  borderRadius: 4,
  padding: "16px 20px 6px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

const InfoCard2 = styled("div")(({ theme }) => ({
  maxWidth: 323,
  height: 142,
  borderRadius: 4,
  padding: "16px 20px 10px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "center",
  background: SynckitColors.Primary[50],
}));
export default function InfoCardSection({ setPointsMode }) {
  return (
    <Grid
      container
      spacing={3}
      direction="row"
      justify="center"
      alignItems="center"
      alignContent="center"
    >
      <Grid item xs={12} md={4}>
        <InfoCard>
          <Typography variant="body2" sx={{ color: SynckitColors.Gray[900] }}>
            with expiration
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <PaidOutlinedIcon
              sx={{
                color: SynckitColors.Primary[600],
                width: 20,
                height: 20,
                mr: 1,
              }}
            />
            <Typography variant="h4">100 points</Typography>
          </Box>
          <Box sx={{ textAlign: "right" }}>
            <Button
              endIcon={<ArrowForwardIosOutlinedIcon sx={{ width: 12 }} />}
              onClick={() => setPointsMode("MyPointsWithExpiration")}
            >
              See details
            </Button>
          </Box>
        </InfoCard>
      </Grid>
      <Grid item xs={12} md={4}>
        <InfoCard>
          <Typography variant="body2" sx={{ color: SynckitColors.Gray[900] }}>
            unlimited
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <PaidOutlinedIcon
              sx={{
                color: SynckitColors.Orange[600],
                width: 20,
                height: 20,
                mr: 1,
              }}
            />
            <Typography variant="h4">40 points</Typography>
          </Box>
          <Box sx={{ textAlign: "right" }}>
            <Button
              endIcon={<ArrowForwardIosOutlinedIcon sx={{ width: 12 }} />}
              sx={{ color: SynckitColors.Orange[600] }}
              onClick={() => setPointsMode("MyPointsUnlimited")}
            >
              See details
            </Button>
          </Box>
        </InfoCard>
      </Grid>
      <Grid item xs={12} md={4}>
        <InfoCard2>
          <ImgShoppingCard style={{ width: 46 }} />
          <Button
            variant="primary"
            onClick={() => setPointsMode("PointsPurchaeList")}
          >
            Buy Points
          </Button>
        </InfoCard2>
      </Grid>
    </Grid>
  );
}

import React from "react";
import TextField from "@mui/material/TextField";
import { Box, Button, MenuItem } from "@mui/material";
import { IconPaperPlane } from "../../assets/SynckitIcons";

export default function ProblemReport() {
  return (
    <Box sx={{ maxWidth: 600 }}>
      <TextField
        label="Type"
        // value={}
        // onChange={}
        required
        select
        size="small"
        sx={{ mt: 3, mb: 2, width: 177 }}
      >
        {typeList.map((item, index) => (
          <MenuItem key={index} value={item.title}>
            {item.title}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Statement"
        // value={}
        // onChange={}
        required
        multiline
        rows={10}
        maxRows={10}
        fullWidth
      />
      <Box sx={{ textAlign: "right" }}>
        <Button variant="primary" startIcon={<IconPaperPlane />} sx={{ mt: 2 }}>
          Send
        </Button>
      </Box>
    </Box>
  );
}

const typeList = [
  { id: 1, title: "Dispute complaint" },
  { id: 2, title: "Payment problem " },
  { id: 3, title: "Service problem" },
  { id: 4, title: "Account problem" },
  { id: 5, title: "Usage problem" },
];

import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import { IconMedcial, IconOut, IconPersonFill } from "../assets/SynckitIcons";
export const userMenuList = [
  {
    id: 1,
    title: "My Account",
    icon: <IconPersonFill />,
  },
  {
    id: 2,
    title: "My service",
    icon: <IconMedcial />,
  },
  {
    id: 3,
    title: "My points",
    icon: <PaidOutlinedIcon />,
  },
  {
    id: 4,
    title: "Transaction record",
    icon: <FactCheckOutlinedIcon />,
  },
  {
    id: 5,
    title: "Log out",
    icon: <IconOut />,
  },
];

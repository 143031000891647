export const PartsDataList = {};
PartsDataList[""] = { name: "" };
PartsDataList["f1"] = { name: "F1" };
PartsDataList["f2"] = { name: "F2" };
PartsDataList["f3"] = { name: "F3" };
PartsDataList["f4"] = { name: "F4" };
PartsDataList["f5"] = { name: "F5" };
PartsDataList["f6"] = { name: "F6" };
PartsDataList["f7"] = { name: "F7" };
PartsDataList["f8"] = { name: "F8" };
PartsDataList["f9"] = { name: "F9" };
PartsDataList["f10"] = { name: "F10" };
PartsDataList["f11"] = { name: "F11" };
PartsDataList["f12"] = { name: "F12" };
PartsDataList["f13"] = { name: "F13" };
PartsDataList["f14"] = { name: "F14" };
PartsDataList["f15"] = { name: "F15" };
PartsDataList["f16"] = { name: "F16" };
PartsDataList["f17"] = { name: "F17" };
PartsDataList["f18"] = { name: "F18" };
PartsDataList["f19"] = { name: "F19" };
PartsDataList["f20"] = { name: "F20" };
PartsDataList["f21"] = { name: "F21" };
PartsDataList["r1"] = { name: "R1" };
PartsDataList["r2"] = { name: "R2" };
PartsDataList["r3"] = { name: "R3" };
PartsDataList["r4"] = { name: "R4" };
PartsDataList["r5"] = { name: "R5" };
PartsDataList["r6"] = { name: "R6" };
PartsDataList["r7"] = { name: "R7" };
PartsDataList["r8"] = { name: "R8" };
PartsDataList["r9"] = { name: "R9" };
PartsDataList["r10"] = { name: "R10" };
PartsDataList["r11"] = { name: "R11" };
PartsDataList["r12"] = { name: "R12" };
PartsDataList["r13"] = { name: "R13" };
PartsDataList["r14"] = { name: "R14" };
PartsDataList["r15"] = { name: "R15" };
PartsDataList["r16"] = { name: "R16" };
PartsDataList["r17"] = { name: "R17" };
PartsDataList["r18"] = { name: "R18" };
PartsDataList["r19"] = { name: "R19" };
PartsDataList["r20"] = { name: "R20" };
PartsDataList["r21"] = { name: "R21" };
PartsDataList["ear1"] = { name: "左" };
PartsDataList["ear2"] = { name: "右" };
PartsDataList["nose1"] = { name: "左" };
PartsDataList["nose2"] = { name: "右" };

PartsDataList["throat_pu"] = { name: "PU" };
PartsDataList["throat_left"] = { name: "TL" };
PartsDataList["throat_right"] = { name: "TR" };

PartsDataList["bt"] = { name: "" };

PartsDataList["teeth_adult_11"] = { name: "11" };
PartsDataList["teeth_adult_12"] = { name: "12" };
PartsDataList["teeth_adult_13"] = { name: "13" };
PartsDataList["teeth_adult_14"] = { name: "14" };
PartsDataList["teeth_adult_15"] = { name: "15" };
PartsDataList["teeth_adult_16"] = { name: "16" };
PartsDataList["teeth_adult_17"] = { name: "17" };
PartsDataList["teeth_adult_18"] = { name: "18" };
PartsDataList["teeth_adult_21"] = { name: "21" };
PartsDataList["teeth_adult_22"] = { name: "22" };
PartsDataList["teeth_adult_23"] = { name: "23" };
PartsDataList["teeth_adult_24"] = { name: "24" };
PartsDataList["teeth_adult_25"] = { name: "25" };
PartsDataList["teeth_adult_26"] = { name: "26" };
PartsDataList["teeth_adult_27"] = { name: "27" };
PartsDataList["teeth_adult_28"] = { name: "28" };
PartsDataList["teeth_adult_31"] = { name: "31" };
PartsDataList["teeth_adult_32"] = { name: "32" };
PartsDataList["teeth_adult_33"] = { name: "33" };
PartsDataList["teeth_adult_34"] = { name: "34" };
PartsDataList["teeth_adult_35"] = { name: "35" };
PartsDataList["teeth_adult_36"] = { name: "36" };
PartsDataList["teeth_adult_37"] = { name: "37" };
PartsDataList["teeth_adult_38"] = { name: "38" };
PartsDataList["teeth_adult_41"] = { name: "41" };
PartsDataList["teeth_adult_42"] = { name: "42" };
PartsDataList["teeth_adult_43"] = { name: "43" };
PartsDataList["teeth_adult_44"] = { name: "44" };
PartsDataList["teeth_adult_45"] = { name: "45" };
PartsDataList["teeth_adult_46"] = { name: "46" };
PartsDataList["teeth_adult_47"] = { name: "47" };
PartsDataList["teeth_adult_48"] = { name: "48" };

PartsDataList["teeth_child_51"] = { name: "51" };
PartsDataList["teeth_child_52"] = { name: "52" };
PartsDataList["teeth_child_53"] = { name: "53" };
PartsDataList["teeth_child_54"] = { name: "54" };
PartsDataList["teeth_child_55"] = { name: "55" };
PartsDataList["teeth_child_61"] = { name: "61" };
PartsDataList["teeth_child_62"] = { name: "62" };
PartsDataList["teeth_child_63"] = { name: "63" };
PartsDataList["teeth_child_64"] = { name: "64" };
PartsDataList["teeth_child_65"] = { name: "65" };
PartsDataList["teeth_child_71"] = { name: "71" };
PartsDataList["teeth_child_72"] = { name: "72" };
PartsDataList["teeth_child_73"] = { name: "73" };
PartsDataList["teeth_child_74"] = { name: "74" };
PartsDataList["teeth_child_75"] = { name: "75" };
PartsDataList["teeth_child_81"] = { name: "81" };
PartsDataList["teeth_child_82"] = { name: "82" };
PartsDataList["teeth_child_83"] = { name: "83" };
PartsDataList["teeth_child_84"] = { name: "84" };
PartsDataList["teeth_child_85"] = { name: "85" };

PartsDataList["artery_a"] = { name: "A" };
PartsDataList["artery_rr"] = { name: "Rr" };
PartsDataList["artery_lr"] = { name: "Lr" };
PartsDataList["artery_rf"] = { name: "Rf" };
PartsDataList["artery_lf"] = { name: "Lf" };
PartsDataList["artery_li"] = { name: "Li" };
PartsDataList["artery_ri"] = { name: "Ri" };

PartsDataList["heart_a"] = { name: "A" };
PartsDataList["heart_p"] = { name: "P" };
PartsDataList["heart_e"] = { name: "E" };
PartsDataList["heart_t"] = { name: "T" };
PartsDataList["heart_m"] = { name: "M" };

PartsDataList["lung_front_m1"] = { name: "FM1" };
PartsDataList["lung_front_r1"] = { name: "FR1" };
PartsDataList["lung_front_r2"] = { name: "FR2" };
PartsDataList["lung_front_r3"] = { name: "FR3" };
PartsDataList["lung_front_l1"] = { name: "FL1" };
PartsDataList["lung_front_l2"] = { name: "FL2" };
PartsDataList["lung_front_l3"] = { name: "FL3" };

PartsDataList["lung_back_rl1"] = { name: "RL1" };
PartsDataList["lung_back_rl2"] = { name: "RL2" };
PartsDataList["lung_back_rl3"] = { name: "RL3" };
PartsDataList["lung_back_rl4"] = { name: "RL4" };
PartsDataList["lung_back_rr1"] = { name: "RR1" };
PartsDataList["lung_back_rr2"] = { name: "RR2" };
PartsDataList["lung_back_rr3"] = { name: "RR3" };
PartsDataList["lung_back_rr4"] = { name: "RR4" };

PartsDataList["abdominal_ruq"] = { name: "RUQ" };
PartsDataList["abdominal_rlq"] = { name: "RLQ" };
PartsDataList["abdominal_luq"] = { name: "LUQ" };
PartsDataList["abdominal_llq"] = { name: "LLQ" };

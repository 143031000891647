import React from "react";
import Grid from "@mui/material/Grid";
import {
  Avatar,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { SynckitPanel } from "../../../components/SynckitPanel";
import { SynckitButton } from "../../../components/SynckitButton";
import AvatarImg from "../../../assets/images/demo/avatar_doctor1.jpg";

export default function DoctorBasicInfo() {
  return (
    <SynckitPanel>
      <Grid
        container
        spacing={3}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
      >
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: 170,
            alignItems: "center",
          }}
          xs={12}
          // md={6}
          lg={3}
        >
          <Avatar sx={{ width: 96, height: 96 }} src={AvatarImg} />
          <SynckitButton variant="outlined">Upload</SynckitButton>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <TextField required label="First Name" size="small" fullWidth />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <TextField
            required
            label="Last Name"
            size="small"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <FormControl component="fieldset">
            <FormLabel component="legend" required>
              Gender
            </FormLabel>
            <RadioGroup row aria-label="gender">
              <FormControlLabel value="male" control={<Radio />} label="Male" />
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="Female"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </SynckitPanel>
  );
}

import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { SynckitColors } from "../../../../../assets/SynckitColors";

import {
  Avatar,
  Box,
  IconButton,
  TableFooter,
  TablePagination,
  Typography,
} from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import {
  IconStarFill,
  IconStarLine,
  IconUpload,
} from "../../../../../assets/SynckitIcons";
import FemaleIcon from "@mui/icons-material/Female";
import MaleOutlinedIcon from "@mui/icons-material/MaleOutlined";
import { demoDoctorList } from "../../../../../assets/demoData";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: SynckitColors.Gray[200],
    color: SynckitColors.Gray[700],
    fontSize: 14,
    fontWeight: 700,
    height: 36,
    paddingTop: 8,
    paddingBottom: 8,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // "&:nth-of-type(odd)": {
  //   backgroundColor: theme.palette.action.hover,
  // },
  // hide last border
  "&:last-child td, &:last-child th": {
    // border: 0,
  },
}));

const TableRoot = styled(TableContainer)({
  width: "100%",
  padding: 0,
  borderRadius: 12,
  backgroundColor: "white",
  marginBottom: 24,
  filter: SynckitColors.Shadow[1],
});

export default function ContactsTable() {
  return (
    <TableRoot>
      <Table>
        <TableBody>
          {demoDoctorList.map((row) => (
            <StyledTableRow key={row.name} sx={{ height: 88 }}>
              <StyledTableCell sx={{ width: 50 }}>
                <IconButton>
                  {row.favorite ? (
                    <IconStarFill
                      sx={{ color: SynckitColors.secondary[300] }}
                    />
                  ) : (
                    <IconStarFill sx={{ color: SynckitColors.Gray[600] }} />
                  )}
                </IconButton>
              </StyledTableCell>
              <StyledTableCell sx={{ width: 56 }}>
                <Avatar src={row.avatar} sx={{ width: 48, height: 48 }} />
              </StyledTableCell>
              <StyledTableCell>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography variant="subtitle1">{row.name}</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ color: SynckitColors.Gray[800] }}
                    >
                      {row.drId}
                    </Typography>
                    {"　"}
                    {row.gender === "male" ? (
                      <MaleOutlinedIcon color="primary" sx={{ width: 18 }} />
                    ) : (
                      <FemaleIcon color="error" sx={{ width: 18 }} />
                    )}
                  </Box>
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Typography
                  variant="body1"
                  sx={{ color: SynckitColors.Gray[800] }}
                >
                  {row.organization}
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography
                  variant="body1"
                  sx={{ color: SynckitColors.Gray[800] }}
                >
                  {row.division}
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <IconButton variant="third">
                  <IconUpload style={{ stroke: SynckitColors.Primary[600] }} />
                </IconButton>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableRoot>
  );
}

import { SynckitColors } from "../../../../assets/SynckitColors";

export const ModelBodyBackMid = ({ currentPartId = "", handleClick }) => {
  return (
    <svg viewBox="56 292 196 582" onClick={handleClick}>
      <path
        d="M116.169 301.54C118.039 306.11 117.099 317.02 117.099 317.02C117.189 320 113.649 344.8 113.649 344.8C112.439 349.65 108.519 369.97 108.519 369.97C108.219 371.6 107.749 374.46 107.199 377.83H200.359C199.469 372.27 198.539 366.57 198.539 366.57C198.169 362.47 195.479 349.93 195.479 349.93C194.609 347.69 194.049 344.13 194.049 344.13C194.049 338.12 191.879 319.03 191.879 319.03C190.969 313.58 192.159 307.98 192.159 307.98C193.099 305.82 195.369 299.05 197.349 293H113.179L116.169 301.54Z"
        stroke=""
        style={{
          fill: currentPartId === "r4" ? SynckitColors.Yellow[200] : "",
        }}
        id="r4"
      />
      <path
        d="M103.21 403.17C101.69 410.38 100.64 420.85 99.9805 429.17H150.49C150.91 422.64 149.57 419.79 149.57 419.79C148.63 417.62 149.531 417.31 149.531 417.31C150.371 416.3 151.171 413.81 151.171 413.81C151.311 412.9 152.08 412.9 152.08 412.9C152.64 413.32 154.281 412.97 154.281 412.97C155.081 412.9 155.26 413.46 155.26 413.46C155.72 414.93 157.95 418.28 157.95 418.28C158.44 419.01 158.271 419.54 158.271 419.54C157.321 421.89 157.181 425.88 157.271 429.17H206.94C206.59 424.73 206.281 421.25 206.281 421.25C205.791 413.7 202.46 391.65 201.71 386.52C201.43 384.57 200.9 381.23 200.36 377.83H107.2C105.54 388.1 103.21 403.17 103.21 403.17Z"
        stroke=""
        style={{
          fill: currentPartId === "r5" ? SynckitColors.Yellow[200] : "",
        }}
        id="r5"
      />
      <path
        d="M101.44 307.701L107.41 283.181C107.59 282.491 107.78 282.031 107.96 281.731C103.9 264.051 101.15 230.191 100.25 217.971C96.5596 219.601 94.5096 220.671 94.5096 220.671C84.3496 226.381 81.7596 236.521 81.7596 236.521C79.9396 243.161 79.9696 254.331 79.9696 254.331C79.9696 257.221 80.9496 271.671 80.9496 271.671C81.8796 280.951 81.4197 286.631 81.4197 286.631C81.3297 288.451 79.1797 305.881 79.1797 305.881C79.0397 309.701 75.7797 326.111 75.7797 326.111C75.5797 327.081 75.2697 328.341 74.9297 329.671H97.4896C97.8796 321.701 101.44 307.701 101.44 307.701Z"
        stroke=""
        style={{
          fill: currentPartId === "r6" ? SynckitColors.Yellow[200] : "",
        }}
        id="r6"
      />
      <path
        d="M97.5295 332.96C97.4095 332.08 97.3995 330.95 97.4595 329.67H74.8995C73.9795 333.24 72.8195 337.25 72.8195 337.25C70.5795 343.64 69.7395 350.21 69.7395 350.21C69.6595 350.65 69.5895 351.2 69.5195 351.83H97.2996C99.1296 342.12 97.5295 332.96 97.5295 332.96Z"
        stroke=""
        style={{
          fill: currentPartId === "r7" ? SynckitColors.Yellow[200] : "",
        }}
        id="r7"
      />
      <path
        d="M80.1419 401.18C81.3319 397.75 84.2018 389.92 84.2018 389.92C86.1618 385.72 95.2018 359.15 95.2018 359.15C96.1818 356.76 96.8519 354.28 97.3119 351.82H69.5319C68.8019 358.57 68.4919 375.23 68.4919 375.23C68.3519 379.66 67.3218 393.36 67.3218 393.36C66.9018 395.92 66.5818 405.25 66.5818 405.25C66.5018 405.96 66.5019 406.65 66.5419 407.3H79.9518C78.9318 404.44 80.1419 401.18 80.1419 401.18Z"
        stroke=""
        style={{
          fill: currentPartId === "r8" ? SynckitColors.Yellow[200] : "",
        }}
        id="r8"
      />
      <path
        d="M68.3885 419.76C68.7185 423.16 69.5586 431.04 69.5586 431.04C70.0286 436.45 73.3985 447.77 73.4985 448.4C73.5985 449.03 74.0186 449.54 74.0186 449.54C74.1986 449.87 74.9685 451.66 74.9685 451.66L77.4885 459.33C77.8185 460.15 78.4186 459.14 78.4186 459.14C78.9286 458.56 80.0685 459.63 80.0685 459.63L81.9985 462.64C82.2985 463.24 82.3285 463.18 82.3285 463.18C83.3285 463.23 83.4286 461.39 83.4286 461.39C83.7986 461.34 84.0386 461.16 84.0386 461.16C85.2286 460.34 83.9685 457.34 83.9685 457.34C83.3885 456.57 83.7385 456.08 83.7385 456.08C84.5785 456.31 84.8185 455.28 84.8185 455.28C85.1585 453.64 82.8485 450.74 82.8485 450.74C82.4985 450.18 82.0486 449.06 82.0486 449.06C81.6286 446.9 80.3385 444.83 80.2285 444.59C80.1185 444.34 79.5685 443.19 79.5685 443.19C79.4685 441.69 78.8685 437.53 78.8685 437.53C78.4485 436.06 78.6586 435.75 78.6586 435.75C79.4986 434.77 79.8086 436.59 79.8086 436.59C80.8086 441.11 82.4685 440.51 82.4685 440.51C85.1485 440.04 84.5985 434.18 84.5985 434.18C84.5285 433.55 84.4286 431.7 84.4286 431.7C84.8486 429.04 84.2886 423.03 84.2886 423.03C84.6386 412.96 80.8585 408.98 80.8585 408.98C80.4485 408.47 80.1685 407.91 79.9685 407.35H66.5586C66.6886 409.4 67.2485 410.99 67.2485 410.99C67.5485 412.72 68.3885 419.76 68.3885 419.76Z"
        stroke=""
        style={{
          fill: currentPartId === "r9" ? SynckitColors.Yellow[200] : "",
        }}
        id="r9"
      />
      <path
        d="M100.51 474.77C100.98 481.48 104.61 503.3 104.61 503.3C104.83 504.83 104.95 506.36 105 507.84H135.37C135.37 507.46 135.36 507.14 135.33 506.89C135.14 504.79 136.54 498.08 136.54 498.08C136.54 498.08 140.64 478.87 141.57 476.17C142.5 473.47 145.53 457.76 146.84 453.37C148.14 448.99 150.1 432.86 150.1 432.86C150.29 431.51 150.41 430.29 150.48 429.17H99.9702C99.3802 436.67 99.1002 442.42 99.1002 442.42C98.6402 452.39 100.51 474.77 100.51 474.77Z"
        stroke=""
        style={{
          fill: currentPartId === "r10" ? SynckitColors.Yellow[200] : "",
        }}
        id="r10"
      />

      <path
        d="M231.239 309.51C230.889 303.15 228.719 282.94 228.719 282.94C228.159 277.34 229.209 266.79 229.209 266.79C231.659 251.97 228.579 242.11 228.579 242.11C225.499 225.89 214.659 221.13 214.659 221.13C213.649 220.36 211.629 219.39 209.489 218.48C208.569 230.87 205.919 263.01 202.039 280.69C202.459 280.92 202.699 281.96 202.699 281.96L208.719 305.31C209.349 307.83 212.979 328.8 212.979 328.8C213.019 329.1 213.059 329.37 213.099 329.65H234.639C233.239 323.08 231.239 309.51 231.239 309.51Z"
        stroke=""
        style={{
          fill: currentPartId === "r14" ? SynckitColors.Yellow[200] : "",
        }}
        id="r14"
      />
      <path
        d="M235.301 332.31C235.111 331.7 234.891 330.78 234.651 329.67H213.111C214.001 336.23 213.601 337.25 213.601 337.25C212.731 342.85 212.641 347.79 212.891 351.83H239.851C238.591 343.05 235.301 332.31 235.301 332.31Z"
        stroke=""
        style={{
          fill: currentPartId === "r15" ? SynckitColors.Yellow[200] : "",
        }}
        id="r15"
      />
      <path
        d="M240.699 405.52C241.069 395.59 240.699 391.89 240.699 391.89C240.089 385.52 240.399 376.72 240.399 376.72C241.239 366.44 240.049 353.43 240.049 353.43C239.989 352.91 239.919 352.38 239.839 351.84H212.879C213.319 359.04 214.809 363.36 214.809 363.36L219.279 378.74L225.339 399.02C225.339 399.02 227.329 405.75 227.709 406.25C227.709 406.25 227.809 406.68 227.889 407.31H240.759C240.749 406.23 240.699 405.52 240.699 405.52Z"
        stroke=""
        style={{
          fill: currentPartId === "r16" ? SynckitColors.Yellow[200] : "",
        }}
        id="r16"
      />
      <path
        d="M227.892 407.311C228.012 408.251 228.102 409.651 227.772 410.811C224.082 414.911 224.792 424.421 224.792 424.421C224.702 427.081 224.392 433.991 224.392 433.991C224.202 435.301 224.022 436.491 224.022 436.491C223.642 438.901 224.042 441.541 224.042 441.541C223.952 443.511 226.802 443.111 226.802 443.111C227.202 443.131 227.292 443.351 227.292 443.351C227.252 444.031 227.032 445.531 227.032 445.531C226.922 446.231 226.122 447.651 226.122 447.651C225.072 449.561 224.762 451.141 224.762 451.141C224.742 451.491 224.362 452.101 224.362 452.101C222.922 453.591 222.062 455.341 222.062 455.341C221.082 457.221 221.932 458.021 221.932 458.021C222.822 458.651 224.282 456.331 224.282 456.331C225.292 455.041 226.342 454.681 226.342 454.681C227.212 454.891 225.862 456.641 225.862 456.641C225.032 457.641 223.602 459.701 223.602 459.701C222.262 461.841 223.272 463.561 223.272 463.561C224.162 464.831 225.242 462.741 225.242 462.741C225.832 462.371 225.782 463.011 225.782 463.011C225.532 465.591 226.232 465.771 226.232 465.771C227.282 466.821 229.132 462.441 229.132 462.441C229.702 461.681 230.432 461.951 230.432 461.951C230.942 462.041 231.552 460.741 231.552 460.741C231.812 460.211 232.372 458.621 232.372 458.621C234.332 456.101 235.842 449.391 235.842 449.391C236.312 448.131 236.822 444.341 236.822 444.341C236.802 442.891 237.172 441.541 237.172 441.541C238.152 439.611 238.222 437.621 238.272 436.581C238.322 435.531 238.292 434.321 238.292 434.321C238.762 431.041 239.272 422.641 239.272 422.641C239.202 418.441 239.622 416.141 239.622 416.141C240.652 413.371 240.782 409.531 240.752 407.301H227.892V407.311Z"
        stroke=""
        style={{
          fill: currentPartId === "r17" ? SynckitColors.Yellow[200] : "",
        }}
        id="r17"
      />
      <path
        d="M157.612 434.61C157.612 439.5 163.412 466.22 163.412 466.22C165.792 475.24 170.472 496.08 170.472 496.08C171.752 499.62 172.402 504.16 172.732 507.84H202.102C202.122 507.38 202.142 507.13 202.142 507.13C201.932 503.92 203.892 494.9 203.892 494.9C205.012 492.1 206.342 472.87 206.342 472.87C207.462 466.86 207.882 444.69 207.882 444.69C207.802 440.88 207.342 434.37 206.932 429.18H157.262C157.352 432.19 157.612 434.61 157.612 434.61Z"
        stroke=""
        style={{
          fill: currentPartId === "r18" ? SynckitColors.Yellow[200] : "",
        }}
        id="r18"
      />
    </svg>
  );
};

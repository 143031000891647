import { styled } from "@mui/material";
import React from "react";
import {
  SIDMENU_WIDTH_MD,
  SIDMENU_WIDTH_NORMAL,
  SIDMENU_WIDTH_SM,
} from "../../assets/constant";
import SidemenuNavBG from "../../assets/images/sidemenu_nav_bg.png";
import { SynckitColors } from "../../assets/SynckitColors";
import SideMenu from "./SideMenu";

const SideMenuRoot = styled("div")(({ theme }) => ({
  background: SynckitColors.Primary[600],
  position: "fixed",
  top: 0,
  left: 0,
  backgroundImage: `url(${SidemenuNavBG})`,
  width: SIDMENU_WIDTH_NORMAL,
  height: "100%",
  overflowY: "auto",
  overflowX: "hidden",
  boxShadow:
    " 0px 31.8937px 27.4641px rgba(9, 55, 98, 0.02025), 0px 12.6px 10.2375px rgba(9, 55, 98, 0.015), 0px 2.75625px 3.64219px rgba(9, 55, 98, 0.00975)",
  zIndex: 888,
  transition: "0.3s",
  [theme.breakpoints.down("md")]: {
    width: SIDMENU_WIDTH_MD,
  },
  [theme.breakpoints.down("sm")]: {
    width: SIDMENU_WIDTH_SM,
    "&.toggle": {
      width: SIDMENU_WIDTH_MD,
      boxShadow: "0 6px 15px rgb(0 0 0 / 4%), 0 6px 6px rgb(0 0 0 / 4%)",
    },
  },
}));
export default function SideMenuIndex({ sideMenuToggle, setSideMenuToggle }) {
  return (
    <SideMenuRoot className={sideMenuToggle ? "toggle" : null}>
      <SideMenu
        sideMenuToggle={sideMenuToggle}
        setSideMenuToggle={setSideMenuToggle}
      />
    </SideMenuRoot>
  );
}

import { Button } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BasicInfoForm from "../add-new-patient/BasicInfoForm";
import MedicalRecord from "../add-new-patient/MedicalRecord";
import ResidenceInfoForm from "../add-new-patient/ResidenceInfoForm";
import CheckIcon from "@mui/icons-material/Check";
import DeleteDialog from "../patient/profile/DeleteDialog";
export default function EditPatientIndex() {
  const navigate = useNavigate();

  return (
    <>
      <Box sx={{ textAlign: "right", pt: 2, pb: 2 }}>
        <Button
          startIcon={<CheckIcon />}
          variant="secondary"
          sx={{ width: 136, mr: 1 }}
          onClick={() => navigate(-1)}
        >
          Save
        </Button>

        <DeleteDialog />
      </Box>
      <BasicInfoForm />
      <ResidenceInfoForm />
      <MedicalRecord />
    </>
  );
}

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import React from "react";
import { SynckitColors } from "../../assets/SynckitColors";
import { SynckitDialog } from "./SynckitDialog";
import CloseIcon from "@mui/icons-material/Close";

const CustomButton = styled(Button)({
  borderRadius: 100,
  width: "50%",
  border: `1px solid ${SynckitColors.Gray[700]}`,
  color: SynckitColors.Gray[700],
  "&.cancel": {
    background: SynckitColors.Error["Dark"],
    color: "white",
    border: "none",
  },
});
export default function ConfirmDialog({
  open,
  setOpen,
  title,
  content,
  confirmBtnTitle,
  confirmBtnHandleClick,
  cancelBtnTitle = "Cancel",
}) {
  return (
    <SynckitDialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle sx={{ padding: 1, textAlign: "right" }}>
        <IconButton onClick={() => setOpen(false)}>
          <CloseIcon sx={{ fill: SynckitColors.Gray[600] }} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "0px 56px" }}>
        <Typography variant="h2" sx={{ marginBottom: 2 }}>
          {title}
        </Typography>
        <Typography variant="h6">{content}</Typography>
      </DialogContent>
      <DialogActions sx={{ padding: "30px 56px" }}>
        <CustomButton onClick={() => setOpen(false)}>
          {cancelBtnTitle}
        </CustomButton>
        &nbsp; &nbsp;
        <CustomButton className="cancel" onClick={confirmBtnHandleClick}>
          {confirmBtnTitle}
        </CustomButton>
      </DialogActions>
    </SynckitDialog>
  );
}
